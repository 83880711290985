var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "progress-bloc" }, [
    _c("h2", [_vm._v("Ma progression")]),
    _vm.nbQuestionnaries === 0
      ? _c("div", { staticClass: "grey-text-bloc" }, [
          _c("p", { staticClass: "title" }, [
            _vm._v(" Vous n'avez fait aucun test... "),
          ]),
          _c("p", [
            _vm._v(
              " Réalisez une première session pour commencer à suivre votre progression ou consultez les fiches de révision pour renforcer vos connaissances. "
            ),
          ]),
        ])
      : _c("div", [
          _c("div", { staticClass: "progress" }, [
            _c("div", { staticClass: "score-bloc" }, [
              _c("p", { staticClass: "score" }, [
                _vm._v(" " + _vm._s(_vm.nbQuestionnaries) + " "),
              ]),
              _c("p", { staticClass: "annotation" }, [
                _vm._v(" session"),
                _vm.nbQuestionnaries > 1 ? _c("span", [_vm._v("s")]) : _vm._e(),
                _c("br"),
                _vm._v(" de test "),
              ]),
            ]),
            _vm.nbQuestionnaries > 1
              ? _c("div", { staticClass: "score-bloc" }, [
                  _c(
                    "p",
                    {
                      staticClass: "score",
                      class: _vm.statusColorCSS(_vm.averageScore),
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm._f("round")(_vm.averageScore)) + " "
                      ),
                    ]
                  ),
                  _c("p", { staticClass: "annotation" }, [
                    _c("strong", [_vm._v("Score moyen")]),
                    _c("br"),
                    _vm.nbQuestionnaries > _vm.nbQuestionnariesOkToPass
                      ? _c("span", [
                          _vm._v(
                            "sur les " +
                              _vm._s(_vm.nbQuestionnariesOkToPass) +
                              " derniers tests"
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ])
              : _vm._e(),
          ]),
          _c("div", { staticClass: "grey-text-bloc" }, [
            _c("div", { staticClass: "last-scores-bloc" }, [
              _c("p", { staticClass: "title" }, [
                _vm._v(" Mes "),
                _vm.nbQuestionnaries > 1 &&
                _vm.nbQuestionnaries > _vm.nbQuestionnariesOkToPass
                  ? _c("span", [_vm._v(_vm._s(_vm.nbQuestionnariesOkToPass))])
                  : _vm._e(),
                _vm._v(" "),
                _vm.nbQuestionnaries > _vm.nbQuestionnariesOkToPass
                  ? _c("span", [_vm._v("derniers")])
                  : _vm._e(),
                _vm._v(" résulats "),
              ]),
              _c(
                "div",
                { staticClass: "last-scores" },
                _vm._l(
                  _vm.lastSevenQuestionnaries,
                  function (questionnary, index) {
                    return _c(
                      "div",
                      { key: "ques" + index, staticClass: "score-ring" },
                      [
                        _c(
                          "progress-ring",
                          {
                            attrs: {
                              radius: 25,
                              stroke: 4,
                              progress: _vm.calculateProgress(
                                questionnary.score
                              ),
                              color:
                                _vm.calculateProgress(questionnary.score) >=
                                _vm.minScorePercentage
                                  ? "#25c368"
                                  : "#e71212",
                            },
                          },
                          [
                            _c(
                              "p",
                              {
                                staticClass: "score",
                                class: _vm.statusColorCSS(questionnary.score),
                              },
                              [_vm._v(" " + _vm._s(questionnary.score) + " ")]
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  }
                ),
                0
              ),
            ]),
            _vm.userMature
              ? _c(
                  "div",
                  [
                    _c("p", { staticClass: "title" }, [
                      _vm._v(" Vous êtes prêt ! "),
                    ]),
                    _c("p", [
                      _vm._v(
                        " Vos résultats sont prometteurs, vous devriez être capable de réussir le test d'évaluation RGE ! "
                      ),
                    ]),
                    _vm.canPassExam
                      ? _c(
                          "router-link",
                          {
                            staticClass: "button rdv-button",
                            attrs: { to: "/dates-examen" },
                          },
                          [_vm._v(" Prendre un rendez-vous ")]
                        )
                      : _vm.userType === "customer" && _vm.$store.state.examDate
                      ? _c("p", [
                          _c("strong", [
                            _vm._v(
                              "Vous avez fait une demande d'inscription à la session d'examen du " +
                                _vm._s(
                                  _vm._f("formatDate")(
                                    _vm.$store.state.examDate.date
                                  )
                                )
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _c("p", [
                      _vm._v(
                        " Une fois devenu RGE, n'hésitez pas à revenir tester vos connaissances ou consulter vos fiches de révision de temps en temps. "
                      ),
                    ]),
                  ],
                  1
                )
              : _vm.userStatus === "keep-going"
              ? _c("div", [
                  _c("p", { staticClass: "title" }, [_vm._v(" Continuez ! ")]),
                  _c("p", [
                    _vm._v(
                      " Afin d'aborder l’examen sereinement, continuez à vous entrainer jusqu’à obtenir des résultats corrects sur plusieurs sessions. "
                    ),
                  ]),
                ])
              : _vm.userStatus === "progress"
              ? _c("div", [
                  _c("p", { staticClass: "title" }, [
                    _vm._v(" Ce n’est pas encore ça... "),
                  ]),
                  _c("p", [
                    _vm._v(
                      " Vos résultats ne sont pas encore assez bons pour passer l'examen en toute sérénité. Concentrez vos efforts sur la lecture des fiches de révision pour progresser plus rapidement. "
                    ),
                  ]),
                ])
              : _vm.userStatus === "almost"
              ? _c("div", [
                  _c("p", { staticClass: "title" }, [
                    _vm._v(" Encore un effort, on y est presque ! "),
                  ]),
                  _c("p", [
                    _vm._v(
                      " Votre assiduité porte ses fruits mais vos résultats ne sont pas encore stables. Faites quelques sessions et aidez-vous des fiches de révision pour continuer de vous améliorer. "
                    ),
                  ]),
                ])
              : _vm._e(),
          ]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }