var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "about-rge-advantages" }, [
    _c(
      "div",
      { staticClass: "title-wrapper" },
      [
        _c("image-title", {
          attrs: {
            title: "Les avantages et coûts de la qualification&nbsp;RGE",
            image: require("../../assets/pictos/scale.svg"),
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "steps-wrapper" },
      [
        _c("drawer", {
          scopedSlots: _vm._u([
            {
              key: "head",
              fn: function (slotProps) {
                return [
                  _c("drawer-head-image", {
                    attrs: {
                      label: "Les avantages d'être qualifié RGE",
                      "is-open": slotProps.isOpen,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "image",
                          fn: function () {
                            return [
                              _c("icon-theme-colored", {
                                attrs: {
                                  "icon-inline": require("@/assets/pictos/thumb-up.svg?inline"),
                                },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                ]
              },
            },
            {
              key: "content",
              fn: function () {
                return [
                  _c("div", { staticClass: "content" }, [
                    _c("div", { staticClass: "grey-wrapper" }, [
                      _c("p", { staticClass: "title" }, [
                        _vm._v("Réaliser des devis attractifs"),
                      ]),
                      _c("p", [
                        _vm._v(
                          " en faisant bénéficier à vos clients d’aides financières "
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "grey-wrapper" }, [
                      _c("p", { staticClass: "title" }, [
                        _vm._v("Maintenir / Augmenter son Chiffre d’Affaires"),
                      ]),
                      _c("p", [_vm._v(" en valorisant votre savoir faire ")]),
                    ]),
                    _c("div", { staticClass: "grey-wrapper" }, [
                      _c("p", { staticClass: "title" }, [
                        _vm._v("Gagner en visibilité"),
                      ]),
                      _c("p", [
                        _vm._v(" en étant référencé sur "),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "https://france-renov.gouv.fr/",
                              target: "_blank",
                              rel: "noopener",
                            },
                          },
                          [_vm._v("le site de l’État")]
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "grey-wrapper" }, [
                      _c("p", { staticClass: "title" }, [
                        _vm._v(
                          "Utiliser les services liés à la rénovation énergétique :"
                        ),
                      ]),
                      _vm.isEnseignePP
                        ? _c("p", [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: "https://www.pointp.fr/services-pour-les-professionnels/renoprim-obtenez-des-primes-pour-vos-chantiers",
                                  target: "_blank",
                                  rel: "noopener",
                                },
                              },
                              [_vm._v("Rénoprim")]
                            ),
                            _vm._v(" / "),
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: "https://www.pointp.fr/services-pour-les-professionnels/cap-renov-votre-stimulateur-de-renovation-energetiques",
                                  target: "_blank",
                                  rel: "noopener",
                                },
                              },
                              [_vm._v("Cap Rénov")]
                            ),
                            _vm._v(" / "),
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: "https://www.pointp.fr/services-pour-les-professionnels/solu-chiffrez-simplement-et-rapidement-vos-chantiers-de-0",
                                  target: "_blank",
                                  rel: "noopener",
                                },
                              },
                              [_vm._v("Solu+")]
                            ),
                          ])
                        : _vm.isEnseigneCedeo
                        ? _c("p", [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: "https://www.cedeo.fr/ma-prime-facile",
                                  target: "_blank",
                                  rel: "noopener",
                                },
                              },
                              [_vm._v("Ma prime facile")]
                            ),
                            _vm._v(" / "),
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: "https://www.cedeo.fr/simulateur-renovation-energetique-caprenov",
                                  target: "_blank",
                                  rel: "noopener",
                                },
                              },
                              [_vm._v("Cap Rénov + Cap Rénov Aides")]
                            ),
                            _vm._v(" / "),
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: "https://www.cedeo.fr/configurateur-chantier-solu",
                                  target: "_blank",
                                  rel: "noopener",
                                },
                              },
                              [_vm._v("Solu+")]
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        }),
        _c("drawer", {
          scopedSlots: _vm._u([
            {
              key: "head",
              fn: function (slotProps) {
                return [
                  _c("drawer-head-image", {
                    attrs: {
                      label: "Les coûts pour devenir RGE",
                      "is-open": slotProps.isOpen,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "image",
                          fn: function () {
                            return [
                              _c("icon-theme-colored", {
                                attrs: {
                                  "icon-inline": require("@/assets/pictos/coin-stack.svg?inline"),
                                },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                ]
              },
            },
            {
              key: "content",
              fn: function () {
                return [
                  _c("div", { staticClass: "content cost-content" }, [
                    _c("div", { staticClass: "flex-wrapper or-connector" }, [
                      _c("div", { staticClass: "grey-wrapper" }, [
                        _c("p", { staticClass: "title" }, [
                          _vm._v(" Formation FEEBat "),
                        ]),
                        _c("div", { staticClass: "white-wrapper" }, [
                          _c("p", { staticClass: "sub-title" }, [
                            _vm._v("660 € H.T"),
                          ]),
                          _c("p", [_vm._v("100 % remboursable")]),
                        ]),
                      ]),
                      _c("div", { staticClass: "grey-wrapper" }, [
                        _c("p", { staticClass: "title" }, [
                          _vm._v(" Examen en candidat libre "),
                        ]),
                        _c("div", { staticClass: "white-wrapper" }, [
                          _c("p", { staticClass: "sub-title" }, [
                            _vm._v("149 € H.T"),
                          ]),
                          _c("p", [_vm._v("non remboursable")]),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "spliter" }),
                    _c("div", { staticClass: "grey-wrapper" }, [
                      _c("p", { staticClass: "title" }, [
                        _vm._v(" Formation technique "),
                      ]),
                      _c("div", { staticClass: "white-wrapper" }, [
                        _c("p", { staticClass: "sub-title" }, [
                          _vm._v("entre 400 € et 650 € H.T"),
                        ]),
                        _c("p", [_vm._v("selon l’industriel")]),
                      ]),
                    ]),
                    _c("p", [
                      _vm._v(" Obligatoire pour "),
                      _c(
                        "a",
                        {
                          attrs: {
                            href: "/tout-savoir-rge-parcours/formation-technique",
                          },
                        },
                        [_vm._v("certaines qualifications")]
                      ),
                      _vm._v(" et recommandées pour toutes les autres "),
                    ]),
                    _c("div", { staticClass: "spliter" }),
                    _c("div", { staticClass: "grey-wrapper" }, [
                      _c("p", { staticClass: "title" }, [
                        _vm._v(" Dossier de qualification RGE "),
                      ]),
                      _c("div", { staticClass: "white-wrapper" }, [
                        _c("p", { staticClass: "sub-title" }, [
                          _vm._v("280 € H.T"),
                        ]),
                        _c("p", [_vm._v("pour 1 qualification")]),
                      ]),
                      _c("br"),
                      _c("p", { staticClass: "title" }, [
                        _vm._v(" Aide au montage de dossier RGE (facultatif) "),
                      ]),
                      _c("div", { staticClass: "white-wrapper" }, [
                        _c("p", { staticClass: "sub-title" }, [
                          _vm._v("275 € H.T"),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "spliter" }),
                    _c("div", { staticClass: "enseigne-wrapper" }, [
                      _c("p", { staticClass: "title" }, [
                        _vm._v(" Coût total pour 1 qualification "),
                      ]),
                      _c("p", { staticClass: "sub-title" }, [
                        _vm._v(" à partir de 555 € H.T "),
                      ]),
                    ]),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }