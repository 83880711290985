<template>
  <div class="drawer-head-image" :class="{ open: isOpen}">
    <div class="left-side">
      <div class="img-wrapper">
        <slot name="image">
          <img :src="image">
        </slot>
      </div>
      <p class="label">{{ label }}</p>
    </div>
    <transition name="fade">
      <div v-if="!isOpen" class="plus">+</div>
      <div v-else class="plus">-</div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'DrawerHeadImage',
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    image: {
      type: String,
      required: false
    }
  }
}
</script>

<style lang="scss">
.drawer-head-image {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 70px;
  border: 1px solid #c6c6c6;
  padding: 15px;
  box-sizing: border-box;
  .left-side {
    display: flex;
    align-items: center;
    .img-wrapper {
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 20px;
    }
    .label {
      font-size: 1.08rem;
      font-weight: 900;
      text-align: left;
      padding-right: 20px;
    }
  }
  .plus {
    transform: scale(3);
    margin-right: 10px;
    @include themify($themes) { color: themed('backgroundColor'); }
  }
  &.open {
    border-width: 2px;
    border-color: #000;
  }
}
</style>
