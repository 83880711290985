<template>
  <div class="check-buttons">
    <label v-for="choice in choices"
      :key="choice.name"
      :for="name + choice.name"
      :data-e2e="name + '_' + choice.name"
      class="check-button"
      :class="{ checked: isChecked(choice.name), disabled: choice.locked }"
    >
      <input type="checkbox"
        :id="name + choice.name"
        :disabled="choice.locked"
        :name="choice.name + name"
        :value="choice.name"
        :checked="isChecked(choice.name)"
        @click="setValue(choice.name)"
      >
        <span class="checkbox" :class="{ checked: isChecked(choice.name) }">
          <img class="tick" src="../assets/pictos/tick.svg">
        </span>
        <span class="label">{{ choice.label }}</span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'CheckButtons',
  props: {
    choices: {
      type: Array,
      required: true
    },
    value: {
      type: Array,
      required: true
    },
    name: {
      type: String,
      required: true
    }
  },
  methods: {
    isChecked (name) {
      return this.value.includes(name)
    },
    setValue (valueName) {
      let values = [...this.value]
      if (this.isChecked(valueName)) {
        values = values.filter(choice => choice !== valueName)
      } else {
        values.push(valueName)
      }
      this.$emit('input', values)
    }
  }
}
</script>

<style lang="scss" scoped>
.check-buttons {
  display: flex;
  flex-direction: column;
  gap: 20px;
  .check-button {
    display: flex;
    align-items: center;
    cursor: pointer;
    input {
      display: none;
    }
    .checkbox {
      width: 26px;
      height: 26px;
      border-radius: 4px;
      border: 1px solid #000;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 15px;
      flex-shrink: 0;
      .tick {
        display: none;
      }
    }
    .label {
      font-size: 0.85rem;
      font-weight: 900;
      text-align: left;
    }
    &.checked {
      .checkbox {
        .tick {
          display: block;
        }
      }
    }
  }
}
</style>
