const Questionnary = {
  calculateScore (questions, answers) {
    let score = 0
    questions.forEach((question) => {
      if (question.correctAnswers.every(ans => answers[question.id].includes(ans)) &&
        answers[question.id].length === question.correctAnswers.length) {
        score++
      }
    })
    return score
  }
}

export default Questionnary
