const corrections = [
  {
    name: 'rs-b1-1',
    content: 'Le phénomène de « paroi froide » est un <strong>inconfort</strong> ressenti par l’occupant d’un local, en saison hivernale, lorsque les parois du local ont une température de <strong>surface</strong> significativement plus <strong>froide</strong> que la température de l’air du local (air chauffé à la température de confort). Pour qu’un local soit <strong>confortable en hiver</strong>, il faut donc que la température de ses <strong>parois</strong> soit la plus proche possible (donc <strong>chaude</strong>) de la température de son air intérieur.'
  },
  {
    name: 'rs-b1-2',
    content: 'L’inertie thermique d’un matériau est sa capacité à stocker et restituer de l’énergie thermique (la chaleur) dans sa masse. Plus le matériau est « massique » plus il pourra stocker d’énergie, donc plus sa densité est importante, plus son inertie sera importante.'
  },
  {
    name: 'rs-b1-3',
    content: 'Mis à part l’habillement et le métabolisme, il y a 4 critères qui ont une influence sur le confort thermique d’une personne dans un local : la température de l’air, la température des parois, l’humidité relative de l’air (pourcentage d’humidité) et la vitesse de l’air (mouvement d’air).'
  },
  {
    name: 'rs-b1-4',
    content: 'Dans une paroi, dès qu’il y a une rupture ou un affaiblissement de l’isolation cela créé un pont thermique intégré. Une ossature métallique pour maintenir le parement par exemple peut générer d’importants ponts thermiques intégrés : jusqu’à 50% de perte d’efficacité de l’isolant !'
  },
  {
    name: 'rs-b1-5',
    content: 'L’inertie thermique d’un bâtiment dépend de l’inertie de ses parois. Plus ces parois ont de l’inertie, plus le bâtiment a de l’inertie. Donc potentiellement en augmentant l’épaisseur des murs, on peut augmenter l’inertie du bâtiment.'
  },
  {
    name: 'rs-b1-6',
    content: 'L’isolation thermique par l’extérieur (ITE) est plus efficace que l’isolation thermique par l’intérieur (ITI) car elle permet de limiter les ponts thermiques importants tels que les ponts thermiques de plancher intermédiaire.'
  },
  {
    name: 'rs-b1-7',
    content: 'Dans une paroi, lorsqu’il y a des ponts thermiques intégrés, cela dégrade ses performances. Par exemple : une ossature métallique pour maintenir le parement peut générer d’importants ponts thermiques intégrés et dégrader jusqu’à 50% l’efficacité de l’isolant !'
  },
  {
    name: 'rs-b1-8',
    content: 'Un pont thermique est une jonction entre deux parois dont au moins une est déperditive. Souvent l’isolation ne peut pas y être continue.'
  },
  {
    name: 'rs-b1-9',
    content: 'Un pont thermique est une jonction entre deux parois dont au moins une est déperditive. On en trouve à de nombreux endroits dans les bâtiments : <br>' +
      '<ul>' +
      '<li>Jonction mur extérieur plancher haut, intermédiaire ou bas (nez de plancher)</li>' +
      '<li>Jonction mur extérieur refend</li>' +
      '<li>Jonction mur extérieur menuiserie</li>' +
      '<li>…</li>' +
      '</ul>' +
      'On considère des ponts thermiques aussi bien pour les parois donnant sur l’extérieur que pour les parois donnant sur un local non chauffé.'
  },
  {
    name: 'rs-b2-1',
    content: 'L’agence de l’environnement et de la maîtrise de l’énergie (ADEME) estime qu’en moyenne, 1°C de plus sur la consigne de chauffage en hiver engendre 7% d’augmentation de la consommation d’énergie. De la même manière, 1°C de moins sur la consigne de climatisation en été, engendre 14 % d’augmentation de la consommation d’énergie. Ces valeurs sont des moyennes constatées pour des bâtiments pas ou peu isolés.'
  },
  {
    name: 'rs-b2-2',
    content: 'Une protection solaire est toujours plus efficace si elle est placée à l’extérieur de la menuiserie : pour se protéger du soleil, il vaut mieux arrêter son rayonnement avant qu’il ne traverse le vitrage.'
  },
  {
    name: 'rs-b2-3',
    content: 'Chauffage par convection = chauffage de l’air qui se diffuse par son mouvement (l’air chaud monte, l’air froid descend). Exemple : Convecteur électrique, ventilo-convecteur, radiateur haute température …\n' +
      'Chauffage par rayonnement = C’est le rayonnement infrarouge des émetteurs chaud qui se diffuse. Exemple : Plancher chauffant, plafond chauffant, …'
  },
  {
    name: 'rs-b2-4',
    content: 'La solution la plus efficace pour améliorer le confort d’été dans un logement est l’installation de protection/occultation solaire extérieure sur les parois vitrées. L’amélioration de l’étanchéité à l’air, l’augmentation de l’isolation ou l’installation de grilles de ventilation n’auront qu’un faible effet voire un effet négatif sur le confort d’été par rapport à l’installation d’occultations extérieures.'
  },
  {
    name: 'rs-b2-5',
    content: 'Facteur solaire d’une paroi = quantité d\'énergie traversant la paroi / l\'énergie solaire incidente reçu sur cette paroi. On utilise généralement ce coefficient pour les menuiseries.'
  },
  {
    name: 'rs-b3-1',
    content: 'Les apports gratuits peuvent être de deux types : internes ou externes.<br>' +
      'Les apports externes sont principalement les apports solaires à travers les parois vitrées. Les apports internes sont les différentes chaleurs dégagées par les occupants, l’électroménager, la production de chaleur, l’informatique, la bureautique, le stockage de l’Eau Chaude Sanitaire, …'
  },
  {
    name: 'rs-b3-2',
    content: 'Le Lambda (λ) = conductivité thermique du matériau = capacité à transmettre/conduire la chaleur.<br>' +
      'Plus le Lambda (λ) est petit, plus le matériau est isolant.'
  },
  {
    name: 'rs-b3-3',
    content: 'Une laine minérale courante (laine de verre, laine de roche) présente un lambda (λ) compris entre 0,03 et 0,04. Cela dépendra du processus de fabrication.'
  },
  {
    name: 'rs-b3-4',
    content: 'Déperditions totales d’un bâtiment = Déperditions par les parois (surfaciques + linéiques) + Déperditions par renouvellement d’air (ventilation + infiltrations).'
  },
  {
    name: 'rs-b4-1',
    content: 'Le coefficient de transmission surfacique d’une paroi est noté U. C’est l’inverse de la résistance thermique totale de la paroi (U = 1/R<sub style="font-size: 0.5rem">totale paroi</sub>). U est donc inversement proportionnel à la résistance thermique totale de la paroi.'
  },
  {
    name: 'rs-b4-2',
    content: 'Déperditions par renouvellement d\'air = déperditions par la ventilation + déperditions par les infiltrations d\'air à travers l\'enveloppe.'
  },
  {
    name: 'rs-b4-3',
    content: 'La résistance thermique d’un matériau (R en m².K/W) se calcule en divisant son épaisseur (e en mètre) par sa conductivité thermique (λ en W/m.K). R = e/λ'
  },
  {
    name: 'rs-b4-4',
    content: 'Le vent créé des phénomènes de pression/dépression sur les bâtiments et de ce fait, il accentue les fuites d’air à travers l’enveloppe des bâtiments.'
  },
  {
    name: 'rs-b4-5',
    content: 'En hiver, la meilleure exposition pour une baie afin de profiter au mieux des apports solaires, est le Sud.'
  },
  {
    name: 'rs-b5-confort-ete-1',
    content: 'La densité est indépendante de la résistance thermique. Plus un isolant est dense, plus il ralentira le transfert de chaleur et améliorera ainsi le confort d’été.'
  },
  {
    name: 'rs-b5-confort-ete-2',
    content: 'La toiture est la paroi qui est la plus exposée au rayonnement solaire direct. La qualité d’isolation thermique impacte donc fortement le confort d’été.'
  },
  {
    name: 'rs-b5-confort-ete-3',
    content: 'La résistance thermique seule ne garantit pas le déphasage qui est une propriété essentielle pour le confort d’été. Il faut privilégier des matériaux isolants denses.'
  },
  {
    name: 'rs-b5-confort-ete-4',
    content: 'La réglementation thermique dans l’existant impose de maintenir ou remplacer les protections solaires existantes lors des travaux de rénovations des menuiseries extérieures.'
  },
  {
    name: 'rs-b5-confort-ete-5',
    content: 'Lors de l’installation ou du remplacement d’un système de climatisation, la réglementation thermique dans l’existant impose que les baies non orientées au nord soient équipées de protections solaires.'
  },
  {
    name: 'rs-b5-confort-ete-6',
    content: 'Les protections solaires extérieures diminuent fortement le facteur solaire de la baie équipée et sont plus efficaces que les protections intérieures.'
  },
  {
    name: 'rs-b5-1',
    content: 'Le tirage thermique correspond au phénomène physique de la convection. La convection est un phénomène qui prend place dans un fluide (air, eau, …). Il se traduit par le fait que les fluides chauds montent et les fluides froids descendent.'
  },
  {
    name: 'rs-b5-2',
    content: 'Le principe des VMC hygroréglables (type A ou Type B) c’est de faire varier les débits d’extraction et/ou d’entrée d’air en fonction du taux d’humidité dans les locaux. Le taux d’humidité variant en fonction de la quantité de vapeur d’eau émise par les occupants.<br>' +
      'Le principe des VMC autoréglables est de maintenir un débit constant.'
  },
  {
    name: 'rs-b5-3',
    content: 'Ce qui caractérise la capacité d\'un matériau à résister au passage de la vapeur d\'eau, c’est le coefficient Sd qui s’exprime en mètre de lame d’air équivalente (m). Plus ce coefficient est grand, moins il laisse passer la vapeur d’eau.'
  },
  {
    name: 'rs-b5-4',
    content: 'On considère qu’un plancher de combles perdus commence à être bien isolé lorsqu’il présente une résistance thermique R de 5 m².K/W, soit un coefficient de transmission surfacique U de 0,2 W/m².K, ou encore 20 cm d’épaisseur d’isolation standard (λ = 0.04 m.K/W).'
  },
  {
    name: 'rs-b5-5',
    content: 'La raison principale pour installer une ventilation dans un logement est la raison sanitaire.'
  },
  {
    name: 'rs-b6-position-isolant-1',
    content: 'La position de l’isolant thermique dans la paroi impacte son inertie et donc le confort thermique à l’intérieur du local.'
  },
  {
    name: 'rs-b6-position-isolant-2',
    content: 'L’isolation thermique par l’extérieur favorise l’inertie de la paroi vers l’intérieur du local et donc le confort des usagers.'
  },
  {
    name: 'rs-b6-1',
    content: 'ACERMI = Association pour la certification des matériaux isolants. C’est un label qui concerne exclusivement les matériaux isolants du bâtiment, il certifie les différentes caractéristiques des matériaux (résistance thermique, résistance à l’eau, caractéristiques mécaniques, …).'
  },
  {
    name: 'rs-b6-2',
    content: 'Le marquage CE sur un produit signifie que le produit est conforme à la réglementation et aux normes européennes qui le concerne.'
  },
  {
    name: 'rs-b6-3',
    content: 'La VMC double flux est un système qui permet de récupérer les calories de l\'air vicié pour réchauffer l\'air neuf.'
  },
  {
    name: 'rs-b6-4',
    content: 'Le logo ACERMI sur un matériau isolant signifie qu’il a été contrôlé et certifié par l’Association pour la Certification des Matériaux Isolants qui est un organisme indépendant.'
  }
]

export default corrections
