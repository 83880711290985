<template>
  <div class="revision">
    <h2 class="title">
      Fiches de révision
    </h2>
    <div class="revisions-list">
      <div v-for="revision in revisions"
        :key="'revision' + revision.id"
        class="revision">
        <collapsable>
          <template v-slot:head>
            <div class="revision-head">
              <h3>{{ revision.label }}</h3>
              <p class="counter">
                {{ removeDuplicates(revision.fiches).length }}
              </p>
            </div>
          </template>
          <template v-slot:content>
            <router-link v-for="fiche in removeDuplicates(revision.fiches)"
               :key="fiche.name"
               :href="`${baseUrl}${fiche.name}.pdf`"
               :to="{ name:'fiche de revision', params: { ficheName: fiche.name }}"
               class="doc-link">
              {{ fiche.label }}
            </router-link>
          </template>
        </collapsable>
      </div>
    </div>
  </div>
</template>

<script>
import revisionsA from '../datas/categorie-A/revisions.js'
import revisionsB from '../datas/categorie-B/revisions.js'
import revisionsC from '../datas/categorie-C/revisions.js'
import revisionsD from '../datas/categorie-D/revisions.js'
import revisionsE from '../datas/categorie-E/revisions.js'
import revisionsF from '../datas/categorie-F/revisions.js'
import revisionsG from '../datas/categorie-G/revisions.js'
import revisionsH from '../datas/categorie-H/revisions.js'
import revisionsI from '../datas/categorie-I/revisions.js'
import Collapsable from '../components/Collapsable'

const revisions = [
  {
    id: 'a',
    label: 'Connaître le contexte et les enjeux',
    fiches: [...revisionsA]
  },
  {
    id: 'b',
    label: 'Comprendre le fonctionnement thermique d’un bâtiment',
    fiches: [...revisionsB]
  },
  {
    id: 'c',
    label: 'Connaitre le contexte réglementaire',
    fiches: [...revisionsC]
  },
  {
    id: 'd',
    label: 'Savoir repérer les principaux risques',
    fiches: [...revisionsD]
  },
  {
    id: 'e',
    label: 'Connaitre les principales technologies clés',
    fiches: [...revisionsE]
  },
  {
    id: 'f',
    label: 'Démontrer les intérêts d\'une évaluation thermique',
    fiches: [...revisionsF]
  },
  {
    id: 'g',
    label: 'Savoir interpréter une évaluation énergétique',
    fiches: [...revisionsG]
  },
  {
    id: 'h',
    label: 'Connaitre les scénarios de rénovation',
    fiches: [...revisionsH]
  },
  {
    id: 'i',
    label: 'Être capable d\'expliquer le bouquet de travaux retenu',
    fiches: [...revisionsI]
  }
]

export default {
  name: 'Revision',
  components: {
    Collapsable
  },
  data () {
    return {
      revisions: revisions
    }
  },
  computed: {
    baseUrl () {
      return this.$store.state.revisionsBaseUrl
    }
  },
  methods: {
    removeDuplicates (arr) {
      return arr.reduce((accumulator, current) => {
        if (accumulator.filter(fiche => fiche.label === current.label).length > 0) {
          return accumulator
        } else {
          return [...accumulator, current]
        }
      }, [])
    }
  }
}
</script>

<style lang=scss>
.revision {
  padding: $spacer-md;
  h2 {
    font-size: 1.2rem;
    text-align: left;
    font-weight: 900;
    margin: 0 0 $spacer 0;
  }

  .revisions-list {
    .revision-head {
      display: flex;
      align-items: center;
      h3 {
        text-align: left;
      }
      .counter {
        flex-shrink: 0;
        font-size: 0.7rem;
        margin-left: 10px;
        background-color: #000;
        color: #fff;
        width: 22px;
        height: 22px;
        text-align: center;
        line-height: 23px;
        border-radius: 50%;
      }
    }
    .doc-link + .doc-link {
      margin-top: $spacer-md;
    }
  }
  .return-button {
    margin-top: $spacer;
  }
}
</style>
