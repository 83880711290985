const questions = [
  {
    id: 'i1-1',
    category: 'i',
    subject: 'i1',
    label: 'Le procès-verbal de réception des travaux ne peut être signé qu’après la levée de toutes les réserves émises par le client\u00A0:',
    fullExplanation: 'ra-i1-1',
    simpleExplanation: 'rs-i1-1',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'i1-2',
    category: 'i',
    subject: 'i1',
    label: 'Le procès-verbal de réception des travaux doit être signé dès la fin des travaux\u00A0:',
    fullExplanation: 'ra-i1-1',
    simpleExplanation: 'rs-i1-1',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'i1-3',
    category: 'i',
    subject: 'i1',
    label: 'Le procès-verbal de réception des travaux ne peut pas contenir de réserves émises par le client\u00A0:',
    fullExplanation: 'ra-i1-1',
    simpleExplanation: 'rs-i1-1',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'i1-4',
    category: 'i',
    subject: 'i1',
    label: 'Quelle est la meilleure attitude après l\'envoi d\'un devis\u00A0?',
    fullExplanation: 'ra-i1-2',
    simpleExplanation: 'rs-i1-2',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'Ne surtout pas relancer le client (s\'il ne répond pas au devis, c\'est qu\'il n\'est pas intéressé ou qu\'il a trouvé moins cher ailleurs)'
      },
      {
        name: 'b',
        label: 'Attendre deux mois, puis reprendre contact avec le client pour savoir pourquoi il n\'a pas donné suite au devis'
      },
      {
        name: 'c',
        label: 'Reprendre contact dans les deux semaines avec le client pour s\'assurer qu\'il a bien reçu le devis et en discuter'
      }
    ]
  },
  {
    id: 'i1-5',
    category: 'i',
    subject: 'i1',
    label: 'Après l\'envoi d\'un devis\u00A0:',
    fullExplanation: 'ra-i1-2',
    simpleExplanation: 'rs-i1-2',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'Je ne relance pas le client car s\'il ne répond pas au devis, c\'est qu\'il n\'est pas intéressé ou qu\'il a trouvé moins cher ailleurs.'
      },
      {
        name: 'b',
        label: 'J’attends au moins deux mois avant de reprendre contact avec le client pour savoir pourquoi il n\'a pas donné suite au devis'
      },
      {
        name: 'c',
        label: 'Je reprends contact avec le client dans les deux semaines pour m\'assurer qu\'il a bien reçu le devis et en discuter'
      }
    ]
  },
  {
    id: 'i1-6',
    category: 'i',
    subject: 'i1',
    label: 'Pour avoir plus de chance de pouvoir expliquer le devis que l’on a envoyé à un client, la meilleure attitude à avoir, est d’attendre deux mois avant de reprendre contact avec le client\u00A0?',
    fullExplanation: 'ra-i1-2',
    simpleExplanation: 'rs-i1-2',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'i1-7',
    category: 'i',
    subject: 'i1',
    label: 'Suite à l\'envoi d\'un devis, je dois reprendre contact avec le client dans les deux semaines pour m’assurer de la bonne réception du devis et pouvoir l’expliquer si le client a des questions\u00A0:',
    fullExplanation: 'ra-i1-2',
    simpleExplanation: 'rs-i1-2',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'i1-8',
    category: 'i',
    subject: 'i1',
    label: 'Que marque la signature du procès-verbal de réception des travaux\u00A0?',
    fullExplanation: 'ra-i1-3',
    simpleExplanation: 'rs-i1-3',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'L\'acquittement de la facture'
      },
      {
        name: 'b',
        label: 'Le début des garanties légales (assurance décennale...)'
      },
      {
        name: 'c',
        label: 'L\'autorisation de facturer'
      }
    ]
  },
  {
    id: 'i1-9',
    category: 'i',
    subject: 'i1',
    label: 'La signature du procès-verbal de réception des travaux déclenche le début des garanties légales (assurance décennale...)\u00A0?',
    fullExplanation: 'ra-i1-3',
    simpleExplanation: 'rs-i1-3',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'i1-10',
    category: 'i',
    subject: 'i1',
    label: 'Pour que les garanties légales (assurance décennale...) soient déclenchées, il faut que le procès-verbal de réception des travaux soit signé\u00A0:',
    fullExplanation: 'ra-i1-3',
    simpleExplanation: 'rs-i1-3',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'i1-11',
    category: 'i',
    subject: 'i1',
    label: 'Les garanties légales (assurance décennale...) prennent effet à partir\u00A0:',
    fullExplanation: 'ra-i1-3',
    simpleExplanation: 'rs-i1-3',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'De la signature des marchés'
      },
      {
        name: 'b',
        label: 'De la signature du procès-verbal de réception'
      },
      {
        name: 'c',
        label: 'De la déclaration d’achèvement des travaux'
      }
    ]
  },
  {
    id: 'i1-12',
    category: 'i',
    subject: 'i1',
    label: 'Quand débutent les garanties légales des travaux réalisés\u00A0?',
    fullExplanation: 'ra-i1-4',
    simpleExplanation: 'rs-i1-4',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Dès que le client a payé les travaux '
      },
      {
        name: 'b',
        label: 'Dès signature du PV de réception '
      },
      {
        name: 'c',
        label: 'Dès que les travaux sont terminés'
      }
    ]
  },
  {
    id: 'i1-13',
    category: 'i',
    subject: 'i1',
    label: 'La signature du PV de réception déclenche-t-elle le début des garanties légales des travaux réalisés\u00A0?',
    fullExplanation: 'ra-i1-4',
    simpleExplanation: 'rs-i1-4',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Oui'
      },
      {
        name: 'b',
        label: 'Non'
      }
    ]
  },
  {
    id: 'i1-14',
    category: 'i',
    subject: 'i1',
    label: 'Le début des garanties légales des travaux réalisés commence dès la signature du PV de réception\u00A0:',
    fullExplanation: 'ra-i1-4',
    simpleExplanation: 'rs-i1-4',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'i1-15',
    category: 'i',
    subject: 'i1',
    label: 'Pour que les garanties légales des travaux réalisés puissent débuter, il faut que\u00A0:',
    fullExplanation: 'ra-i1-4',
    simpleExplanation: 'rs-i1-4',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'La déclaration d’achèvement des travaux soit signée'
      },
      {
        name: 'b',
        label: 'Le PV de réception des travaux soit signé '
      },
      {
        name: 'c',
        label: 'Les factures des travaux soient payées'
      }
    ]
  },
  {
    id: 'i1-16',
    category: 'i',
    subject: 'i1',
    label: 'L’assurance dommage-ouvrage prend effet\u00A0:',
    fullExplanation: 'ra-i1-5',
    simpleExplanation: 'rs-i1-5',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'Dès la fin des travaux'
      },
      {
        name: 'b',
        label: 'Dès la signature du PV de réception'
      },
      {
        name: 'c',
        label: 'Une année après la réception des travaux'
      }
    ]
  },
  {
    id: 'i1-17',
    category: 'i',
    subject: 'i1',
    label: 'L’assurance dommage-ouvrage prend effet seulement un an après la réception des travaux\u00A0:',
    fullExplanation: 'ra-i1-5',
    simpleExplanation: 'rs-i1-5',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'i1-18',
    category: 'i',
    subject: 'i1',
    label: 'La prise d’effet de l’assurance dommage-ouvrage intervient\u00A0:',
    fullExplanation: 'ra-i1-5',
    simpleExplanation: 'rs-i1-5',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'Dès la signature de la déclaration d’achèvement des travaux'
      },
      {
        name: 'b',
        label: 'Dès la signature du PV de réception des travaux réalisés'
      },
      {
        name: 'c',
        label: 'Une année après la réception des travaux'
      }
    ]
  },
  {
    id: 'i1-19',
    category: 'i',
    subject: 'i1',
    label: 'Quelle assurance prend effet un an après la réception des travaux\u00A0?',
    fullExplanation: 'ra-i1-5',
    simpleExplanation: 'rs-i1-5',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'L’assurance décennale'
      },
      {
        name: 'b',
        label: 'L’assurance de parfait achèvement'
      },
      {
        name: 'c',
        label: 'L’assurance dommage-ouvrage'
      }
    ]
  },
  {
    id: 'i1-20',
    category: 'i',
    subject: 'i1',
    label: 'L’audit réalisé dans le cadre de l’obtention de la qualification RGE peut s’effectuer\u00A0:',
    fullExplanation: 'ra-i1-6',
    simpleExplanation: 'rs-i1-6',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Par téléphone'
      },
      {
        name: 'b',
        label: 'Sur un chantier que vous avez réalisé (en votre présence)'
      },
      {
        name: 'c',
        label: 'Sur pièces (si le dossier est complet : fiche contact client, évaluation thermique, devis détaillé, photos, PV de réception, facture, fiche satisfaction ou attestation de travaux, notices et garanties)'
      }
    ]
  },
  {
    id: 'i1-21',
    category: 'i',
    subject: 'i1',
    label: 'Pour obtenir la qualification RGE, un audit de l’un de vos projets de rénovation énergétique doit être réalisé. Celui-ci peut être réalisé\u00A0:',
    fullExplanation: 'ra-i1-6',
    simpleExplanation: 'rs-i1-6',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'A distance, par une réunion téléphonique'
      },
      {
        name: 'b',
        label: 'Sur le chantier qui a été réalisé et en présence du référent technique'
      },
      {
        name: 'c',
        label: 'Simplement en envoyant le dossier complet à l’organisme de certification (fiche contact client, évaluation thermique, devis détaillé, …)'
      }
    ]
  },
  {
    id: 'i1-22',
    category: 'i',
    subject: 'i1',
    label: 'L’obtention de la qualification RGE est subordonnée à la réalisation d’un audit sur le chantier, et en votre présence, des travaux qui ont été réalisés pour la réalisation d’un projet de rénovation énergétiques\u00A0:',
    fullExplanation: 'ra-i1-6',
    simpleExplanation: 'rs-i1-6',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'i1-23',
    category: 'i',
    subject: 'i1',
    label: 'Une entreprise peut-elle obtenir la qualification RGE sans faire procéder à la réalisation d’un audit sur l’un de ses chantiers de travaux de rénovation énergétiques qui ont été réalisés\u00A0?',
    fullExplanation: 'ra-i1-6',
    simpleExplanation: 'rs-i1-6',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Oui'
      },
      {
        name: 'b',
        label: 'Non'
      }
    ]
  }
]

export default questions
