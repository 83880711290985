var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
        _vm.loadingExams
          ? _c("div", { key: "loading", staticClass: "loading" }, [
              _c("img", {
                attrs: {
                  src: require("../assets/loading.gif"),
                  alt: "image de chargement",
                },
              }),
            ])
          : _vm.loadingExamsError
          ? _c("div", { key: "error-content" }, [
              _c("p", [_vm._v(" " + _vm._s(_vm.loadingExamsError) + " ")]),
            ])
          : _c("div", [
              _vm.examen
                ? _c("div", { staticClass: "examen-info" }, [
                    _c("span", { staticClass: "date" }, [
                      _c("img", {
                        attrs: {
                          src: require("../assets/pictos/calendar-orange.svg"),
                        },
                      }),
                      _vm._v(
                        " Date d'examen : " +
                          _vm._s(_vm._f("formatDate")(_vm.examen.date)) +
                          " "
                      ),
                    ]),
                    _c("span", { staticClass: "date" }, [
                      _c("img", {
                        attrs: {
                          src: require("../assets/pictos/calendar-orange.svg"),
                        },
                      }),
                      _vm._v(
                        " Date limite d’inscription : " +
                          _vm._s(
                            _vm._f("formatDate")(
                              _vm.examen.date_limit_inscription
                            )
                          ) +
                          " "
                      ),
                    ]),
                    _c("span", { staticClass: "location" }, [
                      _c("img", {
                        attrs: {
                          src: require("../assets/pictos/location.svg"),
                        },
                      }),
                      _vm._v(" " + _vm._s(_vm.examen.location) + " "),
                    ]),
                  ])
                : _vm._e(),
              _vm.examDate === null
                ? _c("div", { staticClass: "important-info" }, [
                    _c("span", { staticClass: "title" }, [
                      _vm._v(" Information importante "),
                    ]),
                    _c("p", { staticClass: "details" }, [
                      _vm._v(
                        " Merci de renseigner les informations de la personne qui va passer l'examen."
                      ),
                      _c("br"),
                      _vm._v(" Le candidat doit faire partie de la société. "),
                    ]),
                  ])
                : _vm._e(),
              _vm.examReservationError
                ? _c("div", { staticClass: "important-info error" }, [
                    _c("span", { staticClass: "title" }, [_vm._v(" Erreur ")]),
                    _c("p", { staticClass: "details" }, [
                      _vm._v(
                        " " + _vm._s(_vm.examReservationErrorMessage) + " "
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm.demoMode
                ? _c(
                    "p",
                    {
                      staticStyle: {
                        "margin-top": "1em",
                        "font-size": "0.8rem",
                      },
                    },
                    [
                      _vm._v(
                        " Environnement de démonstration : l'inscription ne sera pas prise en compte. "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.examDate === null
                ? _c("form", { staticClass: "inscription-form" }, [
                    _c(
                      "div",
                      {
                        staticClass: "form",
                        class: { error: _vm.firstName.error },
                      },
                      [
                        _c("label", { attrs: { for: "firstName" } }, [
                          _vm._v("Prénom*"),
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.firstName.value,
                              expression: "firstName.value",
                            },
                          ],
                          attrs: {
                            type: "text",
                            name: "firstName",
                            id: "firstName",
                          },
                          domProps: { value: _vm.firstName.value },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.firstName,
                                "value",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm.firstName.help
                          ? _c("div", { staticClass: "question-help" }, [
                              _vm._v(" " + _vm._s(_vm.firstName.help) + " "),
                            ])
                          : _vm._e(),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "form",
                        class: { error: _vm.lastName.error },
                      },
                      [
                        _c("label", { attrs: { for: "lastName" } }, [
                          _vm._v("Nom*"),
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.lastName.value,
                              expression: "lastName.value",
                            },
                          ],
                          attrs: {
                            type: "text",
                            name: "lastName",
                            id: "lastName",
                          },
                          domProps: { value: _vm.lastName.value },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.lastName,
                                "value",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm.lastName.help
                          ? _c("div", { staticClass: "question-help" }, [
                              _vm._v(" " + _vm._s(_vm.lastName.help) + " "),
                            ])
                          : _vm._e(),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "form",
                        class: { error: _vm.phone.error },
                      },
                      [
                        _c("label", { attrs: { for: "phone" } }, [
                          _vm._v("Téléphone*"),
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.phone.value,
                              expression: "phone.value",
                            },
                          ],
                          attrs: {
                            type: "tel",
                            name: "phone",
                            id: "phone",
                            pattern: "/[0-9+]$/g",
                          },
                          domProps: { value: _vm.phone.value },
                          on: {
                            input: [
                              function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.phone,
                                  "value",
                                  $event.target.value
                                )
                              },
                              _vm.acceptNumber,
                            ],
                          },
                        }),
                        _vm.phone.help
                          ? _c("div", { staticClass: "question-help" }, [
                              _vm._v(" " + _vm._s(_vm.phone.help) + " "),
                            ])
                          : _vm._e(),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "form",
                        class: { error: _vm.email.error },
                      },
                      [
                        _c("label", { attrs: { for: "email" } }, [
                          _vm._v("Email*"),
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.email.value,
                              expression: "email.value",
                            },
                          ],
                          attrs: { type: "email", name: "email", id: "email" },
                          domProps: { value: _vm.email.value },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(_vm.email, "value", $event.target.value)
                            },
                          },
                        }),
                        _vm.email.help
                          ? _c("div", { staticClass: "question-help" }, [
                              _vm._v(" " + _vm._s(_vm.email.help) + " "),
                            ])
                          : _vm._e(),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "button", on: { click: _vm.checkForm } },
                      [_vm._v("Réserver")]
                    ),
                  ])
                : _c("div", { staticClass: "important-info validation" }, [
                    _c("p", [
                      _vm._v(
                        "Vous avez effectué une inscription pour cet examen le "
                      ),
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm._f("formatDate")(_vm.examDate.inscription.date)
                          )
                        ),
                      ]),
                      _vm._v("."),
                    ]),
                    _c("p", [
                      _c("br"),
                      _vm._v("Votre inscription est "),
                      _c("strong", [
                        _vm._v(_vm._s(_vm.examDate.inscription.status)),
                      ]),
                    ]),
                    _vm.examDate.inscription.status === "En attente de paiement"
                      ? _c("span", { staticClass: "payment" }, [
                          _c("br"),
                          _c("p", [
                            _vm._v(
                              "Plus qu'une étape avant la réservation de votre examen :"
                            ),
                          ]),
                          _c(
                            "a",
                            {
                              staticClass: "button",
                              attrs: { href: _vm.payementLink },
                            },
                            [_vm._v("Régler mon examen")]
                          ),
                        ])
                      : _vm._e(),
                  ]),
              _c("span", { staticClass: "info-rgpd" }, [
                _c("h2", [_vm._v("Inscription à l'examen")]),
                _c("p", [
                  _vm._v(
                    "PIA Production SAS traite vos données pour vous incrire à l'examen de la certification RGE."
                  ),
                ]),
                _c(
                  "p",
                  [
                    _vm._v(
                      "Pour en savoir plus sur la manière dont nous gérons vos données à caractère personnel, veuillez consulter la rubrique "
                    ),
                    _c(
                      "router-link",
                      {
                        staticClass: "link",
                        attrs: { to: "/vos-donnees-et-vos-droits" },
                      },
                      [_vm._v("Vos données et vos droits")]
                    ),
                    _vm._v("."),
                  ],
                  1
                ),
                _c("p", [
                  _vm._v(
                    "Les données personnelles renseignées dans ce formulaire seront communiquées à ATEE avec pour objectif d’assurer l’amélioration de la qualité des produits et services proposés, d’établir des statistiques commerciales et/ou de lui adresser des sollicitations commerciales de ATEE, de ses filiales et/ou de leurs partenaires commerciaux qui pourront accéder aux informations qui le concernent dans ce cadre."
                  ),
                ]),
              ]),
            ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }