var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "rgpd" },
    [
      _c("h2", [_vm._v("Vos données et vos droits")]),
      _c("p", [
        _vm._v(
          "Nous attachons une grande importance au respect de votre vie privée et à la protection de vos données. Nous nous engageons ainsi à assurer le meilleur niveau de protection à vos données personnelles et à les traiter de manière loyale et transparente, conformément aux réglementations européennes et françaises applicables en matière de protection des données personnelles. Vous trouverez ainsi sur cette page l’ensemble des notices d’information relatives aux traitements de données que nous réalisons ainsi que nos politiques relatives à la gestion des cookies et Privacy."
        ),
      ]),
      _c("h2", [_vm._v("Notices d'information")]),
      _c("p", [
        _vm._v("Retrouvez ici la liste et le détail de tous nos traitements :"),
      ]),
      _c("Accordion", {
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function () {
              return [
                _vm._v(
                  " Notice d'information : Création et gestion de votre compte "
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "content",
            fn: function () {
              return [
                _c("p", [
                  _vm._v(
                    "La présente notice a pour objet de vous apporter l’ensemble des éléments d’information prévus par la règlementation applicable et, en particulier, par le Règlement européen Général sur la protection des données (« RGPD »)."
                  ),
                ]),
                _c("h2", [
                  _vm._v(
                    "Identité et coordonnées du responsable du traitement :"
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "PIA Production SAS Le Forum – Bâtiment B 27 rue Maurice Flandin 69003 LYON Représentée par : Monsieur Florent Buffin"
                  ),
                ]),
                _c("h2", [_vm._v("Objet du traitement :")]),
                _c("ul", [
                  _c("li", [_vm._v("Finalité(s) :")]),
                  _vm._v(" Ce traitement a été mis en place afin de : "),
                  _c("ul", [
                    _c("li", [
                      _vm._v(
                        "Créer un compte pour l'accès à l'entrainement à l'examen de la certification RGE"
                      ),
                    ]),
                    _c("li", [
                      _vm._v("Garantir l'unicité de l'accès à l'entrainement"),
                    ]),
                  ]),
                  _c("li", [_vm._v("Base(s) juridique(s) du traitement :")]),
                  _vm._v(
                    " Ce traitement est réalisé dans le cadre de l’exécution d’un contrat, pour vous offrir un accès complet à nos services en ligne. "
                  ),
                ]),
                _c("h2", [_vm._v("Destinataire(s) :")]),
                _c("p", [
                  _vm._v(
                    "Les informations liées à votre demande et les données personnelles permettant de vous contacter sont communiquées uniquement à nos services internes afin de vous apporter une réponse correspondant à vos besoins."
                  ),
                ]),
                _c("h2", [_vm._v("Catégorie(s) de données concernées :")]),
                _c("p", [
                  _vm._v(
                    "Pour effectuer ce traitement, les données suivantes sont utilisées :"
                  ),
                ]),
                _c("ul", [
                  _c("li", [
                    _vm._v(
                      "Des données identifiantes : Nom, prénom, email, raison sociale de l’entreprise, numéro de téléphone, adresse"
                    ),
                  ]),
                ]),
                _c("h2", [_vm._v("Durée(s) de conservation des données :")]),
                _c("p", [
                  _vm._v(
                    "Nous ne conserverons vos informations personnelles que pendant la durée strictement nécessaire aux finalités énoncées précédemment ou pour satisfaire à nos obligations légales ou en vue d'exercer, de défendre ou de faire valoir nos droits."
                  ),
                ]),
                _c("h2", [_vm._v("Droits des personnes :")]),
                _c("p", [
                  _vm._v(
                    "Vous pouvez exercer l’ensemble des droits suivants sur vos données personnelles :"
                  ),
                ]),
                _c("ul", [
                  _c("li", [_vm._v("Droit d’accès ;")]),
                  _c("li", [_vm._v("Droit de rectification ;")]),
                  _c("li", [_vm._v("Droit à l’effacement ;")]),
                  _c("li", [_vm._v("Droit à la limitation du traitement ;")]),
                  _c("li", [_vm._v("Droit d’opposition ;")]),
                  _c("li", [_vm._v("Droit à la portabilité.")]),
                ]),
                _c("p", [
                  _vm._v(
                    "Si vous estimez, après nous avoir contactés, que vos droits ne sont pas respectés ou que le présent traitement de données n’est pas conforme aux règles de protection des données, vous disposez du droit d’introduire une réclamation auprès de la "
                  ),
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "https://www.cnil.fr/fr/plaintes",
                        target: "_blank",
                      },
                    },
                    [
                      _vm._v(
                        "Commission Nationale de l’Informatique et des Libertés (CNIL)"
                      ),
                    ]
                  ),
                  _vm._v(
                    ". Pour exercer ces droits ou pour toute question sur le traitement de vos données par notre société, consultez la rubrique « Comment exercer vos droits »."
                  ),
                ]),
                _c("h2", [_vm._v("Information(s) complémentaire(s)")]),
                _c("p", [
                  _vm._v(
                    "Nous vous rappelons que conformément aux dispositions de l’article 40-1 de la loi 78-17 du 6 janvier 1978, vous disposez du droit de formuler des directives générales (auprès d’un tiers de confiance numérique certifié par la CNIL) ou particulières (auprès du responsable de traitement) relatives à la conservation, à l’effacement et à la communication de vos données à caractère personnel après votre décès."
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("Accordion", {
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function () {
              return [_vm._v(" Notice d'information : Inscription à l'examen ")]
            },
            proxy: true,
          },
          {
            key: "content",
            fn: function () {
              return [
                _c("p", [
                  _vm._v(
                    "La présente notice a pour objet de vous apporter l’ensemble des éléments d’information prévus par la règlementation applicable et, en particulier, par le Règlement européen Général sur la protection des données (« RGPD »)."
                  ),
                ]),
                _c("h2", [
                  _vm._v(
                    "Identité et coordonnées du responsable du traitement :"
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "PIA Production SAS Le Forum – Bâtiment B 27 rue Maurice Flandin 69003 LYON Représentée par : Monsieur Florent Buffin"
                  ),
                ]),
                _c("h2", [_vm._v("Objet du traitement :")]),
                _c("ul", [
                  _c("li", [_vm._v("Finalité(s) :")]),
                  _vm._v(" Ce traitement a été mis en place afin de : "),
                  _c("ul", [
                    _c("li", [
                      _vm._v(
                        "S'inscrire à l'examen de la certification RGE auprès de l'APEE"
                      ),
                    ]),
                    _c("li", [
                      _vm._v("Garantir l'unicité de l'inscription à l'examen"),
                    ]),
                  ]),
                  _c("li", [_vm._v("Base(s) juridique(s) du traitement :")]),
                  _vm._v(
                    " Ce traitement est réalisé dans le cadre de l’exécution d’un contrat, pour vous offrir un accès complet à nos services en ligne. "
                  ),
                ]),
                _c("h2", [_vm._v("Destinataire(s) :")]),
                _c("p", [
                  _vm._v(
                    "Les informations liées à votre demande et les données personnelles permettant de vous contacter sont communiquées uniquement à nos services internes afin de vous apporter une réponse correspondant à vos besoins."
                  ),
                ]),
                _c("h2", [_vm._v("Catégorie(s) de données concernées :")]),
                _c("p", [
                  _vm._v(
                    "Pour effectuer ce traitement, les données suivantes sont utilisées :"
                  ),
                ]),
                _c("ul", [
                  _c("li", [
                    _vm._v(
                      "Des données identifiantes : Nom, prénom, email, raison sociale de l’entreprise, numéro de téléphone, adresse"
                    ),
                  ]),
                ]),
                _c("h2", [_vm._v("Durée(s) de conservation des données :")]),
                _c("p", [
                  _vm._v(
                    "Nous ne conserverons vos informations personnelles que pendant la durée strictement nécessaire aux finalités énoncées précédemment ou pour satisfaire à nos obligations légales ou en vue d'exercer, de défendre ou de faire valoir nos droits."
                  ),
                ]),
                _c("h2", [_vm._v("Droits des personnes :")]),
                _c("p", [
                  _vm._v(
                    "Vous pouvez exercer l’ensemble des droits suivants sur vos données personnelles :"
                  ),
                ]),
                _c("ul", [
                  _c("li", [_vm._v("Droit d’accès ;")]),
                  _c("li", [_vm._v("Droit de rectification ;")]),
                  _c("li", [_vm._v("Droit à l’effacement ;")]),
                  _c("li", [_vm._v("Droit à la limitation du traitement ;")]),
                  _c("li", [_vm._v("Droit d’opposition ;")]),
                  _c("li", [_vm._v("Droit à la portabilité.")]),
                ]),
                _c("p", [
                  _vm._v(
                    "Si vous estimez, après nous avoir contactés, que vos droits ne sont pas respectés ou que le présent traitement de données n’est pas conforme aux règles de protection des données, vous disposez du droit d’introduire une réclamation auprès de la "
                  ),
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "https://www.cnil.fr/fr/plaintes",
                        target: "_blank",
                      },
                    },
                    [
                      _vm._v(
                        "Commission Nationale de l’Informatique et des Libertés (CNIL)"
                      ),
                    ]
                  ),
                  _vm._v(
                    " et des Libertés (CNIL). Pour exercer ces droits ou pour toute question sur le traitement de vos données par notre société, consultez la rubrique « Comment exercer vos droits »."
                  ),
                ]),
                _c("h2", [_vm._v("Information(s) complémentaire(s)")]),
                _c("p", [
                  _vm._v(
                    "Nous vous rappelons que conformément aux dispositions de l’article 40-1 de la loi 78-17 du 6 janvier 1978, vous disposez du droit de formuler des directives générales (auprès d’un tiers de confiance numérique certifié par la CNIL) ou particulières (auprès du responsable de traitement) relatives à la conservation, à l’effacement et à la communication de vos données à caractère personnel après votre décès."
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("h2", [_vm._v("Charte des données personnelles")]),
      _c("h3", [_vm._v("De quoi s’agit-il ?")]),
      _c("p", [
        _vm._v(
          "Chez PIA Production, le respect de la vie privée représente un enjeu majeur et nous nous engageons à la protéger. Conformément au Règlement Général sur la Protection des Données (« RGPD » ou « GDPR » en anglais), cette politique a pour but de préciser quand, pourquoi et comment nous :"
        ),
      ]),
      _vm._m(0),
      _c("p", [
        _vm._v(
          "Dans le cadre de la présente politique et sauf indication contraire, l’emploi des mots « nous », « notre » ou « notre Société » fait référence à PIA Production. Le RGPD a catégorisé les entreprises selon deux types majeurs, les responsables de traitement et les sous-traitants, définis selon leur position dans la chaîne de collecte et de circulation des données personnelles. Le responsable de traitement détermine le but, les conditions et les moyens liés au traitement des données personnelles. Le sous-traitant traite les données pour le compte du responsable de traitement. Pour autant, chaque entreprise soumise au RGPD est un peu des deux."
        ),
      ]),
      _c("h3", [_vm._v("Comment cette politique peut évoluer")]),
      _c("p", [
        _vm._v(
          "Cette politique est susceptible d’évoluer périodiquement, vous pourrez consulter la présente page afin de prendre connaissance de la version la plus récente. La dernière mise à jour de cette politique a été réalisée le 22 janvier 2021."
        ),
      ]),
      _c("h3", [_vm._v("La collecte de vos données personnelles")]),
      _c("h4", [_vm._v("Lesquelles ?")]),
      _c("p", [
        _vm._v(
          "Nous pouvons recueillir et traiter les données personnelles suivantes :"
        ),
      ]),
      _vm._m(1),
      _vm._v(
        " Les autres dispositions de la présente politique s’appliquent également à l’ensemble des données personnelles que nous obtenons de ces sources. "
      ),
      _c("h4", [_vm._v("Pourquoi ?")]),
      _c("p", [
        _vm._v(
          "Nous traitons vos données personnelles en toute légalité dans le but :"
        ),
      ]),
      _vm._m(2),
      _c("p", [
        _vm._v(
          "Nous utilisons et traitons vos données personnelles principalement dans les conditions suivantes :"
        ),
      ]),
      _vm._m(3),
      _c("h3", [_vm._v("L’acces à vos données personnelles")]),
      _c("h4", [_vm._v("Nos fournisseurs et prestataires")]),
      _c("p", [
        _vm._v(
          "Nous pouvons communiquer des informations vous concernant à des prestataires externes, agents, sous-traitants et autres organismes pour leur permettre de nous fournir des services ou de vous les fournir directement de notre part. Parmi ces parties tierces on peut citer des prestataires de cloud (notamment pour l’hébergement et la gestion de la messagerie), des services administratifs, des services de traitement des paiements par carte de crédit et de débit, des enquêtes de satisfaction clients ou d’autres parties tierces qui nous fournissent des services et nous assistent dans la réalisation de nos activités et l’exploitation de nos services. Lorsque nous avons recours à des prestataires externes, nous ne divulguons que les données personnelles nécessaires à leur prestation et nous mettons en place un contrat qui prévoit qu’ils assurent la sécurité et la confidentialité des renseignements vous concernant, et qui leur interdit de les utiliser à d’autres fins qui ne seraient pas conformes à nos directives spécifiques."
        ),
      ]),
      _c("h4", [
        _vm._v(
          "Autres moyens pour nous de partager des informations vous concernant"
        ),
      ]),
      _c("p", [_vm._v("Nous pouvons transmettre vos données personnelles :")]),
      _vm._m(4),
      _c("p", [
        _vm._v(
          "Cependant, dans les différents cas énoncés, nous prendrons toujours les mesures appropriées permettant de garantir la protection de vos droits à la vie privée."
        ),
      ]),
      _c("h3", [_vm._v("Le stockage de vos données personnelles")]),
      _c("h4", [_vm._v("Où ?")]),
      _vm._v(
        " Les renseignements vous concernant sont conservés soit dans nos bases de données, soit dans les bases de données de nos prestataires. Principalement dans le but d’optimiser la qualité des services, les informations que vous mettez à notre disposition peuvent, le cas échéant, être transmises à des pays en dehors de l’Espace Économique Européen (EEE). Dans ce cas, nous veillerons à ce que les mesures appropriées de sécurité et de confidentialité soient prises afin de garantir le maintien de la protection de vos droits à la vie privée comme précisé dans cette politique. Parmi ces mesures, il est prévu d’imposer des obligations contractuelles au destinataire de vos données personnelles ou de s’assurer que les destinataires ont souscrit à des « cadres internationaux » visant à assurer une protection suffisante de vos données. "
      ),
      _c("h4", [_vm._v("Comment ?")]),
      _c("p", [
        _vm._v(
          "Nous visons à conserver vos données en toute sécurité à tout moment de leur traitement. Dès la réception de vos données personnelles, nous mettons en place des mesures techniques et organisationnelles appropriées ainsi que différents contrôles afin de prévenir, dans la mesure du possible, toute destruction, perte, altération, divulgation ou accès non autorisé, de manière accidentelle ou illicite."
        ),
      ]),
      _c("h4", [_vm._v("Combien de temps ?")]),
      _c("p", [
        _vm._v(
          "Nous ne conservons vos données personnelles que le temps où elles nous sont nécessaires pour atteindre l’objectif de leur traitement, et pour répondre à nos différentes obligations légales et réglementaires. Sauf cas particulier, cette durée est indiquée au moment de la collecte de vos données personnelles. Nous ne conservons pas de données personnelles sous un format rendant possible l’identification de leur détenteur plus longtemps que ne l’exigent les finalités de leur collecte."
        ),
      ]),
      _c("h2", [_vm._v("L’exercice de vos droits")]),
      _c("p", [
        _vm._v(
          "Sous réserve des législations applicables et de certaines exemptions, restrictions ou circonstances, vous pourrez être en droit :"
        ),
      ]),
      _vm._m(5),
      _c("p", [
        _vm._v(
          'Ces droits peuvent être exercés en transmettant la demande écrite aux interlocuteurs dont les coordonnées sont indiquées dans la rubrique "Comment exercer vos droits". Vous recevrez une réponse dans un délai raisonnable, conformément aux lois applicables.'
        ),
      ]),
      _c("h2", [_vm._v("Comment exercer vos droits")]),
      _vm._m(6),
      _c("p", [
        _vm._v(
          "Données personnelles PIA Production SAS Le Forum – Bâtiment B 27 rue Maurice Flandin 69003 LYON"
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", [
      _c("li", [
        _vm._v(
          "recueillons des données personnelles relatives à une personne physique ;"
        ),
      ]),
      _c("li", [_vm._v("utilisons ces informations ;")]),
      _c("li", [
        _vm._v(
          "communiquons de telles données à un tiers, lorsque cela s’avère nécessaire et dans certaines conditions ;"
        ),
      ]),
      _c("li", [
        _vm._v("assurons la confidentialité de ces données personnelles."),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", [
      _c("li", [
        _vm._v(
          "Les données personnelles que vous nous fournissez : il s’agit de renseignements que vous nous fournissez de votre plein gré en saisissant des informations via :"
        ),
      ]),
      _c("ul", [
        _c("li", [_vm._v("un de nos sites internet ;")]),
        _c("li", [_vm._v("nos applications bureaux et mobiles ;")]),
        _c("li", [
          _vm._v(
            "une correspondance avec nous par voie téléphonique, e-mail ou tout autre moyen."
          ),
        ]),
        _c("p", [
          _vm._v(
            "Exemple : sont concernés, entre autres, des renseignements communiqués à l’occasion :"
          ),
        ]),
        _c("ul", [
          _c("li", [
            _vm._v(
              "de votre création de compte utilisateur sur nos sites internet ;"
            ),
          ]),
          _c("li", [
            _vm._v(
              "de votre abonnement aux services que nous mettons à votre disposition via nos sites internet ;"
            ),
          ]),
          _c("li", [_vm._v("de la demande de services complémentaires ;")]),
          _c("li", [
            _vm._v(
              "ou dans le cadre de la gestion de votre compte en ligne (y compris l’accès à la documentation et la correspondance avec nous par téléphone, e-mail ou autrement)."
            ),
          ]),
          _c("p", [
            _vm._v(
              "Nous sommes également susceptibles de vous demander des renseignements si vous nous signalez un problème avec nos sites internet, ou encore si vous participez à nos études et sondages réalisés à des fins de recherches. Les informations que vous nous communiquez peuvent porter sur votre identification, notamment vos nom, adresse postale, adresse e-mail et numéro de téléphone, des renseignements au sujet de la demande que vous nous auriez adressée, et peuvent également inclure des enregistrements relatifs à nos correspondances ainsi que vos réponses à différents sondages."
            ),
          ]),
        ]),
      ]),
      _c("li", [
        _vm._v(
          "Les données personnelles vous concernant que nous pouvons recueillir automatiquement :"
        ),
      ]),
      _c("ul", [
        _c("li", [
          _vm._v(
            "les informations relatives aux transactions que vous effectuez sur les sites internet, ainsi que vos visites sur nos sites internet, avec, mais sans s’y limiter : les données relatives au trafic, à la localisation, aux Weblogs et toute autre donnée de communication, ainsi que les ressources auxquelles vous accédez ;"
          ),
        ]),
        _c("li", [
          _vm._v(
            "les informations techniques, automatiquement recueillies, y compris les données anonymes recueillies par le serveur d’hébergement à des fins statistiques, l’adresse IP (Internet Protocol) utilisée pour connecter votre appareil à Internet, le type et la version du navigateur, le réglage du fuseau horaire, les types et les versions de plug-in du navigateur, le système d’exploitation et la plateforme. Consultez la partie consacrée aux cookies pour plus d’informations."
          ),
        ]),
      ]),
      _c("li", [
        _vm._v(
          "Les données personnelles que nous pouvons recevoir d’autres sources :"
        ),
      ]),
      _c("ul", [
        _c("li", [
          _vm._v(
            "nous obtenons certaines données personnelles vous concernant de sources extérieures à notre Société, y compris d’autres sociétés tierces."
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", [
      _c("li", [
        _vm._v(
          "d’assurer la communication et/ou l’échange d’information avec les utilisateurs de nos sites internet ;"
        ),
      ]),
      _c("li", [
        _vm._v(
          "de gérer les relations avec nos clients et prospects, notamment dans le cadre du traitement de vos commandes, des réponses à vos demandes ou de l’envoi régulier des actualités et informations relatives à nos produits, nos marques ou nos initiatives susceptibles de susciter votre intérêt ;"
        ),
      ]),
      _c("li", [
        _vm._v(
          "ou dans tout autre but précisé au moment du recueil de vos données."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", [
      _c("li", [
        _vm._v(
          "lorsque vous avez exprimé votre CONSENTEMENT libre et explicite, pour des finalités déterminées, explicites et légitimes. Vous pouvez retirer votre consentement à tout moment en nous contactant."
        ),
      ]),
      _c("li", [
        _vm._v(
          "lorsque le traitement est nécessaire à la poursuite de nos INTÉRÊTS LÉGITIMES en tant qu’entreprise, à condition que ne prévalent pas vos intérêts ou vos libertés et droits fondamentaux compte tenu des attentes raisonnables que vous pouvez escompter au regard de la relation que vous entretenez avec nous. Par exemple, pour les finalités suivantes :"
        ),
      ]),
      _c("ul", [
        _c("li", [
          _vm._v(
            "la réalisation d’actions de marketing direct (différentes de celles basées sur votre consentement) telles que des analyses nous permettant d’affiner notre stratégie commerciale, ainsi que d’améliorer et de personnaliser votre expérience utilisateur ;"
          ),
        ]),
        _c("li", [
          _vm._v(
            "la détection, la prévention et la réduction des fraudes ou d’autres activités illégales ;"
          ),
        ]),
        _c("li", [
          _vm._v(
            "la sécurisation du réseau et de l’information nous permettant de prendre les mesures nécessaires pour protéger vos données des risques de perte ou d’altération, de vol ou d’accès non autorisé."
          ),
        ]),
      ]),
      _c("li", [
        _vm._v(
          "lorsque nous devons RESPECTER UNE OBLIGATION LÉGALE, et notamment :"
        ),
      ]),
      _c("ul", [
        _c("li", [
          _vm._v(
            "pour assister une autorité publique ou un organisme dans le cadre d’une enquête ;"
          ),
        ]),
        _c("li", [
          _vm._v(
            "pour vous identifier dans le cas où vous nous contactez, conformément à certains impératifs d’ordre juridique ;"
          ),
        ]),
        _c("li", [
          _vm._v(
            "pour vérifier l’exactitude des données que nous détenons à votre sujet."
          ),
        ]),
      ]),
      _c("li", [
        _vm._v(
          "lorsque le traitement s’impose dans le cadre de L’EXÉCUTION D’UN CONTRAT qui existe entre nous ou lorsque que vous nous demandez de prendre des mesures particulières avant la conclusion de celui-ci, ce qui inclut notamment le traitement des informations nécessaires pour vous permettre d’utiliser nos sites internet et de bénéficier des services que nous proposons à travers ces derniers."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", [
      _c("li", [
        _vm._v(
          "Dans le cadre d’une cession d’une partie ou de l’intégralité de notre entreprise et de nos actifs à une partie tierce ou dans le cadre d’une restructuration ou d’une réorganisation de l’entreprise ;"
        ),
      ]),
      _c("li", [
        _vm._v(
          "Si nous sommes dans l’obligation de divulguer ou de partager vos renseignements personnels afin de nous conformer à toute obligation légale, assignation, réquisition judiciaire ou réglementation gouvernementale, de déceler ou signaler un crime, d’exécuter ou appliquer les modalités de nos contrats ou de préserver les droits, la propriété ou la sécurité de nos visiteurs et clients ;"
        ),
      ]),
      _c("li", [
        _vm._v(
          "Afin de faire respecter les conditions d’utilisation applicables, pour préserver les droits, dont le droit à la vie privée, la propriété ou la sécurité de nos sociétés ;"
        ),
      ]),
      _c("li", [_vm._v("Si vous avez consenti à cette communication.")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", [
      _c("li", [_vm._v("D’accéder à vos données personnelles ;")]),
      _c("li", [
        _vm._v(
          "De corriger, modifier et mettre à jour vos données personnelles ;"
        ),
      ]),
      _c("li", [
        _vm._v(
          "De supprimer vos données personnelles ou de limiter leur traitement ;"
        ),
      ]),
      _c("li", [_vm._v("De retirer votre consentement ;")]),
      _c("li", [
        _vm._v(
          "De vous opposer à ce que nous utilisions vos données personnelles afin :"
        ),
      ]),
      _c("ul", [
        _c("li", [
          _vm._v("De prendre des décisions automatisées à votre égard ;"),
        ]),
        _c("li", [
          _vm._v(
            "De nous demander de vous transmettre vos données personnelles dans un fichier structuré ou à un prestataire que vous nous indiqueriez si cela est techniquement possible (portabilité des données) ;"
          ),
        ]),
        _c("li", [
          _vm._v(
            "De déposer une plainte auprès de l’autorité de contrôle compétente et de disposer d’un recours juridictionnel."
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v(
        "Si vous avez des questions sur la manière dont nous traitons et utilisons vos données, ou si vous souhaitez exercer l’un quelconque de vos droits Informatique et Libertés, vous pouvez nous contacter à l’adresse suivante "
      ),
      _c(
        "a",
        {
          attrs: {
            href: "mailto:privacycontact.pia-production.fr@saint-gobain.com",
          },
        },
        [_vm._v("privacycontact.pia-production.fr@saint-gobain.com")]
      ),
      _vm._v(" ou nous écrire à l’adresse postale suivante :"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }