<template>
  <div class="how-it-works">
    <div class="grey-text-bloc">
      <b>Entrainez-vous gratuitement</b> au passage de l’examen en réalisant autant de tests que nécessaire.
    </div>
    <div class="grey-text-bloc">
      Les sessions de test contiennent <b>30 questions et doivent être complétées en 1h30</b> pour reproduire les conditions de l’examen.
    </div>
    <div class="grey-text-bloc">
      Une session de test est considérée comme réussie lorsque vous obtenez <b>au moins 25 bonnes réponses</b>.
    </div>
    <div class="grey-text-bloc">
      Pour accéder à la réservation d’une date d’examen en candidat libre, vous devez <b>réussir 7 sessions de test consécutives.</b>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HowItWorks'
}
</script>

<style lang="scss" scoped>
.how-it-works {
  padding: 15px 10px;
  .grey-text-bloc {
    margin-top: 1px;
  }
}
</style>
