var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.isSinglePage
        ? _c("div", { staticClass: "pdf-navigation" }, [
            _c("div", [
              _vm.currentPage !== 1
                ? _c(
                    "div",
                    {
                      on: {
                        click: function ($event) {
                          _vm.currentPage -= 1
                        },
                      },
                    },
                    [_vm._v(" < précédent ")]
                  )
                : _vm._e(),
            ]),
            _c("div", [
              _c("div", { staticClass: "current" }, [
                _vm._v(_vm._s(_vm.currentPage) + " / " + _vm._s(_vm.pageCount)),
              ]),
            ]),
            _c("div", [
              _vm.currentPage !== _vm.pageCount
                ? _c(
                    "div",
                    {
                      on: {
                        click: function ($event) {
                          _vm.currentPage += 1
                        },
                      },
                    },
                    [_vm._v(" suivant > ")]
                  )
                : _vm._e(),
            ]),
          ])
        : _vm._e(),
      _c("pdf", {
        attrs: { src: _vm.pdfUrl, page: _vm.currentPage },
        on: {
          "num-pages": function ($event) {
            _vm.pageCount = $event
          },
          "page-loaded": function ($event) {
            _vm.currentPage = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }