<template>
  <div class="first-login-form">
    <div class="steps-wrapper" v-if="!submitError">
      <div class="step">
        <component :is="currentStep"
          :currentStepIndex="currentStepIndex"
          :nbSteps="steps.length - 1"
          :model="model[currentStep]"
          :fullModel="model"
          v-on:setModel="setModel">
        </component>
      </div>
      <div class="button-wrapper">
        <button class="button next-button" :class="{ disabled: !isCurrentStepValid }" @click="nextStep">valider</button>
      </div>
    </div>
    <p class="technical-error" v-else>
      Une erreur est survenue lors de la sauvegarde de vos données. <br>
      Merci de réessayer ultérieurement.
    </p>
  </div>
</template>

<script>
import User from '@/services/User'
import ReasonStep from '@/views/first-login-form/ReasonStep'
import PrestationsStep from '@/views/first-login-form/PrestationsStep'
import LevelStep from '@/views/first-login-form/LevelStep'
import OutroStep from '@/views/first-login-form/OutroStep'
import * as Sentry from '@sentry/vue'

export default {
  name: 'FirstLoginForm',
  components: {
    reason: ReasonStep,
    prestations: PrestationsStep,
    level: LevelStep,
    outro: OutroStep
  },
  data () {
    return {
      model: {
        reason: '',
        prestations: [],
        level: ''
      },
      currentStepIndex: 0,
      steps: ['reason', 'prestations', 'level', 'outro'],
      submitError: false
    }
  },
  computed: {
    currentStep () {
      return this.steps[this.currentStepIndex]
    },
    isCurrentStepValid () {
      const currentModel = this.model[this.currentStep]
      if (typeof currentModel === 'string') return this.model[this.currentStep] !== ''
      if (Array.isArray(currentModel)) return currentModel.length !== 0
      return true
    }
  },
  methods: {
    nextStep () {
      if (!this.isCurrentStepValid) return

      if (this.currentStepIndex < this.steps.length - 1) {
        this.currentStepIndex++
      } else {
        this.submitFirstLoginForm()
      }
    },
    setModel (value) {
      this.model[this.currentStep] = value
    },
    async submitFirstLoginForm () {
      try {
        await User.postFirstLoginForm({
          prestations: this.model.prestations,
          reason: this.model.reason,
          level: this.model.level
        })
        this.submitError = false
        await this.$store.dispatch('user/getUserData')
        this.$router.push('/')
      } catch (e) {
        console.error(e)
        Sentry.captureException(e)
        this.submitError = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.first-login-form {
  padding: 10px 0;
}
.button-wrapper {
  padding: 0 10px;
}
.next-button {
  @include themify($themes) { background-color: themed('backgroundColor'); }
  color: #fff;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 900;
  width: 100%;
  max-width: 380px;
  padding: 15px;
  margin-top: $spacer;
  cursor: pointer;
  &.disabled {
    background-color: $border-color;
    cursor: default;
  }
}
</style>
