const firstLoginStepMixin = {
  props: {
    model: {
      type: [String, Array],
      required: true
    },
    currentStepIndex: {
      type: Number,
      required: true
    },
    nbSteps: {
      type: Number,
      required: true
    }
  },
  methods: {
    emitModel (value) {
      this.$emit('setModel', value)
    },
    scrollToQuestion () {
      const questionEl = this.$refs.question
      if (questionEl) {
        questionEl.$el.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
      }
    }
  }
}

export default firstLoginStepMixin
