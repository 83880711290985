var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "check-buttons" },
    _vm._l(_vm.choices, function (choice) {
      return _c(
        "label",
        {
          key: choice.name,
          staticClass: "check-button",
          class: {
            checked: _vm.isChecked(choice.name),
            disabled: choice.locked,
          },
          attrs: {
            for: _vm.name + choice.name,
            "data-e2e": _vm.name + "_" + choice.name,
          },
        },
        [
          _c("input", {
            attrs: {
              type: "checkbox",
              id: _vm.name + choice.name,
              disabled: choice.locked,
              name: choice.name + _vm.name,
            },
            domProps: {
              value: choice.name,
              checked: _vm.isChecked(choice.name),
            },
            on: {
              click: function ($event) {
                return _vm.setValue(choice.name)
              },
            },
          }),
          _c(
            "span",
            {
              staticClass: "checkbox",
              class: { checked: _vm.isChecked(choice.name) },
            },
            [
              _c("img", {
                staticClass: "tick",
                attrs: { src: require("../assets/pictos/tick.svg") },
              }),
            ]
          ),
          _c("span", { staticClass: "label" }, [_vm._v(_vm._s(choice.label))]),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }