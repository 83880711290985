var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "reason-step" },
    [
      _c("div", { staticClass: "background-head head" }, [
        _c("div", { staticClass: "logo-wrapper" }, [
          _c("img", { attrs: { src: _vm.orgeLogo, alt: "logo Objectif RGE" } }),
        ]),
        _c("div", { staticClass: "content" }, [
          _c("p", { staticClass: "title" }, [
            _vm._v("Bienvenue " + _vm._s(_vm.userName) + " !"),
          ]),
          _vm._m(0),
        ]),
        _c(
          "div",
          { staticClass: "bottom-arrow", on: { click: _vm.scrollToQuestion } },
          [
            _c("img", {
              attrs: { src: require("../../assets/pictos/bottom-arrow.svg") },
            }),
          ]
        ),
      ]),
      _c("step-question", {
        ref: "question",
        attrs: {
          "current-step-index": _vm.currentStepIndex,
          "nb-steps": _vm.nbSteps,
          title: "Pour quelle raison voulez-vous utiliser OBJECTIF RGE&nbsp;?",
        },
        scopedSlots: _vm._u([
          {
            key: "question",
            fn: function () {
              return [
                _c("radio-buttons", {
                  attrs: {
                    name: "reason",
                    value: _vm.model,
                    choices: _vm.choices,
                  },
                  on: { input: _vm.emitModel },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "intro" }, [
      _vm._v(" Avant de commencer votre entraînement, "),
      _c("b", [
        _vm._v(
          "veuillez répondre aux questions suivantes afin de nous permettre de vous accompagner au mieux"
        ),
      ]),
      _vm._v(" dans votre parcours de qualification. "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }