var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "about-rge-farther" }, [
    _c(
      "div",
      { staticClass: "title-wrapper" },
      [
        _c("image-title", {
          attrs: {
            title: "Se faire accompagner au montage de son dossier RGE",
            image: require("../../assets/pictos/documents.svg"),
          },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "content" }, [
      _vm._v(
        " Le montage administratif du dossier est souvent une étape chronophage et compliquée pour les artisans du Bâtiment. "
      ),
      _c("br"),
      _c("br"),
      _vm._v(" Pour vous aider dans cette étape, "),
      _c("b", [
        _vm._v(
          _vm._s(_vm.enseigneLabel) +
            " propose à ses clients de se faire accompagner par une entreprise spécialisée"
        ),
      ]),
      _vm._v(
        " dans la collecte des pièces administratives et la présentation des dossiers de qualification auprès de Qualibat "
      ),
      _c("br"),
      _c("br"),
      _vm._v(" Pour découvrir ce service, rendez-vous sur : "),
      _c(
        "a",
        {
          attrs: { href: _vm.enseigneLink, rel: "noopener", target: "_blank" },
        },
        [_vm._v(_vm._s(_vm.enseigneLink))]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }