<template>
  <div class="test-questions">
    <div v-for="category in categories" :key="category.name" class="category">
      <h2>{{ category.label }}</h2>
      <div class="questions">
        <div v-for="question in getQuestionsByCategory(category.name)" :key="question.id" class="question">
          <div class="id">
            {{ question.id }}
          </div>
          <div class="label">
            {{ question.label }}
          </div>
          <div class="answers">
            <div v-for="choice in question.choices" :key="question.id + choice.name" class="answer" :class="{ valid: question.correctAnswers.includes(choice.name) }">
              {{ choice.name + ' - ' + choice.label }}
            </div>
          </div>
          <br>
          <div class="correction-name"><b>{{ getCorrectionByName(question.simpleExplanation).name }}</b></div>
          <br>
          <div class="correction" v-html="getCorrectionByName(question.simpleExplanation).content"></div>
          <br>
          <div class="revision-name"><b>{{ getRevisionByName(question.fullExplanation).name }}</b></div>
          <br>
          <a :href="`${revisionsBaseUrl}${getRevisionByName(question.fullExplanation).name}.pdf`" target="_blank" rel="noopener" class="revision">{{ getRevisionByName(question.fullExplanation).label }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import questions from '../datas/questions'
import corrections from '../datas/corrections'
import revisions from '@/datas/revisions'
import { mapState } from 'vuex'

export default {
  name: 'TestQuestions',
  data () {
    return {
      questions: questions,
      corrections: corrections,
      revisions: revisions,
      categories: [
        {
          name: 'a',
          label: 'Connaître le contexte et les enjeux'
        },
        {
          name: 'b',
          label: 'Comprendre le fonctionnement thermique d’un bâtiment'
        },
        {
          name: 'c',
          label: 'Connaitre le contexte réglementaire'
        },
        {
          name: 'd',
          label: 'Savoir repérer les principaux risques'
        },
        {
          name: 'e',
          label: 'Connaitre les principales technologies clés'
        },
        {
          name: 'f',
          label: 'Démontrer les intérêts d\'une évaluation thermique'
        },
        {
          name: 'g',
          label: 'Savoir interpréter une évaluation énergétique'
        },
        {
          name: 'h',
          label: 'Connaitre les scénarios de rénovation'
        },
        {
          name: 'i',
          label: 'Être capable d\'expliquer le bouquet de travaux retenu'
        }
      ]
    }
  },
  computed: {
    ...mapState(['revisionsBaseUrl'])
  },
  methods: {
    getCorrectionByName (name) {
      return this.corrections.filter(correction => correction.name === name)[0]
    },
    getRevisionByName (name) {
      return this.revisions.filter(revision => revision.name === name)[0]
    },
    getQuestionsByCategory (cat) {
      return this.questions.filter(question => question.category === cat)
    }
  }
}
</script>

<style lang="scss">
.test-questions {
  .category {
    .questions {
      text-align: left;
      .question {
        .id {
          font-weight: 700;
        }
        .label {
          font-weight: 500;
          margin: 10px 0;
        }
        .answers {
          .answer {
            &.valid {
              color: #25c368;
            }
          }
        }
      }
      .question + .question {
        margin-top: 20px;
        padding-top: 20px;
        border-top: 1px solid lightgrey;
      }
    }
  }
}
</style>
