<template>
  <div class="icon-themed-colored">
    <div class="icon" v-html="iconInline"></div>
  </div>
</template>

<script>
export default {
  name: 'IconThemeColored',
  props: {
    iconInline: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss">
.icon-themed-colored {
  .icon {
    svg path:not(.stroke) {
      @include themify($themes) { fill: themed('backgroundColor'); }
    }
    svg path.stroke {
      @include themify($themes) { stroke: themed('backgroundColor'); }
    }
  }
}
</style>
