const revisions = [
  {
    name: 'ra-d1-1',
    label: 'Le rôle d’un pare-vapeur'
  },
  {
    name: 'ra-d1-2',
    label: 'Le rôle d’un pare-vapeur'
  },
  {
    name: 'ra-d1-3',
    label: 'Plaque de plâtre - pare-vapeur'
  },
  {
    name: 'ra-d2-1',
    label: 'Origine de l’humidité dans un logement'
  },
  {
    name: 'ra-d2-2',
    label: 'Vapeur d’eau et point de rosé'
  },
  {
    name: 'ra-d2-3',
    label: 'Parois froides et risques de condensation'
  },
  {
    name: 'ra-d2-4',
    label: 'La vapeur d’eau est-elle visible ?'
  },
  {
    name: 'ra-d3-1',
    label: 'Le principe des chaudières à condensation'
  },
  {
    name: 'ra-d3-2',
    label: 'Surdimensionnement du chauffage et consommation'
  },
  {
    name: 'ra-d3-3',
    label: 'Isolant et humidité'
  },
  {
    name: 'ra-d4-1',
    label: 'Isolation et ventilation'
  },
  {
    name: 'ra-d4-2',
    label: 'La ventilation mécanique - Principe'
  },
  {
    name: 'ra-d4-3',
    label: 'Ventilation mécanique double-flux et étanchéité à l’air'
  },
  {
    name: 'ra-d5-1',
    label: 'Infiltration d’air et condensation'
  },
  {
    name: 'ra-d5-2',
    label: 'Réalisation des appuis et pose des menuiseries'
  },
  {
    name: 'ra-d6-1',
    label: 'La caméra thermique (thermographie)'
  },
  {
    name: 'ra-d6-2',
    label: 'Réalisation des appuis et pose des menuiseries'
  }
]

export default revisions
