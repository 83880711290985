import User from '@/services/User'
import QuestionnaryBuilder from '@/services/QuestionnaryBuilder'
import Questionnary from '@/services/Questionnary'

const questionnarySubjects = ['a1', 'a2', 'b1', 'b2', 'b3', 'b4', 'b5', 'b6', 'c1', 'c2', 'c3', 'c4', 'd1', 'd2', 'd3', 'd4', 'd5', 'd6', 'e1', 'e2', 'e3', 'e4', 'e5', 'e6', 'e7', 'e8', 'f1', 'g1', 'h1', 'i1']

const testStore = {
  namespaced: true,
  state: {
    minScore: 24,
    minScorePercentage: 80,
    nbQuestionnariesOkToPass: 7,
    questionnaryTimer: 5400000, // 5400000 ms => 1,5 heures
    questionnaries: [],
    averageScore: 0,
    questions: null,
    testToken: null,
    testAnswer: null,
    testScore: null
  },
  getters: {
    nbQuestions () {
      return questionnarySubjects.length
    },
    nbQuestionnaries (state) {
      return state.questionnaries.length
    },
    nbQuestionnariesOk (state) {
      return state.questionnaries.filter(testScore => testScore > state.minScore).length
    },
    lastSevenQuestionnaries (state) {
      return state.questionnaries.slice(-state.nbQuestionnariesOkToPass)
    },
    averageScore (state) {
      return state.averageScore
    }
  },
  mutations: {
    setTestToken (state, testToken) {
      state.testToken = testToken
    },
    setTestAnswer (state, answer) {
      state.testAnswer = answer
    },
    setTestScore (state, score) {
      state.testScore = score
    },
    initQuestions (state, value) {
      state.questions = value
    },
    initQuestionnaries (state, questionnaries) {
      if (questionnaries) {
        state.questionnaries = questionnaries
      }
    },
    initAverageScore (state, averageScore) {
      if (averageScore) {
        state.averageScore = averageScore
      }
    },
    saveQuestionnary (state, questionnary) {
      state.questionnaries.push(questionnary)
    },
    setLoadingErrorMessage (state, message) {
      state.loadingErrorMessage = message
    }
  },
  actions: {
    getTestDatas ({ rootState, state, commit, dispatch }) {
      if (rootState.demoMode) {
        if (state.questionnaries.length === 0) {
          commit('initQuestionnaries', [{ score: 27 }, { score: 28 }, { score: 29 }, { score: 25 }, { score: 26 }, { score: 26 }, { score: 30 }])
        }
        if (state.questionnaries.length > 7) {
          state.questionnaries = state.questionnaries.splice(state.questionnaries.length - 7, state.questionnaries.length)
        }
        let averageScore = 0
        for (let i = 0; i < state.questionnaries.length; i += 1) {
          averageScore += state.questionnaries[i].score
        }
        averageScore = averageScore / state.questionnaries.length
        commit('initAverageScore', averageScore)
      } else if (rootState.user.apiToken) {
        User.getTestDatas()
          .then(response => {
            if (response.data.average_score_on_last_tests && Array.isArray(response.data.last_tests)) {
              commit('initQuestionnaries', response.data.last_tests.length > 0 ? response.data.last_tests : [])
              commit('initAverageScore', response.data.average_score_on_last_tests)
            }
          })
          .catch(() => {
            dispatch('user/loginRedirection', null, { root: true })
          })
      }
    },
    initQuestionnary ({ commit }) {
      commit('initQuestions', QuestionnaryBuilder.build(questionnarySubjects))
    },
    startTest ({ rootState, commit }) {
      commit('setTestAnswer', null)
      commit('setTestScore', null)
      if (!rootState.demoMode) {
        User.postTestStart()
          .then(response => {
            if (response.data.token && Object.keys(response.data).length > 0) {
              commit('setTestToken', response.data.token)
            }
          })
          .catch(error => {
            console.error(error)
          })
      }
    },
    finishTest ({ rootState, state, commit, dispatch }, answers) {
      commit('setTestAnswer', answers)
      commit('setTestScore', Questionnary.calculateScore(state.questions, answers))
      if (rootState.demoMode) {
        commit('saveQuestionnary', { score: state.testScore })
      } else {
        User.postTestFinish({
          token: state.testToken,
          score: Questionnary.calculateScore(state.questions, answers),
          data: answers,
          success: Questionnary.calculateScore(state.questions, answers) >= state.minScore
        })
          .then(response => {
            commit('setTestToken', '')
            if (response.data.status && Object.keys(response.data).length > 0) {
              commit('user/setUserInfo', response.data, { root: true })
            }
          })
          .catch(error => {
            console.error(error)
          })
      }
    }
  }
}

export default testStore
