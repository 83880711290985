var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "time-up" }, [
    _c("div", { staticClass: "content-wrapper" }, [
      _c("div", { staticClass: "content" }, [
        _c("p", { staticClass: "title" }, [_vm._v(" Le temps est écoulé ! ")]),
        _c("div", { staticClass: "time" }, [
          _c("img", {
            attrs: { src: require("../../assets/pictos/clock-red.svg") },
          }),
          _c("p", [
            _vm._v(" " + _vm._s(_vm._f("millisecondFormat")(_vm.time)) + " "),
          ]),
        ]),
        _c("p", [_vm._v(" Vous avez échoué au test... ")]),
        _c("img", {
          attrs: { src: require("../../assets/pictos/down-arrow.svg") },
        }),
      ]),
      _c("div", { staticClass: "circle-bg" }),
    ]),
    _c(
      "div",
      { staticClass: "buttons" },
      [
        _c(
          "router-link",
          {
            staticClass: "button valid-button",
            attrs: { to: "/questionnary" },
          },
          [_vm._v("Lancer une session de test")]
        ),
        _c(
          "router-link",
          { staticClass: "button return-button", attrs: { to: "/" } },
          [_vm._v("retourner a l'accueil")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }