const corrections = [
  {
    name: 'rs-h1-1',
    content: 'Les logiciels d\'évaluation thermique permettent de réaliser des simulations de travaux permettant d\'améliorer la performance énergétique des bâtiments. Après enquête auprès des occupants, ils utilisent une méthode de calcul comportementale pour prendre en compte leurs habitudes. Ils permettent également de saisir un descriptif complet du bâtiment afin que les maîtres d’ouvrages s’approprient l’étude.'
  },
  {
    name: 'rs-h1-2',
    content: 'Les propositions de travaux, lors d’une évaluation thermique, doivent se concentrer sur les travaux les plus efficaces, c’est-à-dire les travaux sur le bâti (isolation, menuiseries, étanchéité, …) et l’installation d’équipements efficaces. La véranda ou le puit de lumière par exemple sont plutôt considérés comme des travaux d’agrément.'
  },
  {
    name: 'rs-h1-3',
    content: 'Les logiciels d’évaluation thermique doivent permettre de fournir une estimation des coûts des travaux à entreprendre pour réduire significativement la consommation énergétique et une estimation des économies qui pourrait en découler.'
  }
]

export default corrections
