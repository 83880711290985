const questions = [
  {
    id: 'h1-1',
    category: 'h',
    subject: 'h1',
    label: 'Les logiciels d\'évaluation thermique intègrent une méthode de calcul ... :\u00A0:',
    fullExplanation: 'ra-h1-1',
    simpleExplanation: 'rs-h1-1',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: '...Conventionnelle'
      },
      {
        name: 'b',
        label: '...Comportementale'
      },
      {
        name: 'c',
        label: '...Réelle'
      }
    ]
  },
  {
    id: 'h1-2',
    category: 'h',
    subject: 'h1',
    label: 'Les logiciels d\'évaluation thermique permettent de simuler l’impact sur la performance énergétique du bâtiment ...\u00A0:',
    fullExplanation: 'ra-h1-1',
    simpleExplanation: 'rs-h1-1',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: '... pour des travaux d’entretien des équipements'
      },
      {
        name: 'b',
        label: '... pour seulement un seul type de travaux à la fois'
      },
      {
        name: 'c',
        label: '... pour des bouquets de travaux'
      }
    ]
  },
  {
    id: 'h1-3',
    category: 'h',
    subject: 'h1',
    label: 'Une évaluation thermique doit comporter\u00A0:',
    fullExplanation: 'ra-h1-1',
    simpleExplanation: 'rs-h1-1',
    correctAnswers: ['a', 'b'],
    choices: [
      {
        name: 'a',
        label: '... Un descriptif complet du bâtiment étudié'
      },
      {
        name: 'b',
        label: '... Une enquête auprès des occupants'
      },
      {
        name: 'c',
        label: '... Un scénario de travaux pour changer d’énergie'
      }
    ]
  },
  {
    id: 'h1-4',
    category: 'h',
    subject: 'h1',
    label: 'Dans le cadre de l\'évaluation thermique, quels types de travaux sont susceptibles d\'être proposés\u00A0?',
    fullExplanation: 'ra-h1-2',
    simpleExplanation: 'rs-h1-2',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'La création d\'une véranda'
      },
      {
        name: 'b',
        label: 'La création d\'un puits de lumière'
      },
      {
        name: 'c',
        label: 'L’isolation des combles'
      }
    ]
  },
  {
    id: 'h1-5',
    category: 'h',
    subject: 'h1',
    label: 'Dans le cadre d’une évaluation thermique, il est pertinent de proposer des travaux de création d’une véranda\u00A0:',
    fullExplanation: 'ra-h1-2',
    simpleExplanation: 'rs-h1-2',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Oui'
      },
      {
        name: 'b',
        label: 'Non'
      }
    ]
  },
  {
    id: 'h1-6',
    category: 'h',
    subject: 'h1',
    label: 'Lorsque je fais une évaluation thermique, quels sont les travaux que je suis susceptible de proposer\u00A0?',
    fullExplanation: 'ra-h1-2',
    simpleExplanation: 'rs-h1-2',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'Le remplacement de la couverture'
      },
      {
        name: 'b',
        label: 'La création d\'une ouverture dans un mur'
      },
      {
        name: 'c',
        label: 'L’isolation des murs'
      }
    ]
  },
  {
    id: 'h1-7',
    category: 'h',
    subject: 'h1',
    label: 'Il n’est pas pertinent de proposer des travaux de création d’une véranda dans le cadre d’une évaluation thermique\u00A0:',
    fullExplanation: 'ra-h1-2',
    simpleExplanation: 'rs-h1-2',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'h1-8',
    category: 'h',
    subject: 'h1',
    label: 'Les logiciels d\'évaluation thermique fournissent\u00A0:',
    fullExplanation: 'ra-h1-3',
    simpleExplanation: 'rs-h1-3',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'Une recommandation de comportements permettant de réduire significativement la consommation énergétique'
      },
      {
        name: 'b',
        label: 'Une estimation des coûts des travaux à entreprendre pour réduire significativement la consommation énergétique'
      },
      {
        name: 'c',
        label: 'Une estimation des économies qui pourraient être réalisées par des travaux d\'économies d\'énergie'
      }
    ]
  },
  {
    id: 'h1-9',
    category: 'h',
    subject: 'h1',
    label: 'Les logiciels d\'évaluation thermique permettent d’avoir une estimation des économies qui pourraient être réalisées par des travaux d\'économies d\'énergie\u00A0:',
    fullExplanation: 'ra-h1-3',
    simpleExplanation: 'rs-h1-3',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'h1-10',
    category: 'h',
    subject: 'h1',
    label: 'Parmi les informations que les logiciels d\'évaluation thermique peuvent me fournir, il y a notamment\u00A0:',
    fullExplanation: 'ra-h1-3',
    simpleExplanation: 'rs-h1-3',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'La température à laquelle devrait être chauffé le logement pour qu’il ne dépasse pas un certain seuil de consommation'
      },
      {
        name: 'b',
        label: 'Une information sur la mise en œuvre précise des matériaux'
      },
      {
        name: 'c',
        label: 'Une estimation des économies qui pourraient être réalisées par des travaux d\'économies d\'énergie'
      }
    ]
  },
  {
    id: 'h1-11',
    category: 'h',
    subject: 'h1',
    label: 'Quel outil me permet d’obtenir une estimation des coûts des travaux à entreprendre pour réduire significativement la consommation énergétique d’un logement et les économies associées\u00A0?',
    fullExplanation: 'ra-h1-3',
    simpleExplanation: 'rs-h1-3',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'Un logiciel de diagnostic de performance énergétique (DPE)'
      },
      {
        name: 'b',
        label: 'Un logiciel d’étude thermique réglementaire'
      },
      {
        name: 'c',
        label: 'Un logiciel d’évaluation thermique'
      }
    ]
  }
]

export default questions
