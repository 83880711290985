<template>
  <div class="big-link">
    <slot>
      <p class="label">{{ label }}</p>
      <p class="title">{{ title }}</p>
      <div class="arrow">
        <img src="../assets/pictos/right-arrow.svg">
      </div>
      <div class="bg-image" v-html="bgImage"></div>
    </slot>
  </div>
</template>

<script>
export default {
  name: 'BigLink',
  props: {
    label: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    bgImage: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.big-link {
  min-height: 144px;
  box-shadow: 0 1px 3px rgba(52, 52, 52, 0.15);
  border-radius: 8px;
  border: 1px solid #c6c6c6;
  background-color: #fff;
  padding: 15px 100px 15px 20px;
  position: relative;
  text-align: left;
  box-sizing: border-box;
  .label {
    color: #000;
    font-size: 0.85rem;
    font-weight: 300;
  }
  .title {
    color: #343434;
    font-size: 1.4rem;
    font-weight: 900;
    line-height: 1.8rem;
  }
  .arrow {
    position: absolute;
    background-color: $bg-color;
    border-radius: 50%;
    top: 6px;
    right: 9px;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 8px;
      height: 12px;
    }
  }
  .bg-image {
    position: absolute;
    bottom: 15px;
    right: 15px;
  }
}
</style>

<style lang="scss">
.big-link {
  .bg-image {
    svg {
      path, rect {
        @include themify($themes) {
          fill: themed('backgroundColor');
        }
      }
    }
  }
}
</style>
