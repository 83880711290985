$<template>
  <div
    class="select"
    :class="{ open: showList }"
    @click="showList = !showList"
    @focusout="showList = false"
    tabindex="0"
  >
    <div class="current">
      {{ model ? model.name : '' }}
    </div>
    <div class="list">
      <span
        v-for="(choice, indexChoice) in list"
        :key="indexChoice"
        class="label"
        @click="$emit('update:model', choice)"
      >
        {{ choice.name }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Dropdown',
  props: {
    list: {
      required: true,
      type: Array
    },
    model: {
      required: true
    }
  },
  data () {
    return {
      showList: false
    }
  }
}
</script>

<style lang="scss">
.select {
  margin: 0 auto;
  max-width: 400px;
  text-align: left;
  background: #ffffff;
  border: 1px solid $border-color;
  border-radius: 0.5em;
  position: relative;
  transition: border .2s ease-out;
  .current {
    min-height: 18px;
    padding: 0.6em;
  }
  .list {
    top: 100%;
    z-index: 1;
    margin-top: 0;
    position: absolute;
    display: flex;
    flex-direction: column;
    opacity: 0;
    pointer-events: none;
    width: 100%;
    background: #ffffff;
    border-radius: 0.5em;
    box-shadow: 0px 5px 5px 0px #f8f9fc;
    border: 1px solid $border-color;
    transition: opacity .1s ease-out, margin-top .2s ease-out;
    .label {
      padding: 0.5em 1em;
      margin-bottom: 0.5em;
      transition: background .2s ease-out;
      &:first-child {
        margin-top: 0.5em;
      }
      &:hover {
        cursor: pointer;
        background: $bg-color;
      }
    }
  }
  &:before {
    pointer-events: none;
    width: 20px;
    height: 20px;
    content: '\203A';
    color: #010101;
    font-weight: 800;
    font-size: 2em;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-30%) rotate(90deg);
    transition: transform .1s ease-out;
  }
  &.open {
    border: 1px solid $border-color;
    &:before {
      transform: translateY(-70%) translateX(-100%) rotate(-90deg);
    }
    .list {
      opacity: 1;
      pointer-events: auto;
      margin-top: 1em;
    }
  }
}
</style>
