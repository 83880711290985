<template>
  <div class="about-rge">
    <div class="links-wrapper">
      <router-link :to="{ name: 'aboutRGECourse' }" class="link-wrapper">
        <big-link label="Comment devenir RGE ?"
          title="Le parcours de qualification RGE"
          :bg-image="require('../assets/illus/illu-parcours.svg?inline')"
        ></big-link>
      </router-link>

      <router-link :to="{ name: 'aboutRGEAdvantages' }" class="link-wrapper">
        <big-link label="Quels coûts pour quels avantages ?"
          title="Les avantages et coûts de la qualification RGE"
          :bg-image="require('../assets/illus/illu-balance.svg?inline')"
        ></big-link>
      </router-link>

      <router-link :to="{ name: 'aboutRGEFarther' }" class="link-wrapper">
        <big-link label="Pour aller plus loin…"
          title="Se faire accompagner au montage de son dossier RGE"
          :bg-image="require('../assets/illus/illu-dossier.svg?inline')"
        ></big-link>
      </router-link>
    </div>
  </div>
</template>

<script>
import BigLink from '@/components/BigLink'
export default {
  name: 'AboutRGE',
  components: { BigLink }
}
</script>

<style lang="scss" scoped>
.about-rge {
  .links-wrapper {
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    .link-wrapper {
      text-decoration: none;
    }
  }
}
</style>
