<template>
  <div class="result">
    <div class="head">
      <p class="title">
        Vous avez complété le test !
      </p>
    </div>
    <div class="content">
      <p class="title">
        votre score
      </p>
      <div class="score" :class="{ success: testSuccess }">
        <progress-ring :radius="60" :progress="progress" :stroke="8" :color="testSuccess ? '#25c368' : '#e71212'">
          <span class="score-value">
            {{ testScore }}
          </span>
        </progress-ring>
      </div>
      <span v-if="progress > 80" class="big-tag success">
        Félicitations, vous avez réussi le test&nbsp;!
      </span>
      <span v-else-if="progress > 60" class="big-tag fail">
        Encore un effort, on y est presque&nbsp;!
      </span>
      <span v-else class="big-tag fail">
        Ce n’est pas encore ça...
      </span>
      <div class="buttons">
        <router-link to="/correction" class="button valid-button">Consulter le correctif</router-link>
      </div>
    </div>
    <div class="button-wrapper">
      <router-link class="button return-button" to="/">retourner a l'accueil</router-link>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ProgressRing from '../../components/ProgressRing'

export default {
  name: 'Result',
  components: {
    ProgressRing
  },
  computed: {
    ...mapState('test', ['questionnaries', 'minScorePercentage', 'questions', 'testScore']),
    testSuccess () {
      return this.progress >= this.minScorePercentage
    },
    progress () {
      return this.testScore / this.questions.length * 100
    }
  }
}
</script>

<style lang=scss>
.result {
  .head {
    background-color: $bg-color;
    padding: $spacer-xl 0;
    .title {
      font-weight: 900;
      font-size: 1.2rem;
      text-align: center;
    }
  }
  .content {
    padding: $spacer;
    margin: $spacer-md;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.16);
    border-radius: $radius;
    border: 1px solid $border-color;
    background-image: url('../../assets/background_pattern.svg');
    background-repeat: no-repeat;
    background-position: center;
    min-height: 260px;
    position: relative;
    .title {
      font-weight: 900;
      text-transform: uppercase;
      font-size: 0.95rem;
    }
    .score {
      .score-value {
        font-size: 2.5rem;
      }
    }
    .big-tag {
      padding: $spacer-sm $spacer-md;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 900;
      font-size: 0.9rem;
      border-radius: 17px;
      color: #fff;
      &.success {
        background-color: $secondary-color;
      }
      &.fail {
        background-color: $error-color;
      }
    }
    .buttons {
      background-color: #fff;
      padding: $spacer-md 0;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      box-sizing: border-box;
      box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.16);
      border-radius: 0 0 $radius $radius;
    }
  }
}
</style>
