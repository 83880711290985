<template>
  <div class="radio-buttons">
    <label v-for="choice in choices"
      :key="choice.name"
      :for="name + choice.name"
      class="radio-button"
      :class="{ checked: isChecked(choice.name) }"
    >
      <input type="radio"
        :id="name + choice.name"
        :name="choice.name + name"
        :value="choice.name"
        @click="setValue(choice.name)"
        :checked="isChecked(choice.name)"
      >
      <span class="radio-label">
        {{ choice.label }}
      </span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'RadioButtons',
  props: {
    choices: {
      type: Array,
      required: true
    },
    value: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    }
  },
  methods: {
    isChecked (choiceName) {
      return this.value === choiceName
    },
    setValue (choiceName) {
      this.$emit('input', choiceName)
    }
  }
}
</script>

<style lang="scss" scoped>
.radio-buttons {
  display: flex;
  flex-direction: column;
  gap: 6px;
  .radio-button {
    cursor: pointer;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    min-height: 80px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.16);
    border-radius: 3px;
    border: 1px solid #c6c6c6;
    background-color: #fff;
    padding: 20px 25px;
    text-align: left;
    position: relative;
    &:before {
      content: '';
      width: 7px;
      display: inline-block;
      transform: scaleX(0);
      height: 100%;
      background-color: #707070;
      transition: transform 0.3s ease-out;
      position: absolute;
      left: 0;
      top: 0;
      transform-origin: left;
      border-radius: 2px 0 0 2px;
    }
    input {
      display: none;
    }
    &.checked:before {
      transform: scaleX(1);
    }
  }
}
</style>
