const questions = [
  {
    id: 'a1-1',
    category: 'a',
    subject: 'a1',
    label: 'Lesquelles de ces sources d’énergies sont considérées comme des énergies renouvelables\u00A0?',
    fullExplanation: 'ra-a1-1',
    simpleExplanation: 'rs-a1-1',
    correctAnswers: ['b', 'd'],
    choices: [
      {
        name: 'a',
        label: 'Le charbon'
      },
      {
        name: 'b',
        label: 'Le bois et la biomasse'
      },
      {
        name: 'c',
        label: 'Le gaz naturel'
      },
      {
        name: 'd',
        label: 'La géothermie'
      }
    ]
  },
  {
    id: 'a1-2',
    category: 'a',
    subject: 'a1',
    label: 'La chaleur issue de la combustion des déchets est considérée comme une source d’énergie renouvelable\u00A0:',
    fullExplanation: 'ra-a1-1',
    simpleExplanation: 'rs-a1-1',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'a1-3',
    category: 'a',
    subject: 'a1',
    label: 'Laquelle de ces installations produit une énergie considérée comme renouvelable\u00A0?',
    fullExplanation: 'ra-a1-1',
    simpleExplanation: 'rs-a1-1',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'Une centrale thermique'
      },
      {
        name: 'b',
        label: 'Une centrale nucléaire'
      },
      {
        name: 'c',
        label: 'Une centrale marémotrice'
      }
    ]
  },
  {
    id: 'a1-4',
    category: 'a',
    subject: 'a1',
    label: 'Une énergie est considérée comme renouvelable\u00A0:',
    fullExplanation: 'ra-a1-2',
    simpleExplanation: 'rs-a1-1',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Lorsqu\'elle est inépuisable'
      },
      {
        name: 'b',
        label: 'Lorsqu\'elle ne pollue pas'
      },
      {
        name: 'c',
        label: 'Lorsqu\'elle n\'est pas issue d\'une combustion'
      }
    ]
  },
  {
    id: 'a1-5',
    category: 'a',
    subject: 'a1',
    label: 'Une énergie renouvelable est une énergie inépuisable\u00A0:',
    fullExplanation: 'ra-a1-2',
    simpleExplanation: 'rs-a1-1',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'a1-6',
    category: 'a',
    subject: 'a1',
    label: 'Une énergie inépuisable est considérée comme une énergie\u00A0:',
    fullExplanation: 'ra-a1-2',
    simpleExplanation: 'rs-a1-1',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Primaire'
      },
      {
        name: 'b',
        label: 'Renouvelable'
      },
      {
        name: 'c',
        label: 'Fossile'
      }
    ]
  },
  {
    id: 'a1-7',
    category: 'a',
    subject: 'a1',
    label: 'Quelle énergie est considérée comme inépuisable\u00A0?',
    fullExplanation: 'ra-a1-2',
    simpleExplanation: 'rs-a1-1',
    correctAnswers: ['b', 'c'],
    choices: [
      {
        name: 'a',
        label: 'Potentielle'
      },
      {
        name: 'b',
        label: 'Solaire'
      },
      {
        name: 'c',
        label: 'Renouvelable'
      },
      {
        name: 'd',
        label: 'Fossile'
      }
    ]
  },
  /* {
    id: 'a1-8',
    category: 'a',
    subject: 'a1',
    label: 'A l\'horizon 2017, quel est l\'objectif annuel de nombre de rénovations énergétiques de logements en France\u00A0?',
    fullExplanation: 'ra-a1-3',
    simpleExplanation: 'rs-a1-3',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: '100 000'
      },
      {
        name: 'b',
        label: '500 000'
      },
      {
        name: 'c',
        label: '1 000 000'
      }
    ]
  },
  {
    id: 'a1-9',
    category: 'a',
    subject: 'a1',
    label: 'La France s’est donnée comme objectif, à l\'horizon 2017, 500 000 rénovations énergétiques de logements par an\u00A0:',
    fullExplanation: 'ra-a1-3',
    simpleExplanation: 'rs-a1-3',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Faux'
      },
      {
        name: 'b',
        label: 'Vrai'
      }
    ]
  },
  {
    id: 'a1-10',
    category: 'a',
    subject: 'a1',
    label: 'A l\'horizon 2017, la France s’est donnée quel objectif\u00A0?',
    fullExplanation: 'ra-a1-3',
    simpleExplanation: 'rs-a1-3',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: '500 000 remplacements de chaudières fioul par an'
      },
      {
        name: 'b',
        label: '500 000 m² de panneaux solaires installés par an'
      },
      {
        name: 'c',
        label: '500 000 rénovations énergétiques de logements par an'
      }
    ]
  },
  {
    id: 'a1-11',
    category: 'a',
    subject: 'a1',
    label: '500 000 rénovations énergétiques de logements par an, c’est l’objectif que s’est donnée La France\u00A0:',
    fullExplanation: 'ra-a1-3',
    simpleExplanation: 'rs-a1-3',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'A l\'horizon 2017'
      },
      {
        name: 'b',
        label: 'A l\'horizon 2030'
      },
      {
        name: 'c',
        label: 'A l\'horizon 2050'
      }
    ]
  }, */
  {
    id: 'a1-12',
    category: 'a',
    subject: 'a1',
    label: 'Laquelle de ces sources d’énergies est fossile\u00A0?',
    fullExplanation: 'ra-a1-4',
    simpleExplanation: 'rs-a1-4',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'L\'énergie solaire'
      },
      {
        name: 'b',
        label: 'La géothermie très basse énergie'
      },
      {
        name: 'c',
        label: 'Le gaz naturel'
      }
    ]
  },
  {
    id: 'a1-13',
    category: 'a',
    subject: 'a1',
    label: 'L’énergie géothermique est une source d’énergie fossile\u00A0:',
    fullExplanation: 'ra-a1-4',
    simpleExplanation: 'rs-a1-4',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'a1-14',
    category: 'a',
    subject: 'a1',
    label: 'Lesquelles de ces sources d’énergies ne sont pas d’origine fossile\u00A0?',
    fullExplanation: 'ra-a1-4',
    simpleExplanation: 'rs-a1-4',
    correctAnswers: ['a', 'b', 'd'],
    choices: [
      {
        name: 'a',
        label: 'L\'énergie solaire'
      },
      {
        name: 'b',
        label: 'La géothermie très basse énergie'
      },
      {
        name: 'c',
        label: 'Le gaz naturel'
      },
      {
        name: 'd',
        label: 'L’éolien offshore'
      }
    ]
  },
  {
    id: 'a1-15',
    category: 'a',
    subject: 'a1',
    label: 'La géothermie est une source d’énergie\u00A0:',
    fullExplanation: 'ra-a1-4',
    simpleExplanation: 'rs-a1-4',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Fossile'
      },
      {
        name: 'b',
        label: 'Renouvelable'
      }
    ]
  },
  {
    id: 'a1-16',
    category: 'a',
    subject: 'a1',
    label: 'Quel secteur consomme le plus d’énergie en France\u00A0?',
    fullExplanation: 'ra-a1-5',
    simpleExplanation: 'rs-a1-5',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Le bâtiment (habitat et tertiaire)'
      },
      {
        name: 'b',
        label: 'L\'industrie'
      },
      {
        name: 'c',
        label: 'Le transport'
      }
    ]
  },
  {
    id: 'a1-17',
    category: 'a',
    subject: 'a1',
    label: 'L\'industrie est le secteur qui consomme le plus d’énergie en France\u00A0:',
    fullExplanation: 'ra-a1-5',
    simpleExplanation: 'rs-a1-5',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Faux'
      },
      {
        name: 'b',
        label: 'Vrai'
      }
    ]
  },
  {
    id: 'a1-18',
    category: 'a',
    subject: 'a1',
    label: 'Le transport est un secteur qui consomme plus d’énergie que le secteur du bâtiment (habitat et tertiaire) en France\u00A0:',
    fullExplanation: 'ra-a1-5',
    simpleExplanation: 'rs-a1-5',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'a1-19',
    category: 'a',
    subject: 'a1',
    label: 'En France, le secteur du bâtiment (habitat et tertiaire)\u00A0:',
    fullExplanation: 'ra-a1-5',
    simpleExplanation: 'rs-a1-5',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Est le premier secteur le plus consommateur d’énergie'
      },
      {
        name: 'b',
        label: 'Est le deuxième secteur le plus consommateur d’énergie'
      },
      {
        name: 'c',
        label: 'Est le secteur qui consomme le moins d’énergie'
      }
    ]
  },
  {
    id: 'a1-20',
    category: 'a',
    subject: 'a1',
    label: 'Parmi les secteurs suivants, lequel représente la majorité des émissions françaises de gaz à effet de serre\u00A0?',
    fullExplanation: 'ra-a1-6',
    simpleExplanation: 'rs-a1-6',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'Le bâtiment (habitat et tertiaire)'
      },
      {
        name: 'b',
        label: 'L\'industrie'
      },
      {
        name: 'c',
        label: 'Les transports'
      }
    ]
  },
  {
    id: 'a1-21',
    category: 'a',
    subject: 'a1',
    label: 'Le secteur du bâtiment (habitat et tertiaire) représente la majorité des émissions françaises de gaz à effet de serre\u00A0:',
    fullExplanation: 'ra-a1-6',
    simpleExplanation: 'rs-a1-6',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'a1-22',
    category: 'a',
    subject: 'a1',
    label: 'Le secteur des transports est le plus gros poste des émissions françaises de gaz à effet de serre\u00A0:',
    fullExplanation: 'ra-a1-6',
    simpleExplanation: 'rs-a1-6',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'a1-23',
    category: 'a',
    subject: 'a1',
    label: 'En France, le secteur du bâtiment (habitat et tertiaire), émet plus de gaz à effet de serre que le secteur des transports\u00A0:',
    fullExplanation: 'ra-a1-6',
    simpleExplanation: 'rs-a1-6',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'a1-24',
    category: 'a',
    subject: 'a1',
    label: 'MaPrimeRénov\' s\'applique\u00A0:',
    fullExplanation: 'ra-a2-mpr-1',
    simpleExplanation: 'rs-a1-7',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'A tous les contribuables'
      },
      {
        name: 'b',
        label: 'Aux contribuables imposables uniquement'
      }
    ]
  },
  {
    id: 'a1-25',
    category: 'a',
    subject: 'a1',
    label: 'Seuls les contribuables imposables peuvent bénéficier de MaPrimeRénov\'\u00A0:',
    fullExplanation: 'ra-a2-mpr-1',
    simpleExplanation: 'rs-a1-7',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'a1-26',
    category: 'a',
    subject: 'a1',
    label: 'Tous les contribuables peuvent bénéficier de MaPrimeRénov\'\u00A0:',
    fullExplanation: 'ra-a2-mpr-1',
    simpleExplanation: 'rs-a1-7',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'a1-27',
    category: 'a',
    subject: 'a1',
    label: 'MaPrimeRénov\' est une aide financière pouvant bénéficier à\u00A0:',
    fullExplanation: 'ra-a2-mpr-1',
    simpleExplanation: 'rs-a1-7',
    correctAnswers: ['a', 'b', 'c'],
    choices: [
      {
        name: 'a',
        label: 'Des contribuables non imposables'
      },
      {
        name: 'b',
        label: 'Des retraités'
      },
      {
        name: 'c',
        label: 'Des jeunes actifs'
      }
    ]
  },
  {
    id: 'a1-28',
    category: 'a',
    subject: 'a1',
    label: 'Quelle est la part d\'énergie consommée par le secteur du bâtiment en France\u00A0?',
    fullExplanation: 'ra-a1-8',
    simpleExplanation: 'rs-a1-6',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: '23%'
      },
      {
        name: 'b',
        label: '63%'
      },
      {
        name: 'c',
        label: '46%'
      }
    ]
  },
  {
    id: 'a1-29',
    category: 'a',
    subject: 'a1',
    label: 'En France, 46%, c’est la part d\'énergie consommée par\u00A0:',
    fullExplanation: 'ra-a1-8',
    simpleExplanation: 'rs-a1-6',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Le secteur du transport'
      },
      {
        name: 'b',
        label: 'Le secteur du bâtiment'
      },
      {
        name: 'c',
        label: 'Le secteur de l’industrie'
      }
    ]
  },
  {
    id: 'a1-30',
    category: 'a',
    subject: 'a1',
    label: 'La part d\'énergie consommée par le secteur du bâtiment en France est de 23%\u00A0:',
    fullExplanation: 'ra-a1-8',
    simpleExplanation: 'rs-a1-6',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'a1-31',
    category: 'a',
    subject: 'a1',
    label: 'La part d\'énergie consommée par le secteur du bâtiment en France est de 46%\u00A0:',
    fullExplanation: 'ra-a1-8',
    simpleExplanation: 'rs-a1-6',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'a2-mpr-1',
    category: 'c',
    subject: 'c4',
    label: 'L’aide Ma Prime Rénov’ MPR est réservée aux foyers aux revenus modestes\u00A0:',
    fullExplanation: 'ra-a2-mpr-1',
    simpleExplanation: 'rs-a2-mpr-1',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'a2-mpr-2',
    category: 'c',
    subject: 'c4',
    label: 'Pour que mon client puisse bénéficier de Ma Prime Rénov’ MPR, je dois respecter des contraintes de performance énergétique dans mes travaux\u00A0:',
    fullExplanation: 'ra-a2-mpr-1',
    simpleExplanation: 'rs-a2-mpr-2',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Oui'
      },
      {
        name: 'b',
        label: 'Oui, uniquement en métropole'
      },
      {
        name: 'c',
        label: 'Non'
      }
    ]
  },
  {
    id: 'a2-mpr-3',
    category: 'c',
    subject: 'c4',
    label: 'Pour que mon client puisse bénéficier de Ma Prime Rénov’ MPR, je dois respecter des critères écologiques dans le choix des matériaux isolants\u00A0:',
    fullExplanation: 'ra-a2-mpr-1',
    simpleExplanation: 'rs-a2-mpr-3',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'Oui'
      },
      {
        name: 'b',
        label: 'Oui, uniquement en métropole'
      },
      {
        name: 'c',
        label: 'Non'
      }
    ]
  },
  {
    id: 'a2-mpr-4',
    category: 'c',
    subject: 'c4',
    label: 'Pour une même opération d’isolation, le montant de Ma Prime Rénov’ MPR dépend des revenus du ménage\u00A0:',
    fullExplanation: 'ra-a2-mpr-1',
    simpleExplanation: 'rs-a2-mpr-1',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'a2-1',
    category: 'a',
    subject: 'a2',
    label: 'La France s’est engagée à diviser ses émissions nationales de gaz à effet de serre par rapport au niveau de 1990 d’ici 2050 par\u00A0:',
    fullExplanation: 'ra-a2-1',
    simpleExplanation: 'rs-a2-1',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'Un facteur 2'
      },
      {
        name: 'b',
        label: 'Un facteur 3'
      },
      {
        name: 'c',
        label: 'Un facteur 4'
      }
    ]
  },
  {
    id: 'a2-2',
    category: 'a',
    subject: 'a2',
    label: 'Dans un contexte de réduction de consommation énergétique nationale, que signifie «\u00A0Facteur\u00A04\u00A0»\u00A0?',
    fullExplanation: 'ra-a2-1',
    simpleExplanation: 'rs-a2-1',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Que la France s’est engagée à diviser ses émissions nationales de gaz à effet de serre par 4, par rapport au niveau de 1990 et d’ici 2050.'
      },
      {
        name: 'b',
        label: 'Que la France s’est engagée à diviser sa consommation énergétique nationale par 4, par rapport au niveau de 1990 et d’ici 2050.'
      },
      {
        name: 'c',
        label: 'Que la France s’est engagée à multiplier par 4 sa production d’énergie renouvelable, par rapport au niveau de 1990 et d’ici 2050.'
      }
    ]
  },
  {
    id: 'a2-3',
    category: 'a',
    subject: 'a2',
    label: 'Pour limiter son impact sur le réchauffement climatique, la France doit diviser ses émissions nationales de gaz à effet de serre par rapport au niveau de 1990 d’ici 2050 par\u00A0:',
    fullExplanation: 'ra-a2-1',
    simpleExplanation: 'rs-a2-1',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Un facteur 4'
      },
      {
        name: 'b',
        label: 'Un facteur 10'
      },
      {
        name: 'c',
        label: 'Un facteur 100'
      }
    ]
  },
  /* {
    id: 'a2-4',
    category: 'a',
    subject: 'a2',
    label: 'J\'ai le droit de bénéficier d\'un éco prêt à taux zéro pour un bouquet de travaux si ma résidence principale a été achevée avant le\u00A0:',
    fullExplanation: 'ra-a2-2',
    simpleExplanation: 'rs-a2-2',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: '1er janvier 2010 '
      },
      {
        name: 'b',
        label: '1er janvier 2000 '
      },
      {
        name: 'c',
        label: '1er janvier 1990'
      }
    ]
  },
  {
    id: 'a2-5',
    category: 'a',
    subject: 'a2',
    label: 'J\'ai le droit de bénéficier d\'un éco prêt à taux zéro pour un bouquet de travaux si ma résidence principale a été achevée avant le 1er janvier 1990\u00A0:',
    fullExplanation: 'ra-a2-2',
    simpleExplanation: 'rs-a2-2',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'a2-6',
    category: 'a',
    subject: 'a2',
    label: 'Pour pouvoir bénéficier d\'un éco prêt à taux zéro pour un bouquet de travaux, ma résidence principale doit avoir été achevée\u00A0:',
    fullExplanation: 'ra-a2-2',
    simpleExplanation: 'rs-a2-2',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'Avant le 1er janvier 2000 '
      },
      {
        name: 'b',
        label: 'Après 1er janvier 2000 '
      },
      {
        name: 'c',
        label: 'Avant 1er janvier 1990'
      },
      {
        name: 'd',
        label: 'Après 1er janvier 1990'
      }
    ]
  },
  {
    id: 'a2-7',
    category: 'a',
    subject: 'a2',
    label: 'Ma résidence principale a été achevée le 12 mars 1990. Ai-je le droit de bénéficier d\'un éco prêt à taux zéro pour un bouquet de travaux sur celle-ci\u00A0?',
    fullExplanation: 'ra-a2-2',
    simpleExplanation: 'rs-a2-2',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Oui'
      },
      {
        name: 'b',
        label: 'Non'
      }
    ]
  }, */
  {
    id: 'a2-8',
    category: 'a',
    subject: 'a2',
    label: 'Qu\'est-ce que la valeur verte d\'un bâtiment\u00A0?',
    fullExplanation: 'ra-a2-3',
    simpleExplanation: 'rs-a2-3',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'La part des consommations liées aux énergies renouvelables'
      },
      {
        name: 'b',
        label: 'Les économies d\'énergie réalisées'
      },
      {
        name: 'c',
        label: 'La plus-value du bâtiment liée à ses performances environnementales'
      }
    ]
  },
  {
    id: 'a2-9',
    category: 'a',
    subject: 'a2',
    label: 'La plus-value d’un bâtiment liée à ses performances environnementales est appelée\u00A0:',
    fullExplanation: 'ra-a2-3',
    simpleExplanation: 'rs-a2-3',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'La plus-value verte'
      },
      {
        name: 'b',
        label: 'Le bénéfice vert'
      },
      {
        name: 'c',
        label: 'La valeur verte'
      }
    ]
  },
  {
    id: 'a2-10',
    category: 'a',
    subject: 'a2',
    label: 'la valeur verte d\'un bâtiment est sa production d’énergies renouvelables\u00A0:',
    fullExplanation: 'ra-a2-3',
    simpleExplanation: 'rs-a2-3',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'a2-11',
    category: 'a',
    subject: 'a2',
    label: 'La plus-value du bâtiment liée à ses performances environnementales est appelée la valeur verte\u00A0:',
    fullExplanation: 'ra-a2-3',
    simpleExplanation: 'rs-a2-3',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'a2-12',
    category: 'a',
    subject: 'a2',
    label: 'Que signifie CEE\u00A0?',
    fullExplanation: 'ra-a2-4',
    simpleExplanation: 'rs-a2-4',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'Correspondant Economique et Environnemental'
      },
      {
        name: 'b',
        label: 'Contribution Environnementale Européenne'
      },
      {
        name: 'c',
        label: 'Certificat d\'Economie d\'Energie'
      }
    ]
  },
  {
    id: 'a2-13',
    category: 'a',
    subject: 'a2',
    label: 'CEE signifie\u00A0?',
    fullExplanation: 'ra-a2-4',
    simpleExplanation: 'rs-a2-4',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Certificat d\'Economie d\'Energie'
      },
      {
        name: 'b',
        label: 'Caractéristique Energétique et Environnemental'
      },
      {
        name: 'c',
        label: 'Critère d’Evaluation Environnementale'
      }
    ]
  },
  {
    id: 'a2-14',
    category: 'a',
    subject: 'a2',
    label: 'Les Certificats d’Economie d’Energie sont aussi appelés CEE\u00A0?',
    fullExplanation: 'ra-a2-4',
    simpleExplanation: 'rs-a2-4',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'a2-15',
    category: 'a',
    subject: 'a2',
    label: 'Que signifie CEE\u00A0?',
    fullExplanation: 'ra-a2-4',
    simpleExplanation: 'rs-a2-4',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Certificat Energétique et Environnemental'
      },
      {
        name: 'b',
        label: 'Certificat d\'Economie d\'Energie'
      },
      {
        name: 'c',
        label: 'Certificat Environnementale Européen'
      }
    ]
  },
  {
    id: 'a2-16',
    category: 'a',
    subject: 'a2',
    label: 'Pour qu\'il puisse bénéficier de MaPrimeRénov\', la facture que je remets à mon client doit comporter\u00A0:',
    fullExplanation: 'ra-a2-mpr-1',
    simpleExplanation: 'rs-a2-7',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Des critères de performances des équipements et/ou des matériaux ainsi que leur coût'
      },
      {
        name: 'b',
        label: 'Les coûts des équipements et/ou des matériaux seulement'
      },
      {
        name: 'c',
        label: 'La marque des équipements'
      }
    ]
  },
  {
    id: 'a2-17',
    category: 'a',
    subject: 'a2',
    label: 'Pour qu\'un particulier puisse bénéficier de MaPrimeRénov\', la facture qui lui est remise par l’entreprise, doit comporter les critères de performances des équipements et/ou des matériaux ainsi que leur coût\u00A0:',
    fullExplanation: 'ra-a2-mpr-1',
    simpleExplanation: 'rs-a2-7',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'a2-18',
    category: 'a',
    subject: 'a2',
    label: 'Si une entreprise ne mentionne pas les critères de performances des équipements et/ou des matériaux ainsi que leur coût sur la facture des travaux\u00A0:',
    fullExplanation: 'ra-a2-mpr-1',
    simpleExplanation: 'rs-a2-7',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Le propriétaire pourra bénéficier de MaPrimeRénov\''
      },
      {
        name: 'b',
        label: 'Le propriétaire ne pourra pas bénéficier de MaPrimeRénov\''
      }
    ]
  },
  {
    id: 'a2-19',
    category: 'a',
    subject: 'a2',
    label: 'Sur la facture des travaux, la mention des critères de performances des équipements et/ou des matériaux ainsi que leur coût\u00A0:',
    fullExplanation: 'ra-a2-mpr-1',
    simpleExplanation: 'rs-a2-7',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Est facultative pour que le client puisse bénéficier de MaPrimeRénov\''
      },
      {
        name: 'b',
        label: 'Est indispensable pour que le client puisse bénéficier de MaPrimeRénov\''
      }
    ]
  },
  {
    id: 'a2-20',
    category: 'a',
    subject: 'a2',
    label: 'La qualification RGE peut être obtenue\u00A0:',
    fullExplanation: 'ra-a2-8',
    simpleExplanation: 'rs-a2-8',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Suite à l\'obtention d\'une qualification par un organisme habilité par l\'état à délivrer cette mention'
      },
      {
        name: 'b',
        label: 'En suivant une formation uniquement'
      },
      {
        name: 'c',
        label: 'Indifféremment par l\'un de ces deux moyens'
      }
    ]
  },
  {
    id: 'a2-21',
    category: 'a',
    subject: 'a2',
    label: 'Pour obtenir la qualification RGE, une entreprise doit\u00A0:',
    fullExplanation: 'ra-a2-8',
    simpleExplanation: 'rs-a2-8',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Obtenir une qualification par un organisme habilité par l\'état à délivrer cette mention'
      },
      {
        name: 'b',
        label: 'Suivre une formation uniquement'
      },
      {
        name: 'c',
        label: 'Réaliser un chiffre d’affaire minimum'
      },
      {
        name: 'd',
        label: 'Avoir un effectif de 10 personnes minimum'
      }
    ]
  },
  {
    id: 'a2-22',
    category: 'a',
    subject: 'a2',
    label: 'L’obtention de la qualification RGE est conditionnée par l\'obtention d\'une qualification par un organisme habilité par l\'état à délivrer cette mention\u00A0:',
    fullExplanation: 'ra-a2-8',
    simpleExplanation: 'rs-a2-8',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Faux'
      },
      {
        name: 'b',
        label: 'Vrai'
      }
    ]
  },
  {
    id: 'a2-23',
    category: 'a',
    subject: 'a2',
    label: 'Sans qualification (Qualifelec, QualiPAC, Qualibois, …), une entreprise peut-elle être qualifiée RGE\u00A0?',
    fullExplanation: 'ra-a2-8',
    simpleExplanation: 'rs-a2-8',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Oui'
      },
      {
        name: 'b',
        label: 'Non'
      }
    ]
  },
  {
    id: 'a2-24',
    category: 'a',
    subject: 'a2',
    label: 'Avec la stratégie Nationale Bas-Carbonne (SNBC), la France s’est engagée à l’horizon 2050 à\u00A0:',
    fullExplanation: 'ra-a3-1',
    simpleExplanation: 'rs-a3-1',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Diviser par 4 ses émissions de gaz à effet de serre'
      },
      {
        name: 'b',
        label: 'La neutralité carbone'
      },
      {
        name: 'c',
        label: 'Ses émissions de gaz à effet de serre'
      }
    ]
  },
  {
    id: 'a2-25',
    category: 'a',
    subject: 'a2',
    label: 'Quel texte règlementaire impose la neutralité carbone de la France à 2050\u00A0?',
    fullExplanation: 'ra-a3-1',
    simpleExplanation: 'rs-a3-1',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Le Facteur 4'
      },
      {
        name: 'b',
        label: 'La Stratégie Nationale Bas Carbone'
      },
      {
        name: 'c',
        label: 'La COP 21'
      },
      {
        name: 'd',
        label: 'Les Accords de Paris'
      }
    ]
  },
  {
    id: 'a2-26',
    category: 'a',
    subject: 'a2',
    label: 'La Stratégie Nationale Bas Carbone consiste à\u00A0:',
    fullExplanation: 'ra-a3-1',
    simpleExplanation: 'rs-a3-1',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Diviser les émissions de gaz à effets de serre par 4'
      },
      {
        name: 'b',
        label: 'Réduire les émissions de CO2 et développer les puits de carbone afin d’être neutre'
      },
      {
        name: 'c',
        label: 'Augmenter le piégeage du CO2 dans les végétaux et le sol'
      },
      {
        name: 'd',
        label: 'Réduire l’augmentation moyenne de température à 1,5°C'
      }
    ]
  },
  {
    id: 'a2-27',
    category: 'a',
    subject: 'a2',
    label: 'Dans la Stratégie Nationale Bas Carbone (SNBC), la majeure partie de l’équilibre carbone est liée à\u00A0:',
    fullExplanation: 'ra-a3-1',
    simpleExplanation: 'rs-a3-1',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'la réduction drastique des émissions de CO2 de façon globale'
      },
      {
        name: 'b',
        label: 'la généralisation des voitures électriques'
      },
      {
        name: 'c',
        label: 'le développement des puits de carbone'
      },
      {
        name: 'd',
        label: 'la RE2020'
      }
    ]
  },
  {
    id: 'a2-28',
    category: 'a',
    subject: 'a2',
    label: 'En 20 ans, le coût des énergies fossiles (gaz et fioul) a\u00A0:',
    fullExplanation: 'ra-a4-1',
    simpleExplanation: 'rs-a4-1',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Augmenté de 6% en moyenne chaque année'
      },
      {
        name: 'b',
        label: 'Diminué de 10% en moyenne chaque année'
      },
      {
        name: 'c',
        label: 'Augmenté de 40% en moyenne chaque année'
      }
    ]
  },
  {
    id: 'a2-29',
    category: 'a',
    subject: 'a2',
    label: 'En augmentant de 6% en moyenne sur 20 ans, le prix des énergies fossiles augmentera d’environ\u00A0:',
    fullExplanation: 'ra-a4-1',
    simpleExplanation: 'rs-a4-1',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: '200% c’est-à-dire multiplié par 3'
      },
      {
        name: 'b',
        label: '120%'
      },
      {
        name: 'c',
        label: '60%'
      }
    ]
  },
  {
    id: 'a2-30',
    category: 'a',
    subject: 'a2',
    label: 'Sur les 20 dernières années, l’augmentation moyenne des énergies fossiles a été de\u00A0:',
    fullExplanation: 'ra-a4-1',
    simpleExplanation: 'rs-a4-1',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Entre 1 et 5% par an'
      },
      {
        name: 'b',
        label: 'Entre 5 et 8% par an'
      },
      {
        name: 'c',
        label: 'Entre 8 et 15% par an'
      }
    ]
  },
  {
    id: 'a2-31',
    category: 'a',
    subject: 'a2',
    label: 'Depuis 20 ans, le coût des énergies fossiles a baissé\u00A0:',
    fullExplanation: 'ra-a4-1',
    simpleExplanation: 'rs-a4-1',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'a2-32',
    category: 'a',
    subject: 'a2',
    label: 'Depuis 20 ans, l’augmentation moyenne du coût des énergies est de\u00A0:',
    fullExplanation: 'ra-a4-1',
    simpleExplanation: 'rs-a4-1',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: '+6%/an'
      },
      {
        name: 'b',
        label: '+40%/an'
      },
      {
        name: 'c',
        label: '-10%/an'
      }
    ]
  }
]

export default questions
