<template>
  <div class="outro-step">
    <div class="background-head head">
      <div class="content">
        <p class="title">C’est tout !</p>
        <p class="intro">
          <b>Veuillez prendre connaissance des points suivants avant de commencer votre entrainement.</b>
        </p>
      </div>
      <div class="bottom-arrow" @click="scrollToQuestion">
        <img src="../../assets/pictos/bottom-arrow.svg">
      </div>
    </div>
    <div class="outro-content" ref="outroContent">
      <div class="warning-content" v-if="isLevelWarning">
        <div class="title-wrapper">
          <img src="../../assets/pictos/warning.svg">
          <p class="title">
            Attention
          </p>
        </div>
        <div class="content">
          Compte tenu de votre niveau de connaissance, <b>nous vous recommandons de suivre une formation complète pour être certain d’acquérir les bases nécessaires</b> et d’utiliser OBJECTIF RGE pour vous entraîner et tester vos connaissances.
        </div>
      </div>
      <div class="info-content">
        <div class="title-wrapper">
          <img src="../../assets/pictos/lightbulb.svg">
          <p class="title">
            Pré-requis à la qualification RGE
          </p>
        </div>
        <div class="content">
          D’après vos domaines de compétence, plusieurs pré-requis seront nécessaires pour pouvoir soumettre votre dossier de qualification :<br>
          - <b>des preuves de vos réalisations antérieures</b> (devis, photos avant-pendant-après)<br>
          <p v-if="isPrestaNeedTraining">
            - <b>suivre une formation technique</b> dispensée par un industriel (nécessaire pour l’isolation par soufflage, l’ITI et l’ITE)
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OutroStep',
  props: {
    fullModel: {
      type: Object,
      required: true
    }
  },
  computed: {
    isLevelWarning () {
      return this.fullModel.reason === 'passer_examen' &&
        (this.fullModel.level === 'connais_bases' || this.fullModel.level === 'tout_a_apprendre')
    },
    isPrestaNeedTraining () {
      const trainingPresta = ['isolation_combles_soufflage', 'isolation_interieur', 'isolation_exterieur']
      return this.fullModel.prestations.some(presta => trainingPresta.includes(presta))
    }
  },
  methods: {
    scrollToQuestion () {
      const contentEl = this.$refs.outroContent
      if (contentEl) {
        contentEl.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.outro-step {
  .head .content {
    padding: 20px 40px 30px 40px;
    .title {
      font-size: 1.2rem;
      font-weight: 900;
    }
    .intro {
      margin-top: 15px;
      font-size: 0.9rem;
      font-weight: 900;
    }
  }
  .outro-content {
    margin-top: 10px;
    padding: 20px;
    text-align: left;
    .title-wrapper {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      img {
        margin-right: 20px;
      }
      .title {
        font-size: 0.95rem;
        font-weight: 900;
      }
    }
    .warning-content {
      margin-bottom: 15px;
      padding-bottom: 15px;
      border-bottom: 1px solid #c6c6c6;
    }
  }
}
</style>
