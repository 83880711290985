<template>
  <div :class="{ 'collapsable': true, 'collapsed': collapse }">
    <div class="head" @click="toggleCollapse">
      <p class="collapse-arrow"></p>
      <slot name="head"></slot>
    </div>
    <div class="content" v-show="!collapse">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Collapsable',
  props: {
    initialCollapse: {
      type: Boolean,
      required: false,
      default () {
        return true
      }
    }
  },
  data () {
    return {
      collapse: this.initialCollapse
    }
  },
  methods: {
    toggleCollapse () {
      this.collapse = !this.collapse
    }
  }
}
</script>

<style lang="scss">
.collapsable {
  .head {
    cursor: pointer;
    display: flex;
    align-items: center;
    .collapse-arrow {
      flex-shrink: 0;
      margin-right: 10px;
      background-image: url('../assets/chevron.svg');
      background-repeat: no-repeat;
      width: 20px;
      height: 12px;
      transition: 0.4s ease transform;
      transform: rotateX(180deg);
    }
  }
  &.collapsed {
    .head {
      .collapse-arrow {
        transform: rotateX(0deg);
      }
    }
  }
  .content {
    padding-left: 10px;
  }
}
</style>
