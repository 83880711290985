<template>
  <div class="progress-bloc">
    <h2>Ma progression</h2>
    <div v-if="nbQuestionnaries === 0" class="grey-text-bloc">
      <p class="title">
        Vous n'avez fait aucun test...
      </p>
      <p>
        Réalisez une première session pour commencer à suivre votre progression ou consultez les fiches de révision pour renforcer vos connaissances.
      </p>
    </div>
    <div v-else>
      <div class="progress">
        <div class="score-bloc">
          <p class="score">
            {{ nbQuestionnaries }}
          </p>
          <p class="annotation">
            session<span v-if="nbQuestionnaries > 1">s</span>
            <br>
            de test
          </p>
        </div>
        <div class="score-bloc" v-if="nbQuestionnaries > 1">
          <p class="score" :class="statusColorCSS(averageScore)">
            {{ averageScore | round }}
          </p>
          <p class="annotation">
            <strong>Score moyen</strong>
            <br>
            <span v-if="nbQuestionnaries > nbQuestionnariesOkToPass">sur les {{ nbQuestionnariesOkToPass }} derniers tests</span>
          </p>
        </div>
      </div>
      <div class="grey-text-bloc">
        <div class="last-scores-bloc">
          <p class="title">
            Mes <span v-if="nbQuestionnaries > 1 && nbQuestionnaries > nbQuestionnariesOkToPass">{{ nbQuestionnariesOkToPass }}</span> <span v-if="nbQuestionnaries > nbQuestionnariesOkToPass">derniers</span> résulats
          </p>
          <div class="last-scores">
            <div v-for="(questionnary, index) in lastSevenQuestionnaries"
                 :key="'ques' + index"
                 class="score-ring">
              <progress-ring
                  :radius="25"
                  :stroke="4"
                  :progress="calculateProgress(questionnary.score)"
                  :color="calculateProgress(questionnary.score) >= minScorePercentage ? '#25c368' : '#e71212'">
                <p class="score" :class="statusColorCSS(questionnary.score)">
                  {{ questionnary.score }}
                </p>
              </progress-ring>
            </div>
          </div>
        </div>
        <div v-if="userMature">
          <p class="title">
            Vous êtes prêt !
          </p>
          <p>
            Vos résultats sont prometteurs, vous devriez être capable de réussir le test d'évaluation RGE !
          </p>
          <router-link
              to="/dates-examen"
              v-if="canPassExam"
              class="button rdv-button">
            Prendre un rendez-vous
          </router-link>
          <p v-else-if="userType === 'customer' && $store.state.examDate">
            <strong>Vous avez fait une demande d'inscription à la session d'examen du {{ $store.state.examDate.date | formatDate }}</strong>
          </p>
          <p>
            Une fois devenu RGE, n'hésitez pas à revenir tester vos connaissances ou consulter vos fiches de révision de temps en temps.
          </p>
        </div>
        <div v-else-if="userStatus === 'keep-going'">
          <p class="title">
            Continuez !
          </p>
          <p>
            Afin d'aborder l’examen sereinement, continuez à vous entrainer jusqu’à obtenir des résultats corrects sur plusieurs sessions.
          </p>
        </div>
        <div v-else-if="userStatus === 'progress'">
          <p class="title">
            Ce n’est pas encore ça...
          </p>
          <p>
            Vos résultats ne sont pas encore assez bons pour passer l'examen en toute sérénité. Concentrez vos efforts sur la lecture des fiches de révision pour progresser plus rapidement.
          </p>
        </div>
        <div v-else-if="userStatus === 'almost'">
          <p class="title">
            Encore un effort, on y est presque&nbsp;!
          </p>
          <p>
            Votre assiduité porte ses fruits mais vos résultats ne sont pas encore stables. Faites quelques sessions et aidez-vous des fiches de révision pour continuer de vous améliorer.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProgressRing from '@/components/ProgressRing'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'HomeProgressBlock',
  components: {
    ProgressRing
  },
  computed: {
    ...mapState('test', ['nbQuestionnariesOkToPass', 'questionnaries', 'minScorePercentage']),
    ...mapGetters('test', ['nbQuestionnaries', 'nbQuestions', 'averageScore', 'lastSevenQuestionnaries']),
    ...mapState('user', ['userStatus', 'userType', 'userMature']),
    ...mapGetters(['canPassExam'])
  },
  methods: {
    calculateProgress (score) {
      return score / this.nbQuestions * 100
    },
    statusColorCSS (score) {
      return {
        success: this.calculateProgress(score) >= this.minScorePercentage,
        fail: this.calculateProgress(score) < this.minScorePercentage
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.progress-bloc {
  .progress {
    margin-top: $spacer-md;
    margin-bottom: $spacer;
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    .score-bloc {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .score {
        font-size: 3rem;
        font-weight: 900;
      }
      .annotation {
        margin-top: -$spacer-sm;
        font-size: 0.85rem;
      }
    }
  }
}
.rdv-button {
  margin: $spacer-md 0;
  height: 60px;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  font-weight: 900;
  color: #fff;
  @include themify($themes) {
    background-color: themed('backgroundColor');
  }
  transition: 0.3s background-color;
  font-size: 0.95rem;
  background-image: url('../assets/pictos/calendar.svg');
  background-repeat: no-repeat;
  background-position: left 25px center;
  background-size: 18px 18px;
}
.last-scores-bloc {
  text-align: center;
  .last-scores {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: $spacer-md 0;
    .score-ring {
      .score {
        font-weight: 900;
        font-size: 0.9rem;
        margin-top: -5px;
      }
    }
  }
}
</style>
