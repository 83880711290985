<template>
  <div class="progress-ring">
    <svg
      :height="radius * 2"
      :width="radius * 2">
      <circle
        stroke="#b2b2b2"
        :stroke-width="stroke"
        fill="transparent"
        :r="normalizedRadius"
        :cx="radius"
        :cy="radius"/>
      <circle
        :stroke="color"
        :stroke-dasharray="circumference + ' ' + circumference"
        :style="{ strokeDashoffset: strokeDashoffset }"
        :stroke-width="stroke"
        fill="transparent"
        :r="normalizedRadius"
        :cx="radius"
        :cy="radius"/>
    </svg>
    <div class="inner-circle">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProgressRing',
  props: {
    radius: {
      type: Number,
      required: false,
      default () {
        return 20
      }
    },
    progress: {
      type: Number,
      required: true
    },
    stroke: {
      type: Number,
      required: true
    },
    color: {
      type: String,
      required: false,
      default () {
        return 'grey'
      }
    }
  },
  data () {
    const normalizedRadius = this.radius - this.stroke * 2
    const circumference = normalizedRadius * 2 * Math.PI
    return {
      normalizedRadius,
      circumference,
      strokeDashoffset: circumference
    }
  },
  mounted () {
    setTimeout(() => {
      this.strokeDashoffset = this.circumference - this.progress / 100 * this.circumference
    }, 300)
  }
}
</script>

<style lang=scss>
.progress-ring {
  position: relative;
  circle {
    transition: stroke-dashoffset 0.8s;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
  }
  .inner-circle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
