import correctionsA from './categorie-A/corrections.js'
import correctionsB from './categorie-B/corrections.js'
import correctionsC from './categorie-C/corrections.js'
import correctionsD from './categorie-D/corrections.js'
import correctionsE from './categorie-E/corrections.js'
import correctionsF from './categorie-F/corrections.js'
import correctionsG from './categorie-G/corrections.js'
import correctionsH from './categorie-H/corrections.js'
import correctionsI from './categorie-I/corrections.js'

export default [...correctionsA, ...correctionsB, ...correctionsC, ...correctionsD, ...correctionsE, ...correctionsF, ...correctionsG, ...correctionsH, ...correctionsI]
