var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "select",
      class: { open: _vm.showList },
      attrs: { tabindex: "0" },
      on: {
        click: function ($event) {
          _vm.showList = !_vm.showList
        },
        focusout: function ($event) {
          _vm.showList = false
        },
      },
    },
    [
      _c("div", { staticClass: "current" }, [
        _vm._v(" " + _vm._s(_vm.model ? _vm.model.name : "") + " "),
      ]),
      _c(
        "div",
        { staticClass: "list" },
        _vm._l(_vm.list, function (choice, indexChoice) {
          return _c(
            "span",
            {
              key: indexChoice,
              staticClass: "label",
              on: {
                click: function ($event) {
                  return _vm.$emit("update:model", choice)
                },
              },
            },
            [_vm._v(" " + _vm._s(choice.name) + " ")]
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }