import questionsA from './categorie-A/questions.js'
import questionsB from './categorie-B/questions.js'
import questionsC from './categorie-C/questions.js'
import questionsD from './categorie-D/questions.js'
import questionsE from './categorie-E/questions.js'
import questionsF from './categorie-F/questions.js'
import questionsG from './categorie-G/questions.js'
import questionsH from './categorie-H/questions.js'
import questionsI from './categorie-I/questions.js'

export default [...questionsA, ...questionsB, ...questionsC, ...questionsD, ...questionsE, ...questionsF, ...questionsG, ...questionsH, ...questionsI]
