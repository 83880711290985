const questions = [
  {
    id: 'd1-1',
    category: 'd',
    subject: 'd1',
    label: 'Pour protéger l’isolant, le pare-vapeur doit toujours être positionné\u00A0:',
    fullExplanation: 'ra-d1-1',
    simpleExplanation: 'rs-d1-1',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Côté froid (vers l’extérieur pour protéger l’isolant des intempéries)'
      },
      {
        name: 'b',
        label: 'Côté chaud (vers l’intérieur pour protéger l’isolant de l’humidité de l’air intérieur)'
      }
    ]
  },
  {
    id: 'd1-2',
    category: 'd',
    subject: 'd1',
    label: 'On installe un pare-vapeur pour protéger l’isolation de l’humidité de l’air intérieur\u00A0:',
    fullExplanation: 'ra-d1-1',
    simpleExplanation: 'rs-d1-1',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'd1-3',
    category: 'd',
    subject: 'd1',
    label: ' Pour protéger l’isolation de l’humidité de l’air intérieur, on utilise\u00A0:',
    fullExplanation: 'ra-d1-1',
    simpleExplanation: 'rs-d1-1',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'Un pare-pluie'
      },
      {
        name: 'b',
        label: 'Une membrane d’étanchéité à l’air'
      },
      {
        name: 'c',
        label: 'Un pare-vapeur'
      }
    ]
  },
  {
    id: 'd1-4',
    category: 'd',
    subject: 'd1',
    label: 'De quel côté de la paroi doit-on poser le pare-vapeur d\'un isolant\u00A0?',
    fullExplanation: 'ra-d1-2',
    simpleExplanation: 'rs-d1-2',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'Côté "non chauffé"'
      },
      {
        name: 'b',
        label: 'Indifféremment du côté "chauffé" ou du côté "non chauffé"'
      },
      {
        name: 'c',
        label: 'Côté "chauffé"'
      }
    ]
  },
  {
    id: 'd1-5',
    category: 'd',
    subject: 'd1',
    label: 'Il faut toujours poser le pare-vapeur de l’isolant du côté chauffé de la paroi\u00A0:',
    fullExplanation: 'ra-d1-2',
    simpleExplanation: 'rs-d1-2',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'd1-6',
    category: 'd',
    subject: 'd1',
    label: 'Lorsque je pose un isolant avec un pare-vapeur, je dois toujours poser le pare-vapeur\u00A0:',
    fullExplanation: 'ra-d1-2',
    simpleExplanation: 'rs-d1-2',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Du côté "chauffé" de la paroi'
      },
      {
        name: 'b',
        label: 'Du côté "non chauffé" de la paroi'
      },
      {
        name: 'c',
        label: 'Indifféremment du côté "chauffé" ou du côté "non chauffé" de la paroi'
      }
    ]
  },
  {
    id: 'd1-7',
    category: 'd',
    subject: 'd1',
    label: 'Si un isolant est posé avec son pare-vapeur du côté froid de la paroi, il y a un risque d’apparition de pathologies à terme.',
    fullExplanation: 'ra-d1-2',
    simpleExplanation: 'rs-d1-2',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'd1-8',
    category: 'd',
    subject: 'd1',
    label: 'Une plaque de plâtre est étanche à la vapeur d\'eau.',
    fullExplanation: 'ra-d1-3',
    simpleExplanation: 'rs-d1-3',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'd1-9',
    category: 'd',
    subject: 'd1',
    label: 'Une plaque de plâtre peut jouer le rôle de pare-vapeur\u00A0:',
    fullExplanation: 'ra-d1-3',
    simpleExplanation: 'rs-d1-3',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'd1-10',
    category: 'd',
    subject: 'd1',
    label: 'Lorsque je mets en œuvre un isolant en laine minérale derrière une plaque de plâtre sur ossature métallique, je n’ai pas besoin de mettre de pare-vapeur car la plaque de plâtre joue ce rôle\u00A0:',
    fullExplanation: 'ra-d1-3',
    simpleExplanation: 'rs-d1-3',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'd1-11',
    category: 'd',
    subject: 'd1',
    label: 'Une plaque de plâtre\u00A0:',
    fullExplanation: 'ra-d1-3',
    simpleExplanation: 'rs-d1-3',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Est étanche à la vapeur d\'eau'
      },
      {
        name: 'b',
        label: 'Laisse passer la vapeur d’eau'
      }
    ]
  },
  {
    id: 'd2-1',
    category: 'd',
    subject: 'd2',
    label: 'L’activité humaine à l’intérieur d’un logement génère\u00A0:',
    fullExplanation: 'ra-d2-1',
    simpleExplanation: 'rs-d2-1',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Du monoxyde de carbone'
      },
      {
        name: 'b',
        label: 'De la vapeur d’eau'
      },
      {
        name: 'c',
        label: 'Du radon'
      }
    ]
  },
  {
    id: 'd2-2',
    category: 'd',
    subject: 'd2',
    label: 'L’activité humaine à l’intérieur d’un logement ne modifie pas son taux d’humidité.',
    fullExplanation: 'ra-d2-1',
    simpleExplanation: 'rs-d2-1',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'd2-3',
    category: 'd',
    subject: 'd2',
    label: 'Quel est le principal contributeur d’émission de vapeur d’eau dans un logement\u00A0:',
    fullExplanation: 'ra-d2-1',
    simpleExplanation: 'rs-d2-1',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'Les plantes vertes'
      },
      {
        name: 'b',
        label: 'Les animaux domestiques'
      },
      {
        name: 'c',
        label: 'Les activités humaines'
      }
    ]
  },
  {
    id: 'd2-4',
    category: 'd',
    subject: 'd2',
    label: 'En quoi se transforme la vapeur d\'eau quand elle atteint le point de rosée\u00A0?',
    fullExplanation: 'ra-d2-2',
    simpleExplanation: 'rs-d2-2',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'En glace'
      },
      {
        name: 'b',
        label: 'Elle ne se transforme pas '
      },
      {
        name: 'c',
        label: 'En gouttelettes d\'eau'
      }
    ]
  },
  {
    id: 'd2-5',
    category: 'd',
    subject: 'd2',
    label: 'Le point de rosée, c’est la transformation de la vapeur d\'eau en\u00A0:',
    fullExplanation: 'ra-d2-2',
    simpleExplanation: 'rs-d2-2',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Solide (glace)'
      },
      {
        name: 'b',
        label: 'Liquide (gouttelettes) '
      },
      {
        name: 'c',
        label: 'Gaz'
      }
    ]
  },
  {
    id: 'd2-6',
    category: 'd',
    subject: 'd2',
    label: 'Comment appelle-t-on la température à laquelle la vapeur d\'eau se transforme en gouttelette d’eau\u00A0?',
    fullExplanation: 'ra-d2-2',
    simpleExplanation: 'rs-d2-2',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'La température de fusion'
      },
      {
        name: 'b',
        label: 'La température de vaporisation '
      },
      {
        name: 'c',
        label: 'Le point de rosée'
      }
    ]
  },
  {
    id: 'd2-7',
    category: 'd',
    subject: 'd2',
    label: 'Le point de rosée, c’est la température à laquelle la vapeur d\'eau se transforme en gouttelette d’eau\u00A0:',
    fullExplanation: 'ra-d2-2',
    simpleExplanation: 'rs-d2-2',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'd2-8',
    category: 'd',
    subject: 'd2',
    label: 'A l’intérieur d’un logement, plus une paroi est froide, plus le risque de condensation est important.',
    fullExplanation: 'ra-d2-3',
    simpleExplanation: 'rs-d2-3',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'd2-9',
    category: 'd',
    subject: 'd2',
    label: 'Les parois froides dans un logement, peuvent générer un risque de condensation\u00A0:',
    fullExplanation: 'ra-d2-3',
    simpleExplanation: 'rs-d2-3',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'd2-10',
    category: 'd',
    subject: 'd2',
    label: 'Les condensations dans un logement, peuvent être générer par\u00A0:',
    fullExplanation: 'ra-d2-3',
    simpleExplanation: 'rs-d2-3',
    correctAnswers: ['b', 'c'],
    choices: [
      {
        name: 'a',
        label: 'Des courant d’air'
      },
      {
        name: 'b',
        label: 'Des parois froides'
      },
      {
        name: 'c',
        label: 'Des ponts thermiques'
      }
    ]
  },
  {
    id: 'd2-11',
    category: 'd',
    subject: 'd2',
    label: 'Pour limiter les risques de condensations dans un logement, je traite\u00A0:',
    fullExplanation: 'ra-d2-3',
    simpleExplanation: 'rs-d2-3',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Les parois froides'
      },
      {
        name: 'b',
        label: 'Les parois chaudes'
      },
      {
        name: 'c',
        label: 'Les défauts d’étanchéité à l’air'
      }
    ]
  },
  {
    id: 'd2-12',
    category: 'd',
    subject: 'd2',
    label: 'La vapeur d\'eau est toujours visible sous forme de fumée ou de brume.',
    fullExplanation: 'ra-d2-4',
    simpleExplanation: 'rs-d2-4',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Faux'
      },
      {
        name: 'b',
        label: 'Vrai'
      }
    ]
  },
  {
    id: 'd2-13',
    category: 'd',
    subject: 'd2',
    label: 'La vapeur d\'eau est-elle visible\u00A0?',
    fullExplanation: 'ra-d2-4',
    simpleExplanation: 'rs-d2-4',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Oui'
      },
      {
        name: 'b',
        label: 'Non'
      }
    ]
  },
  {
    id: 'd2-14',
    category: 'd',
    subject: 'd2',
    label: 'Le nuage qui peut se dégager lors de la prise d’une douche, c’est\u00A0:',
    fullExplanation: 'ra-d2-4',
    simpleExplanation: 'rs-d2-4',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'De la vapeur d’eau'
      },
      {
        name: 'b',
        label: 'Des gouttelettes d’eau'
      }
    ]
  },
  {
    id: 'd2-15',
    category: 'd',
    subject: 'd2',
    label: 'Le nuage qui peut se dégager lors de la prise d’une douche, est visible car il est constitué de gouttelettes d’eau\u00A0:',
    fullExplanation: 'ra-d2-4',
    simpleExplanation: 'rs-d2-4',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'd3-1',
    category: 'd',
    subject: 'd3',
    label: 'Une chaudière à condensation condense sur toute la période de chauffage\u00A0:',
    fullExplanation: 'ra-d3-1',
    simpleExplanation: 'rs-d3-1',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'VRAI – Si les émetteurs sont dimensionnés en conséquence.'
      },
      {
        name: 'b',
        label: 'C’est peu probable'
      },
      {
        name: 'c',
        label: 'FAUX - C’est impossible'
      }
    ]
  },
  {
    id: 'd3-2',
    category: 'd',
    subject: 'd3',
    label: 'Une chaudière à condensation ne condense pas\u00A0:',
    fullExplanation: 'ra-d3-1',
    simpleExplanation: 'rs-d3-1',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Lorsque la température de retour de l’eau du chauffage est trop haute'
      },
      {
        name: 'b',
        label: 'Lorsque la température de retour de l’eau du chauffage est trop basse'
      }
    ]
  },
  {
    id: 'd3-3',
    category: 'd',
    subject: 'd3',
    label: 'Une chaudière à condensation ne peut pas être installée sur une installation comprenant des émetteurs à haute température\u00A0:',
    fullExplanation: 'ra-d3-1',
    simpleExplanation: 'rs-d3-1',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'VRAI, ça ne fonctionne pas'
      },
      {
        name: 'b',
        label: 'FAUX, mais le rendement ne sera pas optimum'
      }
    ]
  },
  {
    id: 'd3-4',
    category: 'd',
    subject: 'd3',
    label: 'Le surdimensionnement d’un appareil de chauffage peut entrainer une surconsommation d’énergie\u00A0?',
    fullExplanation: 'ra-d3-2',
    simpleExplanation: 'rs-d3-2',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Faux'
      },
      {
        name: 'b',
        label: 'Vrai'
      }
    ]
  },
  {
    id: 'd3-5',
    category: 'd',
    subject: 'd3',
    label: 'Si un appareil de chauffage est sur dimensionné\u00A0:',
    fullExplanation: 'ra-d3-2',
    simpleExplanation: 'rs-d3-2',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Cela peut entrainer une surconsommation d’énergie'
      },
      {
        name: 'b',
        label: 'Cela peut générer des économies d’énergie'
      },
      {
        name: 'c',
        label: 'Il va forcément tomber en panne'
      }
    ]
  },
  {
    id: 'd3-6',
    category: 'd',
    subject: 'd3',
    label: 'Pour avoir la consommation d’énergie la plus réduite possible, il vaut mieux\u00A0:',
    fullExplanation: 'ra-d3-2',
    simpleExplanation: 'rs-d3-2',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Que mon appareil de chauffage soit surdimensionné (qui peut le plus peut le moins)'
      },
      {
        name: 'b',
        label: 'Que mon appareil de chauffage soit correctement dimensionné'
      },
      {
        name: 'c',
        label: 'Que mon appareil de chauffage soit légèrement sous dimensionné (j’évite les surchauffes)'
      }
    ]
  },
  {
    id: 'd3-7',
    category: 'd',
    subject: 'd3',
    label: 'Une chaudière fioul fait 32 kW de puissance pour 18 kW de déperditions. Cette situation peut-elle entrainer une surconsommation d’énergie\u00A0?',
    fullExplanation: 'ra-d3-2',
    simpleExplanation: 'rs-d3-2',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Oui'
      },
      {
        name: 'b',
        label: 'Non'
      }
    ]
  },
  {
    id: 'd3-8',
    category: 'd',
    subject: 'd3',
    label: 'La résistance thermique d\'un isolant diminue si il est humide\u00A0:',
    fullExplanation: 'ra-d3-3',
    simpleExplanation: 'rs-d3-3',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'd3-9',
    category: 'd',
    subject: 'd3',
    label: 'De l’humidité dans un isolant réduit sa résistance thermique\u00A0:',
    fullExplanation: 'ra-d3-3',
    simpleExplanation: 'rs-d3-3',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'd3-10',
    category: 'd',
    subject: 'd3',
    label: 'Un isolant humide est plus performant qu’un isolant sec\u00A0:',
    fullExplanation: 'ra-d3-3',
    simpleExplanation: 'rs-d3-3',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'd3-11',
    category: 'd',
    subject: 'd3',
    label: 'Protéger un isolant de l’humidité permet de préserver ses performances thermiques\u00A0:',
    fullExplanation: 'ra-d3-3',
    simpleExplanation: 'rs-d3-3',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'd4-1',
    category: 'd',
    subject: 'd4',
    label: 'Il est pertinent de prévoir une ventilation quand on remplace les menuiseries.',
    fullExplanation: 'ra-d4-1',
    simpleExplanation: 'rs-d4-1',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'd4-2',
    category: 'd',
    subject: 'd4',
    label: 'Il est pertinent de prévoir une ventilation quand on isole des parois opaques.',
    fullExplanation: 'ra-d4-1',
    simpleExplanation: 'rs-d4-1',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'd4-3',
    category: 'd',
    subject: 'd4',
    label: 'Quand on rénove de manière performante, on recherche la meilleure étanchéité à l’air possible, il ne faut donc surtout pas prévoir d’entrée d’air dans les pièces principales.',
    fullExplanation: 'ra-d4-1',
    simpleExplanation: 'rs-d4-1',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'd4-4',
    category: 'd',
    subject: 'd4',
    label: 'Pour s\'assurer que l\'air neuf, amené par une ventilation mécanique, transite de manière optimale dans un logement\u00A0:',
    fullExplanation: 'ra-d4-2',
    simpleExplanation: 'rs-d4-2',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Il est nécessaire de prévoir un détalonnage ou des grilles de transferts dans les portes intérieures '
      },
      {
        name: 'b',
        label: 'Il est nécessaire de prévoir un détalonnage ou des grilles de transferts dans les portes donnant sur un garage'
      },
      {
        name: 'c',
        label: 'Il est nécessaire de prévoir un détalonnage ou des grilles de transferts dans les portes donnant sur l\'extérieur'
      }
    ]
  },
  {
    id: 'd4-5',
    category: 'd',
    subject: 'd4',
    label: 'Le détalonnage ou des grilles de transferts dans les portes intérieures sont indispensable pour s\'assurer du bon fonctionnement de la ventilation mécanique d’un logement\u00A0:',
    fullExplanation: 'ra-d4-2',
    simpleExplanation: 'rs-d4-2',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'd4-6',
    category: 'd',
    subject: 'd4',
    label: 'Pour s\'assurer du bon fonctionnement de la ventilation mécanique d’un logement, le détalonnage ou l’installation de grilles de transferts dans les portes intérieures sont \u00A0:',
    fullExplanation: 'ra-d4-2',
    simpleExplanation: 'rs-d4-2',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Facultatifs'
      },
      {
        name: 'b',
        label: 'Indispensables'
      },
      {
        name: 'c',
        label: 'Sans impact sur le fonctionnement du système de ventilation'
      }
    ]
  },
  {
    id: 'd4-7',
    category: 'd',
    subject: 'd4',
    label: 'Une ventilation mécanique simple flux ne peut pas fonctionner correctement sans prévoir le détalonnage ou l’installation de grilles de transferts dans les portes intérieures\u00A0:',
    fullExplanation: 'ra-d4-2',
    simpleExplanation: 'rs-d4-2',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'd4-8',
    category: 'd',
    subject: 'd4',
    label: 'Un système de ventilation double flux avec récupération de chaleur est plus efficace si le bâtiment est étanche à l\'air.',
    fullExplanation: 'ra-d4-3',
    simpleExplanation: 'rs-d4-3',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'd4-9',
    category: 'd',
    subject: 'd4',
    label: 'Le niveau d’étanchéité à l\'air du bâtiment n’a pas d’impact sur la performance du système de ventilation double flux avec récupération de chaleur\u00A0:',
    fullExplanation: 'ra-d4-3',
    simpleExplanation: 'rs-d4-3',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Faux'
      },
      {
        name: 'b',
        label: 'Vrai'
      }
    ]
  },
  {
    id: 'd4-10',
    category: 'd',
    subject: 'd4',
    label: 'Il est préférable que le bâtiment soit très étanche à l\'air pour installer un système de ventilation double flux avec récupération de chaleur\u00A0:',
    fullExplanation: 'ra-d4-3',
    simpleExplanation: 'rs-d4-3',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Faux'
      },
      {
        name: 'b',
        label: 'Vrai'
      }
    ]
  },
  {
    id: 'd4-11',
    category: 'd',
    subject: 'd4',
    label: 'Quel paramètre a un impact sur la performance d’un système de ventilation double flux avec récupération de chaleur\u00A0:',
    fullExplanation: 'ra-d4-3',
    simpleExplanation: 'rs-d4-3',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'La surface de vitrage'
      },
      {
        name: 'b',
        label: 'Le nombre de pièce'
      },
      {
        name: 'c',
        label: 'L’étanchéité à l’air du bâtiment'
      },
      {
        name: 'd',
        label: 'La surface déperditive du bâtiment'
      }
    ]
  },
  {
    id: 'd5-1',
    category: 'd',
    subject: 'd5',
    label: 'La condensation dans les parois peut être provoquée par\u00A0:',
    fullExplanation: 'ra-d5-1',
    simpleExplanation: 'rs-d5-1',
    correctAnswers: ['a', 'b'],
    choices: [
      {
        name: 'a',
        label: 'Des infiltrations d’air parasite '
      },
      {
        name: 'b',
        label: 'La migration de la vapeur d’eau contenue dans l’air intérieur '
      },
      {
        name: 'c',
        label: 'La mise en place d’un pare-vapeur'
      }
    ]
  },
  {
    id: 'd5-2',
    category: 'd',
    subject: 'd5',
    label: 'Les passages d\'air parasites accentuent les risques de condensation\u00A0:',
    fullExplanation: 'ra-d5-1',
    simpleExplanation: 'rs-d5-1',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'd5-3',
    category: 'd',
    subject: 'd5',
    label: 'En hiver, de l’air qui s’exfiltre d’un local chauffé vers l’extérieur risque de provoquer des phénomènes\u00A0:',
    fullExplanation: 'ra-d5-1',
    simpleExplanation: 'rs-d5-1',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'D’évaporation'
      },
      {
        name: 'b',
        label: 'De condensation'
      },
      {
        name: 'c',
        label: 'De vaporisation'
      },
      {
        name: 'd',
        label: 'De fusion'
      }
    ]
  },
  {
    id: 'd5-4',
    category: 'd',
    subject: 'd5',
    label: 'Si je constate des traces d\'humidité chez un client, que puis-je lui conseiller en premier\u00A0?',
    fullExplanation: 'ra-d5-2',
    simpleExplanation: 'rs-d5-2',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'De mettre en place une ventilation mécanique '
      },
      {
        name: 'b',
        label: 'D\'appliquer un enduit ciment sur les parois '
      },
      {
        name: 'c',
        label: 'De changer son système de chauffage'
      }
    ]
  },
  {
    id: 'd5-5',
    category: 'd',
    subject: 'd5',
    label: 'Il faut conseiller l’installation d’une ventilation mécanique à un client chez qui on constate des traces d\'humidité\u00A0:',
    fullExplanation: 'ra-d5-2',
    simpleExplanation: 'rs-d5-2',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Faux'
      },
      {
        name: 'b',
        label: 'Vrai'
      }
    ]
  },
  {
    id: 'd5-6',
    category: 'd',
    subject: 'd5',
    label: 'L’installation d’une ventilation mécanique peut permettre de résoudre les pathologies de types traces d\'humidité\u00A0:',
    fullExplanation: 'ra-d5-2',
    simpleExplanation: 'rs-d5-2',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Faux'
      },
      {
        name: 'b',
        label: 'Vrai'
      }
    ]
  },
  {
    id: 'd5-7',
    category: 'd',
    subject: 'd5',
    label: 'Un défaut de ventilation peut être mis en évidence par le constat de présence de\u00A0:',
    fullExplanation: 'ra-d5-2',
    simpleExplanation: 'rs-d5-2',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Traces d\'humidité'
      },
      {
        name: 'b',
        label: 'Ponts thermiques'
      },
      {
        name: 'c',
        label: 'Simple vitrage'
      }
    ]
  },
  {
    id: 'd6-1',
    category: 'd',
    subject: 'd6',
    label: 'Une caméra thermique mesure\u00A0:',
    fullExplanation: 'ra-d6-1',
    simpleExplanation: 'rs-d6-1',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'La température de l’air'
      },
      {
        name: 'b',
        label: 'La température dans les parois'
      },
      {
        name: 'c',
        label: 'La température de la surface des parois'
      }
    ]
  },
  {
    id: 'd6-2',
    category: 'd',
    subject: 'd6',
    label: 'Le rayonnement infrarouge que capte une caméra thermique traduit\u00A0:',
    fullExplanation: 'ra-d6-1',
    simpleExplanation: 'rs-d6-1',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'La température de l’air'
      },
      {
        name: 'b',
        label: 'La température dans les parois'
      },
      {
        name: 'c',
        label: 'La température de la surface des parois'
      }
    ]
  },
  {
    id: 'd6-3',
    category: 'd',
    subject: 'd6',
    label: 'Quel outil peut me permettre de localiser les ponts thermiques\u00A0?',
    fullExplanation: 'ra-d6-1',
    simpleExplanation: 'rs-d6-1',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'Un ampèremètre'
      },
      {
        name: 'b',
        label: 'Un anémomètre'
      },
      {
        name: 'c',
        label: 'Une caméra thermique'
      }
    ]
  },
  {
    id: 'd6-4',
    category: 'd',
    subject: 'd6',
    label: 'Selon le NF DTU pose de menuiseries, quand doivent être réalisés les appuis des baies\u00A0?',
    fullExplanation: 'ra-d6-2',
    simpleExplanation: 'rs-d6-2',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'En même temps que la pose des menuiseries'
      },
      {
        name: 'b',
        label: 'Avant la pose des menuiseries'
      },
      {
        name: 'c',
        label: 'Après la pose des menuiseries'
      }
    ]
  },
  {
    id: 'd6-5',
    category: 'd',
    subject: 'd6',
    label: 'Les appuis des baies doivent être réalisés après la pose des menuiseries\u00A0:',
    fullExplanation: 'ra-d6-2',
    simpleExplanation: 'rs-d6-2',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Faux'
      },
      {
        name: 'b',
        label: 'Vrai'
      }
    ]
  },
  {
    id: 'd6-6',
    category: 'd',
    subject: 'd6',
    label: 'Est-il possible de réaliser les appuis des baies après la pose des menuiseries\u00A0?',
    fullExplanation: 'ra-d6-2',
    simpleExplanation: 'rs-d6-2',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Oui'
      },
      {
        name: 'b',
        label: 'Non'
      }
    ]
  },
  {
    id: 'd6-7',
    category: 'd',
    subject: 'd6',
    label: 'Avant la pose des menuiseries, faut-il que les appuis des baies soient déjà réalisés\u00A0:',
    fullExplanation: 'ra-d6-2',
    simpleExplanation: 'rs-d6-2',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Oui'
      },
      {
        name: 'b',
        label: 'Non'
      }
    ]
  }
]

export default questions
