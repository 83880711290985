const corrections = [
  {
    name: 'rs-e1-1',
    content: 'On appelle Isolation Thermique Répartie (ITR) le mode constructif dont l’élément porteur apporte aussi l’isolation thermique à la paroi. Cette isolation est donc répartie dans l’épaisseur de la paroi. Exemples : béton cellulaire, brique monomur en forte épaisseur, bois massif.'
  },
  {
    name: 'rs-e1-2',
    content: 'Pour que l’isolation des combles perdus soit la plus efficace possible, il faut privilégier une mise en œuvre en deux couches croisées.'
  },
  {
    name: 'rs-e1-3',
    content: 'Tous les isolants industrialisés peuvent être certifiés ACERMI, et les isolant biosourcés ne font pas exception.'
  },
  {
    name: 'rs-e1-4',
    content: 'Il faut être particulièrement vigilant dans le choix des propriétés de migration de vapeur d\'eau de l’isolant que l’on pose dans tout type de bâtiment.'
  },
  {
    name: 'rs-e1-5',
    content: 'Pour savoir s’il est obligatoire d’installer un pare-vapeur lorsque je mets en œuvre un isolant en combles, je dois consulter l\'Avis Technique, le Document Technique d\'Application et le Cahier des Prescriptions Techniques associé.'
  },
  {
    name: 'rs-e1-6',
    content: 'Le polyuréthane est plus isolant que la laine de bois, le béton cellulaire, le béton de chanvre, la laine de verre. Le polyuréthane a une des meilleures conductivité parmi les isolants.'
  },
  {
    name: 'rs-e1-8',
    content: 'Dans tous les cas les trappes d\'accès aux combles perdus doivent être munies d\'un joint pour en assurer l\'étanchéité à l\'air, que ce soit en cas d’isolation des combles ou pas.'
  },
  {
    name: 'rs-e1-9',
    content: 'En France, l’isolant le plus couramment utilisé pour de procédé d’ITE sous enduit est le polystyrène.'
  },
  {
    name: 'rs-e1-10',
    content: 'ITE signifie Isolation Thermique par l’Extérieur.'
  },
  {
    name: 'rs-e2-1',
    content: 'Le label « flamme verte » concerne les appareils de chauffage domestiques au bois : Les appareils indépendants à bûches ou à granulés (poêle, foyer fermé/insert, cuisinière, poêle à accumulation) et les chaudières à bûches ou à granulés.<br>' +
      'Rappel : les cheminées à foyer ouvert ne sont pas considérées comme des systèmes de chauffage.'
  },
  {
    name: 'rs-e2-2',
    content: 'L’humidité dans le bois de chauffage influe sur la quantité d’énergie récupérable : plus un bois est humide moins la quantité d’énergie récupérable est importante.'
  },
  {
    name: 'rs-e2-3',
    content: 'La biomasse désigne les matériaux qui ont une origine végétale ou animale. On peut en extraire de l’énergie par combustion, méthanisation ou transformation chimique.'
  },
  {
    name: 'rs-e3-1',
    content: 'Le solaire photovoltaïque est une technologie qui consiste à produire de l’électricité à partir du rayonnement solaire. A ne pas confondre avec le solaire thermique qui est une technologie qui consiste à produire de l’eau chaude à partir du rayonnement solaire.'
  },
  {
    name: 'rs-e3-2',
    content: 'Pour produire l\'équivalent des besoins annuels en électricité, hors chauffage et eau chaude sanitaire, d\'un foyer de 4 personnes, il faut environ 25 m² de panneaux photovoltaïques.'
  },
  {
    name: 'rs-e3-3',
    content: 'Un « système solaire combiné » est un système qui utilise le rayonnement solaire pour produire l\'eau chaude sanitaire et une partie des besoins en chauffage.'
  },
  {
    name: 'rs-e3-4',
    content: 'Un panneau solaire photovoltaïque produit de l\'électricité, un panneau solaire thermique produit de l’eau chaude par l’intermédiaire d’un circuit de fluide caloporteur.'
  },
  {
    name: 'rs-e3-5',
    content: 'Le sigle CESI signifie Chauffe-Eau Solaire Individuel'
  },
  {
    name: 'rs-e4-1',
    content: 'Une pompe à chaleur est principalement composée des éléments suivants : un <strong>compresseur</strong>, un <strong>condenseur</strong>, un <strong>détendeur</strong>, un <strong>évaporateur</strong>.<br>' +
      'Le système <strong>consomme de l’énergie</strong> (souvent électrique) uniquement avec le <strong>compresseur</strong> qui compresse le gaz. Celui-ci va ensuite dans le condenseur où il <strong>condense en rejetant de la chaleur</strong>. La pression du liquide est ensuite réduite dans le détendeur puis le liquide change d’état <strong>dans l’évaporateur en absorbant des calories</strong>, et le cycle recommence.'
  },
  {
    name: 'rs-e4-2',
    content: 'Le COP (Coefficient de Performance) sert à désigner la performance des Pompes à Chaleur en mode production de chauffage.'
  },
  {
    name: 'rs-e4-3',
    content: 'En géothermie, la performance moyenne d’une Pompe à Chaleur est un COP compris entre 3 et 4.'
  },
  {
    name: 'rs-e4-4',
    content: 'Une installation géothermique est un système de production de chaleur permettant de puiser des calories dans le sol.'
  },
  {
    name: 'rs-e4-5',
    content: 'L’acronyme PAC signifie Pompe A Chaleur.'
  },
  {
    name: 'rs-e4-6',
    content: 'Le coefficient de performance d\'une pompe à chaleur (COP) se calcul en faisant le ratio de l\'énergie thermique restituée sur l’énergie électrique consommée par la pompe à chaleur.'
  },
  {
    name: 'rs-e4-7',
    content: 'On estime une Pompe à Chaleur performante lorsqu’elle affiche un COP supérieur à 3.'
  },
  {
    name: 'rs-e4-8',
    content: 'Le COP (Coefficient de Performance) sert à désigner la performance des Pompes à Chaleur en mode production de chauffage.'
  },
  {
    name: 'rs-e5-1',
    content: 'Par convention, on caractérise un double vitrage de la manière suivante : « épaisseur du verre 1 » – « épaisseur de la lame d’air (ou de gaz rare) » – « épaisseur du verre 2 ». Ce qui donne « 4-16-4 » pour un double vitrage avec un verre de 4 mm, une lame d’air (ou de gaz rare) de 16 mm et un verre de 4 mm.'
  },
  {
    name: 'rs-e5-2',
    content: 'Un profilé à rupture de pont thermique dans une menuiserie est un profilé (métallique) qui incorpore un matériau non conducteur de chaleur.'
  },
  {
    name: 'rs-e5-3',
    content: 'Un double vitrage peu émissif est muni d\'une fine couche translucide d\'oxydes métalliques déposée sur une des faces internes du double vitrage.'
  },
  {
    name: 'rs-e5-4',
    content: 'Dans les doubles vitrages, on peut trouver des gaz nobles (rares) que sont l’Argon, le Krypton ou le Xénon.'
  },
  {
    name: 'rs-e6-bruit-pac-1',
    content: 'La réglementation impose une valeur limite de bruit spécifique à l’équipement en limite de propriété, le jour et la nuit. Cette émergence sonore est mesurable et s’exprime en Décibel pondérés A ou dB(A).'
  },
  {
    name: 'rs-e6-1',
    content: 'Le principe de ventilation mécanique simple flux est le suivant :<br>' +
      '<ul><li>Entrées d’air neuf dans les pièces principales (chambres, séjour, …)</li>' +
      '<li>Grilles de transfert ou détalonnage des portes pour les pièces de circulation (entrée, couloir, …)</li>' +
      '<li>Bouches d’extraction de l’air vicié dans les pièces de services (salle de bains, WC, …)</li></ul>'
  },
  {
    name: 'rs-e6-2',
    content: 'Dans une installation de VMC simple flux, les pièces humides (cuisine, salle de bain, WC) sont équipées de bouches d’extractions et les pièces principales sont équipées d’entrées d’air.'
  },
  {
    name: 'rs-e6-3',
    content: 'Dans une installation de VMC double flux, les pièces humides (cuisine, salle de bain, WC) sont équipées de bouches d’extractions, les pièces principales sont équipées de bouches d’insufflation.'
  },
  {
    name: 'rs-e6-4',
    content: 'Dans une installation de VMC simple flux Hygro B, les pièces humides (cuisine, salle de bain, WC) sont équipées de bouches d’extractions qui se règlent en fonction du taux d’humidité et les pièces principales sont équipées d’entrées d’air qui se règlent également en fonction du taux d’humidité.'
  },
  {
    name: 'rs-e6-5',
    content: 'Pour l’entretien normale d’une ventilation mécanique contrôlée double flux il faut prévoir de nettoyer ou remplacer les filtres avec une fréquence de 1 à 2 ans.'
  },
  {
    name: 'rs-e6-6',
    content: 'Dans une installation de VMC simple flux Hygro B, les pièces humides (cuisine, salle de bain, WC) sont équipées de bouches d’extractions qui se règlent en fonction du taux d’humidité et les pièces principales sont équipées d’entrées d’air auto réglables.'
  },
  {
    name: 'rs-e6-7',
    content: 'Les entrées d’air auto réglables ont pour principale caractéristique de posséder une section de passage d’air constante.'
  },
  {
    name: 'rs-e6-8',
    content: 'La VMC double flux est un système de ventilation qui permet de récupérer la chaleur de l’air extrait d’un logement par l’intermédiaire d’un échangeur.'
  },
  {
    name: 'rs-e7-1',
    content: 'Tous les appareillages électriques (prise électrique, interrupteur, …) et leurs gaines d’alimentation sont de potentiels sources d’infiltration d’air si leur mise en œuvre ne fait pas l’objet d’une attention particulière.'
  },
  {
    name: 'rs-e7-2',
    content: 'Le test de la porte soufflante est le nom donné couramment au test d’étanchéité à l’air des bâtiments. Ce test permet de détecter les fuites d’air parasites à travers l’enveloppe du bâtiment.'
  },
  {
    name: 'rs-e7-3',
    content: 'Pour conserver les qualités thermiques de l’ouvrage doublage collé, le DTU 25.42 n’autorise pas à réaliser le rainurage coté froid pour le passage des gaines.'
  },
  {
    name: 'rs-e8-1',
    content: 'Lors de la conception d’un bâtiment neuf ou d’une rénovation de bâtiment, il est conseillé de prévoir le minimum de distance entre le ballon de stockage de l’eau chaude sanitaire et les points de puisage. Cela permet de faire des économies d’eau, des économies d’énergie et d’améliorer le confort des utilisateurs.'
  },
  {
    name: 'rs-e8-3',
    content: 'La puissance des chaudières est exprimée en kW.'
  },
  {
    name: 'rs-e8-4',
    content: 'Une chaudière à condensation performante a un rendement supérieur à 100 % sur Pouvoir Calorifique Inférieur (PCI). Son rendement restera inférieur à 100 % sur Pouvoir Calorifique Supérieur.'
  },
  {
    name: 'rs-e8-5',
    content: 'Dans la mesure du possible, il vaut mieux installer le ballon d\'eau chaude en volume chauffé, cela permet de récupérer les pertes en période de chauffage.'
  },
  {
    name: 'rs-e8-6',
    content: 'La sonde extérieure de régulation d’une installation de chauffage doit être placée au Nord en dehors de toute source de perturbation.'
  },
  {
    name: 'rs-e8-7',
    content: 'Une chaudière à condensation génère des condensas au niveau de son échangeur, il faut donc prévoir un système d’évacuation de ces condensas au moment de son installation.'
  }
]

export default corrections
