import revisionsA from './categorie-A/revisions.js'
import revisionsB from './categorie-B/revisions.js'
import revisionsC from './categorie-C/revisions.js'
import revisionsD from './categorie-D/revisions.js'
import revisionsE from './categorie-E/revisions.js'
import revisionsF from './categorie-F/revisions.js'
import revisionsG from './categorie-G/revisions.js'
import revisionsH from './categorie-H/revisions.js'
import revisionsI from './categorie-I/revisions.js'

export default [...revisionsA, ...revisionsB, ...revisionsC, ...revisionsD, ...revisionsE, ...revisionsF, ...revisionsG, ...revisionsH, ...revisionsI]
