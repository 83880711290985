import Vue from 'vue'
import store from '@/store'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Revision from '../views/Revision'
import Correction from '../views/questionnary/Correction'
import Result from '../views/questionnary/Result'
import Questionnary from '../views/questionnary/Questionnary'
import TimeUp from '../views/questionnary/TimeUp'
import Correctif from '../views/questionnary/Correctif'
import TestQuestions from '../views/TestQuestions'
import PageNotFound from '../views/PageNotFound'
import Logout from '../views/Logout'
import DatesExamen from '../views/DatesExamen'
import ReservationExamen from '../views/ReservationExamen'
import VosDonnesEtVosDroits from '../views/VosDonnesEtVosDroits'
import FicheRevision from '../views/FicheRevision'
import FirstLoginForm from '@/views/FirstLoginForm'
import HowItWorks from '@/views/HowItWorks'
import AboutRGE from '@/views/AboutRGE'
import AboutRGECourse from '@/views/about-rge/AboutRGECourse'
import AboutRGEAdvantages from '@/views/about-rge/AboutRGEAdvantages'
import AboutRGEFarther from '@/views/about-rge/AboutRGEFarther'

Vue.use(VueRouter)

const aboutRgeSubHeader = {
  title: 'Tout savoir du label RGE',
  isReturnArrow: true,
  picto: 'rge'
}

const routes = [
  {
    path: '/',
    alias: '/demo',
    name: 'home',
    component: Home,
    meta: {
      showHeader: true,
      showFooter: true
    }
  },
  {
    path: '/premiere-connexion',
    name: 'firstLoginForm',
    component: FirstLoginForm,
    meta: {
      showHeader: true,
      showFooter: false
    }
  },
  {
    path: '/questionnary',
    name: 'questionnary',
    component: Questionnary,
    meta: {
      showHeader: false,
      showFooter: false,
      subHeader: {
        title: 'Test',
        isReturnArrow: true,
        picto: 'check',
        returnLabel: 'Quitter'
      }
    }
  },
  {
    path: '/vos-donnees-et-vos-droits',
    name: 'vos données et vos droits',
    component: VosDonnesEtVosDroits,
    meta: {
      showHeader: false,
      showFooter: false,
      subHeader: {
        title: 'Vos données et vos droits',
        isReturnArrow: true,
        picto: 'book'
      }
    }
  },
  {
    path: '/comment-ca-marche',
    name: 'howItWorks',
    component: HowItWorks,
    meta: {
      showHeader: false,
      showFooter: false,
      subHeader: {
        title: 'Comment ça marche ?',
        isReturnArrow: true,
        picto: 'book'
      }
    }
  },
  {
    path: '/tout-savoir-rge',
    name: 'aboutRGE',
    component: AboutRGE,
    meta: {
      showHeader: false,
      showFooter: false,
      subHeader: aboutRgeSubHeader
    }
  },
  {
    path: '/tout-savoir-rge-parcours/:openedDrawer?',
    name: 'aboutRGECourse',
    component: AboutRGECourse,
    meta: {
      showHeader: false,
      showFooter: false,
      subHeader: aboutRgeSubHeader
    }
  },
  {
    path: '/tout-savoir-rge-avantages',
    name: 'aboutRGEAdvantages',
    component: AboutRGEAdvantages,
    meta: {
      showHeader: false,
      showFooter: false,
      subHeader: aboutRgeSubHeader
    }
  },
  {
    path: '/tout-savoir-rge-plus-loin',
    name: 'aboutRGEFarther',
    component: AboutRGEFarther,
    meta: {
      showHeader: false,
      showFooter: false,
      subHeader: aboutRgeSubHeader
    }
  },
  {
    path: '/revision/:ficheName',
    name: 'fiche de revision',
    component: FicheRevision,
    meta: {
      showHeader: false,
      showFooter: false,
      subHeader: {
        title: 'Fiche de révision',
        isReturnArrow: true,
        picto: 'book'
      }
    }
  },
  {
    path: '/resultat',
    name: 'result',
    component: Result,
    meta: {
      showHeader: false,
      showFooter: false,
      subHeader: {
        title: 'Test',
        isReturnArrow: false,
        picto: 'check'
      }
    },
    beforeEnter: (to, from, next) => {
      if (store.state.test.testAnswer && store.state.test.testScore) {
        next()
      }
    }
  },
  {
    path: '/correction',
    name: 'correction',
    component: Correction,
    meta: {
      showHeader: false,
      showFooter: false,
      subHeader: {
        title: 'Correctif',
        isReturnArrow: true,
        returnTo: '/resultat',
        picto: 'check'
      }
    }
  },
  {
    path: '/correctif/:questionId',
    name: 'correctif',
    component: Correctif,
    meta: {
      showHeader: false,
      showFooter: false,
      subHeader: {
        title: 'Correctif',
        isReturnArrow: true,
        returnTo: '/correction',
        picto: 'check'
      }
    }
  },
  {
    path: '/revision',
    name: 'revision',
    component: Revision,
    meta: {
      showHeader: true,
      showFooter: true
    }
  },
  {
    path: '/reservation-examen/:examenId',
    name: 'reservation-examen',
    component: ReservationExamen,
    meta: {
      showHeader: false,
      showFooter: true,
      subHeader: {
        title: 'Reservation',
        isReturnArrow: true,
        returnTo: '/dates-examen',
        picto: 'check'
      }
    }
  },
  {
    path: '/dates-examen',
    name: 'dates-examen',
    component: DatesExamen,
    meta: {
      showHeader: true,
      showFooter: true
    }
  },
  {
    path: '/temps-ecoule',
    name: 'timeup',
    component: TimeUp,
    meta: {
      showHeader: false,
      showFooter: false
    }
  },
  {
    path: '/test-questions',
    name: 'test-questions',
    component: TestQuestions,
    meta: {
      showHeader: true,
      showFooter: false
    }
  },
  {
    path: '/logout',
    name: 'logout',
    component: Logout,
    meta: {
      showHeader: false,
      showFooter: false
    }
  },
  {
    path: '*',
    name: '404',
    component: PageNotFound,
    meta: {
      showHeader: true,
      showFooter: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    window.scrollTo(0, 0)
  }
})

export default router
