import axios from 'axios'

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

const User = {
  saveAxiosToken (token) {
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
  },
  saveDemoToken (token) {
    apiClient.defaults.headers.common['DEMO-TOKEN'] = token
  },
  getTestDatas () {
    return apiClient.get('/test/data')
  },
  getUserDatas () {
    return apiClient.get('/security/info')
  },
  getUserApiToken (userInfo) {
    return apiClient.post('/security/login', userInfo)
  },
  postTestStart () {
    return apiClient.post('/test/start')
  },
  postTestFinish (testInfo) {
    return apiClient.post('/test/finish', testInfo)
  },
  getDatesExamens () {
    return apiClient.get('/examens')
  },
  postInscriptionExamen (examenId, datas) {
    return apiClient.post(`/examen/${examenId}/inscription`, datas)
  },
  getDemoRegionsEnseigne (enseigne) {
    return apiClient.get(`/demo/regions/${enseigne}`)
  },
  getDemoExamenRegion (regionCode) {
    return apiClient.get(`/demo/examens/${regionCode}`)
  },
  postFirstLoginForm (formData) {
    return apiClient.post('/customer/first/login', {
      registrationPrestations: formData.prestations,
      registrationReason: formData.reason,
      registrationLevel: formData.level
    })
  }
}

export default User
