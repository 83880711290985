<template>
  <div class="step-question">
    <div class="count-wrapper">
      <slot name="counter">
        {{ currentStepIndex + 1 }} / {{ nbSteps }}
      </slot>
    </div>
    <div class="title">
      <slot name="title">
        <p v-html="title"></p>
      </slot>
    </div>
    <div class="question-wrapper">
      <slot name="question"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StepQuestion',
  props: {
    title: {
      type: String,
      required: false
    },
    currentStepIndex: {
      type: Number,
      required: false
    },
    nbSteps: {
      type: Number,
      required: false
    }
  }
}
</script>

<style lang="scss" scoped>
.step-question {
  padding: 0 10px;
  .count-wrapper {
    text-align: left;
    padding: 0 10px;
    font-size: 0.9rem;
  }
  .title {
    padding: 0 10px;
    margin-top: 10px;
    font-size: 1.16rem;
    font-weight: 900;
    text-align: left;
  }
  .question-wrapper {
    margin-top: 15px;
  }
}
</style>
