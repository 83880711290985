<template>
  <div class="accordion">
    <div class="head" @click="display = !display">
      <div class="toggle">{{ toggleIcon }}</div>
      <div class="title"><slot name="title"></slot></div>
    </div>
    <div class="content" v-if="display">
      <slot name="content"></slot>
    </div>

  </div>
</template>

<script>
export default {
  name: 'Accordion',
  data () {
    return {
      display: false
    }
  },
  computed: {
    toggleIcon () {
      if (this.display) {
        return '-'
      }
      return '+'
    }
  }
}
</script>

<style scoped lang="scss">
.accordion {
  border: 1px solid #dfdfdf;
  margin: 20px 0;
  background-color: #fff;
  .head {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 20px;
    cursor: pointer;
    min-height: 90px;
    .toggle {
      margin-right: 60px;
      font-size: 3.5em;
      @include themify($themes) {
        color: themed('backgroundColor');
      }
      cursor: pointer;
      width: 50px;
      font-weight: 400;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .title {
      font-size: 1.5em;
    }
  }
  .content {
    padding: 30px 20px;
    h2 {
      font-size: 1.4em;
      text-align: left;
      margin: 30px 0;
      &:after {
        content: none;
      }
    }
    p {
      margin: 10px 0;
    }
    li {
      margin: 5px 0;
    }
  }
}

</style>
