<template>
  <div class="test" v-if="questions">
    <div class="head">
      <div class="infos">
        <p class="steps">{{ questionIndex + 1 }} / {{ questions.length }}</p>
        <p class="time">{{ timer | millisecondFormat }}</p>
      </div>
      <p class="question">
        {{ this.currentQuestion.label }}
      </p>
      <p v-if="currentQuestion.correctAnswers.length > 1" class="annotation">
        Plusieurs choix possibles
      </p>
    </div>
    <div class="content">
      <div>
        <div v-for="choice in currentQuestion.choices"
          :key="choice.name"
          class="answer">
          <input :type="currentQuestionType" :id="choice.name" @change="setAnswer(choice.name)" :checked="currentAnswer.includes(choice.name)">
          <label
            :for="choice.name">
            <span class="label">{{ choice.label }}</span>
          </label>
        </div>
      </div>
    </div>
    <div class="buttons">
      <button
        class="valid-button button"
        :class="{ disabled: !isCurrentQuestionAnswered }"
        @click="nextQuestion">
        Valider
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Questionnary',
  data () {
    return {
      questionnaryAnswers: {},
      questionIndex: 0,
      currentAnswer: [],
      timer: 0,
      timerInterval: null
    }
  },
  computed: {
    ...mapState('test', ['questions', 'questionnaryTimer']),
    currentQuestion () {
      return this.questions[this.questionIndex]
    },
    currentQuestionType () {
      return this.currentQuestion.correctAnswers.length > 1 ? 'checkbox' : 'radio'
    },
    isCurrentQuestionAnswered () {
      return this.currentAnswer.length
    }
  },
  created () {
    this.$store.dispatch('test/initQuestionnary')
    this.$store.dispatch('test/startTest')
  },
  mounted () {
    window.addEventListener('keyup', (event) => {
      if (event.keyCode === 13) {
        this.nextQuestion()
      }
    })
    this.timer = this.questionnaryTimer
    this.timerInterval = setInterval(() => {
      this.timer -= 1000
      if (this.timer < 1000) {
        this.$router.push('/temps-ecoule')
      }
    }, 1000)
  },
  destroyed () {
    clearInterval(this.timerInterval)
  },
  methods: {
    setAnswer (choiceName) {
      if (this.currentQuestionType === 'checkbox') {
        if (this.currentAnswer.includes(choiceName)) {
          this.currentAnswer.splice(this.currentAnswer.indexOf(choiceName), 1)
        } else {
          this.currentAnswer.push(choiceName)
        }
      } else {
        this.currentAnswer = [choiceName]
      }
    },
    nextQuestion () {
      if (this.isCurrentQuestionAnswered) {
        this.$set(this.questionnaryAnswers, this.currentQuestion.id, this.currentAnswer)
        this.currentAnswer = []
        if (this.questionIndex < this.questions.length - 1) {
          this.questionIndex++
        } else {
          this.$store.dispatch('test/finishTest', this.questionnaryAnswers)
          this.$router.push({ name: 'result' })
        }
      }
    }
  }
}
</script>

<style lang=scss>
.test {
  padding-bottom: 70px;
  .head {
    background-color: $bg-color;
    padding: $spacer;
    .infos {
      font-size: 0.95rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: $spacer;
      .time {
        padding-right: $spacer-lg;
        background-image: url('../../assets/pictos/clock.svg');
        background-repeat: no-repeat;
        background-position: right 0 center;
      }
    }
    .question {
      font-weight: 900;
      font-size: 1.2rem;
      text-align: left;
    }
    .annotation {
      text-align: left;
      margin-top: 10px;
      font-size: 0.9rem;
    }
  }
  .content {
    padding: $spacer-md;
    .answer {
      label {
        font-size: 1.1rem;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        cursor: pointer;
        min-height: 80px;
        border-radius: $radius;
        border: 1px solid $border-color;
        padding: $spacer $spacer-lg;
        position: relative;
        text-align: left;
        &:before {
          content: '';
          position: absolute;
          top: -1px;
          left: -1px;
          width: 0;
          height: calc(100% + 2px);
          background: #707070;
          transition: 0.2s ease-in width;
        }
        &:hover {
          box-shadow: 0 1px 5px rgba(0, 0, 0, 0.16);
        }
      }
      input {
        display: none;
      }
      input:checked + label {
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.16);
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        &:before {
          width: 7px;
        }
      }
    }
    .answer + .answer {
      margin-top: $spacer-md;
    }
  }
  .buttons {
    margin-top: 20px;
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
    background-color: #fff;
  }
}
</style>
