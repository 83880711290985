<template>
  <footer>
    <router-link
      to="/"
      class="profil-link"
      :class="{ 'activ': $route.name === 'home' }">
      <span class="ico"></span>
      <span class="label">Accueil</span>
    </router-link>
    <router-link
      to="/revision"
      class="revision-link"
      :class="{ 'activ': $route.name === 'revision' }">
      <span class="ico"></span>
      <span class="label">Révision</span>
    </router-link>
    <router-link
      to="/dates-examen"
      class="dates-link"
      :class="{ 'activ': $route.name === 'dates-examen' }">
      <span class="ico"></span>
      <span class="label">Dates d'examen</span>
    </router-link>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style lang=scss>
footer {
  @include themify($themes) {
    background-color: themed('backgroundColor');
  }

  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 0.8rem;
  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex: 1 1 0;
    transition: 0.4s opacity;
    opacity: 0.5;
    text-decoration: none;
    color: #fff;
    &.activ {
      opacity: 1;
    }
    .ico {
      width: 28px;
      height: 20px;
      display: inline-block;
      background-position: center;
      background-repeat: no-repeat;
      margin-bottom: $spacer-sm;
    }
  }
  .profil-link .ico {
    background-image: url('../assets/pictos/profil-white.svg');
  }
  .revision-link .ico {
    background-image: url('../assets/pictos/book-white.svg');
  }
  .dates-link .ico {
    background-image: url('../assets/pictos/calendar.svg');
  }
}
</style>
