const revisions = [
  {
    name: 'ra-h1-1',
    label: 'Les logiciels d’évaluation énergétique'
  },
  {
    name: 'ra-h1-2',
    label: 'Logiciel d’évaluation énergétique – Travaux et informations de sortie'
  },
  {
    name: 'ra-h1-3',
    label: 'Logiciel d’évaluation énergétique – Travaux et informations de sortie'
  }
]

export default revisions
