var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "accordion" }, [
    _c(
      "div",
      {
        staticClass: "head",
        on: {
          click: function ($event) {
            _vm.display = !_vm.display
          },
        },
      },
      [
        _c("div", { staticClass: "toggle" }, [_vm._v(_vm._s(_vm.toggleIcon))]),
        _c("div", { staticClass: "title" }, [_vm._t("title")], 2),
      ]
    ),
    _vm.display
      ? _c("div", { staticClass: "content" }, [_vm._t("content")], 2)
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }