var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "prestation-step" },
    [
      _c("step-question", {
        attrs: {
          title: "Quelles prestations réalisez-vous&nbsp;?",
          "current-step-index": _vm.currentStepIndex,
          "nb-steps": _vm.nbSteps,
        },
        scopedSlots: _vm._u([
          {
            key: "question",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "categories" },
                  _vm._l(_vm.categoriesByEnseigne, function (categorie) {
                    return _c("drawer", {
                      key: categorie.name,
                      staticClass: "categorie",
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "head",
                            fn: function (slotProps) {
                              return [
                                _c("drawer-head-image", {
                                  attrs: {
                                    label: categorie.label,
                                    image: require(`@/assets/prestations/${categorie.name}.svg`),
                                    "is-open": slotProps.isOpen,
                                  },
                                }),
                              ]
                            },
                          },
                          {
                            key: "content",
                            fn: function () {
                              return [
                                _c(
                                  "div",
                                  { staticClass: "categorie-content" },
                                  [
                                    _c("check-buttons", {
                                      attrs: {
                                        name: "prestations",
                                        choices: _vm.categorieChoices(
                                          categorie.name
                                        ),
                                        value: _vm.model,
                                      },
                                      on: { input: _vm.emitModel },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                    })
                  }),
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }