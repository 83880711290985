<template>
  <div class="about-rge-course">
    <div class="title-wrapper">
      <image-title title="Le parcours de qualification&nbsp;RGE"
        :image="require('../../assets/pictos/course.svg')">
      </image-title>
    </div>

    <div class="steps-wrapper">
      <div class="step">
        <drawer>
          <template v-slot:head="slotProps">
            <drawer-head-image :label="firstStepTitle"
              :is-open="slotProps.isOpen">
              <template v-slot:image>
                <icon-theme-colored :icon-inline="require('@/assets/pictos/pin-locator.svg?inline')"></icon-theme-colored>
              </template>
            </drawer-head-image>
          </template>
          <template v-slot:content>
            <div class="content">
              <p>
                Il est important de choisir en amont du parcours la qualification et l’organisme de qualification qui correspond à votre métier ainsi que la personne qui sera formée. Cette personne deviendra le référent technique permettant à l’entreprise de devenir RGE, son choix est donc essentiel.
              </p>
              <p>
                Pour les métiers de l’isolation, <a href="https://www.qualibat.com/nomenclature/" target="_blank" rel="noopener">découvrez la nomenclature de Qualibat</a>
              </p>
              <p>
                Pour les métiers du chauffage, <a href="https://www.qualit-enr.org/decouvrir-nos-qualifications-rge/" target="_blank" rel="noopener">découvrez les qualifications de Qualit’ENR</a>
              </p>
              <p>
                Pour les métiers liés à l’électricité, <a href="https://pros.qualifelec.fr/toutes-nos-qualifications" target="_blank" rel="noopener">découvrez les qualifications de Qualifelec</a>
              </p>
            </div>
          </template>
        </drawer>
      </div>

      <div class="step" v-if="!isCedeo">
        <drawer :is-initial-open="this.$route.params.openedDrawer === 'formation-technique'">
          <template v-slot:head="slotProps">
            <drawer-head-image label="Suivre une formation technique"
              :is-open="slotProps.isOpen">
              <template v-slot:image>
                <icon-theme-colored :icon-inline="require('@/assets/pictos/pin-locator.svg?inline')"></icon-theme-colored>
              </template>
            </drawer-head-image>
          </template>
          <template v-slot:content>
            <div class="content">
              <p>
                Depuis le 1er janvier 2021, certaines qualifications RGE requièrent des exigences supplémentaires, notamment l’obligation de suivre une formation technique sur la pose des produits chez un industriel ou autre organisme de formation externe.
              </p>
              <br>
              <p>
                <b>Les qualifications concernées sont&nbsp;:</b><br>
                - L’isolation thermique et acoustique par soufflage<br>
                - L’isolation thermique par l’extérieur<br>
                - L’isolation thermique par l’intérieur<br>
                - L’isolation par projection - injection - sécurité passive contre l’incendie
              </p>
              <br>
              <p>
                <b>Entreprise de moins de 10 salariés&nbsp;:</b><br>
                au moins 50 % des salariés affectés à l’exécution des travaux doivent avoir été formé.
              </p>
              <br>
              <p>
                <b>Entreprise de plus de 10 salariés&nbsp;:</b><br>
                au moins  5 salariés affectés à l’exécution des travaux doivent avoir été formé.
              </p>
            </div>
          </template>
        </drawer>
      </div>

      <div class="step">
        <drawer>
          <template v-slot:head="slotProps">
            <drawer-head-image label="Se former à l’efficacité énergétique"
              :is-open="slotProps.isOpen">
              <template v-slot:image>
                <icon-theme-colored :icon-inline="require('@/assets/pictos/pin-locator.svg?inline')"></icon-theme-colored>
              </template>
            </drawer-head-image>
          </template>
          <template v-slot:content>
            <div class="content">
              <p>
                Pour devenir RGE, il est nécessaire de justifier de compétence en efficacité énergétique.
                <br>
                <b>Selon votre niveau de connaissances initial, vous avez le choix entre deux modes&nbsp;:</b>
              </p>
              <br>
              <p>
                <span class="underline-title">option a</span><br>
                <b>Suivre une formation FEEBat de 3 jours</b><br>
                Formez-vous avec notre partenaire APEE et suivez le stage de 3 jours dans un centre APEE&nbsp;;<br>
                Coût : 660 € H.T – 100 % remboursable par votre OPCO<br>
                <a href="https://apee.fr/formez-vous/" rel="noopener" target="_blank">Consulter l’agenda des formations</a>
              </p>
              <div class="grey-text-bloc greened">
                <img src="../../assets/pictos/lightbulb.svg">
                <p>
                  Nous vous recommandons ce format si vous estimez que votre niveau de connaissances est faible
                </p>
              </div>
              <br>
              <p>
                <span class="underline-title">option b</span><br>
                <b>Passer l’examen d’1h en candidat libre dans une agence POINT. P</b><br>
                Entraînez-vous à passer l’examen grâce à l’application Objectif RGE. Une fois que vous êtes mature (7 tests consécutifs avec 24/30), vous êtes prêt à passer l’examen.
                <br>
                Coût : 149 € H.T (99 € si membre {{ membershipLabel }})
                <br>
                <a href="/dates-examen" target="_blank">Consulter les dates d’examen</a>
              </p>
            </div>
          </template>
        </drawer>
      </div>

      <div class="step">
        <drawer>
          <template v-slot:head="slotProps">
            <drawer-head-image label="Monter son dossier de qualification RGE"
              :is-open="slotProps.isOpen">
              <template v-slot:image>
                <icon-theme-colored :icon-inline="require('@/assets/pictos/pin-locator.svg?inline')"></icon-theme-colored>
              </template>
            </drawer-head-image>
          </template>
          <template v-slot:content>
            <div class="content">
              Une fois que le référent technique de l’entreprise a réussi son examen, vous devez constituer votre dossier auprès de l’organisme de qualification correspondant au signe de qualité choisi en amont.
              <br><br>
              <b>Liste des documents à fournir :</b>
              <br><br>
              <p class="underline-title">Volet administratif</p>
              <br>
              - Deux derniers exercices complets (global + celui de l’activité concernée par la demande de qualification)
              <br>
              - Déclaration Annuelle des Données Sociales Unifiées de l’année précédente
              <br><br><br>
              <p class="underline-title">Volet Administratif et Juridique</p>
              <br>
              - Attestation de réussite au QCM FEEBat<br>
              - KBIS ou avis INSEE (moins de 3 mois)<br>
              - Attestation URSSAF ou SSI (moins de 3 mois)<br>
              - Attestation sur l’honneur du paiement des impôts et taxes + Lettre d’engagement signée du responsable légal<br>
              - Attestation Responsabilité Civile Professionnelle + Décennale<br>
              - État des sinistres enregistrés par l’assurance en responsabilité travaux sur les 4 dernières années
              <br><br><br>
              <p class="underline-title">Volet Chantiers</p>
              <br>
              - 3 chantiers de référence<br>
              - Photos de l’ouvrage (avant, pendant et après sa réalisation) / pensez aux EPI !<br>
              - Attestation de travaux signés du maître d’œuvre + Bureau de contrôle ou maître d’ouvrage<br>
              - Contrat de sous-traitance (si concerné)<br>
              - Factures et devis descriptifs des chantiers<br>
              - Liste des chantiers réalisés au cours des 4 dernières années
              <br><br>
              <p class="underline-title">Volet Moyens humains et matériels</p>
              <br>
              - Liste du personnel dans l’activité<br>
              - Certificats de travail, attestation de stage et copie des diplômes<br>
              - Liste du matériel et machines<br>
              - Description immobilière (locaux de l’entreprise)
            </div>
          </template>
        </drawer>
      </div>

      <div class="step">
        <drawer>
          <template v-slot:head="slotProps">
            <drawer-head-image label="Vous êtes RGE !"
              :is-open="slotProps.isOpen">
              <template v-slot:image>
                <icon-theme-colored :icon-inline="require('@/assets/pictos/flag.svg?inline')"></icon-theme-colored>
              </template>
            </drawer-head-image>
          </template>
          <template v-slot:content>
            <div class="content">
              Votre entreprise est RGE pour les 4 prochaines années.
              <br>
              Chaque année, votre organisme de qualification vous demandera de justifier de la présence du référent technique ainsi que la réalisation d’au moins 2 chantiers sur la qualification RGE de votre entreprise.
              <br>
              Au cours de ces 4 années, au moins un de vos chantiers sera audité par votre organisme de qualification pour s’assurer de sa bonne réalisation.
            </div>
          </template>
        </drawer>
      </div>
    </div>
  </div>
</template>

<script>
import ImageTitle from '@/components/ImageTitle'
import Drawer from '@/components/Drawer'
import DrawerHeadImage from '@/components/DrawerHeadImage'
import IconThemeColored from '@/components/IconThemeColored'
import ENSEIGNE_CONST from '@/constants/ENSEIGNE_CONST'

export default {
  name: 'AboutRGECourse',
  components: { DrawerHeadImage, Drawer, ImageTitle, IconThemeColored },
  computed: {
    isCedeo () {
      return this.$store.state.enseigne === ENSEIGNE_CONST.CEDEO
    },
    membershipLabel () {
      return this.isCedeo
        ? 'CEDEO BOOST'
        : 'GA'
    },
    firstStepTitle () {
      return this.$store.state.enseigne === ENSEIGNE_CONST.POINTP
        ? 'Choisir son signe de qualité'
        : 'choisir son organisme de qualification'
    }
  }
}
</script>

<style lang="scss" scoped>
.about-rge-course {
  padding: 20px 10px;
  .title-wrapper {
    padding: 0 12px;
  }
  .steps-wrapper {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .step {
    position: relative;
    &:not(:first-child):before {
      content: url('~@/assets/illus/step-link.svg');
      position: absolute;
      top: -18px;
      left: 30px;
    }
    .content {
      padding: 15px 15px 20px 15px;
      text-align: left;
      font-size: 0.95rem;
      font-weight: 400;
      border: 2px solid #000;
      border-top: none;
      line-height: 1.4rem;
      .sub-title {
        font-size: 1.15rem;
      }
      .underline-title {
        text-transform: uppercase;
        font-weight: 700;
        position: relative;
        margin-bottom: 15px;
        display: inline-block;
        &:after {
          content: "";
          width: 80%;
          height: 1px;
          @include themify($themes) {
            background-color: themed('backgroundColor');
          }
          display: block;
          position: absolute;
          bottom: 0;
          left: 0;
        }
      }
      a {
        @include themify($themes) { color: themed('backgroundColor'); }
        font-weight: 700;
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
      .grey-text-bloc {
        margin: 5px -10px;
        display: flex;
        &.greened {
          background-color: rgba($secondary-color, .2);
        }
        img {
          margin-right: 10px;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.about-rge-course {
  .drawer-head-image {
    &.open {
      border-bottom: none;
    }
  }
}
</style>
