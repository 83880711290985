const revisions = [
  {
    name: 'ra-b1-1',
    label: 'L’effet « PAROI FROIDE »'
  },
  {
    name: 'ra-b1-2',
    label: 'Relation Inertie / Densité'
  },
  {
    name: 'ra-b1-3',
    label: 'Le confort thermique'
  },
  {
    name: 'ra-b1-4',
    label: 'Les ponts thermiques intégrés'
  },
  {
    name: 'ra-b1-5',
    label: 'L’inertie thermique d’un bâtiment'
  },
  {
    name: 'ra-b1-6',
    label: 'Technique d’isolation VS ponts thermiques'
  },
  {
    name: 'ra-b1-7',
    label: 'Les ponts thermiques intégrés'
  },
  {
    name: 'ra-b1-8',
    label: 'Les ponts thermiques – Où les trouvent-on ?'
  },
  {
    name: 'ra-b1-9',
    label: 'Les ponts thermiques – Où les trouvent-on ?'
  },
  {
    name: 'ra-b2-1',
    label: 'Température de consigne et consommation'
  },
  {
    name: 'ra-b2-2',
    label: 'Protection solaire et confort d’été'
  },
  {
    name: 'ra-b2-3',
    label: 'Emission de chauffage : convection ou rayonnement ?'
  },
  {
    name: 'ra-b2-4',
    label: 'Protection solaire et confort d’été'
  },
  {
    name: 'ra-b2-5',
    label: 'Le facteur solaire'
  },
  {
    name: 'ra-b3-1',
    label: 'Les apports gratuits'
  },
  {
    name: 'ra-b3-2',
    label: 'La conductivité thermique (λ)'
  },
  {
    name: 'ra-b3-3',
    label: 'Ordres de grandeur de conductivité thermique (λ) des matériaux'
  },
  {
    name: 'ra-b3-4',
    label: 'Les déperditions totales d’un bâtiment'
  },
  {
    name: 'ra-b3-5',
    label: 'La conductivité thermique (λ)'
  },
  {
    name: 'ra-b4-1',
    label: 'Le coefficient de déperdition surfacique d’une paroi : Uparoi'
  },
  {
    name: 'ra-b4-2',
    label: 'Les déperditions par renouvellement d’air'
  },
  {
    name: 'ra-b4-3',
    label: 'La résistance thermique d’un matériau'
  },
  {
    name: 'ra-b4-4',
    label: 'Vent et fuite d’air dans le bâtiment'
  },
  {
    name: 'ra-b4-5',
    label: 'Orientation des baies et apports solaires'
  },
  {
    name: 'ra-b5-confort-ete-1',
    label: 'Confort d’été et déphasage'
  },
  {
    name: 'ra-b5-confort-ete-2',
    label: 'Confort d’été et bioclimatisme'
  },
  {
    name: 'ra-b5-confort-ete-3',
    label: 'Confort d’été et bioclimatisme'
  },
  {
    name: 'ra-b5-1',
    label: 'Le Tirage thermique'
  },
  {
    name: 'ra-b5-2',
    label: 'La VMC hygroréglable - Principe'
  },
  {
    name: 'ra-b5-3',
    label: 'Le coefficient Sd – Résistance à la diffusion de vapeur'
  },
  {
    name: 'ra-b5-4',
    label: 'Niveau d’isolation en combles perdus – Ordre de grandeur'
  },
  {
    name: 'ra-b5-5',
    label: 'Les raisons d’installer une ventilation dans un logement'
  },
  {
    name: 'ra-b6-position-isolant-1',
    label: 'Inertie thermique des parois'
  },
  {
    name: 'ra-b6-1',
    label: 'ACERMI'
  },
  {
    name: 'ra-b6-2',
    label: 'Le marquage CE'
  },
  {
    name: 'ra-b6-3',
    label: 'La ventilation mécanique double-flux - Principe'
  },
  {
    name: 'ra-b6-4',
    label: 'La certification ACERMI'
  },
  {
    name: 'ra-b6-5',
    label: 'La résistance thermique d’un matériau'
  }
]

export default revisions
