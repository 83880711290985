import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import millisecondFormat from './filters/millisecondFormat'
import precisionRound from './filters/precisionRound'
import formatDate from './filters/formatDate'
import * as Sentry from '@sentry/vue'

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  environment: process.env.VUE_APP_SENTRY_ENV,
  sampleRate: 1
})

Vue.config.productionTip = false

Vue.filter('millisecondFormat', millisecondFormat)
Vue.filter('round', precisionRound)
Vue.filter('formatDate', formatDate)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
