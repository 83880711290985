<template>
  <div class="about-rge-farther">
    <div class="title-wrapper">
      <image-title title="Se faire accompagner au montage de son dossier RGE"
        :image="require('../../assets/pictos/documents.svg')">
      </image-title>
    </div>
    <div class="content">
      Le montage administratif du dossier est souvent une étape chronophage et compliquée pour les artisans du Bâtiment.
      <br><br>
      Pour vous aider dans cette étape, <b>{{ enseigneLabel }} propose à ses clients de se faire accompagner par une entreprise spécialisée</b> dans la collecte des pièces administratives et la présentation des dossiers de qualification auprès de Qualibat
      <br><br>
      Pour découvrir ce service, rendez-vous sur : <a :href="enseigneLink" rel="noopener" target="_blank">{{ enseigneLink }}</a>
    </div>
  </div>
</template>

<script>
import ImageTitle from '@/components/ImageTitle'
import { mapGetters } from 'vuex'

export default {
  name: 'AboutRGEFarther',
  components: {
    ImageTitle
  },
  computed: {
    ...mapGetters(['isEnseignePP']),
    enseigneLabel () {
      return this.isEnseignePP ? 'POINT. P' : 'CEDEO'
    },
    enseigneLink () {
      return this.isEnseignePP
        ? 'https://www.pointp.fr/services-pour-les-professionnels/accompagment-au-montage-de-dossier'
        : 'https://www.cedeo.fr/montage-dossier-qualification-RGE'
    }
  }
}
</script>

<style lang="scss" scoped>
.about-rge-farther {
  margin-top: 20px;
  .title-wrapper {
    padding: 0 20px;
  }
  .content {
    padding: 20px;
    text-align: left;
    a {
      @include themify($themes) { color: themed('backgroundColor'); }
      font-weight: 700;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
}
</style>
