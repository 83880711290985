const questions = [
  {
    id: 'c1-1',
    category: 'c',
    subject: 'c1',
    label: 'Comment exprime-t-on les consommations d’énergie dans la réglementation thermique\u00A0?',
    fullExplanation: 'ra-c1-1',
    simpleExplanation: 'rs-c1-1',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'En énergie secondaire'
      },
      {
        name: 'b',
        label: 'En énergie finale'
      },
      {
        name: 'c',
        label: 'En énergie primaire'
      }
    ]
  },
  {
    id: 'c1-2',
    category: 'c',
    subject: 'c1',
    label: 'Le kWhep/m².an est l’unité utilisée pour exprimer\u00A0:',
    fullExplanation: 'ra-c1-1',
    simpleExplanation: 'rs-c1-1',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Des émissions de gaz à effet de serre'
      },
      {
        name: 'b',
        label: 'Une consommation d’énergie'
      },
      {
        name: 'c',
        label: 'Des déperditions'
      }
    ]
  },
  {
    id: 'c1-3',
    category: 'c',
    subject: 'c1',
    label: 'Les consommations d’énergie dans la réglementation thermique s’exprime en kilowattheure d’énergie finale par mètre carré et par an (kWhef/m².an)\u00A0?',
    fullExplanation: 'ra-c1-1',
    simpleExplanation: 'rs-c1-1',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'c2-energie-primaire-1',
    category: 'c',
    subject: 'c4',
    label: 'Dans la règlementaire thermique actuelle pour le neuf et le DPE, quelle quantité d’énergie primaire faut-il pour obtenir 1 kWh d’énergie électrique finale\u00A0?',
    fullExplanation: 'ra-c2-energie-primaire-1',
    simpleExplanation: 'rs-c2-energie-primaire-1',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: '2,3 kWh ep '
      },
      {
        name: 'b',
        label: '1,25 kWh ep '
      },
      {
        name: 'c',
        label: '8,52 kWh ep '
      }
    ]
  },
  {
    id: 'c2-energie-primaire-2',
    category: 'c',
    subject: 'c4',
    label: 'Les consommations en énergie primaire et énergie finale sont identiques pour toutes les énergies\u00A0?',
    fullExplanation: 'ra-c2-energie-primaire-1',
    simpleExplanation: 'rs-c2-energie-primaire-1',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'c2-energie-primaire-3',
    category: 'c',
    subject: 'c4',
    label: 'Le coefficient de conversion de l’énergie finale en énergie primaire du gaz naturel est de\u00A0?',
    fullExplanation: 'ra-c2-energie-primaire-1',
    simpleExplanation: 'rs-c2-energie-primaire-1',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: '1'
      },
      {
        name: 'b',
        label: '2,30'
      },
      {
        name: 'c',
        label: '2,58'
      }
    ]
  },
  {
    id: 'c2-energie-primaire-4',
    category: 'c',
    subject: 'c4',
    label: '100 kWh d’énergie finale électrique représentent\u00A0:',
    fullExplanation: 'ra-c2-energie-primaire-1',
    simpleExplanation: 'rs-c2-energie-primaire-2',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: '100 kWh d’énergie primaire (prise dans la nature)'
      },
      {
        name: 'b',
        label: '230 kWh d’énergie primaire'
      },
      {
        name: 'c',
        label: '258 kWh d’énergie primaire'
      },
      {
        name: 'd',
        label: '111 kWh d’énergie primaire'
      }
    ]
  },
  {
    id: 'c2-energie-primaire-5',
    category: 'c',
    subject: 'c4',
    label: 'Quelle est la perte d’énergie sur le réseau de production et de distribution électrique pour obtenir 100 kWh d’électricité à la prise de courant d’un logement\u00A0:',
    fullExplanation: 'ra-c2-energie-primaire-1',
    simpleExplanation: 'rs-c2-energie-primaire-3',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: '100 kWh'
      },
      {
        name: 'b',
        label: '130 kWh'
      },
      {
        name: 'c',
        label: '258 kWh'
      },
      {
        name: 'd',
        label: '111 kWh'
      }
    ]
  },
  {
    id: 'c2-1',
    category: 'c',
    subject: 'c2',
    label: 'Lors de travaux de rénovation, les bâtiments de moins de 1000 m² de SHON doivent répondre à la Réglementation Thermique existante par élément.',
    fullExplanation: 'ra-c2-1',
    simpleExplanation: 'rs-c2-1',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'c2-2',
    category: 'c',
    subject: 'c2',
    label: 'Une opération de travaux d’isolation des combles sur un bâtiment de 1960 et de 2500 m² de SHON doit répondre à\u00A0:',
    fullExplanation: 'ra-c2-1',
    simpleExplanation: 'rs-c2-1',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'La Réglementation Thermique existante élément par élément'
      },
      {
        name: 'b',
        label: 'La Réglementation Thermique existante globale'
      }
    ]
  },
  {
    id: 'c2-3',
    category: 'c',
    subject: 'c2',
    label: 'Certains bâtiments existants ne sont pas concernés par la réglementation thermique existante lorsqu’ils font l’objet de travaux de rénovation.',
    fullExplanation: 'ra-c2-1',
    simpleExplanation: 'rs-c2-1',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'c2-4',
    category: 'c',
    subject: 'c2',
    label: 'La première réglementation thermique française pour les bâtiments neufs a vu le jour en\u00A0:',
    fullExplanation: 'ra-c2-2',
    simpleExplanation: 'rs-c2-2',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: '1985'
      },
      {
        name: 'b',
        label: '1974'
      },
      {
        name: 'c',
        label: '2005'
      }
    ]
  },
  {
    id: 'c2-5',
    category: 'c',
    subject: 'c2',
    label: 'De quand date la première réglementation thermique française pour les bâtiments neufs\u00A0:',
    fullExplanation: 'ra-c2-2',
    simpleExplanation: 'rs-c2-2',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: '1974'
      },
      {
        name: 'b',
        label: '1982'
      },
      {
        name: 'c',
        label: '2005'
      },
      {
        name: 'd',
        label: '2012'
      }
    ]
  },
  {
    id: 'c2-6',
    category: 'c',
    subject: 'c2',
    label: 'La première réglementation thermique française pour les bâtiments neufs a été légiférée en 2005 (RT2005)\u00A0:',
    fullExplanation: 'ra-c2-2',
    simpleExplanation: 'rs-c2-2',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'c2-7',
    category: 'c',
    subject: 'c2',
    label: 'La première réglementation thermique française pour les bâtiments neufs a été légiférée\u00A0:',
    fullExplanation: 'ra-c2-2',
    simpleExplanation: 'rs-c2-2',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'En 1948 pour la reconstruction après les deux guerres mondiales'
      },
      {
        name: 'b',
        label: 'En 1974 suite au choc pétrolier de 1973'
      },
      {
        name: 'c',
        label: 'En 2005 pour créer le label BBC (Bâtiment Basse Consommation)'
      }
    ]
  },
  {
    id: 'c2-8',
    category: 'c',
    subject: 'c2',
    label: 'Comment appelle-t-on la réglementation thermique qui s\'impose dans les bâtiments existants à l\'occasion d\'une rénovation\u00A0?',
    fullExplanation: 'ra-c2-3',
    simpleExplanation: 'rs-c2-3',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'RT2012'
      },
      {
        name: 'b',
        label: 'RTEX'
      },
      {
        name: 'c',
        label: 'BBC'
      }
    ]
  },
  {
    id: 'c2-9',
    category: 'c',
    subject: 'c2',
    label: 'La RTEX s’impose aux bâtiments existants à l\'occasion de travaux de rénovation\u00A0:',
    fullExplanation: 'ra-c2-3',
    simpleExplanation: 'rs-c2-3',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'c2-10',
    category: 'c',
    subject: 'c2',
    label: 'Lors de travaux de rénovation sur un bâtiment existant, quelle réglementation thermique doit-on appliquer\u00A0?',
    fullExplanation: 'ra-c2-3',
    simpleExplanation: 'rs-c2-3',
    correctAnswers: ['d'],
    choices: [
      {
        name: 'a',
        label: 'RT2005'
      },
      {
        name: 'b',
        label: 'RT2007'
      },
      {
        name: 'c',
        label: 'RT2012 '
      },
      {
        name: 'd',
        label: 'RTEX '
      }
    ]
  },
  {
    id: 'c2-11',
    category: 'c',
    subject: 'c2',
    label: 'RTEX Signifie\u00A0:',
    fullExplanation: 'ra-c2-3',
    simpleExplanation: 'rs-c2-3',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'Retour Technique EXpérimental'
      },
      {
        name: 'b',
        label: 'Rénovation Thermique EXemplaire'
      },
      {
        name: 'c',
        label: 'Réglementation Thermique des bâtiments EXistants'
      }
    ]
  },
  {
    id: 'c2-12',
    category: 'c',
    subject: 'c2',
    label: 'La première réglementation thermique française pour les bâtiments existants a vu le jour en\u00A0:',
    fullExplanation: 'ra-c2-4',
    simpleExplanation: 'rs-c2-3',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: '2000'
      },
      {
        name: 'b',
        label: '2007'
      },
      {
        name: 'c',
        label: '1982'
      }
    ]
  },
  {
    id: 'c2-13',
    category: 'c',
    subject: 'c2',
    label: 'De quand date la première réglementation thermique française concernant les travaux sur des bâtiments existants\u00A0?',
    fullExplanation: 'ra-c2-4',
    simpleExplanation: 'rs-c2-3',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: '1974'
      },
      {
        name: 'b',
        label: '2005'
      },
      {
        name: 'c',
        label: '2007'
      },
      {
        name: 'd',
        label: '2012'
      }
    ]
  },
  {
    id: 'c2-14',
    category: 'c',
    subject: 'c2',
    label: 'En France, la première réglementation thermique concernant les travaux sur des bâtiments existants date de 2008\u00A0?',
    fullExplanation: 'ra-c2-4',
    simpleExplanation: 'rs-c2-3',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'c2-15',
    category: 'c',
    subject: 'c2',
    label: 'En France, le deuxième volet de la réglementation thermique des bâtiments existants date de 2008. De quand date le premier volet de la réglementation thermique des bâtiments existants\u00A0?',
    fullExplanation: 'ra-c2-4',
    simpleExplanation: 'rs-c2-3',
    correctAnswers: ['d'],
    choices: [
      {
        name: 'a',
        label: '1974'
      },
      {
        name: 'b',
        label: '1982'
      },
      {
        name: 'c',
        label: '2005'
      },
      {
        name: 'd',
        label: '2007'
      }
    ]
  },
  {
    id: 'c3-4',
    category: 'c',
    subject: 'c3',
    label: 'Le DPE permet d\'afficher les consommations réelles d\'un logement.',
    fullExplanation: 'ra-c3-dpe',
    simpleExplanation: 'rs-c3-2',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'c3-5',
    category: 'c',
    subject: 'c3',
    label: 'Si je veux connaître les consommations réelles d\'un logement, je peux les trouver sur le DPE\u00A0:',
    fullExplanation: 'ra-c3-dpe',
    simpleExplanation: 'rs-c3-2',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'c3-6',
    category: 'c',
    subject: 'c3',
    label: 'Sur le DPE on trouve les consommations du logement. De quelles consommations s’agit-il\u00A0?',
    fullExplanation: 'ra-c3-dpe',
    simpleExplanation: 'rs-c3-2',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'Réelles'
      },
      {
        name: 'b',
        label: 'Moyennes'
      },
      {
        name: 'c',
        label: 'Conventionnelles'
      }
    ]
  },
  {
    id: 'c3-7',
    category: 'c',
    subject: 'c3',
    label: 'Les consommations du logement qui sont affichées sur le DPE sont des consommation conventionnelles et non pas réelles\u00A0:',
    fullExplanation: 'ra-c3-dpe',
    simpleExplanation: 'rs-c3-2',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'c3-8',
    category: 'c',
    subject: 'c3',
    label: 'Le Diagnostic de Performance Energétique (D.P.E.) est réalisé par\u00A0:',
    fullExplanation: 'ra-c3-dpe',
    simpleExplanation: 'rs-c3-3',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'Un architecte'
      },
      {
        name: 'b',
        label: 'Un agent de l\'Administration dument mandaté'
      },
      {
        name: 'c',
        label: 'Un professionnel indépendant, assuré et certifié'
      }
    ]
  },
  {
    id: 'c3-9',
    category: 'c',
    subject: 'c3',
    label: 'Qui est autorisé à réaliser le Diagnostic de Performance Energétique (D.P.E.) d’un logement\u00A0?',
    fullExplanation: 'ra-c3-dpe',
    simpleExplanation: 'rs-c3-3',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'Le propriétaire du logement'
      },
      {
        name: 'b',
        label: 'Un agent immobilier'
      },
      {
        name: 'c',
        label: 'Un professionnel indépendant, assuré et certifié'
      },
      {
        name: 'd',
        label: 'Un agent de la préfecture du département concerné'
      }
    ]
  },
  {
    id: 'c3-10',
    category: 'c',
    subject: 'c3',
    label: 'Un agent immobilier peut réaliser le Diagnostic de Performance Energétique (D.P.E.) d’un logement\u00A0:',
    fullExplanation: 'ra-c3-dpe',
    simpleExplanation: 'rs-c3-3',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'c3-11',
    category: 'c',
    subject: 'c3',
    label: 'La personne réalisant le Diagnostic de Performance Energétique (D.P.E.) d’un logement doit être indépendante, assurée et certifiée\u00A0:',
    fullExplanation: 'ra-c3-dpe',
    simpleExplanation: 'rs-c3-3',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'c3-12',
    category: 'c',
    subject: 'c3',
    label: 'Le DPE est obligatoire pour tout bien immobilier de plus de 10 ans.',
    fullExplanation: 'ra-c3-dpe',
    simpleExplanation: 'rs-c3-4',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Faux'
      },
      {
        name: 'b',
        label: 'Vrai'
      }
    ]
  },
  {
    id: 'c3-13',
    category: 'c',
    subject: 'c3',
    label: ' Le propriétaire d’un logement de plus de 10 ans, est dans l’obligation d’en faire réaliser le DPE\u00A0:',
    fullExplanation: 'ra-c3-dpe',
    simpleExplanation: 'rs-c3-4',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Faux'
      },
      {
        name: 'b',
        label: 'Vrai'
      }
    ]
  },
  {
    id: 'c3-14',
    category: 'c',
    subject: 'c3',
    label: 'A quel moment est-on dans l’obligation de faire réaliser le DPE d’un logement\u00A0?',
    fullExplanation: 'ra-c3-dpe',
    simpleExplanation: 'rs-c3-4',
    correctAnswers: ['a', 'b'],
    choices: [
      {
        name: 'a',
        label: 'Lorsqu’on vend le logement'
      },
      {
        name: 'b',
        label: 'Lorsqu’on loue le logement'
      },
      {
        name: 'c',
        label: 'Lorsqu’on rénove le logement'
      }
    ]
  },
  {
    id: 'c3-15',
    category: 'c',
    subject: 'c3',
    label: 'Je suis obligé de faire réaliser le DPE de mon logement lorsque j’entreprends des travaux de rénovation dans celui-ci\u00A0:',
    fullExplanation: 'ra-c3-dpe',
    simpleExplanation: 'rs-c3-4',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Faux'
      },
      {
        name: 'b',
        label: 'Vrai'
      }
    ]
  },
  {
    id: 'c3-16',
    category: 'c',
    subject: 'c3',
    label: 'Dans l\'étiquette climat, que signifie GES\u00A0?',
    fullExplanation: 'ra-c3-dpe',
    simpleExplanation: 'rs-c3-5',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'Graduation des Economies Soumises '
      },
      {
        name: 'b',
        label: 'Gestion Energétique Satisfaisante '
      },
      {
        name: 'c',
        label: 'Gaz à Effet de Serre'
      }
    ]
  },
  {
    id: 'c3-17',
    category: 'c',
    subject: 'c3',
    label: 'Dans le DPE, GES signifie\u00A0:',
    fullExplanation: 'ra-c3-dpe',
    simpleExplanation: 'rs-c3-5',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Grandes Economies Significatives'
      },
      {
        name: 'b',
        label: 'Gaz à Effet de Serre'
      },
      {
        name: 'c',
        label: 'Génération Energétique Supérieure'
      }
    ]
  },
  {
    id: 'c3-18',
    category: 'c',
    subject: 'c3',
    label: 'L\'étiquette climat dans le DPE Concerne les émissions de Gaz à Effet de Serre (GES)\u00A0:',
    fullExplanation: 'ra-c3-dpe',
    simpleExplanation: 'rs-c3-5',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'c3-19',
    category: 'c',
    subject: 'c3',
    label: 'Si je veux connaître le niveau d’émission de Gaz à Effet de Serre (GES) d’un logement, je regarde\u00A0:',
    fullExplanation: 'ra-c3-dpe',
    simpleExplanation: 'rs-c3-5',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Son étiquette Climat'
      },
      {
        name: 'b',
        label: 'Son étiquette Energie'
      },
      {
        name: 'c',
        label: 'Son étiquette CRITAIR'
      }
    ]
  },
  {
    id: 'c3-20',
    category: 'c',
    subject: 'c3',
    label: 'En cas de changement de menuiserie dans un local ne disposant pas de système de ventilation, la RT existante impose la mise en place de bouches d\'entrée d\'air\u00A0:',
    fullExplanation: 'ra-c3-6',
    simpleExplanation: 'rs-c3-6',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'c3-21',
    category: 'c',
    subject: 'c3',
    label: 'Si je change les menuiseries d’un local qui n’a pas de système de ventilation, je dois prévoir des entrées d’air d’après la RT existante\u00A0:',
    fullExplanation: 'ra-c3-6',
    simpleExplanation: 'rs-c3-6',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'c3-22',
    category: 'c',
    subject: 'c3',
    label: 'Si je change les menuiseries d’un local qui n’a pas de système de ventilation, que m’impose la RT existante\u00A0?',
    fullExplanation: 'ra-c3-6',
    simpleExplanation: 'rs-c3-6',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'Le détalonnage des portes intérieures '
      },
      {
        name: 'b',
        label: 'La mise en place de protections solaires'
      },
      {
        name: 'c',
        label: 'La mise en place de grilles d’entrée d’air'
      }
    ]
  },
  {
    id: 'c3-23',
    category: 'c',
    subject: 'c3',
    label: 'La mise en place de grilles d’entrée d’air est-elle imposée par la RT existante dans le cas où l’on change les menuiseries d’un local qui n’a pas de système de ventilation\u00A0?',
    fullExplanation: 'ra-c3-6',
    simpleExplanation: 'rs-c3-6',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Oui'
      },
      {
        name: 'b',
        label: 'Non'
      }
    ]
  },
  {
    id: 'c3-24',
    category: 'c',
    subject: 'c3',
    label: 'Le DPE est obligatoire en cas de rénovation d\'un bien immobilier.',
    fullExplanation: 'ra-c3-dpe',
    simpleExplanation: 'rs-c3-7',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'c3-25',
    category: 'c',
    subject: 'c3',
    label: 'Lorsque je rénove un bien immobilier, je suis obligé d’en faire réaliser le DPE\u00A0:',
    fullExplanation: 'ra-c3-dpe',
    simpleExplanation: 'rs-c3-7',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'c3-26',
    category: 'c',
    subject: 'c3',
    label: 'La réalisation de travaux de rénovation sur un bien immobilier entraine l’obligation de faire réaliser le DPE de celui-ci\u00A0:',
    fullExplanation: 'ra-c3-dpe',
    simpleExplanation: 'rs-c3-7',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'c3-27',
    category: 'c',
    subject: 'c3',
    label: 'Il est obligatoire de faire réaliser le DPE d\'un bien immobilier en cas de\u00A0:',
    fullExplanation: 'ra-c3-dpe',
    simpleExplanation: 'rs-c3-7',
    correctAnswers: ['b', 'c'],
    choices: [
      {
        name: 'a',
        label: 'Travaux de rénovation énergétique'
      },
      {
        name: 'b',
        label: 'Vente'
      },
      {
        name: 'c',
        label: 'Location'
      }
    ]
  },
  {
    id: 'c3-28',
    category: 'c',
    subject: 'c3',
    label: 'Quels sont les postes de consommation rentrant dans la réglementation thermique\u00A0?',
    fullExplanation: 'ra-c3-8',
    simpleExplanation: 'rs-c3-8',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'Chauffage uniquement'
      },
      {
        name: 'b',
        label: 'Chauffage, Eau Chaude Sanitaire et Froid uniquement'
      },
      {
        name: 'c',
        label: 'Chauffage, Eau Chaude Sanitaire, Froid, Auxiliaires, Eclairage'
      }
    ]
  },
  {
    id: 'c3-29',
    category: 'c',
    subject: 'c3',
    label: 'Dans les calculs de la réglementation thermique, quels postes de consommation sont pris en compte\u00A0?',
    fullExplanation: 'ra-c3-8',
    simpleExplanation: 'rs-c3-8',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'Chauffage uniquement'
      },
      {
        name: 'b',
        label: 'Chauffage, Eau Chaude Sanitaire et Froid uniquement'
      },
      {
        name: 'c',
        label: 'Chauffage, Eau Chaude Sanitaire, Froid, Auxiliaires, Eclairage'
      }
    ]
  },
  {
    id: 'c3-30',
    category: 'c',
    subject: 'c3',
    label: 'La réglementation thermique prend en compte les postes de consommation du chauffage, de l’eau chaude sanitaire et du refroidissement uniquement \u00A0:',
    fullExplanation: 'ra-c3-8',
    simpleExplanation: 'rs-c3-8',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'c3-31',
    category: 'c',
    subject: 'c3',
    label: 'La réglementation thermique prend en compte les postes de consommation du chauffage, de l’eau chaude sanitaire, du refroidissement, des auxiliaires et de l’éclairage\u00A0:',
    fullExplanation: 'ra-c3-8',
    simpleExplanation: 'rs-c3-8',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'c3-35',
    category: 'c',
    subject: 'c3',
    label: 'Combien y a-t-il d’étiquette de classement des performances dans un D.P.E. (diagnostic de performance énergétique)\u00A0?',
    fullExplanation: 'ra-c3-dpe',
    simpleExplanation: 'rs-c3-9',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: '1'
      },
      {
        name: 'b',
        label: '2'
      },
      {
        name: 'c',
        label: '3'
      }
    ]
  },
  {
    id: 'c3-36',
    category: 'c',
    subject: 'c3',
    label: 'Quelle est l\'unité utilisée pour quantifier les consommations d\'énergie dans un DPE\u00A0?',
    fullExplanation: 'ra-c3-dpe',
    simpleExplanation: 'rs-c3-10',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'kWhep/m²/an (habitable)'
      },
      {
        name: 'b',
        label: 'kWhef'
      },
      {
        name: 'c',
        label: 'm²'
      }
    ]
  },
  {
    id: 'c3-37',
    category: 'c',
    subject: 'c3',
    label: 'Dans un DPE on utilise le kWhep/m²/an (habitable) pour quantifier les consommations d\'énergie\u00A0:',
    fullExplanation: 'ra-c3-dpe',
    simpleExplanation: 'rs-c3-10',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'c3-38',
    category: 'c',
    subject: 'c3',
    label: 'Les kWh dans l’étiquette « énergie » du DPE sont exprimés en énergie\u00A0:',
    fullExplanation: 'ra-c3-dpe',
    simpleExplanation: 'rs-c3-10',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Primaire'
      },
      {
        name: 'b',
        label: 'Secondaire'
      },
      {
        name: 'c',
        label: 'Finale'
      }
    ]
  },
  {
    id: 'c3-39',
    category: 'c',
    subject: 'c3',
    label: 'Dans un DPE on utilise le kWhep/m².an pour quantifier les consommations d\'énergie. A quelle surface les m² correspondent-ils\u00A0?',
    fullExplanation: 'ra-c3-dpe',
    simpleExplanation: 'rs-c3-10',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'Surface de Plancher'
      },
      {
        name: 'b',
        label: 'Surface RT'
      },
      {
        name: 'c',
        label: 'Surface habitable'
      },
      {
        name: 'd',
        label: 'Surface Hors Œuvre Nette'
      }
    ]
  },
  {
    id: 'c3-dpe-1',
    category: 'c',
    subject: 'c3',
    label: 'Le classement des performances du D.P.E ( diagnostic de performance énergétique) se concrétise par\u00A0:',
    fullExplanation: 'ra-c3-dpe-1',
    simpleExplanation: 'rs-c3-dpe-1',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'Seulement un étiquette « énergie »'
      },
      {
        name: 'b',
        label: 'Seulement un étiquette « climat » pour les émissions de gaz à effet de serre'
      },
      {
        name: 'c',
        label: 'Une étiquette « énergie » et une étiquette « climat »'
      }
    ]
  },
  {
    id: 'c3-dpe-2',
    category: 'c',
    subject: 'c3',
    label: 'Depuis le 1er juillet 2021, l’étiquette DPE d’un logement dépend\u00A0:',
    fullExplanation: 'ra-c3-dpe-1',
    simpleExplanation: 'rs-c3-dpe-1',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'De la consommation énergétique uniquement'
      },
      {
        name: 'b',
        label: 'De l’étiquette CO2 uniquement'
      },
      {
        name: 'c',
        label: 'De l’étiquette CO2 et de l’étiquette énergétique'
      }
    ]
  },
  {
    id: 'c3-dpe-3',
    category: 'c',
    subject: 'c3',
    label: 'Depuis le 1er juillet 2021, un logement qui serait classé en B selon sa consommation énergétique et en C selon son étiquette climat sera classé en C\u00A0:',
    fullExplanation: 'ra-c3-dpe-1',
    simpleExplanation: 'rs-c3-dpe-2',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'c3-dpe-4',
    category: 'c',
    subject: 'c3',
    label: 'Depuis le 1er juillet 2021, un logement qui serait classé en B selon sa consommation énergétique et en C selon son étiquette climat sera classé en B\u00A0:',
    fullExplanation: 'ra-c3-dpe-1',
    simpleExplanation: 'rs-c3-dpe-2',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'c3-dpe-5',
    category: 'c',
    subject: 'c3',
    label: 'Pour établir l’étiquette DPE d’un logement, on se base sur la pire valeur entre le classement énergétique et le classement climatique\u00A0?',
    fullExplanation: 'ra-c3-dpe-1',
    simpleExplanation: 'rs-c3-dpe-3',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'c3-dpe-6',
    category: 'c',
    subject: 'c3',
    label: 'Un logement classé à 65kWh/m².an et une génération très faible de 4kg CO2/m².an sera classé\u00A0:',
    fullExplanation: 'ra-c3-dpe-1',
    simpleExplanation: 'rs-c3-dpe-4',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'A car très performant et peu polluant'
      },
      {
        name: 'b',
        label: 'B car très performant énergétiquement mais « un peu » polluant'
      },
      {
        name: 'c',
        label: 'A car on ne tient compte que de la consommation énergétique'
      }
    ]
  },
  {
    id: 'c3-dpe-7',
    category: 'c',
    subject: 'c3',
    label: 'Un logement classé B produit moins de 11kg CO2/m².an et\u00A0:',
    fullExplanation: 'ra-c3-dpe-1',
    simpleExplanation: 'rs-c3-dpe-5',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Aura une consommation énergétique comprise entre 70 et 110 kWh/m²an'
      },
      {
        name: 'b',
        label: 'Aura une consommation énergétique supérieure à 250 kWh/m².an'
      },
      {
        name: 'c',
        label: 'Aura une consommation énergétique entre 0 et 65kWh/m².an'
      }
    ]
  },
  {
    id: 'c3-dpe-8',
    category: 'c',
    subject: 'c3',
    label: 'Un logement classé A aura une consommation inférieure à 70 kWh/m².an\u00A0:',
    fullExplanation: 'ra-c3-dpe-1',
    simpleExplanation: 'rs-c3-dpe-6',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Et une production de GES inférieure à 6 kg CO2/m².an'
      },
      {
        name: 'b',
        label: 'Et une production de CO2 entre 6 et 11 kg CO2/m².an car on ne peut pas techniquement émettre moins de 6kgCO2/m².an'
      },
      {
        name: 'c',
        label: 'Et un chauffage électrique uniquement'
      }
    ]
  },
  {
    id: 'c4-0',
    category: 'c',
    subject: 'c4',
    label: 'Un test d\'étanchéité à l\'air est obligatoire dans le cadre d\'un label BBC rénovation.',
    fullExplanation: 'ra-c4-1',
    simpleExplanation: 'rs-c4-1',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Faux'
      },
      {
        name: 'b',
        label: 'Vrai'
      }
    ]
  },
  {
    id: 'c4-1',
    category: 'c',
    subject: 'c4',
    label: 'Dans le cadre d\'un label BBC rénovation un test d\'étanchéité à l\'air est obligatoire et le résultat de celui-ci ne doit pas dépasser un certain seuil\u00A0:',
    fullExplanation: 'ra-c4-1',
    simpleExplanation: 'rs-c4-1',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Faux'
      },
      {
        name: 'b',
        label: 'Vrai'
      }
    ]
  },
  {
    id: 'c4-2',
    category: 'c',
    subject: 'c4',
    label: 'Si on veut obtenir un label BBC rénovation, on doit obligatoirement\u00A0:',
    fullExplanation: 'ra-c4-1',
    simpleExplanation: 'rs-c4-1',
    correctAnswers: ['a', 'b'],
    choices: [
      {
        name: 'a',
        label: 'Réaliser une étude thermique réglementaire'
      },
      {
        name: 'b',
        label: 'Réaliser un test d’étanchéité à l’air'
      },
      {
        name: 'c',
        label: 'Installer un système de production d’énergie photovoltaïque'
      }
    ]
  },
  {
    id: 'c4-3',
    category: 'c',
    subject: 'c4',
    label: 'Pour obtenir un label BBC rénovation, je dois obligatoirement passer par un organisme de certification\u00A0:',
    fullExplanation: 'ra-c4-1',
    simpleExplanation: 'rs-c4-1',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'c4-4',
    category: 'c',
    subject: 'c4',
    label: 'Un test d\'étanchéité à l\'air est obligatoire dans le cadre de la RT existant.',
    fullExplanation: 'ra-c4-2',
    simpleExplanation: 'rs-c4-2',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'c4-5',
    category: 'c',
    subject: 'c4',
    label: 'La RT existant impose la réalisation d’un test d\'étanchéité à l\'air\u00A0:',
    fullExplanation: 'ra-c4-2',
    simpleExplanation: 'rs-c4-2',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'c4-6',
    category: 'c',
    subject: 'c4',
    label: 'La RT existant impose d’atteindre un haut niveau d’étanchéité à l’air lors de travaux de rénovation. L’atteinte de ce niveau sera sanctionnée par la réalisation d’un test d\'étanchéité à l\'air à l’achèvement\u00A0:',
    fullExplanation: 'ra-c4-2',
    simpleExplanation: 'rs-c4-2',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'c4-7',
    category: 'c',
    subject: 'c4',
    label: 'Je dois respecter la RT existant pour les travaux de rénovation énergétiques que j’envisage, donc je devrais faire réaliser un test d\'étanchéité à l\'air à l’achèvement des travaux\u00A0:',
    fullExplanation: 'ra-c4-2',
    simpleExplanation: 'rs-c4-2',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'c4-rt-1',
    category: 'c',
    subject: 'c4',
    label: 'Quels travaux annexes impose la Réglementation thermique dans l’existant lors de l’isolation des parois d’un local à usage d’habitation\u00A0:',
    fullExplanation: 'ra-c4-rt-1',
    simpleExplanation: 'rs-c4-rt-1',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'La création d’entrées d’air dans les pièces principales s’il n’en existait pas auparavant'
      },
      {
        name: 'b',
        label: 'La création d’entrées d’air dans toutes les pièces du logement s’il n’en existait pas auparavant'
      },
      {
        name: 'c',
        label: 'La création d’entrées d’air en cas d’installation d’une VMC double-flux'
      },
      {
        name: 'd',
        label: 'La suppression des entrées d’air si elles existaient auparavant'
      }
    ]
  },
  {
    id: 'c4-rt-2',
    category: 'c',
    subject: 'c4',
    label: 'Les résistances thermiques minimales définies dans la RT dans l’existant sont\u00A0:',
    fullExplanation: 'ra-c4-rt-2',
    simpleExplanation: 'rs-c4-rt-2',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Facultatives'
      },
      {
        name: 'b',
        label: 'D’application obligatoire pour tous les bâtiments, y-compris non-résidentiel'
      },
      {
        name: 'c',
        label: 'Supérieures aux contraintes demandées par MPR et les CEE'
      }
    ]
  },
  {
    id: 'c4-rt-3',
    category: 'c',
    subject: 'c4',
    label: 'La réglementation thermique RE2020 s’applique\u00A0:',
    fullExplanation: 'ra-c4-rt-3',
    simpleExplanation: 'rs-c4-rt-3',
    correctAnswers: ['a', 'd'],
    choices: [
      {
        name: 'a',
        label: 'Aux opérations de constructions de bâtiments neufs'
      },
      {
        name: 'b',
        label: 'Aux travaux de rénovation des logements individuels'
      },
      {
        name: 'c',
        label: 'Aux travaux de rénovation des bâtiments tertiaires'
      },
      {
        name: 'd',
        label: 'A certains travaux d’extensions'
      }
    ]
  }
]

export default questions
