const questions = [
  {
    id: 'e1-1',
    category: 'e',
    subject: 'e1',
    label: 'Que signifie ITR\u00A0?',
    fullExplanation: 'ra-e1-1',
    simpleExplanation: 'rs-e1-1',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'Installation technique rénovée'
      },
      {
        name: 'b',
        label: 'Isolation thermique réussie'
      },
      {
        name: 'c',
        label: 'Isolation thermique répartie'
      }
    ]
  },
  {
    id: 'e1-2',
    category: 'e',
    subject: 'e1',
    label: 'Laquelle de ces parois est considérée comme une isolation thermique répartie\u00A0?',
    fullExplanation: 'ra-e1-1',
    simpleExplanation: 'rs-e1-1',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: '20 cm de bloc de béton creux + 14 cm de laine de bois'
      },
      {
        name: 'b',
        label: '50 cm de béton cellulaire'
      },
      {
        name: 'c',
        label: '20 cm de brique creuse'
      }
    ]
  },
  {
    id: 'e1-3',
    category: 'e',
    subject: 'e1',
    label: 'Laquelle de ces parois n’est pas considérée comme une isolation thermique répartie\u00A0?',
    fullExplanation: 'ra-e1-1',
    simpleExplanation: 'rs-e1-1',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Isolation insufflée dans la lame d’air d’un mur double'
      },
      {
        name: 'b',
        label: '37,5 cm de béton cellulaire'
      },
      {
        name: 'c',
        label: '20 cm de brique monomur'
      }
    ]
  },
  {
    id: 'e1-4',
    category: 'e',
    subject: 'e1',
    label: 'Pour accroitre l\'efficacité énergétique d’un isolant en rouleau ou en panneau dans les combles, on le pose\u00A0:',
    fullExplanation: 'ra-e1-2',
    simpleExplanation: 'rs-e1-2',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'En deux couches croisées '
      },
      {
        name: 'b',
        label: 'En une seule couche'
      }
    ]
  },
  {
    id: 'e1-5',
    category: 'e',
    subject: 'e1',
    label: 'Dans des combles perdus, pour une meilleure performance et pour une même épaisseur d’isolation, il est préférable de dérouler une seule couche d’isolant en forte épaisseur plutôt que deux couches plus fine croisées\u00A0:',
    fullExplanation: 'ra-e1-2',
    simpleExplanation: 'rs-e1-2',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'e1-6',
    category: 'e',
    subject: 'e1',
    label: 'Une isolation mise en œuvre en deux couches croisées est plus efficace qu’une isolation mise en œuvre en une seule couche\u00A0:',
    fullExplanation: 'ra-e1-2',
    simpleExplanation: 'rs-e1-2',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'e1-7',
    category: 'e',
    subject: 'e1',
    label: 'Dans le cas où vous conseillez l’isolation de combles perdus, il est préférable de poser l’isolant\u00A0:',
    fullExplanation: 'ra-e1-2',
    simpleExplanation: 'rs-e1-2',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'En deux couches croisées '
      },
      {
        name: 'b',
        label: 'En une seule couche'
      }
    ]
  },
  {
    id: 'e1-8',
    category: 'e',
    subject: 'e1',
    label: 'Un isolant bio-sourcé peut avoir un certificat ACERMI.',
    fullExplanation: 'ra-e1-3',
    simpleExplanation: 'rs-e1-3',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      },
      {
        name: 'c',
        label: 'Cela dépend de sa provenance'
      }
    ]
  },
  {
    id: 'e1-9',
    category: 'e',
    subject: 'e1',
    label: 'Un isolant biosourcé ne peut pas avoir ses performances certifiées.',
    fullExplanation: 'ra-e1-3',
    simpleExplanation: 'rs-e1-3',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'e1-10',
    category: 'e',
    subject: 'e1',
    label: 'Le certificat ACERMI est plus compliqué à obtenir pour un isolant biosourcé que pour un isolant traditionnel.',
    fullExplanation: 'ra-e1-3',
    simpleExplanation: 'rs-e1-3',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'e1-11',
    category: 'e',
    subject: 'e1',
    label: 'La certification ACERMI n’est pas accessible aux isolants biosourcés.',
    fullExplanation: 'ra-e1-3',
    simpleExplanation: 'rs-e1-3',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'e1-12',
    category: 'e',
    subject: 'e1',
    label: 'Pour quel type de bâti faut-il être particulièrement vigilant dans le choix des propriétés de migration de vapeur d\'eau de son isolant\u00A0?',
    fullExplanation: 'ra-e1-4',
    simpleExplanation: 'rs-e1-4',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Dans le bâti neuf résidentiel uniquement '
      },
      {
        name: 'b',
        label: 'Dans tout type de bâtiment'
      },
      {
        name: 'c',
        label: 'Dans le bâti ancien uniquement'
      }
    ]
  },
  {
    id: 'e1-13',
    category: 'e',
    subject: 'e1',
    label: 'Les propriétés de migration de vapeur d\'eau des isolants sont des caractéristiques auxquelles il faut être vigilant lorsque l’on travaille sur du bâti ancien uniquement\u00A0:',
    fullExplanation: 'ra-e1-4',
    simpleExplanation: 'rs-e1-4',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'e1-14',
    category: 'e',
    subject: 'e1',
    label: 'Il faut être vigilant dans le choix des propriétés de migration de vapeur d\'eau des isolants dans tous les types de bâtiments\u00A0:',
    fullExplanation: 'ra-e1-4',
    simpleExplanation: 'rs-e1-4',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'e1-15',
    category: 'e',
    subject: 'e1',
    label: 'Les propriétés de migration de vapeur d\'eau des isolants ont-elles une influence sur le choix de l’isolant à mettre en œuvre dans un bâti ancien\u00A0?',
    fullExplanation: 'ra-e1-4',
    simpleExplanation: 'rs-e1-4',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Oui'
      },
      {
        name: 'b',
        label: 'Non'
      }
    ]
  },
  {
    id: 'e1-16',
    category: 'e',
    subject: 'e1',
    label: 'Pour s\'assurer de l\'obligation de mise en œuvre d\'un pare-vapeur pour la pose d\'un isolant sous Avis Technique ou Document Technique d\'Application en comble, je dois regarder\u00A0:',
    fullExplanation: 'ra-e1-5',
    simpleExplanation: 'rs-e1-5',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'Il n\'y a jamais besoin de pare-vapeur'
      },
      {
        name: 'b',
        label: 'Le NF DTU'
      },
      {
        name: 'c',
        label: 'L\'Avis Technique le Document Technique d\'Application et le Cahier des Prescriptions Techniques associé'
      }
    ]
  },
  {
    id: 'e1-17',
    category: 'e',
    subject: 'e1',
    label: 'Quel(s) document(s) technique(s) va me renseigner sur l\'obligation de mise en œuvre d\'un pare-vapeur pour la pose d\'un isolant sous Avis Technique ou Document Technique d\'Application en comble\u00A0:',
    fullExplanation: 'ra-e1-5',
    simpleExplanation: 'rs-e1-5',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'Il n\'existe pas de document sur ce sujet'
      },
      {
        name: 'b',
        label: 'Le NF DTU'
      },
      {
        name: 'c',
        label: 'L\'Avis Technique, le Document Technique d\'Application et le Cahier des Prescriptions Techniques associé'
      }
    ]
  },
  {
    id: 'e1-18',
    category: 'e',
    subject: 'e1',
    label: 'Pour s\'avoir si je dois mettre en œuvre un pare-vapeur pour la pose d\'un isolant sous Avis Technique ou Document Technique d\'Application en comble, je dois seulement consulter le NF DTU\u00A0:',
    fullExplanation: 'ra-e1-5',
    simpleExplanation: 'rs-e1-5',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'e1-19',
    category: 'e',
    subject: 'e1',
    label: 'Lorsque je mets en œuvre en combles un isolant sous Avis Technique ou Document Technique d\'Application j’installe systématiquement un pare-vapeur\u00A0:',
    fullExplanation: 'ra-e1-5',
    simpleExplanation: 'rs-e1-5',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'Oui, c’est obligatoire'
      },
      {
        name: 'b',
        label: 'Non, jamais'
      },
      {
        name: 'c',
        label: 'Je consulte avant tout, l\'Avis Technique, le Document Technique d\'Application et le Cahier des Prescriptions Techniques associé pour savoir si j’y suis contraint'
      }
    ]
  },
  {
    id: 'e1-20',
    category: 'e',
    subject: 'e1',
    label: 'Parmi les isolants suivants, et pour une même épaisseur, lequel possède la plus grande résistance thermique\u00A0?',
    fullExplanation: 'ra-e1-6',
    simpleExplanation: 'rs-e1-6',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'La laine de bois'
      },
      {
        name: 'b',
        label: 'Le béton cellulaire '
      },
      {
        name: 'c',
        label: 'Le polyuréthane'
      }
    ]
  },
  {
    id: 'e1-21',
    category: 'e',
    subject: 'e1',
    label: 'A épaisseur égale, le polyuréthane possède une meilleure résistance thermique que\u00A0:',
    fullExplanation: 'ra-e1-6',
    simpleExplanation: 'rs-e1-6',
    correctAnswers: ['a', 'b', 'c'],
    choices: [
      {
        name: 'a',
        label: 'La laine de bois'
      },
      {
        name: 'b',
        label: 'Le béton cellulaire '
      },
      {
        name: 'c',
        label: 'Le béton de chanvre'
      }
    ]
  },
  {
    id: 'e1-22',
    category: 'e',
    subject: 'e1',
    label: '10 cm de polyuréthane possèdent une meilleure résistance thermique que 10 cm de béton cellulaire\u00A0:',
    fullExplanation: 'ra-e1-6',
    simpleExplanation: 'rs-e1-6',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Faux'
      },
      {
        name: 'b',
        label: 'Vrai'
      }
    ]
  },
  {
    id: 'e1-23',
    category: 'e',
    subject: 'e1',
    label: 'Pour isoler un mur, si je recherche la meilleure résistance thermique, je l’isole avec\u00A0:',
    fullExplanation: 'ra-e1-6',
    simpleExplanation: 'rs-e1-6',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: '12 cm de polyuréthane'
      },
      {
        name: 'b',
        label: '12 cm le laine de verre'
      },
      {
        name: 'c',
        label: '12 cm de laine de bois'
      }
    ]
  },
  {
    id: 'e1-28',
    category: 'e',
    subject: 'e1',
    label: 'En cas d\'isolation des combles, les trappes d\'accès au comble perdus doivent, pour assurer l\'étanchéité à l\'air, être munies d\'un joint\u00A0:',
    fullExplanation: 'ra-e1-8',
    simpleExplanation: 'rs-e1-8',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'Faux'
      },
      {
        name: 'b',
        label: 'Cela dépend de la situation'
      },
      {
        name: 'c',
        label: 'Vrai'
      }
    ]
  },
  {
    id: 'e1-29',
    category: 'e',
    subject: 'e1',
    label: 'Comment peut-on assurer l\'étanchéité à l\'air d’une trappe d\'accès au comble perdu\u00A0?',
    fullExplanation: 'ra-e1-8',
    simpleExplanation: 'rs-e1-8',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'En l’équipant d’une serrure'
      },
      {
        name: 'b',
        label: 'En collant de l’isolant dessus'
      },
      {
        name: 'c',
        label: 'En l’équipant d’un joint'
      }
    ]
  },
  {
    id: 'e1-30',
    category: 'e',
    subject: 'e1',
    label: 'Isoler la trappe d\'accès au comble perdu suffit à la rendre l\'étanche à l\'air\u00A0?',
    fullExplanation: 'ra-e1-8',
    simpleExplanation: 'rs-e1-8',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'e1-31',
    category: 'e',
    subject: 'e1',
    label: 'Si j’équipe la trappe d\'accès au comble perdu d\'un joint, j’améliore\u00A0:',
    fullExplanation: 'ra-e1-8',
    simpleExplanation: 'rs-e1-8',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Son isolation'
      },
      {
        name: 'b',
        label: 'Son étanchéité à l’air'
      },
      {
        name: 'c',
        label: 'Son inertie'
      }
    ]
  },
  {
    id: 'e1-32',
    category: 'e',
    subject: 'e1',
    label: 'En France, quel est l\'isolant le plus couramment utilisé en ITE sous enduit\u00A0?',
    fullExplanation: 'ra-e1-9',
    simpleExplanation: 'rs-e1-9',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Le polystyrène'
      },
      {
        name: 'b',
        label: 'La laine de roche'
      },
      {
        name: 'c',
        label: 'La laine de verre'
      }
    ]
  },
  {
    id: 'e1-33',
    category: 'e',
    subject: 'e1',
    label: 'En France, lors de travaux d’ITE sous enduit, l’isolant le plus utilisé est\u00A0:',
    fullExplanation: 'ra-e1-9',
    simpleExplanation: 'rs-e1-9',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Le polystyrène'
      },
      {
        name: 'b',
        label: 'Le polyuréthane'
      },
      {
        name: 'c',
        label: 'La laine de roche'
      }
    ]
  },
  {
    id: 'e1-34',
    category: 'e',
    subject: 'e1',
    label: 'En France, on utilise majoritairement du polystyrène lorsque l’on réalise une ITE sous enduit\u00A0?',
    fullExplanation: 'ra-e1-9',
    simpleExplanation: 'rs-e1-9',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'e1-35',
    category: 'e',
    subject: 'e1',
    label: 'Le procédé d’ITE sous enduit est principalement réalisé avec un isolant polyuréthane en France\u00A0:',
    fullExplanation: 'ra-e1-9',
    simpleExplanation: 'rs-e1-9',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'e1-36',
    category: 'e',
    subject: 'e1',
    label: 'Que signifie ITE\u00A0?',
    fullExplanation: 'ra-e1-10',
    simpleExplanation: 'rs-e1-10',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Isolant Très Economique'
      },
      {
        name: 'b',
        label: 'Isolation Thermique par l\'Extérieur'
      },
      {
        name: 'c',
        label: 'Installation Thermique Efficace'
      }
    ]
  },
  {
    id: 'e1-37',
    category: 'e',
    subject: 'e1',
    label: 'ITE signifie : Isolation Très Economique\u00A0:',
    fullExplanation: 'ra-e1-10',
    simpleExplanation: 'rs-e1-10',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'e1-38',
    category: 'e',
    subject: 'e1',
    label: 'On utilise l’acronyme ITE pour\u00A0?',
    fullExplanation: 'ra-e1-10',
    simpleExplanation: 'rs-e1-10',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'Isolation Technique Efficace'
      },
      {
        name: 'b',
        label: 'Installation thermique d’Excellence'
      },
      {
        name: 'c',
        label: 'Isolation Thermique par l\'Extérieur'
      }
    ]
  },
  {
    id: 'e1-39',
    category: 'e',
    subject: 'e1',
    label: 'ITE signifie : Isolation Thermique par l’Extérieur\u00A0:',
    fullExplanation: 'ra-e1-10',
    simpleExplanation: 'rs-e1-10',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'e2-1',
    category: 'e',
    subject: 'e2',
    label: 'Un appareil à combustion classé 6 étoiles au label « Flamme verte » peut être\u00A0:',
    fullExplanation: 'ra-e2-1',
    simpleExplanation: 'rs-e2-1',
    correctAnswers: ['a', 'b'],
    choices: [
      {
        name: 'a',
        label: 'Une chaudière bois '
      },
      {
        name: 'b',
        label: 'Un poêle à granulé'
      },
      {
        name: 'c',
        label: 'Une cheminée à foyer ouvert'
      },
      {
        name: 'd',
        label: 'Une chaudière gaz à condensation'
      }
    ]
  },
  {
    id: 'e2-2',
    category: 'e',
    subject: 'e2',
    label: 'Quel est le label qui distingue les appareils de chauffage au bois performants\u00A0?',
    fullExplanation: 'ra-e2-1',
    simpleExplanation: 'rs-e2-1',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Flamme verte'
      },
      {
        name: 'b',
        label: 'Flamme orange'
      },
      {
        name: 'c',
        label: 'Flamme rouge'
      }
    ]
  },
  {
    id: 'e2-3',
    category: 'e',
    subject: 'e2',
    label: 'Pour sélectionner un appareil domestique de chauffage au bois, je peux me fier au label « Flamme rouge ».',
    fullExplanation: 'ra-e2-1',
    simpleExplanation: 'rs-e2-1',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'e2-4',
    category: 'e',
    subject: 'e2',
    label: 'L\'humidité a beaucoup d\'influence sur la quantité d\'énergie récupérable du bois\u00A0?',
    fullExplanation: 'ra-e2-2',
    simpleExplanation: 'rs-e2-2',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Faux'
      },
      {
        name: 'b',
        label: 'Vrai'
      }
    ]
  },
  {
    id: 'e2-5',
    category: 'e',
    subject: 'e2',
    label: 'Un bois sec est plus calorifique qu’un bois humide\u00A0:',
    fullExplanation: 'ra-e2-2',
    simpleExplanation: 'rs-e2-2',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Faux'
      },
      {
        name: 'b',
        label: 'Vrai'
      }
    ]
  },
  {
    id: 'e2-6',
    category: 'e',
    subject: 'e2',
    label: 'Le fait de brûler un bois humide plutôt qu’un bois sec\u00A0:',
    fullExplanation: 'ra-e2-2',
    simpleExplanation: 'rs-e2-2',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Augmente la quantité d’énergie récupérable'
      },
      {
        name: 'b',
        label: 'Diminue la quantité d’énergie récupérable'
      },
      {
        name: 'c',
        label: 'N’a pas d’influence sur la quantité d’énergie récupérable'
      }
    ]
  },
  {
    id: 'e2-7',
    category: 'e',
    subject: 'e2',
    label: 'On peut récupérer plus d’énergie quand on brûle du bois humide plutôt que du bois sec\u00A0:',
    fullExplanation: 'ra-e2-2',
    simpleExplanation: 'rs-e2-2',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Faux'
      },
      {
        name: 'b',
        label: 'Vrai'
      }
    ]
  },
  {
    id: 'e2-8',
    category: 'e',
    subject: 'e2',
    label: 'Lequel de ces matériaux est considéré comme de la biomasse\u00A0:',
    fullExplanation: 'ra-e2-3',
    simpleExplanation: 'rs-e2-3',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'L\'ensemble des énergies fossiles '
      },
      {
        name: 'b',
        label: 'Le bois'
      },
      {
        name: 'c',
        label: 'Les déchets plastiques'
      }
    ]
  },
  {
    id: 'e2-9',
    category: 'e',
    subject: 'e2',
    label: 'Le bois est considéré comme de la biomasse\u00A0:',
    fullExplanation: 'ra-e2-3',
    simpleExplanation: 'rs-e2-3',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Faux'
      },
      {
        name: 'b',
        label: 'Vrai'
      }
    ]
  },
  {
    id: 'e2-10',
    category: 'e',
    subject: 'e2',
    label: 'Lequel de ces matériaux n’est pas considéré comme de la biomasse\u00A0?',
    fullExplanation: 'ra-e2-3',
    simpleExplanation: 'rs-e2-3',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'Les céréales'
      },
      {
        name: 'b',
        label: 'Le bois'
      },
      {
        name: 'c',
        label: 'L’hydraulique'
      }
    ]
  },
  {
    id: 'e2-11',
    category: 'e',
    subject: 'e2',
    label: 'Comment peut-on extraire l’énergie de la biomasse\u00A0?',
    fullExplanation: 'ra-e2-3',
    simpleExplanation: 'rs-e2-3',
    correctAnswers: ['a', 'b', 'c'],
    choices: [
      {
        name: 'a',
        label: 'Par combustion'
      },
      {
        name: 'b',
        label: 'Par méthanisation'
      },
      {
        name: 'c',
        label: 'Par transformation chimique'
      }
    ]
  },
  {
    id: 'e3-1',
    category: 'e',
    subject: 'e3',
    label: 'La technologie qui consiste à produire de l\'électricité par transformation directe du rayonnement solaire s’appelle\u00A0:',
    fullExplanation: 'ra-e3-1',
    simpleExplanation: 'rs-e3-1',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'Le solaire thermique'
      },
      {
        name: 'b',
        label: 'Le solaire à concentration'
      },
      {
        name: 'c',
        label: 'Le solaire photovoltaïque'
      }
    ]
  },
  {
    id: 'e3-2',
    category: 'e',
    subject: 'e3',
    label: 'Le solaire photovoltaïque consiste à \u00A0:',
    fullExplanation: 'ra-e3-1',
    simpleExplanation: 'rs-e3-1',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Produire de l’eau chaude à partir du rayonnement solaire'
      },
      {
        name: 'b',
        label: 'Produire de l’électricité à partir du rayonnement solaire'
      }
    ]
  },
  {
    id: 'e3-3',
    category: 'e',
    subject: 'e3',
    label: 'La technologie qui consiste à produire de l’eau chaude à partir du rayonnement solaire s’appelle le solaire photovoltaïque\u00A0:',
    fullExplanation: 'ra-e3-1',
    simpleExplanation: 'rs-e3-1',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'e3-4',
    category: 'e',
    subject: 'e3',
    label: 'Combien faut-il poser de m2 de modules photovoltaïques sur une toiture pour produire l\'équivalent des besoins annuels en électricité, hors chauffage et eau chaude sanitaire, d\'un foyer de 4 personnes\u00A0?',
    fullExplanation: 'ra-e3-2',
    simpleExplanation: 'rs-e3-2',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: '5 m2'
      },
      {
        name: 'b',
        label: '100 m2'
      },
      {
        name: 'c',
        label: '25 m2'
      }
    ]
  },
  {
    id: 'e3-5',
    category: 'e',
    subject: 'e3',
    label: '5 m2 de panneaux photovoltaïques sur une toiture sont suffisant pour produire l\'équivalent des besoins annuels en électricité, hors chauffage et eau chaude sanitaire, d\'une famille de 4 personnes\u00A0?',
    fullExplanation: 'ra-e3-2',
    simpleExplanation: 'rs-e3-2',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Faux'
      },
      {
        name: 'b',
        label: 'Vrai'
      }
    ]
  },
  {
    id: 'e3-6',
    category: 'e',
    subject: 'e3',
    label: 'Si je veux subvenir aux besoins en électricité d’une famille de 4 personnes (hors chauffage et ECS), il faudra que je pose combien de m2 de modules photovoltaïques sur la toiture de leur habitation\u00A0?',
    fullExplanation: 'ra-e3-2',
    simpleExplanation: 'rs-e3-2',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: '25 m2'
      },
      {
        name: 'b',
        label: '50 m2'
      },
      {
        name: 'c',
        label: '100 m2'
      }
    ]
  },
  {
    id: 'e3-7',
    category: 'e',
    subject: 'e3',
    label: 'Sur une année, 25 m2 de panneaux photovoltaïques produisent suffisamment d’énergie pour couvrir les besoins annuels en électricité (hors chauffage et eau chaude sanitaire) d\'une famille de 4 personnes\u00A0:',
    fullExplanation: 'ra-e3-2',
    simpleExplanation: 'rs-e3-2',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Faux'
      },
      {
        name: 'b',
        label: 'Vrai'
      }
    ]
  },
  {
    id: 'e3-8',
    category: 'e',
    subject: 'e3',
    label: 'Dans quel cas parle-t-on de "système solaire combiné"\u00A0?',
    fullExplanation: 'ra-e3-3',
    simpleExplanation: 'rs-e3-3',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'Utilisation combinée, pour la production d\'eau chaude, du rayonnement solaire et d\'une énergie fossile qui prend le relais en cas de sous ensoleillement'
      },
      {
        name: 'b',
        label: 'Utilisation combinée, pour la production d\'eau chaude, du rayonnement solaire et d\'une autre énergie renouvelable (par exemple : éolien, biomasse ...)'
      },
      {
        name: 'c',
        label: 'Utilisation du rayonnement solaire pour l\'eau chaude sanitaire et une partie des besoins en chauffage'
      }
    ]
  },
  {
    id: 'e3-9',
    category: 'e',
    subject: 'e3',
    label: 'Un "système solaire combiné" produit de l’eau chaude sanitaire et du chauffage\u00A0:',
    fullExplanation: 'ra-e3-3',
    simpleExplanation: 'rs-e3-3',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Faux'
      },
      {
        name: 'b',
        label: 'Vrai'
      }
    ]
  },
  {
    id: 'e3-10',
    category: 'e',
    subject: 'e3',
    label: 'Un système utilisant le rayonnement solaire pour produire de l\'eau chaude sanitaire et une partie des besoins en chauffage, est appelé\u00A0:',
    fullExplanation: 'ra-e3-3',
    simpleExplanation: 'rs-e3-3',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'Un système solaire hybride'
      },
      {
        name: 'b',
        label: 'Un système solaire double service'
      },
      {
        name: 'c',
        label: 'Un système solaire combiné'
      }
    ]
  },
  {
    id: 'e3-11',
    category: 'e',
    subject: 'e3',
    label: 'Un "système solaire combiné" est un système qui combine les deux technologies solaires : photovoltaïque et thermique\u00A0:',
    fullExplanation: 'ra-e3-3',
    simpleExplanation: 'rs-e3-3',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Faux'
      },
      {
        name: 'b',
        label: 'Vrai'
      }
    ]
  },
  {
    id: 'e3-12',
    category: 'e',
    subject: 'e3',
    label: 'Quelle différence existe-t-il entre un panneau solaire thermique et un panneau photovoltaïque\u00A0?',
    fullExplanation: 'ra-e3-4',
    simpleExplanation: 'rs-e3-4',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Aucune, les deux permettent la production d\'eau chaude'
      },
      {
        name: 'b',
        label: 'Le photovoltaïque produit de l\'électricité et le solaire thermique permet de réchauffer un circuit de fluide caloporteur'
      },
      {
        name: 'c',
        label: 'Aucune, les deux génèrent de l\'électricité'
      }
    ]
  },
  {
    id: 'e3-13',
    category: 'e',
    subject: 'e3',
    label: 'Un panneau solaire thermique et un panneau solaire photovoltaïque produisent la même chose\u00A0:',
    fullExplanation: 'ra-e3-4',
    simpleExplanation: 'rs-e3-4',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'e3-14',
    category: 'e',
    subject: 'e3',
    label: 'Que produit un panneau solaire photovoltaïque\u00A0?',
    fullExplanation: 'ra-e3-4',
    simpleExplanation: 'rs-e3-4',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'De l\'eau chaude'
      },
      {
        name: 'b',
        label: 'De l’électricité'
      },
      {
        name: 'c',
        label: 'De l’eau chaude et de l’électricité'
      }
    ]
  },
  {
    id: 'e3-15',
    category: 'e',
    subject: 'e3',
    label: 'Que produit un panneau solaire thermique\u00A0?',
    fullExplanation: 'ra-e3-4',
    simpleExplanation: 'rs-e3-4',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'De l\'eau chaude'
      },
      {
        name: 'b',
        label: 'De l’électricité'
      },
      {
        name: 'c',
        label: 'De l’eau chaude et de l’électricité'
      }
    ]
  },
  {
    id: 'e3-16',
    category: 'e',
    subject: 'e3',
    label: 'Que désigne le sigle "CESI"\u00A0?',
    fullExplanation: 'ra-e3-5',
    simpleExplanation: 'rs-e3-5',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'Chauffe-eau solaire inertiel'
      },
      {
        name: 'b',
        label: 'Chaudière électrique sans inertie '
      },
      {
        name: 'c',
        label: 'Chauffe-eau solaire individuel'
      }
    ]
  },
  {
    id: 'e3-17',
    category: 'e',
    subject: 'e3',
    label: 'Un chauffe-eau solaire individuel est aussi appelé\u00A0:',
    fullExplanation: 'ra-e3-5',
    simpleExplanation: 'rs-e3-5',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'Un CESOI'
      },
      {
        name: 'b',
        label: 'Un CESID'
      },
      {
        name: 'c',
        label: 'Un CESI'
      }
    ]
  },
  {
    id: 'e3-18',
    category: 'e',
    subject: 'e3',
    label: 'CESI veut dire Chauffe-eau solaire individuel\u00A0:',
    fullExplanation: 'ra-e3-5',
    simpleExplanation: 'rs-e3-5',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'e3-19',
    category: 'e',
    subject: 'e3',
    label: 'Pour produire de l’Eau Chaude Sanitaire Solaire je peux installer\u00A0:',
    fullExplanation: 'ra-e3-5',
    simpleExplanation: 'rs-e3-5',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Un ECSS'
      },
      {
        name: 'b',
        label: 'Un CESI'
      },
      {
        name: 'c',
        label: 'Un ESC Thermodynamique'
      }
    ]
  },
  {
    id: 'e4-1',
    category: 'e',
    subject: 'e4',
    label: 'Une pompe à chaleur consomme de l’énergie pour faire fonctionner son\u00A0:',
    fullExplanation: 'ra-e4-1',
    simpleExplanation: 'rs-e4-1',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Compresseur'
      },
      {
        name: 'b',
        label: 'Condenseur'
      },
      {
        name: 'c',
        label: 'Evaporateur'
      },
      {
        name: 'd',
        label: 'Détendeur'
      }
    ]
  },
  {
    id: 'e4-2',
    category: 'e',
    subject: 'e4',
    label: 'Une pompe à chaleur absorbe les calories grâce à son\u00A0:',
    fullExplanation: 'ra-e4-1',
    simpleExplanation: 'rs-e4-1',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'Compresseur'
      },
      {
        name: 'b',
        label: 'Condenseur'
      },
      {
        name: 'c',
        label: 'Evaporateur'
      },
      {
        name: 'd',
        label: 'Détendeur'
      }
    ]
  },
  {
    id: 'e4-3',
    category: 'e',
    subject: 'e4',
    label: 'Une pompe à chaleur restitue les calories grâce à son\u00A0:',
    fullExplanation: 'ra-e4-1',
    simpleExplanation: 'rs-e4-1',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Compresseur'
      },
      {
        name: 'b',
        label: 'Condenseur'
      },
      {
        name: 'c',
        label: 'Evaporateur'
      },
      {
        name: 'd',
        label: 'Détendeur'
      }
    ]
  },
  {
    id: 'e4-4',
    category: 'e',
    subject: 'e4',
    label: 'Lorsque l\'on parle de COP, à quel équipement se réfère-t-on\u00A0?',
    fullExplanation: 'ra-e4-2',
    simpleExplanation: 'rs-e4-2',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Une pompe à chaleur'
      },
      {
        name: 'b',
        label: 'Un plancher chauffant électrique'
      },
      {
        name: 'c',
        label: 'Une chaudière basse température'
      }
    ]
  },
  {
    id: 'e4-5',
    category: 'e',
    subject: 'e4',
    label: ' On exprime la performance d’une pompe à chaleur faisant du chauffage par\u00A0:',
    fullExplanation: 'ra-e4-2',
    simpleExplanation: 'rs-e4-2',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'Un rendement'
      },
      {
        name: 'b',
        label: 'Un EER'
      },
      {
        name: 'c',
        label: 'Un COP'
      }
    ]
  },
  {
    id: 'e4-6',
    category: 'e',
    subject: 'e4',
    label: 'Le COP permet d’exprimer la performance d’une pompe à chaleur\u00A0:',
    fullExplanation: 'ra-e4-2',
    simpleExplanation: 'rs-e4-2',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'e4-7',
    category: 'e',
    subject: 'e4',
    label: 'Lorsque mon chauffagiste me propose un équipement qui a un bon COP, de quoi s’agit-il\u00A0?',
    fullExplanation: 'ra-e4-2',
    simpleExplanation: 'rs-e4-2',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'Un plancher rayonnant électrique'
      },
      {
        name: 'b',
        label: 'Une chaudière'
      },
      {
        name: 'c',
        label: 'Une pompe à chaleur'
      }
    ]
  },
  {
    id: 'e4-8',
    category: 'e',
    subject: 'e4',
    label: 'Quel est le coefficient de performance moyen d\'une pompe à chaleur en géothermie individuelle\u00A0?',
    fullExplanation: 'ra-e4-3',
    simpleExplanation: 'rs-e4-3',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: '3 à 4'
      },
      {
        name: 'b',
        label: '1 à 2'
      },
      {
        name: 'c',
        label: '0,5 à 1'
      }
    ]
  },
  {
    id: 'e4-9',
    category: 'e',
    subject: 'e4',
    label: 'le coefficient de performance moyen d\'une pompe à chaleur en géothermie individuelle est compris entre 0,5 et 1\u00A0:',
    fullExplanation: 'ra-e4-3',
    simpleExplanation: 'rs-e4-3',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'e4-10',
    category: 'e',
    subject: 'e4',
    label: 'Pour sélectionner une pompe à chaleur en géothermie individuelle je regarde son coefficient de performance. Quelle valeur celui-ci doit avoir pour une performance correcte du système\u00A0?',
    fullExplanation: 'ra-e4-3',
    simpleExplanation: 'rs-e4-3',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: '10 à 20'
      },
      {
        name: 'b',
        label: '3 à 4'
      },
      {
        name: 'c',
        label: '0,3 à 0,4'
      }
    ]
  },
  {
    id: 'e4-11',
    category: 'e',
    subject: 'e4',
    label: 'Un coefficient de performance compris entre 10 et 20 est une valeur moyenne pour une pompe à chaleur en géothermie individuelle\u00A0:',
    fullExplanation: 'ra-e4-3',
    simpleExplanation: 'rs-e4-3',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'e4-12',
    category: 'e',
    subject: 'e4',
    label: 'Qu\'est-ce qu’une installation géothermique en maison individuelle\u00A0?',
    fullExplanation: 'ra-e4-4',
    simpleExplanation: 'rs-e4-4',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'Un système de ventilation'
      },
      {
        name: 'b',
        label: 'Un type de fondation'
      },
      {
        name: 'c',
        label: 'Un système de production de chaleur permettant de puiser des calories dans le sol'
      }
    ]
  },
  {
    id: 'e4-13',
    category: 'e',
    subject: 'e4',
    label: 'Une installation géothermique est un système de production de chaleur permettant de puiser des calories dans le sol\u00A0?',
    fullExplanation: 'ra-e4-4',
    simpleExplanation: 'rs-e4-4',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'e4-14',
    category: 'e',
    subject: 'e4',
    label: 'Une installation permettant de puiser des calories dans le sol est appelée\u00A0:',
    fullExplanation: 'ra-e4-4',
    simpleExplanation: 'rs-e4-4',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'Aquathermie'
      },
      {
        name: 'b',
        label: 'Aérothermie'
      },
      {
        name: 'c',
        label: 'Géothermie'
      }
    ]
  },
  {
    id: 'e4-15',
    category: 'e',
    subject: 'e4',
    label: 'Je souhaite me chauffer avec des calories puisées dans le sol, je choisi quel type d’installation\u00A0?',
    fullExplanation: 'ra-e4-4',
    simpleExplanation: 'rs-e4-4',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Un système géothermique'
      },
      {
        name: 'b',
        label: 'Un système aérothermique'
      },
      {
        name: 'c',
        label: 'Un système aquathermique'
      }
    ]
  },
  {
    id: 'e4-16',
    category: 'e',
    subject: 'e4',
    label: 'Que signifie PAC\u00A0?',
    fullExplanation: 'ra-e4-5',
    simpleExplanation: 'rs-e4-5',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'Polystyrène à chauffer '
      },
      {
        name: 'b',
        label: 'Production d\'air chaud'
      },
      {
        name: 'c',
        label: 'Pompe à chaleur'
      }
    ]
  },
  {
    id: 'e4-17',
    category: 'e',
    subject: 'e4',
    label: 'PAC signifie pompe à chaleur\u00A0:',
    fullExplanation: 'ra-e4-5',
    simpleExplanation: 'rs-e4-5',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'e4-18',
    category: 'e',
    subject: 'e4',
    label: 'On désigne souvent une pompe à chaleur par l’acronyme PAC\u00A0:',
    fullExplanation: 'ra-e4-5',
    simpleExplanation: 'rs-e4-5',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'e4-19',
    category: 'e',
    subject: 'e4',
    label: 'Lorsque l’on parle de PAC, on parle de\u00A0:',
    fullExplanation: 'ra-e4-5',
    simpleExplanation: 'rs-e4-5',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Pompe à chaleur'
      },
      {
        name: 'b',
        label: 'Paramètre actualisé du chauffage '
      },
      {
        name: 'c',
        label: 'Production autonome de chaleur'
      }
    ]
  },
  {
    id: 'e4-20',
    category: 'e',
    subject: 'e4',
    label: 'Le coefficient de performance d\'une pompe à chaleur (COP) permet de connaître \u00A0:',
    fullExplanation: 'ra-e4-6',
    simpleExplanation: 'rs-e4-6',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'La durée de l\'amortissement de l\'installation'
      },
      {
        name: 'b',
        label: 'L\'écart maximum de température intérieur/extérieur supporté par l\'installation'
      },
      {
        name: 'c',
        label: 'L\'énergie thermique restituée/énergie électrique consommée'
      }
    ]
  },
  {
    id: 'e4-21',
    category: 'e',
    subject: 'e4',
    label: 'Le rapport entre l\'énergie thermique restituée et l\'énergie électrique consommée par une pompe à chaleur, s’appelle\u00A0:',
    fullExplanation: 'ra-e4-6',
    simpleExplanation: 'rs-e4-6',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'Le rendement'
      },
      {
        name: 'b',
        label: 'Energy Efficiency Ratio (EER)'
      },
      {
        name: 'c',
        label: 'Le Coefficient de performance (COP)'
      }
    ]
  },
  {
    id: 'e4-22',
    category: 'e',
    subject: 'e4',
    label: 'Le rapport entre l\'énergie thermique restituée et l\'énergie électrique consommée par une pompe à chaleur, s’appelle le COP\u00A0:',
    fullExplanation: 'ra-e4-6',
    simpleExplanation: 'rs-e4-6',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'e4-23',
    category: 'e',
    subject: 'e4',
    label: 'Si je dispose du COP et de l\'énergie thermique restituée par une pompe à chaleur, je peux en déduire l’énergie qu’elle consomme\u00A0:',
    fullExplanation: 'ra-e4-6',
    simpleExplanation: 'rs-e4-6',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'e4-24',
    category: 'e',
    subject: 'e4',
    label: 'On estime une Pompe à Chaleur performante, si\u00A0:',
    fullExplanation: 'ra-e4-7',
    simpleExplanation: 'rs-e4-7',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Son COP est supérieur à 3'
      },
      {
        name: 'b',
        label: 'Son COP est de 90%'
      },
      {
        name: 'c',
        label: 'Son COP est inférieur à 1'
      }
    ]
  },
  {
    id: 'e4-25',
    category: 'e',
    subject: 'e4',
    label: 'Une Pompe à Chaleur performante est une Pompe à Chaleur dont le COP est supérieur à 3\u00A0:',
    fullExplanation: 'ra-e4-7',
    simpleExplanation: 'rs-e4-7',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'e4-26',
    category: 'e',
    subject: 'e4',
    label: 'Un COP supérieur à 3\u00A0:',
    fullExplanation: 'ra-e4-7',
    simpleExplanation: 'rs-e4-7',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'Est considéré comme une performance moyenne pour une Pompe à Chaleur'
      },
      {
        name: 'b',
        label: 'Est considéré comme une performance faible pour une Pompe à Chaleur'
      },
      {
        name: 'c',
        label: 'Est considéré comme une performance élevée pour une Pompe à Chaleur'
      }
    ]
  },
  {
    id: 'e4-27',
    category: 'e',
    subject: 'e4',
    label: 'Si je veux sélectionner une Pompe à Chaleur performante, je sélectionne une Pompe à Chaleur avec\u00A0:',
    fullExplanation: 'ra-e4-7',
    simpleExplanation: 'rs-e4-7',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Un COP supérieur à 3'
      },
      {
        name: 'b',
        label: 'Un COP de 150 %'
      },
      {
        name: 'c',
        label: 'Un COP inférieur à 1'
      }
    ]
  },
  {
    id: 'e4-28',
    category: 'e',
    subject: 'e4',
    label: 'Pour caractériser les performances thermiques d\'une pompe à chaleur, on utilise le coefficient\u00A0:',
    fullExplanation: 'ra-e4-8',
    simpleExplanation: 'rs-e4-8',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a ',
        label: 'POC'
      },
      {
        name: 'b',
        label: 'PAC'
      },
      {
        name: 'c',
        label: 'COP'
      }
    ]
  },
  {
    id: 'e4-29',
    category: 'e',
    subject: 'e4',
    label: 'Le coefficient COP caractérise les performances thermiques d\'une pompe à chaleur\u00A0:',
    fullExplanation: 'ra-e4-8',
    simpleExplanation: 'rs-e4-8',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'e4-30',
    category: 'e',
    subject: 'e4',
    label: 'Le coefficient PAC caractérise les performances thermiques d\'une pompe à chaleur\u00A0:',
    fullExplanation: 'ra-e4-8',
    simpleExplanation: 'rs-e4-8',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'e4-31',
    category: 'e',
    subject: 'e4',
    label: 'Les performances thermiques d\'une pompe à chaleur sont caractérisées à travers le coefficient\u00A0:',
    fullExplanation: 'ra-e4-8',
    simpleExplanation: 'rs-e4-8',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'EER'
      },
      {
        name: 'b',
        label: 'PAC'
      },
      {
        name: 'c',
        label: 'COP'
      }
    ]
  },
  {
    id: 'e5-1',
    category: 'e',
    subject: 'e5',
    label: 'Dans un vitrage noté 4-16-4, qu’indique le chiffre 4\u00A0:',
    fullExplanation: 'ra-e5-1',
    simpleExplanation: 'rs-e5-1',
    correctAnswers: ['d'],
    choices: [
      {
        name: 'a',
        label: 'Le coefficient de transmission thermique'
      },
      {
        name: 'b',
        label: 'La masse par unité de surface'
      },
      {
        name: 'c',
        label: 'L’épaisseur de la lame d’air'
      },
      {
        name: 'd',
        label: 'L’épaisseur du verre'
      }
    ]
  },
  {
    id: 'e5-2',
    category: 'e',
    subject: 'e5',
    label: 'Dans une certaine mesure, plus la largeur de la lame d’air dans un double vitrage est importante, plus le vitrage est isolant.',
    fullExplanation: 'ra-e5-1',
    simpleExplanation: 'rs-e5-1',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Faux'
      },
      {
        name: 'b',
        label: 'Vrai'
      }
    ]
  },
  {
    id: 'e5-3',
    category: 'e',
    subject: 'e5',
    label: 'Pour un double vitrage, les épaisseurs de ses vitres ont une importance cruciale sur sa performance.',
    fullExplanation: 'ra-e5-1',
    simpleExplanation: 'rs-e5-1',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Faux'
      },
      {
        name: 'b',
        label: 'Vrai'
      }
    ]
  },
  {
    id: 'e5-4',
    category: 'e',
    subject: 'e5',
    label: 'Dans une menuiserie, qu\'est-ce qu\'un profilé à rupture de pont thermique\u00A0:',
    fullExplanation: 'ra-e5-2',
    simpleExplanation: 'rs-e5-2',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Un profilé ajouré'
      },
      {
        name: 'b',
        label: 'Un profilé avec incorporation d\'un matériau non conducteur de chaleur'
      },
      {
        name: 'c',
        label: 'Un profilé permettant une circulation de l\'air'
      }
    ]
  },
  {
    id: 'e5-5',
    category: 'e',
    subject: 'e5',
    label: 'En menuiserie, un profilé à rupture de pont thermique intègre un matériau conducteur de chaleur\u00A0:',
    fullExplanation: 'ra-e5-2',
    simpleExplanation: 'rs-e5-2',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'e5-6',
    category: 'e',
    subject: 'e5',
    label: 'En menuiserie, un profilé avec incorporation d\'un matériau non conducteur de chaleur est appelé\u00A0:',
    fullExplanation: 'ra-e5-2',
    simpleExplanation: 'rs-e5-2',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Profilé isolant'
      },
      {
        name: 'b',
        label: 'Profilé à rupture de pont thermique'
      },
      {
        name: 'c',
        label: 'Profilé à isolation thermique'
      }
    ]
  },
  {
    id: 'e5-7',
    category: 'e',
    subject: 'e5',
    label: 'Les profilés à rupture de pont thermique sont généralement utilisés pour fabriquer quel type de menuiseries\u00A0?',
    fullExplanation: 'ra-e5-2',
    simpleExplanation: 'rs-e5-2',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'Bois'
      },
      {
        name: 'b',
        label: 'Bois/Aluminium'
      },
      {
        name: 'c',
        label: 'Aluminium'
      },
      {
        name: 'd',
        label: 'PVC-Aluminium'
      }
    ]
  },
  {
    id: 'e5-8',
    category: 'e',
    subject: 'e5',
    label: 'Par rapport à un double vitrage normal, un double vitrage peu émissif\u00A0:',
    fullExplanation: 'ra-e5-3',
    simpleExplanation: 'rs-e5-3',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Est muni d\'une fine couche translucide d\'oxydes métalliques déposée sur une des faces internes du double vitrage'
      },
      {
        name: 'b',
        label: 'Est 30 % moins performant'
      },
      {
        name: 'c',
        label: 'Est muni d\'un cadre à rupture de ponts thermiques'
      }
    ]
  },
  {
    id: 'e5-9',
    category: 'e',
    subject: 'e5',
    label: 'Un double vitrage peu émissif présente quelle particularité\u00A0?',
    fullExplanation: 'ra-e5-3',
    simpleExplanation: 'rs-e5-3',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Il est muni d\'une fine couche translucide d\'oxydes métalliques déposée sur une des faces internes du double vitrage'
      },
      {
        name: 'b',
        label: 'Il ne réémet pas le rayonnement solaire, mais l’absorbe'
      },
      {
        name: 'c',
        label: 'Il renvoie tout ou presque le rayonnement solaire et ainsi évite l’usage de la climatisation'
      }
    ]
  },
  {
    id: 'e5-10',
    category: 'e',
    subject: 'e5',
    label: 'un double vitrage peu émissif est moins performant qu’un double vitrage standard\u00A0:',
    fullExplanation: 'ra-e5-3',
    simpleExplanation: 'rs-e5-3',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'e5-11',
    category: 'e',
    subject: 'e5',
    label: 'Un double vitrage muni d\'une fine couche translucide d\'oxydes métalliques déposée sur une de ses faces internes est\u00A0:',
    fullExplanation: 'ra-e5-3',
    simpleExplanation: 'rs-e5-3',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'Un double vitrage oxydé'
      },
      {
        name: 'b',
        label: 'Un double vitrage argon'
      },
      {
        name: 'c',
        label: 'Un double vitrage peu émissif'
      }
    ]
  },
  {
    id: 'e5-12',
    category: 'e',
    subject: 'e5',
    label: 'Quel gaz noble se trouve parfois dans les vitrages\u00A0?',
    fullExplanation: 'ra-e5-4',
    simpleExplanation: 'rs-e5-4',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Radon '
      },
      {
        name: 'b',
        label: 'Argon '
      },
      {
        name: 'c',
        label: 'Fréon'
      }
    ]
  },
  {
    id: 'e5-13',
    category: 'e',
    subject: 'e5',
    label: 'On trouve parfois dans les vitrages un gaz noble (rare)\u00A0:',
    fullExplanation: 'ra-e5-4',
    simpleExplanation: 'rs-e5-4',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'e5-14',
    category: 'e',
    subject: 'e5',
    label: 'Le Radon fait partie des gaz nobles (rares) que l’on trouve parfois dans les vitrages\u00A0:',
    fullExplanation: 'ra-e5-4',
    simpleExplanation: 'rs-e5-4',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'e5-15',
    category: 'e',
    subject: 'e5',
    label: 'La lame d’air des doubles vitrages peut être remplie de quel gaz noble (rare)\u00A0:',
    fullExplanation: 'ra-e5-4',
    simpleExplanation: 'rs-e5-4',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'L’hydrogène'
      },
      {
        name: 'b',
        label: 'Le krypton'
      },
      {
        name: 'c',
        label: 'Le Co2'
      }
    ]
  },
  {
    id: 'e6-bruit-pac-1',
    category: 'e',
    subject: 'e6',
    label: 'Selon l’emplacement de l’unité extérieure de ma pompe à chaleur Air/Air ou Air/Eau, les nuisances sonores peuvent être multipliées par 2\u00A0:',
    fullExplanation: 'ra-e6-bruit-pac-1',
    simpleExplanation: 'rs-e6-bruit-pac-1',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'e6-bruit-pac-2',
    category: 'e',
    subject: 'e6',
    label: 'Le bruit généré par la pompe à chaleur doit être inférieur à une valeur fixée par la réglementation\u00A0:',
    fullExplanation: 'ra-e6-bruit-pac-1',
    simpleExplanation: 'rs-e6-bruit-pac-1',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'e6-1',
    category: 'e',
    subject: 'e6',
    label: 'Dans une installation de VMC simple flux, où sont situées les bouches de soufflage\u00A0?',
    fullExplanation: 'ra-e6-1',
    simpleExplanation: 'rs-e6-1',
    correctAnswers: ['d'],
    choices: [
      {
        name: 'a',
        label: 'Dans les pièces principales'
      },
      {
        name: 'b',
        label: 'Dans les pièces de service '
      },
      {
        name: 'c',
        label: 'Dans les pièces de circulation'
      },
      {
        name: 'd',
        label: 'Il n’y a pas de bouche de soufflage dans une installation d’une VMC simple flux'
      }
    ]
  },
  {
    id: 'e6-2',
    category: 'e',
    subject: 'e6',
    label: 'Dans une installation de VMC simple flux, où sont situées les modules d’entrée d’air\u00A0?',
    fullExplanation: 'ra-e6-1',
    simpleExplanation: 'rs-e6-1',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Dans les pièces principales'
      },
      {
        name: 'b',
        label: 'Dans les pièces de service '
      },
      {
        name: 'c',
        label: 'Dans les pièces de circulation'
      },
      {
        name: 'd',
        label: 'Il n’y a pas de module d’entrée d’air dans une installation d’une VMC simple flux'
      }
    ]
  },
  {
    id: 'e6-3',
    category: 'e',
    subject: 'e6',
    label: 'Dans une installation de VMC simple flux, les entrées d’air sont situées dans les pièces de service\u00A0:',
    fullExplanation: 'ra-e6-1',
    simpleExplanation: 'rs-e6-1',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Faux'
      },
      {
        name: 'b',
        label: 'Vrai'
      }
    ]
  },
  {
    id: 'e6-4',
    category: 'e',
    subject: 'e6',
    label: 'Lors de l\'installation d’une VMC simple flux, de quoi sont équipées les pièces humides (cuisine, bain, WC)\u00A0?',
    fullExplanation: 'ra-e6-2',
    simpleExplanation: 'rs-e6-2',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Bouches de soufflage'
      },
      {
        name: 'b',
        label: 'Bouches d\'extractions'
      },
      {
        name: 'c',
        label: 'Entrées d\'air'
      }
    ]
  },
  {
    id: 'e6-5',
    category: 'e',
    subject: 'e6',
    label: 'Lorsqu’on installe une VMC simple flux dans un logement, on équipe les pièces humides (cuisine, bain, WC, …) de bouches de soufflage\u00A0:',
    fullExplanation: 'ra-e6-2',
    simpleExplanation: 'rs-e6-2',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Faux'
      },
      {
        name: 'b',
        label: 'Vrai'
      }
    ]
  },
  {
    id: 'e6-6',
    category: 'e',
    subject: 'e6',
    label: 'Lorsqu’on installe une VMC simple flux dans un logement, on équipe les pièces principales (salon, séjour, chambres, …) de bouches d’extraction\u00A0:',
    fullExplanation: 'ra-e6-2',
    simpleExplanation: 'rs-e6-2',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Faux'
      },
      {
        name: 'b',
        label: 'Vrai'
      }
    ]
  },
  {
    id: 'e6-7',
    category: 'e',
    subject: 'e6',
    label: 'Une installation de VMC simple flux correctement installée contient dans les pièces principales, des entrées d’air et\u00A0:',
    fullExplanation: 'ra-e6-2',
    simpleExplanation: 'rs-e6-2',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Des bouches de soufflage'
      },
      {
        name: 'b',
        label: 'Dans les pièces humides, des bouches d’extractions'
      },
      {
        name: 'c',
        label: 'Des bouches d’extraction'
      },
      {
        name: 'd',
        label: 'Dans les pièces humides, des bouches de soufflage'
      }
    ]
  },
  {
    id: 'e6-8',
    category: 'e',
    subject: 'e6',
    label: 'Lors de l\'installation d\'une VMC double flux, de quoi sont équipées les pièces principales (séjour, chambres)\u00A0?',
    fullExplanation: 'ra-e6-3',
    simpleExplanation: 'rs-e6-3',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'Bouches d\'extractions'
      },
      {
        name: 'b',
        label: 'Entrées d\'air'
      },
      {
        name: 'c',
        label: 'Bouches de soufflage'
      }
    ]
  },
  {
    id: 'e6-9',
    category: 'e',
    subject: 'e6',
    label: 'Lorsqu’on installe une VMC double flux dans un logement, on équipe les pièces principales (séjour, salon, chambres…) avec\u00A0:',
    fullExplanation: 'ra-e6-3',
    simpleExplanation: 'rs-e6-3',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'Des bouches d\'extractions'
      },
      {
        name: 'b',
        label: 'Des entrées d\'air'
      },
      {
        name: 'c',
        label: 'Des bouches de soufflage'
      }
    ]
  },
  {
    id: 'e6-10',
    category: 'e',
    subject: 'e6',
    label: 'Dans une installation de VMC double flux, les pièces humides (cuisine, bain, WC, …) sont équipées de\u00A0:',
    fullExplanation: 'ra-e6-3',
    simpleExplanation: 'rs-e6-3',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Des bouches d\'extraction'
      },
      {
        name: 'b',
        label: 'Des entrées d\'air'
      },
      {
        name: 'c',
        label: 'Des bouches de soufflage'
      }
    ]
  },
  {
    id: 'e6-11',
    category: 'e',
    subject: 'e6',
    label: 'Lorsque j’installe une VMC double flux dans un logement, j’équipe les pièces humides (cuisine, bain, WC, …) de bouches d\'extraction\u00A0:',
    fullExplanation: 'ra-e6-3',
    simpleExplanation: 'rs-e6-3',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'e6-12',
    category: 'e',
    subject: 'e6',
    label: 'Quelle est la caractéristique d\'une VMC Hygro B\u00A0?',
    fullExplanation: 'ra-e6-4',
    simpleExplanation: 'rs-e6-4',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Les bouches d\'entrée d\'air et les bouches d\'extraction se règlent en fonction du taux d\'hygrométrie'
      },
      {
        name: 'b',
        label: 'Le moteur de la VMC est classé B (étiquette énergétique)'
      },
      {
        name: 'c',
        label: 'Les bouches d\'entrée d\'air sont auto réglables (au niveau des fenêtres, par exemple) et les bouches d\'extraction se règlent en fonction du taux d\'hygrométrie'
      }
    ]
  },
  {
    id: 'e6-13',
    category: 'e',
    subject: 'e6',
    label: 'On appel VMC Hygro B une ventilation mécanique dont les entrées d’air dans les pièces principales se règlent en fonction du taux d’hygrométrie et\u00A0:',
    fullExplanation: 'ra-e6-4',
    simpleExplanation: 'rs-e6-4',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Les bouches d\'extraction dans les pièces humides se règlent en fonction du taux d\'hygrométrie'
      },
      {
        name: 'b',
        label: 'Les bouches d\'extraction dans les pièces humides sont auto réglables'
      }
    ]
  },
  {
    id: 'e6-14',
    category: 'e',
    subject: 'e6',
    label: 'Une VMC Hygro B est une ventilation mécanique dont les entrées d’air dans les pièces principales se règlent en fonction du taux d’hygrométrie et les bouches d\'extraction dans les pièces humides sont auto réglables\u00A0:',
    fullExplanation: 'ra-e6-4',
    simpleExplanation: 'rs-e6-4',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'e6-15',
    category: 'e',
    subject: 'e6',
    label: 'Lorsque j’installe une VMC Hygro B dans un logement, j’installe\u00A0:',
    fullExplanation: 'ra-e6-4',
    simpleExplanation: 'rs-e6-4',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Des d\'entrées d\'air et des bouches d\'extraction qui se règlent en fonction du taux d\'hygrométrie'
      },
      {
        name: 'b',
        label: 'Des entrées d\'air auto réglables et des bouches d\'extraction qui se règlent en fonction du taux d\'hygrométrie'
      },
      {
        name: 'c',
        label: 'Des entrées d\'air auto réglables et des bouches d\'extraction auto réglables'
      }
    ]
  },
  {
    id: 'e6-16',
    category: 'e',
    subject: 'e6',
    label: 'A quelle fréquence minimale devraient être remplacés ou nettoyés les filtres d\'une ventilation mécanique contrôlée double flux\u00A0?',
    fullExplanation: 'ra-e6-5',
    simpleExplanation: 'rs-e6-5',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'De 1 à 2 ans'
      },
      {
        name: 'b',
        label: 'Plus de 4 ans'
      },
      {
        name: 'c',
        label: 'Entre 1 et 6 mois'
      }
    ]
  },
  {
    id: 'e6-17',
    category: 'e',
    subject: 'e6',
    label: 'Les filtres d\'une ventilation mécanique contrôlée double flux doivent être remplacés ou nettoyés avec une fréquence de 1 à 6 mois\u00A0:',
    fullExplanation: 'ra-e6-5',
    simpleExplanation: 'rs-e6-5',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'e6-18',
    category: 'e',
    subject: 'e6',
    label: ' Les préconisations d’entretien d\'une ventilation mécanique contrôlée double flux stipulent en générale que les filtres doivent être remplacés ou nettoyés avec une fréquence de\u00A0:',
    fullExplanation: 'ra-e6-5',
    simpleExplanation: 'rs-e6-5',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: '1 à 6 mois'
      },
      {
        name: 'b',
        label: '1 à 2 ans'
      },
      {
        name: 'c',
        label: '2 à 4 ans'
      }
    ]
  },
  {
    id: 'e6-19',
    category: 'e',
    subject: 'e6',
    label: 'Lorsque vous avez fini d’installer une ventilation mécanique contrôlée double flux chez votre client, vous devez lui conseiller de remplacer ou nettoyer les filtres avec une fréquence de\u00A0:',
    fullExplanation: 'ra-e6-5',
    simpleExplanation: 'rs-e6-5',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: '2 à 4 ans'
      },
      {
        name: 'b',
        label: '1 à 2 ans'
      },
      {
        name: 'c',
        label: '1 à 6 mois'
      }
    ]
  },
  {
    id: 'e6-20',
    category: 'e',
    subject: 'e6',
    label: 'Quelle est la caractéristique d\'une VMC Hygro A\u00A0?',
    fullExplanation: 'ra-e6-6',
    simpleExplanation: 'rs-e6-6',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Les bouches d\'entrée d\'air sont auto réglables (au niveau des fenêtres, par exemple) et les bouches d\'extraction se règlent en fonction du taux d\'hygrométrie'
      },
      {
        name: 'b',
        label: 'Les bouches d\'entrée d\'air et les bouches d\'extraction se règlent en fonction du taux d\'hygrométrie '
      },
      {
        name: 'c',
        label: 'Le moteur de la VMC est classé A (étiquette énergétique)'
      }
    ]
  },
  {
    id: 'e6-21',
    category: 'e',
    subject: 'e6',
    label: 'On appel VMC Hygro A une ventilation mécanique dont les bouches d\'extraction d’air dans les pièces humides se règlent en fonction du taux d’hygrométrie et\u00A0:',
    fullExplanation: 'ra-e6-6',
    simpleExplanation: 'rs-e6-6',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Les entrées d’air dans les pièces principales se règlent en fonction du taux d\'hygrométrie'
      },
      {
        name: 'b',
        label: 'Les entrées d’air dans les pièces principales sont auto réglables'
      }
    ]
  },
  {
    id: 'e6-22',
    category: 'e',
    subject: 'e6',
    label: 'Une VMC Hygro A est une ventilation mécanique dont les entrées d’air dans les pièces principales se règlent en fonction du taux d’hygrométrie et les bouches d\'extraction dans les pièces humides sont auto réglables\u00A0:',
    fullExplanation: 'ra-e6-6',
    simpleExplanation: 'rs-e6-6',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'e6-23',
    category: 'e',
    subject: 'e6',
    label: 'Lorsque j’installe une VMC Hygro A dans un logement, j’installe\u00A0:',
    fullExplanation: 'ra-e6-6',
    simpleExplanation: 'rs-e6-6',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Des d\'entrées d\'air et des bouches d\'extraction qui se règlent en fonction du taux d\'hygrométrie'
      },
      {
        name: 'b',
        label: 'Des entrées d\'air auto réglables et des bouches d\'extraction qui se règlent en fonction du taux d\'hygrométrie'
      },
      {
        name: 'c',
        label: 'Des entrées d\'air auto réglables et des bouches d\'extraction auto réglables'
      }
    ]
  },
  {
    id: 'e6-24',
    category: 'e',
    subject: 'e6',
    label: 'Quelles sont les caractéristiques d\'une entrée d\'air auto-réglable\u00A0?',
    fullExplanation: 'ra-e6-7',
    simpleExplanation: 'rs-e6-7',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Elle possède une section de passage d\'air constante'
      },
      {
        name: 'b',
        label: 'Sa section de passage d\'air varie automatiquement en fonction du taux d\'hygrométrie '
      },
      {
        name: 'c',
        label: 'Sa section de passage d\'air varie en fonction de la température ambiante'
      }
    ]
  },
  {
    id: 'e6-25',
    category: 'e',
    subject: 'e6',
    label: 'Une entrée d\'air auto réglable possède une section de passage d\'air qui varie en fonction de la température ambiante\u00A0:',
    fullExplanation: 'ra-e6-7',
    simpleExplanation: 'rs-e6-7',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'e6-26',
    category: 'e',
    subject: 'e6',
    label: 'Une entrée d\'air auto réglable possède une section de passage d\'air constante\u00A0:',
    fullExplanation: 'ra-e6-7',
    simpleExplanation: 'rs-e6-7',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'e6-27',
    category: 'e',
    subject: 'e6',
    label: 'Une section de passage d\'air constante est une des caractéristiques des entrées d\'air auto réglables\u00A0:',
    fullExplanation: 'ra-e6-7',
    simpleExplanation: 'rs-e6-7',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'e6-28',
    category: 'e',
    subject: 'e6',
    label: 'Quel système de ventilation permet de récupérer la chaleur (calories) dégagée dans le logement\u00A0?',
    fullExplanation: 'ra-e6-8',
    simpleExplanation: 'rs-e6-8',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'La VMC simple flux auto-réglable'
      },
      {
        name: 'b',
        label: 'La VMC double flux'
      },
      {
        name: 'c',
        label: 'La VMC hygroréglable B'
      }
    ]
  },
  {
    id: 'e6-29',
    category: 'e',
    subject: 'e6',
    label: 'Une VMC double flux permet de récupérer la chaleur (calories) dégagée dans le logement \u00A0?',
    fullExplanation: 'ra-e6-8',
    simpleExplanation: 'rs-e6-8',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'e6-30',
    category: 'e',
    subject: 'e6',
    label: 'Comment appel-t-on le système de ventilation qui permet de récupérer la chaleur (calories) dégagée dans le logement\u00A0?',
    fullExplanation: 'ra-e6-8',
    simpleExplanation: 'rs-e6-8',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Le puit canadien'
      },
      {
        name: 'b',
        label: 'La VMC double flux'
      },
      {
        name: 'c',
        label: 'La VMC hygroréglable B'
      }
    ]
  },
  {
    id: 'e6-31',
    category: 'e',
    subject: 'e6',
    label: 'Si je souhaite récupérer la chaleur (calories) dégagée dans mon logement à l’aide de mon système de ventilation, j’installe\u00A0:',
    fullExplanation: 'ra-e6-8',
    simpleExplanation: 'rs-e6-8',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Un puit canadien'
      },
      {
        name: 'b',
        label: 'Une VMC double flux'
      },
      {
        name: 'c',
        label: 'Une VMC hygroréglable B'
      }
    ]
  },
  {
    id: 'e7-1',
    category: 'e',
    subject: 'e7',
    label: 'La pose d\'un interrupteur peut créer une source d\'infiltration d\'air.',
    fullExplanation: 'ra-e7-1',
    simpleExplanation: 'rs-e7-1',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'e7-2',
    category: 'e',
    subject: 'e7',
    label: 'Les traversées de parois par des canalisations sont des sources importantes d\'infiltration d\'air.',
    fullExplanation: 'ra-e7-1',
    simpleExplanation: 'rs-e7-1',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'e7-3',
    category: 'e',
    subject: 'e7',
    label: 'La pose des nouveaux appareillages électriques doit faire l’objet d’une attention particulière pour limiter les sources d\'infiltration d\'air.',
    fullExplanation: 'ra-e7-1',
    simpleExplanation: 'rs-e7-1',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'e7-4',
    category: 'e',
    subject: 'e7',
    label: 'Quel nom donne-t-on couramment au test d\'étanchéité\u00A0?',
    fullExplanation: 'ra-e7-2',
    simpleExplanation: 'rs-e7-2',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Le test du scaphandre'
      },
      {
        name: 'b',
        label: 'Le test de la porte soufflante'
      },
      {
        name: 'c',
        label: 'Le test de compression'
      }
    ]
  },
  {
    id: 'e7-5',
    category: 'e',
    subject: 'e7',
    label: 'Un test d\'étanchéité est aussi appelé test de la porte soufflante\u00A0?',
    fullExplanation: 'ra-e7-2',
    simpleExplanation: 'rs-e7-2',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'e7-6',
    category: 'e',
    subject: 'e7',
    label: 'Lorsque l’on fait le test de la porte soufflante, que mesure-t-on\u00A0?',
    fullExplanation: 'ra-e7-2',
    simpleExplanation: 'rs-e7-2',
    correctAnswers: ['a', 'c'],
    choices: [
      {
        name: 'a',
        label: 'Les fuites d’air parasites du bâtiment'
      },
      {
        name: 'b',
        label: 'La résistance au vent des menuiseries'
      },
      {
        name: 'c',
        label: 'L’étanchéité du bâtiment'
      }
    ]
  },
  {
    id: 'e7-7',
    category: 'e',
    subject: 'e7',
    label: 'Pour mesurer l’étanchéité à l’air d’une maison, je fais\u00A0:',
    fullExplanation: 'ra-e7-2',
    simpleExplanation: 'rs-e7-2',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Le test du fumigène'
      },
      {
        name: 'b',
        label: 'Le test de la porte soufflante'
      },
      {
        name: 'c',
        label: 'Le test des joints'
      }
    ]
  },
  {
    id: 'e7-8',
    category: 'e',
    subject: 'e7',
    label: 'Quelle pratique est proscrite lors de la pose d\'un doublage collé\u00A0?',
    fullExplanation: 'ra-e7-3',
    simpleExplanation: 'rs-e7-3',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'La création de passages de gaines entre le doublage et la plaque'
      },
      {
        name: 'b',
        label: 'Le rainurage coté froid pour le passage des gaines'
      },
      {
        name: 'c',
        label: 'Le passage des gaines par encastrement dans le support'
      }
    ]
  },
  {
    id: 'e7-9',
    category: 'e',
    subject: 'e7',
    label: 'Le rainurage coté froid pour le passage des gaines lors de la pose d\'un doublage collé est-il autorisé\u00A0?',
    fullExplanation: 'ra-e7-3',
    simpleExplanation: 'rs-e7-3',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Oui'
      },
      {
        name: 'b',
        label: 'Non'
      }
    ]
  },
  {
    id: 'e7-10',
    category: 'e',
    subject: 'e7',
    label: 'Quelles sont les bonnes pratiques de mise en œuvre des gaines, lors de la pose d\'un doublage collé\u00A0?',
    fullExplanation: 'ra-e7-3',
    simpleExplanation: 'rs-e7-3',
    correctAnswers: ['a', 'c', 'd'],
    choices: [
      {
        name: 'a',
        label: 'Créer des passages entre le doublage et la plaque'
      },
      {
        name: 'b',
        label: 'Rainurer l’isolant coté froid'
      },
      {
        name: 'c',
        label: 'Encastrer les gaines dans le support'
      },
      {
        name: 'd',
        label: 'Poser en apparent sous goulotte'
      }
    ]
  },
  {
    id: 'e7-11',
    category: 'e',
    subject: 'e7',
    label: 'Le passage des gaines par encastrement dans le support est à proscrite lors de la pose d\'un doublage collé\u00A0?',
    fullExplanation: 'ra-e7-3',
    simpleExplanation: 'rs-e7-3',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'e8-1',
    category: 'e',
    subject: 'e8',
    label: 'Il est conseillé de limiter au maximum la distance entre le ballon d’eau chaude sanitaire et les points de puisage pour limiter les consommations d’énergie.',
    fullExplanation: 'ra-e8-1',
    simpleExplanation: 'rs-e8-1',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'e8-2',
    category: 'e',
    subject: 'e8',
    label: 'Pour réduire la consommation d\'énergie liées à l\'eau chaude sanitaire, il est conseillé\u00A0:',
    fullExplanation: 'ra-e8-1',
    simpleExplanation: 'rs-e8-1',
    correctAnswers: ['b', 'd'],
    choices: [
      {
        name: 'a',
        label: 'D’installer le ballon de production d’eau chaude dans le garage'
      },
      {
        name: 'b',
        label: 'D’installer le ballon au plus près des points de puisages'
      },
      {
        name: 'c',
        label: 'De faire passer les canalisations d’eau chaude dans les combles perdus '
      },
      {
        name: 'd',
        label: 'D’isoler les canalisations d’eau chaude'
      }
    ]
  },
  {
    id: 'e8-3',
    category: 'e',
    subject: 'e8',
    label: 'Installer le ballon d’eau chaude au plus près des points de puisages permet\u00A0:',
    fullExplanation: 'ra-e8-1',
    simpleExplanation: 'rs-e8-1',
    correctAnswers: ['a', 'b', 'c'],
    choices: [
      {
        name: 'a',
        label: 'De faire des économies d’eau'
      },
      {
        name: 'b',
        label: 'De faire des économies d’énergie '
      },
      {
        name: 'c',
        label: 'D’améliorer le confort des utilisateurs'
      }
    ]
  },
  {
    id: 'e8-4',
    category: 'e',
    subject: 'e8',
    label: 'Pour réduire les consommations d’énergie, il est conseiller de réduire la distance entre\u00A0:',
    fullExplanation: 'ra-e8-2',
    simpleExplanation: 'rs-e8-1',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'La génération de chauffage et le tableau électrique'
      },
      {
        name: 'b',
        label: 'Le ballon et les points de puisage d\'eau chaude sanitaire'
      },
      {
        name: 'c',
        label: 'Le ballon et l\'alimentation générale en eau'
      }
    ]
  },
  {
    id: 'e8-5',
    category: 'e',
    subject: 'e8',
    label: 'Réduire la distance entre le ballon et les points de puisage d\'eau chaude sanitaire permet de limiter les consommations d’énergie\u00A0:',
    fullExplanation: 'ra-e8-2',
    simpleExplanation: 'rs-e8-1',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'e8-6',
    category: 'e',
    subject: 'e8',
    label: 'Il n’est pas utile, pour réduire les consommations d’énergie, de réduire la distance entre le ballon et les points de puisage d\'eau chaude sanitaire\u00A0:',
    fullExplanation: 'ra-e8-2',
    simpleExplanation: 'rs-e8-1',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'e8-7',
    category: 'e',
    subject: 'e8',
    label: 'Lorsque je conçois une installation d’eau chaude sanitaire, je dois faire en sorte de maximiser les distances entre le ballon de stockage et les points de puisage pour réduire les consommations d’énergie\u00A0:',
    fullExplanation: 'ra-e8-2',
    simpleExplanation: 'rs-e8-1',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'e8-8',
    category: 'e',
    subject: 'e8',
    label: 'Quelle unité caractérise la puissance d\'une chaudière\u00A0?',
    fullExplanation: 'ra-e8-3',
    simpleExplanation: 'rs-e8-3',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'kW'
      },
      {
        name: 'b',
        label: 'kWh'
      },
      {
        name: 'c',
        label: '°C'
      }
    ]
  },
  {
    id: 'e8-9',
    category: 'e',
    subject: 'e8',
    label: 'La puissance d\'une chaudière est caractérisée en kJ\u00A0:',
    fullExplanation: 'ra-e8-3',
    simpleExplanation: 'rs-e8-3',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'e8-10',
    category: 'e',
    subject: 'e8',
    label: 'Le kWh est l’unité utilisée pour caractériser la puissance d\'une chaudière\u00A0:',
    fullExplanation: 'ra-e8-3',
    simpleExplanation: 'rs-e8-3',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'e8-11',
    category: 'e',
    subject: 'e8',
    label: 'Mon installateur à fait le calcul des déperditions de mon logement pour pouvoir calculer la puissance de la chaudière à installer. Il me dit qu’il faut une chaudière de 23\u00A0:',
    fullExplanation: 'ra-e8-3',
    simpleExplanation: 'rs-e8-3',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'kWh'
      },
      {
        name: 'b',
        label: 'kJ'
      },
      {
        name: 'c',
        label: 'kW'
      }
    ]
  },
  {
    id: 'e8-12',
    category: 'e',
    subject: 'e8',
    label: 'Une chaudière à condensation très performante a un rendement sur PCI\u00A0:',
    fullExplanation: 'ra-e8-4',
    simpleExplanation: 'rs-e8-4',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'Inférieur ou égal à 75%'
      },
      {
        name: 'b',
        label: 'Inférieur ou égal à 90% '
      },
      {
        name: 'c',
        label: 'Supérieur à 100 %'
      }
    ]
  },
  {
    id: 'e8-13',
    category: 'e',
    subject: 'e8',
    label: 'Une chaudière à condensation peut-elle avoir un rendement sur PCS supérieur à 100 %\u00A0?',
    fullExplanation: 'ra-e8-4',
    simpleExplanation: 'rs-e8-4',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Oui'
      },
      {
        name: 'b',
        label: 'Non'
      }
    ]
  },
  {
    id: 'e8-14',
    category: 'e',
    subject: 'e8',
    label: 'Une chaudière à condensation performante a un rendement sur PCI supérieur à 100 %\u00A0:',
    fullExplanation: 'ra-e8-4',
    simpleExplanation: 'rs-e8-4',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'e8-15',
    category: 'e',
    subject: 'e8',
    label: 'Une chaudière à condensation performante a un rendement\u00A0:',
    fullExplanation: 'ra-e8-4',
    simpleExplanation: 'rs-e8-4',
    correctAnswers: ['a', 'd'],
    choices: [
      {
        name: 'a',
        label: 'Supérieur à 100 % sur PCI'
      },
      {
        name: 'b',
        label: 'Supérieur à 100 % sur PCS'
      },
      {
        name: 'c',
        label: 'Inférieur à 100 % sur PCI'
      },
      {
        name: 'd',
        label: 'Inférieur à 100 % sur PCS'
      }
    ]
  },
  {
    id: 'e8-16',
    category: 'e',
    subject: 'e8',
    label: 'Pour réduire les consommations d\'énergie, il vaut mieux installer le ballon d\'eau chaude\u00A0:',
    fullExplanation: 'ra-e8-5',
    simpleExplanation: 'rs-e8-5',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Hors volume chauffé'
      },
      {
        name: 'b',
        label: 'En volume chauffé'
      },
      {
        name: 'c',
        label: 'Peu importe'
      }
    ]
  },
  {
    id: 'e8-17',
    category: 'e',
    subject: 'e8',
    label: 'Il est plus pertinent d’installer le ballon d\'eau chaude en volume chauffé pour réduire les consommations d\'énergie\u00A0:',
    fullExplanation: 'ra-e8-5',
    simpleExplanation: 'rs-e8-5',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      },
      {
        name: 'c',
        label: 'C’est sans impact'
      }
    ]
  },
  {
    id: 'e8-18',
    category: 'e',
    subject: 'e8',
    label: 'Si je souhaite réduire les consommations d\'énergie il est plus pertinent d’installer le ballon d\'eau chaude\u00A0:',
    fullExplanation: 'ra-e8-5',
    simpleExplanation: 'rs-e8-5',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Dans le garage'
      },
      {
        name: 'b',
        label: 'Dans la salle de bain'
      },
      {
        name: 'c',
        label: 'Dans le grenier'
      }
    ]
  },
  {
    id: 'e8-19',
    category: 'e',
    subject: 'e8',
    label: 'Mon installateur me dit qu’il vaut mieux installer le ballon d\'eau chaude dans la salle de bain plutôt que dans le garage pour réduire les consommations d\'énergie\u00A0:',
    fullExplanation: 'ra-e8-5',
    simpleExplanation: 'rs-e8-5',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Il a raison'
      },
      {
        name: 'b',
        label: 'Il a tort'
      },
      {
        name: 'c',
        label: 'Ça ne changera rien'
      }
    ]
  },
  {
    id: 'e8-20',
    category: 'e',
    subject: 'e8',
    label: 'Ou doit être placée la sonde extérieure qui permet de réguler une installation de chauffage \u00A0?',
    fullExplanation: 'ra-e8-6',
    simpleExplanation: 'rs-e8-6',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'En façade Sud'
      },
      {
        name: 'b',
        label: 'En façade Nord'
      },
      {
        name: 'c',
        label: 'En façade Ouest'
      }
    ]
  },
  {
    id: 'e8-21',
    category: 'e',
    subject: 'e8',
    label: 'Pour qu’une installation de chauffage fonctionne bien, la sonde extérieure de régulation doit être placée\u00A0:',
    fullExplanation: 'ra-e8-6',
    simpleExplanation: 'rs-e8-6',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'En façade Nord'
      },
      {
        name: 'b',
        label: 'En façade Sud'
      },
      {
        name: 'c',
        label: 'En façade Est'
      },
      {
        name: 'd',
        label: 'En façade Ouest'
      }
    ]
  },
  {
    id: 'e8-22',
    category: 'e',
    subject: 'e8',
    label: 'Pour qu’une installation de chauffage fonctionne bien, il faut placer la sonde extérieure de régulation en façade Est\u00A0:',
    fullExplanation: 'ra-e8-6',
    simpleExplanation: 'rs-e8-6',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'e8-23',
    category: 'e',
    subject: 'e8',
    label: 'Quelle est la meilleure orientation pour placée la sonde extérieure qui permet de réguler une installation de chauffage\u00A0?',
    fullExplanation: 'ra-e8-6',
    simpleExplanation: 'rs-e8-6',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Nord'
      },
      {
        name: 'b',
        label: 'Sud'
      },
      {
        name: 'c',
        label: 'Est'
      },
      {
        name: 'd',
        label: 'Ouest'
      }
    ]
  },
  {
    id: 'e8-24',
    category: 'e',
    subject: 'e8',
    label: 'L\'installation d\'une chaudière gaz à condensation nécessite\u00A0:',
    fullExplanation: 'ra-e8-7',
    simpleExplanation: 'rs-e8-7',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Un système d\'évacuation des condensats'
      },
      {
        name: 'b',
        label: 'Des radiateurs haute température'
      },
      {
        name: 'c',
        label: 'Un local ventilé'
      }
    ]
  },
  {
    id: 'e8-25',
    category: 'e',
    subject: 'e8',
    label: 'Faut-il prévoir un système d\'évacuation des condensats lors de l’installation d\'une chaudière gaz à condensation\u00A0?',
    fullExplanation: 'ra-e8-7',
    simpleExplanation: 'rs-e8-7',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Oui'
      },
      {
        name: 'b',
        label: 'Non'
      }
    ]
  },
  {
    id: 'e8-26',
    category: 'e',
    subject: 'e8',
    label: 'Lors de l\'installation d\'une chaudière gaz à condensation, il faut nécessairement prévoir\u00A0:',
    fullExplanation: 'ra-e8-7',
    simpleExplanation: 'rs-e8-7',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Des robinets thermostatiques sur les émetteurs'
      },
      {
        name: 'b',
        label: 'Un système d\'évacuation des condensats'
      },
      {
        name: 'c',
        label: 'Un système de régulation adapté'
      }
    ]
  },
  {
    id: 'e8-27',
    category: 'e',
    subject: 'e8',
    label: 'Lorsque j’installe une chaudière gaz à condensation, je dois prévoir un système d\'évacuation des condensats\u00A0:',
    fullExplanation: 'ra-e8-7',
    simpleExplanation: 'rs-e8-7',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  }
]

export default questions
