var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "level-step" },
    [
      _c("step-question", {
        attrs: {
          "current-step-index": _vm.currentStepIndex,
          "nb-steps": _vm.nbSteps,
          title:
            "Comment estimez-vous votre niveau de connaissance de l’efficacité énergétique&nbsp;?",
        },
        scopedSlots: _vm._u([
          {
            key: "question",
            fn: function () {
              return [
                _c("radio-buttons", {
                  attrs: {
                    name: "reason",
                    value: _vm.model,
                    choices: _vm.choices,
                  },
                  on: { input: _vm.emitModel },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }