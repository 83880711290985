import User from '@/services/User'
import * as Sentry from '@sentry/vue'

const DEMO_USER = {
  name: 'Florent',
  mature: true,
  status: 'ready',
  type: 'customer'
}

const userStore = {
  namespaced: true,
  state: {
    userName: '',
    userMature: false,
    userStatus: '',
    userType: null,
    apiToken: '',
    isFirstLoginFormComplete: null
  },
  getters: {
    localStorageApiToken () {
      if (localStorage.getItem('token') !== null &&
        localStorage.getItem('token') !== 'undefined' &&
        localStorage.getItem('token') !== '') {
        return localStorage.getItem('token')
      } else {
        return false
      }
    },
    isFirstLogin (state) {
      return state.isFirstLoginFormComplete === false
    }
  },
  mutations: {
    setUserInfo (state, userInfo) {
      if (userInfo.name) {
        state.userName = userInfo.name
      }
      if (userInfo.mature) {
        state.userMature = userInfo.mature
      }
      if (userInfo.status) {
        state.userStatus = userInfo.status
      }
      if (userInfo.type) {
        state.userType = userInfo.type
      }
      state.isFirstLoginFormComplete = userInfo.isFirstLoginFormComplete
    },
    setApiToken (state, apiToken) {
      state.apiToken = apiToken
      localStorage.setItem('token', apiToken)
      User.saveAxiosToken(apiToken)
    },
    deleteApiToken (state) {
      state.apiToken = ''
      localStorage.removeItem('token')
      User.saveAxiosToken('')
    }
  },
  actions: {
    loginRedirection ({ rootState, commit }) {
      commit('deleteApiToken')
      window.location.href = rootState.redirectSSOBaseUrl + rootState.enseigne
    },
    async initDemoMode ({ commit, dispatch }) {
      User.saveDemoToken(process.env.VUE_APP_API_DEMO_TOKEN)
      commit('setDemoMode', true, { root: true })
      await dispatch('getDemoRegions', null, { root: true })
    },
    async loginUserFromUrlParams ({ commit, dispatch }, loginInfos) {
      try {
        const response = await User.getUserApiToken(loginInfos)
        if (response.data.token && Object.keys(response.data.token).length > 0) {
          commit('setApiToken', response.data.token)
        }
      } catch (e) {
        commit('setLoadingErrorMessage', 'Une erreur est survenue lors de la connexion', { root: true })
        Sentry.captureException(e)
        console.error(e)
      }
    },
    loginUserFromLocalStorage ({ commit, getters }) {
      commit('setApiToken', getters.localStorageApiToken)
    },
    async getUserData ({ rootState, state, getters, commit, dispatch }) {
      if (rootState.demoMode) {
        commit('setUserInfo', DEMO_USER)

        if (rootState.enseigne === 'sfic' || rootState.enseigne === 'cedeo') {
          commit('setClub', true, { root: true })
        }

        dispatch('test/getTestDatas', null, { root: true })
      } else if (getters.localStorageApiToken) {
        try {
          const response = await User.getUserDatas()
          if (Object.keys(response.data).length > 0) {
            commit('setUserInfo', {
              name: response.data.first_name,
              mature: response.data.mature,
              status: response.data.status,
              type: response.data.user_type,
              isFirstLoginFormComplete: response.data.is_first_login_form_complete
            })

            if (response.data.is_member) {
              commit('setClub', true, { root: true })
            }
            // prevent cedeo members to access without CEDEO BOOST membership
            if (rootState.enseigne === 'cedeo' && state.userType === 'customer' && !rootState.club) {
              commit(
                'setLoadingErrorMessage',
                'Objectif RGE est une application réservée aux clients membres du programme CEDEO BOOST. <br> Si vous souhaitez devenir membre CEDEO BOOST, merci de contacter la Boost Line au 09 72 72 62 60.',
                { root: true }
              )
            }
          } else {
            commit('setLoadingErrorMessage', 'Les données utilisateurs ne sont pas correctes', { root: true })
          }
        } catch (e) {
          commit('setLoadingErrorMessage', 'Une erreur est survenue lors de la récupération de votre compte.', { root: true })
          Sentry.captureException(e)
          console.error(e)
        }
      } else {
        dispatch('loginRedirection', null)
      }
    }
  }
}

export default userStore
