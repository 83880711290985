<template>
  <div class="reason-step">
    <div class="background-head head">
      <div class="logo-wrapper">
        <img :src="orgeLogo" alt="logo Objectif RGE">
      </div>
      <div class="content">
        <p class="title">Bienvenue {{ userName }} !</p>
        <p class="intro">
          Avant de commencer votre entraînement, <b>veuillez répondre aux questions suivantes afin de nous permettre de vous accompagner au mieux</b> dans votre parcours de qualification.
        </p>
      </div>
      <div class="bottom-arrow" @click="scrollToQuestion">
        <img src="../../assets/pictos/bottom-arrow.svg">
      </div>
    </div>
    <step-question :current-step-index="currentStepIndex"
      :nb-steps="nbSteps"
      title="Pour quelle raison voulez-vous utiliser OBJECTIF RGE&nbsp;?"
      ref="question">
      <template v-slot:question>
        <radio-buttons name="reason" :value="model" @input="emitModel" :choices="choices"></radio-buttons>
      </template>
    </step-question>
  </div>
</template>

<script>
import firstLoginStepMixin from '@/views/first-login-form/firstLoginStepMixin'
import StepQuestion from '@/components/StepQuestion'
import RadioButtons from '@/components/RadioButtons'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'ReasonStep',
  components: { RadioButtons, StepQuestion },
  mixins: [firstLoginStepMixin],
  data () {
    return {
      choices: [
        {
          name: 'parallele_formation',
          label: 'M’entraîner en parallèle d’une formation RGE'
        },
        {
          name: 'passer_examen',
          label: 'M’entraîner en vue de passer l’examen RGE en candidat libre'
        },
        {
          name: 'ameliorer_connaissance',
          label: 'Tester et améliorer mes connaissances de l’efficacité énergétique'
        }
      ]
    }
  },
  computed: {
    ...mapState('user', ['userName']),
    ...mapGetters(['isEnseignePP']),
    orgeLogo () {
      return this.isEnseignePP
        ? require('../../assets/logos/obj-rge-pp.png')
        : require('../../assets/logos/obj-rge-vertical.svg')
    }
  }
}
</script>

<style lang="scss" scoped>
.reason-step {
  .head {
    margin-bottom: 30px;
    .logo-wrapper {
      margin: 25px;
      background-color: #fff;
      border-radius: 60%;
      display: inline-block;
      padding: 45px 30px 35px 30px;
      img {
        max-width: 180px;
      }
    }
    .content {
      padding: 25px;
      margin-top: 10px;
      .title {
        font-size: 1.1rem;
        font-weight: 900;
      }
      .intro {
        max-width: 600px;
        font-size: 0.9rem;
        margin: 5px auto;
      }
    }
  }
}
</style>
