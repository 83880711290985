const questions = [
  {
    id: 'b1-1',
    category: 'b',
    subject: 'b1',
    label: 'Le phénomène de « paroi froide » peut être provoqué par\u00A0:',
    fullExplanation: 'ra-b1-1',
    simpleExplanation: 'rs-b1-1',
    correctAnswers: ['a', 'c'],
    choices: [
      {
        name: 'a',
        label: 'Un simple vitrage'
      },
      {
        name: 'b',
        label: 'Un courant d’air'
      },
      {
        name: 'c',
        label: 'Un mur non isolé'
      }
    ]
  },
  {
    id: 'b1-2',
    category: 'b',
    subject: 'b1',
    label: 'L’isolation d’un mur permet de réduire\u00A0:',
    fullExplanation: 'ra-b1-1',
    simpleExplanation: 'rs-b1-1',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'La sensation de « paroi froide » qu’il peut procurer'
      },
      {
        name: 'b',
        label: 'La résistance thermique de la paroi'
      },
      {
        name: 'c',
        label: 'L’étanchéité à l’air de la paroi'
      }
    ]
  },
  {
    id: 'b1-3',
    category: 'b',
    subject: 'b1',
    label: 'Pour assurer le confort thermique d’hiver d’un occupant d’un local, il faut faire en sorte que les parois du local\u00A0:',
    fullExplanation: 'ra-b1-1',
    simpleExplanation: 'rs-b1-1',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Soient froides'
      },
      {
        name: 'b',
        label: 'Soient chaudes'
      },
      {
        name: 'c',
        label: 'Peu importe la température des parois, c’est la température de l’air qui compte'
      }
    ]
  },
  {
    id: 'b1-4',
    category: 'b',
    subject: 'b1',
    label: 'L’inertie thermique d’un matériau dépend de sa densité\u00A0.',
    fullExplanation: 'ra-b1-2',
    simpleExplanation: 'rs-b1-2',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'b1-5',
    category: 'b',
    subject: 'b1',
    label: 'La densité d’un matériau a un impact sur son inertie thermique\u00A0.',
    fullExplanation: 'ra-b1-2',
    simpleExplanation: 'rs-b1-2',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'b1-6',
    category: 'b',
    subject: 'b1',
    label: 'Plus un matériau est dense, plus il a de l’inertie thermique\u00A0.',
    fullExplanation: 'ra-b1-2',
    simpleExplanation: 'rs-b1-2',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'b1-7',
    category: 'b',
    subject: 'b1',
    label: 'L’inertie thermique d’un matériau dépend de\u00A0:',
    fullExplanation: 'ra-b1-2',
    simpleExplanation: 'rs-b1-2',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Sa couleur'
      },
      {
        name: 'b',
        label: 'Sa densité'
      },
      {
        name: 'c',
        label: 'Sa porosité'
      }
    ]
  },
  {
    id: 'b1-8',
    category: 'b',
    subject: 'b1',
    label: 'Le confort thermique dans un local est obtenu\u00A0:',
    fullExplanation: 'ra-b1-3',
    simpleExplanation: 'rs-b1-3',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'Quand les émetteurs de chaleur sont à pleine puissance '
      },
      {
        name: 'b',
        label: 'Seulement par la température de l\'air'
      },
      {
        name: 'c',
        label: 'Par la température de l\'air, la température des parois, le pourcentage d\'humidité et les mouvements de l\'air ambiant'
      }
    ]
  },
  {
    id: 'b1-9',
    category: 'b',
    subject: 'b1',
    label: 'Quels paramètres ont un impact sur le confort thermique d’un occupant dans un local\u00A0?',
    fullExplanation: 'ra-b1-3',
    simpleExplanation: 'rs-b1-3',
    correctAnswers: ['a', 'b', 'c', 'd'],
    choices: [
      {
        name: 'a',
        label: 'La température de l\'air '
      },
      {
        name: 'b',
        label: 'La température des parois'
      },
      {
        name: 'c',
        label: 'Le pourcentage d\'humidité'
      },
      {
        name: 'd',
        label: 'Les mouvements de l\'air ambiant'
      }
    ]
  },
  {
    id: 'b1-10',
    category: 'b',
    subject: 'b1',
    label: 'La température des parois d’un local n’a pas d’impact sur le confort thermique ressenti de ces occupants\u00A0:',
    fullExplanation: 'ra-b1-3',
    simpleExplanation: 'rs-b1-3',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'b1-11',
    category: 'b',
    subject: 'b1',
    label: 'Les mouvements de l\'air ambiant dans un local ont un impact sur le confort thermique ressenti de ces occupants\u00A0:',
    fullExplanation: 'ra-b1-3',
    simpleExplanation: 'rs-b1-3',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'b1-12',
    category: 'b',
    subject: 'b1',
    label: 'Chaque rupture, même partielle de l’isolant, génère un pont thermique intégré\u00A0.',
    fullExplanation: 'ra-b1-4',
    simpleExplanation: 'rs-b1-4',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'b1-13',
    category: 'b',
    subject: 'b1',
    label: 'Les systèmes d’ossature support du parement intérieur et intégrant un isolant, génère des ponts thermiques intégrés\u00A0.',
    fullExplanation: 'ra-b1-4',
    simpleExplanation: 'rs-b1-4',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'b1-14',
    category: 'b',
    subject: 'b1',
    label: 'Parmi ces ouvrages, lesquels présentent des ponts thermiques intégrés\u00A0?',
    fullExplanation: 'ra-b1-4',
    simpleExplanation: 'rs-b1-4',
    correctAnswers: ['a', 'b', 'c'],
    choices: [
      {
        name: 'a',
        label: 'Les systèmes de doublages sur ossature'
      },
      {
        name: 'b',
        label: 'Les ruptures, même partielles de l’isolant'
      },
      {
        name: 'c',
        label: 'Les murs à ossature bois'
      }
    ]
  },
  {
    id: 'b1-15',
    category: 'b',
    subject: 'b1',
    label: 'Parmi ces ouvrages, lesquels ne présentent pas de ponts thermiques intégrés\u00A0?',
    fullExplanation: 'ra-b1-4',
    simpleExplanation: 'rs-b1-4',
    correctAnswers: ['a', 'b', 'd'],
    choices: [
      {
        name: 'a',
        label: 'Mur en béton banché avec un doublage isolant collé'
      },
      {
        name: 'b',
        label: 'Dalle béton avec isolation sous chape en polyuréthane'
      },
      {
        name: 'c',
        label: 'Les murs à ossature bois'
      },
      {
        name: 'd',
        label: 'Dalle béton avec isolation sous étanchéité'
      }
    ]
  },
  {
    id: 'b1-16',
    category: 'b',
    subject: 'b1',
    label: 'L\'inertie thermique d\'un bâtiment s\'accroit\u00A0:',
    fullExplanation: 'ra-b1-5',
    simpleExplanation: 'rs-b1-5',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'En fonction du système de chauffage '
      },
      {
        name: 'b',
        label: 'Avec l\'épaisseur des murs'
      },
      {
        name: 'c',
        label: 'Avec l\'épaisseur des isolants'
      }
    ]
  },
  {
    id: 'b1-17',
    category: 'b',
    subject: 'b1',
    label: 'L\'épaisseur des murs d\'un bâtiment peut avoir un impact sur son inertie thermique\u00A0:',
    fullExplanation: 'ra-b1-5',
    simpleExplanation: 'rs-b1-5',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'b1-18',
    category: 'b',
    subject: 'b1',
    label: 'L\'inertie thermique d\'un bâtiment augmente avec l’épaisseur de ses murs (en béton)\u00A0:',
    fullExplanation: 'ra-b1-5',
    simpleExplanation: 'rs-b1-5',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'b1-19',
    category: 'b',
    subject: 'b1',
    label: 'L\'inertie thermique d\'un bâtiment peut être augmentée en\u00A0:',
    fullExplanation: 'ra-b1-5',
    simpleExplanation: 'rs-b1-5',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Prévoyant des fermetures aux menuiseries'
      },
      {
        name: 'b',
        label: 'Augmentant l’épaisseur de ses murs (en béton)'
      },
      {
        name: 'c',
        label: 'Installant des radiateurs en fonte'
      }
    ]
  },
  {
    id: 'b1-20',
    category: 'b',
    subject: 'b1',
    label: 'Parmi les techniques d\'isolation suivantes, laquelle est la plus efficace pour limiter les ponts thermiques\u00A0?',
    fullExplanation: 'ra-b1-6',
    simpleExplanation: 'rs-b1-6',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'L\'isolation thermique par l\'intérieur'
      },
      {
        name: 'b',
        label: 'L\'isolation thermique par l\'extérieur'
      }
    ]
  },
  {
    id: 'b1-21',
    category: 'b',
    subject: 'b1',
    label: 'Si je souhaite limiter au mieux les ponts thermiques, je privilégierai quel type d’isolation\u00A0?',
    fullExplanation: 'ra-b1-6',
    simpleExplanation: 'rs-b1-6',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'L\'isolation thermique par l\'intérieur'
      },
      {
        name: 'b',
        label: 'L\'isolation thermique par l\'extérieur'
      }
    ]
  },
  {
    id: 'b1-22',
    category: 'b',
    subject: 'b1',
    label: 'L\'isolation thermique par l\'intérieur est la technique la plus efficace pour limiter au mieux les ponts thermiques\u00A0:',
    fullExplanation: 'ra-b1-6',
    simpleExplanation: 'rs-b1-6',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'b1-23',
    category: 'b',
    subject: 'b1',
    label: 'La technique de l\'isolation thermique par l\'intérieur permet de mieux limiter les ponts thermiques que celle de l’isolation thermique par l’extérieur\u00A0:',
    fullExplanation: 'ra-b1-6',
    simpleExplanation: 'rs-b1-6',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'b1-24',
    category: 'b',
    subject: 'b1',
    label: 'Les ponts thermiques intégrés améliorent la performance d\'une paroi\u00A0.',
    fullExplanation: 'ra-b1-7',
    simpleExplanation: 'rs-b1-7',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'b1-25',
    category: 'b',
    subject: 'b1',
    label: 'Les ponts thermiques intégrés augmentent les déperditions de la paroi\u00A0:',
    fullExplanation: 'ra-b1-7',
    simpleExplanation: 'rs-b1-7',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'b1-26',
    category: 'b',
    subject: 'b1',
    label: 'Plus une paroi présente des ponts thermiques intégrés plus elle est performante\u00A0:',
    fullExplanation: 'ra-b1-7',
    simpleExplanation: 'rs-b1-7',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'b1-27',
    category: 'b',
    subject: 'b1',
    label: 'Les ponts thermiques intégrés dans une paroi ont tendance à faire augmenter la résistance thermique de celle-ci\u00A0:',
    fullExplanation: 'ra-b1-7',
    simpleExplanation: 'rs-b1-7',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'b1-28',
    category: 'b',
    subject: 'b1',
    label: 'Qu\'est-ce qu\'un pont thermique\u00A0?',
    fullExplanation: 'ra-b1-8',
    simpleExplanation: 'rs-b1-8',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Un dispositif de production de chaleur'
      },
      {
        name: 'b',
        label: 'Un point de jonction où l\'isolation n\'est pas continue'
      },
      {
        name: 'c',
        label: 'Un courant d\'air chaud'
      }
    ]
  },
  {
    id: 'b1-29',
    category: 'b',
    subject: 'b1',
    label: 'Une jonction de parois où l\'isolation n\'est pas continue est appelée\u00A0:',
    fullExplanation: 'ra-b1-8',
    simpleExplanation: 'rs-b1-8',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'Un défaut d’isolation'
      },
      {
        name: 'b',
        label: 'Une pathologie'
      },
      {
        name: 'c',
        label: 'Un pont thermique'
      },
      {
        name: 'd',
        label: 'Un point de rosée'
      }
    ]
  },
  {
    id: 'b1-30',
    category: 'b',
    subject: 'b1',
    label: 'Une jonction de parois où l\'isolation n\'est pas continue est appelée pont thermique\u00A0:',
    fullExplanation: 'ra-b1-8',
    simpleExplanation: 'rs-b1-8',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'b1-31',
    category: 'b',
    subject: 'b1',
    label: 'Un pont thermique c’est\u00A0:',
    fullExplanation: 'ra-b1-8',
    simpleExplanation: 'rs-b1-8',
    correctAnswers: ['a', 'b'],
    choices: [
      {
        name: 'a',
        label: 'La jonction entre un mur et un plancher intermédiaire'
      },
      {
        name: 'b',
        label: 'La jonction entre un mur et un plancher bas'
      },
      {
        name: 'c',
        label: 'La jonction entre la couverture et une cheminée'
      }
    ]
  },
  {
    id: 'b1-32',
    category: 'b',
    subject: 'b1',
    label: 'Où trouve-t-on le plus souvent les ponts thermiques\u00A0?',
    fullExplanation: 'ra-b1-9',
    simpleExplanation: 'rs-b1-9',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Au niveau des menuiseries intérieures'
      },
      {
        name: 'b',
        label: 'Au niveau des nez de plancher et des murs de refend'
      },
      {
        name: 'c',
        label: 'Au niveau des émetteurs de chaleur'
      }
    ]
  },
  {
    id: 'b1-33',
    category: 'b',
    subject: 'b1',
    label: 'On trouve le plus souvent des ponts thermiques au niveau des nez de plancher et des murs de refend\u00A0:',
    fullExplanation: 'ra-b1-9',
    simpleExplanation: 'rs-b1-9',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'b1-34',
    category: 'b',
    subject: 'b1',
    label: 'Trouve-t-on des ponts thermiques au niveau des menuiseries extérieures\u00A0?',
    fullExplanation: 'ra-b1-9',
    simpleExplanation: 'rs-b1-9',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Oui'
      },
      {
        name: 'b',
        label: 'Non'
      }
    ]
  },
  {
    id: 'b1-35',
    category: 'b',
    subject: 'b1',
    label: 'Les ponts thermiques concernent les parois donnant sur l’extérieur, mais concernent-t-ils aussi les parois donnant sur local non chauffé\u00A0?',
    fullExplanation: 'ra-b1-9',
    simpleExplanation: 'rs-b1-9',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Oui'
      },
      {
        name: 'b',
        label: 'Non'
      }
    ]
  },
  {
    id: 'b2-1',
    category: 'b',
    subject: 'b2',
    label: 'Si j’augmente la consigne de température de mon logement de 2°C en hiver, je risque d’augmenter mes consommations énergétiques d’environ 14 %\u00A0:',
    fullExplanation: 'ra-b2-1',
    simpleExplanation: 'rs-b2-1',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'b2-2',
    category: 'b',
    subject: 'b2',
    label: 'Lorsque j’augmente la consigne de température de mon logement de 1°C en hiver, je risque d’augmenter mes consommations énergétiques d’environ\u00A0:',
    fullExplanation: 'ra-b2-1',
    simpleExplanation: 'rs-b2-1',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: '7%'
      },
      {
        name: 'b',
        label: '14%'
      },
      {
        name: 'c',
        label: '2%'
      }
    ]
  },
  {
    id: 'b2-3',
    category: 'b',
    subject: 'b2',
    label: 'Lorsque je diminue la consigne de climatisation de mon logement de 1°C en été, je risque d’augmenter mes consommations énergétiques d’environ\u00A0:',
    fullExplanation: 'ra-b2-1',
    simpleExplanation: 'rs-b2-1',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: '7%'
      },
      {
        name: 'b',
        label: '14%'
      },
      {
        name: 'c',
        label: '2%'
      }
    ]
  },
  {
    id: 'b2-4',
    category: 'b',
    subject: 'b2',
    label: 'L\'efficacité d’une protection solaire est meilleure, si celle-ci est installée\u00A0:',
    fullExplanation: 'ra-b2-2',
    simpleExplanation: 'rs-b2-2',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'La position n’a pas d\'influence sur l\'efficacité'
      },
      {
        name: 'b',
        label: 'Côté extérieur'
      },
      {
        name: 'c',
        label: 'Côté intérieur'
      }
    ]
  },
  {
    id: 'b2-5',
    category: 'b',
    subject: 'b2',
    label: 'Une protection solaire a une meilleure efficacité si elle est installée côté intérieur plutôt que côté extérieur\u00A0:',
    fullExplanation: 'ra-b2-2',
    simpleExplanation: 'rs-b2-2',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'b2-6',
    category: 'b',
    subject: 'b2',
    label: 'Si je recherche La meilleure efficacité pour une protection solaire, j’installe celle-ci\u00A0:',
    fullExplanation: 'ra-b2-2',
    simpleExplanation: 'rs-b2-2',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Côté extérieur'
      },
      {
        name: 'b',
        label: 'Côté intérieur '
      },
      {
        name: 'c',
        label: 'La position n’a pas d\'influence sur l\'efficacité de la protection solaire'
      }
    ]
  },
  {
    id: 'b2-7',
    category: 'b',
    subject: 'b2',
    label: 'Un store intérieur me protège mieux de la chaleur du soleil que des volets battants extérieurs\u00A0:',
    fullExplanation: 'ra-b2-2',
    simpleExplanation: 'rs-b2-2',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'b2-8',
    category: 'b',
    subject: 'b2',
    label: 'Lequel de ces équipements n\'utilise pas la convection comme principal mode de diffusion de la chaleur\u00A0?',
    fullExplanation: 'ra-b2-3',
    simpleExplanation: 'rs-b2-3',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'Chauffage par air'
      },
      {
        name: 'b',
        label: 'Convecteur électrique'
      },
      {
        name: 'c',
        label: 'Plancher Chauffant'
      }
    ]
  },
  {
    id: 'b2-9',
    category: 'b',
    subject: 'b2',
    label: 'Les émetteurs de type plancher chauffant utilisent la convection comme principal mode de diffusion de la chaleur\u00A0?',
    fullExplanation: 'ra-b2-3',
    simpleExplanation: 'rs-b2-3',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'b2-10',
    category: 'b',
    subject: 'b2',
    label: 'Les émetteurs de type ventilo convecteur utilisent la convection comme principal mode de diffusion de la chaleur\u00A0?',
    fullExplanation: 'ra-b2-3',
    simpleExplanation: 'rs-b2-3',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'b2-11',
    category: 'b',
    subject: 'b2',
    label: 'Lequel de ces équipements utilise le rayonnement comme principal mode de diffusion de la chaleur\u00A0?',
    fullExplanation: 'ra-b2-3',
    simpleExplanation: 'rs-b2-3',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Plancher rayonnant électrique'
      },
      {
        name: 'b',
        label: 'Convecteur électrique'
      },
      {
        name: 'c',
        label: 'Ventilo convecteur'
      }
    ]
  },
  {
    id: 'b2-12',
    category: 'b',
    subject: 'b2',
    label: 'Parmi ces trois propositions laquelle est la plus efficace pour améliorer le confort d\'été dans un logement \u00A0?',
    fullExplanation: 'ra-b2-4',
    simpleExplanation: 'rs-b2-4',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'L\'amélioration de l\'étanchéité à l\'air'
      },
      {
        name: 'b',
        label: 'L\'isolation par l\'intérieur'
      },
      {
        name: 'c',
        label: 'La mise en place d\'un système d\'occultation extérieur des parois vitrées'
      }
    ]
  },
  {
    id: 'b2-13',
    category: 'b',
    subject: 'b2',
    label: 'Pour améliorer le confort d\'été dans un logement, la meilleure des choses à faire c’est\u00A0:',
    fullExplanation: 'ra-b2-4',
    simpleExplanation: 'rs-b2-4',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'D’isoler fortement par l’intérieur'
      },
      {
        name: 'b',
        label: 'D’installer des occultations extérieures sur les parois vitrées'
      },
      {
        name: 'c',
        label: 'D’installer des grilles de ventilation dans les murs'
      }
    ]
  },
  {
    id: 'b2-14',
    category: 'b',
    subject: 'b2',
    label: 'L’action la plus efficace pour améliorer le confort d\'été dans un logement est l’installation d’occultations extérieures sur les parois vitrées\u00A0:',
    fullExplanation: 'ra-b2-4',
    simpleExplanation: 'rs-b2-4',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'b2-15',
    category: 'b',
    subject: 'b2',
    label: 'L’action la plus efficace pour améliorer le confort d\'été dans un logement est d’améliorer son étanchéité à l’air\u00A0:',
    fullExplanation: 'ra-b2-4',
    simpleExplanation: 'rs-b2-4',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'b2-16',
    category: 'b',
    subject: 'b2',
    label: 'Le facteur solaire c’est\u00A0:',
    fullExplanation: 'ra-b2-5',
    simpleExplanation: 'rs-b2-5',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'La variation de l\'énergie solaire selon la région'
      },
      {
        name: 'b',
        label: 'La variation de l\'énergie solaire selon la saison'
      },
      {
        name: 'c',
        label: 'Le rapport entre la quantité d\'énergie entrant dans un local et l\'énergie solaire incidente sur la paroi'
      }
    ]
  },
  {
    id: 'b2-17',
    category: 'b',
    subject: 'b2',
    label: 'Le rapport entre la quantité d\'énergie entrant dans un local et l\'énergie solaire incidente sur la paroi est appelé le facteur solaire\u00A0:',
    fullExplanation: 'ra-b2-5',
    simpleExplanation: 'rs-b2-5',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'b2-18',
    category: 'b',
    subject: 'b2',
    label: 'Le facteur solaire concerne généralement\u00A0:',
    fullExplanation: 'ra-b2-5',
    simpleExplanation: 'rs-b2-5',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'Les chauffe-eaux solaires'
      },
      {
        name: 'b',
        label: 'Les panneaux photovoltaïques'
      },
      {
        name: 'c',
        label: 'Les menuiseries'
      }
    ]
  },
  {
    id: 'b2-19',
    category: 'b',
    subject: 'b2',
    label: 'Le facteur solaire représente\u00A0:',
    fullExplanation: 'ra-b2-5',
    simpleExplanation: 'rs-b2-5',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'La fraction d’énergie solaire produite par le bâtiment'
      },
      {
        name: 'b',
        label: 'Le rapport entre la quantité d\'énergie traversant une menuiserie et l\'énergie solaire incidente sur la menuiserie'
      },
      {
        name: 'c',
        label: 'La fraction du chauffage assurée par l’énergie solaire'
      }
    ]
  },
  {
    id: 'b3-1',
    category: 'b',
    subject: 'b3',
    label: 'La chaleur dégagée par les équipements électroménagers est appelée\u00A0:',
    fullExplanation: 'ra-b3-1',
    simpleExplanation: 'rs-b3-1',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Chauffage d’appoint'
      },
      {
        name: 'b',
        label: 'Apport gratuit'
      },
      {
        name: 'c',
        label: 'Chaleur fatale'
      }
    ]
  },
  {
    id: 'b3-2',
    category: 'b',
    subject: 'b3',
    label: ' Les apports gratuits sont toujours des apports externes (soleil)\u00A0:',
    fullExplanation: 'ra-b3-1',
    simpleExplanation: 'rs-b3-1',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'b3-3',
    category: 'b',
    subject: 'b3',
    label: 'Les apports gratuits sont toujours des apports internes (électroménager)\u00A0:',
    fullExplanation: 'ra-b3-1',
    simpleExplanation: 'rs-b3-1',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'b3-4',
    category: 'b',
    subject: 'b3',
    label: 'Plus le lambda (λ) est faible plus le matériau est isolant\u00A0.',
    fullExplanation: 'ra-b3-2',
    simpleExplanation: 'rs-b3-2',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Faux'
      },
      {
        name: 'b',
        label: 'Vrai'
      }
    ]
  },
  {
    id: 'b3-5',
    category: 'b',
    subject: 'b3',
    label: 'Le lambda (λ) d’un matériau traduit sa capacité à conduire la chaleur\u00A0:',
    fullExplanation: 'ra-b3-2',
    simpleExplanation: 'rs-b3-2',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Faux'
      },
      {
        name: 'b',
        label: 'Vrai'
      }
    ]
  },
  {
    id: 'b3-6',
    category: 'b',
    subject: 'b3',
    label: 'La capacité d’un matériau à conduire la chaleur est symbolisée par\u00A0:',
    fullExplanation: 'ra-b3-2',
    simpleExplanation: 'rs-b3-2',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'R'
      },
      {
        name: 'b',
        label: 'λ '
      },
      {
        name: 'c',
        label: 'U'
      }
    ]
  },
  {
    id: 'b3-7',
    category: 'b',
    subject: 'b3',
    label: 'Pour une même épaisseur, plus le lambda (λ) d’un matériau est faible plus la résistance thermique de ce matériau est forte\u00A0:',
    fullExplanation: 'ra-b3-2',
    simpleExplanation: 'rs-b3-2',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Faux'
      },
      {
        name: 'b',
        label: 'Vrai'
      }
    ]
  },
  {
    id: 'b3-8',
    category: 'b',
    subject: 'b3',
    label: 'Quel est l\'ordre de grandeur du lambda (λ) des laines minérales\u00A0?',
    fullExplanation: 'ra-b3-3',
    simpleExplanation: 'rs-b3-3',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Entre 0,03 et 0,04 W/m.K '
      },
      {
        name: 'b',
        label: 'Inférieur à 0,025 W/m.K '
      },
      {
        name: 'c',
        label: 'Entre 0,04 et 0,05 W/m.K'
      }
    ]
  },
  {
    id: 'b3-9',
    category: 'b',
    subject: 'b3',
    label: 'Une laine minérale courante présente un lambda (λ) compris entre 0,03 et 0,04\u00A0:',
    fullExplanation: 'ra-b3-3',
    simpleExplanation: 'rs-b3-3',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Faux'
      },
      {
        name: 'b',
        label: 'Vrai'
      }
    ]
  },
  {
    id: 'b3-10',
    category: 'b',
    subject: 'b3',
    label: 'Un lambda (λ) compris entre 0,03 et 0,04 correspond à un matériau de type\u00A0:',
    fullExplanation: 'ra-b3-3',
    simpleExplanation: 'rs-b3-3',
    correctAnswers: ['a', 'd'],
    choices: [
      {
        name: 'a',
        label: 'Laine de verre'
      },
      {
        name: 'b',
        label: 'Bois'
      },
      {
        name: 'c',
        label: 'Verre'
      },
      {
        name: 'd',
        label: 'Laine de roche'
      }
    ]
  },
  {
    id: 'b3-11',
    category: 'b',
    subject: 'b3',
    label: 'Une laine minérale courante présente un lambda (λ) compris entre 0,1 et 0,2\u00A0:',
    fullExplanation: 'ra-b3-3',
    simpleExplanation: 'rs-b3-3',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Faux'
      },
      {
        name: 'b',
        label: 'Vrai'
      }
    ]
  },
  {
    id: 'b3-12',
    category: 'b',
    subject: 'b3',
    label: 'Les déperditions totales d\'un bâtiment sont la somme des déperditions des parois et\u00A0:',
    fullExplanation: 'ra-b3-4',
    simpleExplanation: 'rs-b3-4',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Des consommations d\'eau chaude sanitaire'
      },
      {
        name: 'b',
        label: 'Des déperditions par renouvellement d\'air'
      },
      {
        name: 'c',
        label: 'Des consommations de chauffage'
      }
    ]
  },
  {
    id: 'b3-13',
    category: 'b',
    subject: 'b3',
    label: 'Les déperditions totales d\'un bâtiment sont la somme des déperditions des parois et des déperditions par renouvellement d\'air\u00A0:',
    fullExplanation: 'ra-b3-4',
    simpleExplanation: 'rs-b3-4',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Faux'
      },
      {
        name: 'b',
        label: 'Vrai'
      }
    ]
  },
  {
    id: 'b3-14',
    category: 'b',
    subject: 'b3',
    label: 'La somme des déperditions des parois et des déperditions par renouvellement d\'air représente\u00A0:',
    fullExplanation: 'ra-b3-4',
    simpleExplanation: 'rs-b3-4',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'Les déperditions partielles d\'un bâtiment'
      },
      {
        name: 'b',
        label: 'Les déperditions moyennes d\'un bâtiment'
      },
      {
        name: 'c',
        label: 'Les déperditions totales d\'un bâtiment'
      }
    ]
  },
  {
    id: 'b3-15',
    category: 'b',
    subject: 'b3',
    label: 'A quoi s’ajoute les déperditions par renouvellement d\'air pour obtenir les déperditions totales d’un bâtiment\u00A0:',
    fullExplanation: 'ra-b3-4',
    simpleExplanation: 'rs-b3-4',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'Les déperditions liées à la ventilation'
      },
      {
        name: 'b',
        label: 'Les déperditions par défaut d’étanchéité à l’air'
      },
      {
        name: 'c',
        label: 'Les déperditions des parois'
      }
    ]
  },
  {
    id: 'b3-16',
    category: 'b',
    subject: 'b3',
    label: 'Le coefficient lambda (λ) caractérise\u00A0:',
    fullExplanation: 'ra-b3-5',
    simpleExplanation: 'rs-b3-2',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'La conductivité thermique du matériau'
      },
      {
        name: 'b',
        label: 'La température du matériau'
      },
      {
        name: 'c',
        label: 'L\'inertie du matériau'
      }
    ]
  },
  {
    id: 'b3-17',
    category: 'b',
    subject: 'b3',
    label: 'Plus le coefficient lambda (λ) d’un matériau est grand\u00A0:',
    fullExplanation: 'ra-b3-5',
    simpleExplanation: 'rs-b3-2',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Plus il conduit la chaleur'
      },
      {
        name: 'b',
        label: 'Moins il conduit la chaleur'
      },
      {
        name: 'c',
        label: 'Plus il possède de l’inertie'
      },
      {
        name: 'd',
        label: 'Moins il possède de l’inertie'
      }
    ]
  },
  {
    id: 'b3-18',
    category: 'b',
    subject: 'b3',
    label: 'La conductivité thermique du matériau se caractérise par le coefficient lambda (λ)\u00A0:',
    fullExplanation: 'ra-b3-5',
    simpleExplanation: 'rs-b3-2',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Faux'
      },
      {
        name: 'b',
        label: 'Vrai'
      }
    ]
  },
  {
    id: 'b3-19',
    category: 'b',
    subject: 'b3',
    label: 'La conductivité thermique d’un matériau se caractérise par le coefficient\u00A0:',
    fullExplanation: 'ra-b3-5',
    simpleExplanation: 'rs-b3-2',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'R'
      },
      {
        name: 'b',
        label: 'λ'
      },
      {
        name: 'c',
        label: 'U'
      }
    ]
  },
  {
    id: 'b4-1',
    category: 'b',
    subject: 'b4',
    label: 'On utilise le coefficient U pour caractériser\u00A0:',
    fullExplanation: 'ra-b4-1',
    simpleExplanation: 'rs-b4-1',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'La résistance thermique d’une paroi'
      },
      {
        name: 'b',
        label: 'La déperdition surfacique d\'une paroi'
      },
      {
        name: 'c',
        label: 'La conductivité thermique d\'une paroi'
      }
    ]
  },
  {
    id: 'b4-2',
    category: 'b',
    subject: 'b4',
    label: 'On utilise le coefficient U pour caractériser les déperditions surfaciques à travers une paroi\u00A0:',
    fullExplanation: 'ra-b4-1',
    simpleExplanation: 'rs-b4-1',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'b4-3',
    category: 'b',
    subject: 'b4',
    label: 'La coefficient de déperdition surfacique d\'une paroi est\u00A0:',
    fullExplanation: 'ra-b4-1',
    simpleExplanation: 'rs-b4-1',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Proportionnel à la résistance thermique total de la paroi'
      },
      {
        name: 'b',
        label: 'Inversement proportionnel à la résistance thermique total de la paroi '
      },
      {
        name: 'c',
        label: 'Inversement proportionnel à la surface de la paroi'
      }
    ]
  },
  {
    id: 'b4-4',
    category: 'b',
    subject: 'b4',
    label: 'Les déperditions par renouvellement d\'air sont liées aux débits du système de ventilation et\u00A0:',
    fullExplanation: 'ra-b4-2',
    simpleExplanation: 'rs-b4-2',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Aux déperditions de l\'enveloppe'
      },
      {
        name: 'b',
        label: 'Aux infiltrations d\'air à travers l\'enveloppe'
      },
      {
        name: 'c',
        label: 'A l\'exposition aux vents'
      }
    ]
  },
  {
    id: 'b4-5',
    category: 'b',
    subject: 'b4',
    label: 'Les déperditions par renouvellement d\'air sont liées à la somme des débits du système de ventilation et des débits des infiltrations d\'air à travers l\'enveloppe\u00A0:',
    fullExplanation: 'ra-b4-2',
    simpleExplanation: 'rs-b4-2',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'b4-6',
    category: 'b',
    subject: 'b4',
    label: 'Les déperditions par renouvellement d\'air dépendent\u00A0:',
    fullExplanation: 'ra-b4-2',
    simpleExplanation: 'rs-b4-2',
    correctAnswers: ['b', 'c'],
    choices: [
      {
        name: 'a',
        label: 'Des déperditions à travers l\'enveloppe'
      },
      {
        name: 'b',
        label: 'Des infiltrations d\'air à travers l\'enveloppe'
      },
      {
        name: 'c',
        label: 'Du système de ventilation'
      },
      {
        name: 'd',
        label: 'Du système de chauffage'
      }
    ]
  },
  {
    id: 'b4-7',
    category: 'b',
    subject: 'b4',
    label: 'Les déperditions par renouvellement d\'air dépendent uniquement du système de ventilation\u00A0:',
    fullExplanation: 'ra-b4-2',
    simpleExplanation: 'rs-b4-2',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Faux'
      },
      {
        name: 'b',
        label: 'Vrai'
      }
    ]
  },
  {
    id: 'b4-8',
    category: 'b',
    subject: 'b4',
    label: 'Comment s\'exprime la résistance thermique d\'un matériau avec e correspondant à l\'épaisseur du matériau et λ à sa conductivité thermique\u00A0?',
    fullExplanation: 'ra-b4-3',
    simpleExplanation: 'rs-b4-3',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'R = λ x e'
      },
      {
        name: 'b',
        label: 'R = e / λ'
      },
      {
        name: 'c',
        label: 'R = λ / e'
      }
    ]
  },
  {
    id: 'b4-9',
    category: 'b',
    subject: 'b4',
    label: 'La résistance thermique d\'un matériau est égale à sa conductivité thermique (λ) divisée par son épaisseur\u00A0:',
    fullExplanation: 'ra-b4-3',
    simpleExplanation: 'rs-b4-3',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Faux'
      },
      {
        name: 'b',
        label: 'Vrai'
      }
    ]
  },
  {
    id: 'b4-10',
    category: 'b',
    subject: 'b4',
    label: 'La résistance thermique d\'un matériau est égale à son épaisseur multipliée par sa conductivité thermique (λ)\u00A0:',
    fullExplanation: 'ra-b4-3',
    simpleExplanation: 'rs-b4-3',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Faux'
      },
      {
        name: 'b',
        label: 'Vrai'
      }
    ]
  },
  {
    id: 'b4-11',
    category: 'b',
    subject: 'b4',
    label: 'Dans l’expression R = e / λ, que signifie R \u00A0?',
    fullExplanation: 'ra-b4-3',
    simpleExplanation: 'rs-b4-3',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'La résistivité thermique '
      },
      {
        name: 'b',
        label: 'Le ratio thermique'
      },
      {
        name: 'c',
        label: 'La résistance thermique'
      }
    ]
  },
  {
    id: 'b4-12',
    category: 'b',
    subject: 'b4',
    label: 'Quel phénomène peut accentuer les fuites d\'air d\'un bâtiment\u00A0?',
    fullExplanation: 'ra-b4-4',
    simpleExplanation: 'rs-b4-4',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'L\'humidité'
      },
      {
        name: 'b',
        label: 'Les ponts thermiques'
      },
      {
        name: 'c',
        label: 'L\'action du vent'
      }
    ]
  },
  {
    id: 'b4-13',
    category: 'b',
    subject: 'b4',
    label: 'Le vent peut-il accentuer les fuites d\'air d\'un bâtiment \u00A0?',
    fullExplanation: 'ra-b4-4',
    simpleExplanation: 'rs-b4-4',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Oui'
      },
      {
        name: 'b',
        label: 'Non'
      }
    ]
  },
  {
    id: 'b4-14',
    category: 'b',
    subject: 'b4',
    label: 'Le vent n’a pas d’impact sur les fuites d\'air d\'un bâtiment\u00A0:',
    fullExplanation: 'ra-b4-4',
    simpleExplanation: 'rs-b4-4',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'b4-15',
    category: 'b',
    subject: 'b4',
    label: 'Le vent a tendance à réduire les fuites d\'air à travers l’enveloppe des bâtiments\u00A0:',
    fullExplanation: 'ra-b4-4',
    simpleExplanation: 'rs-b4-4',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'b4-16',
    category: 'b',
    subject: 'b4',
    label: 'Quelle exposition des baies génère le plus d’apports solaires en hiver\u00A0?',
    fullExplanation: 'ra-b4-5',
    simpleExplanation: 'rs-b4-5',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Nord'
      },
      {
        name: 'b',
        label: 'Sud '
      },
      {
        name: 'c',
        label: 'Est'
      }
    ]
  },
  {
    id: 'b4-17',
    category: 'b',
    subject: 'b4',
    label: 'Pour optimiser les apports solaires en hiver, il faut privilégier l’exposition des baies au\u00A0:',
    fullExplanation: 'ra-b4-5',
    simpleExplanation: 'rs-b4-5',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Nord'
      },
      {
        name: 'b',
        label: 'Sud'
      },
      {
        name: 'c',
        label: 'Est'
      },
      {
        name: 'd',
        label: 'Ouest'
      }
    ]
  },
  {
    id: 'b4-18',
    category: 'b',
    subject: 'b4',
    label: 'La meilleure exposition des baies pour optimiser les apports solaires en hiver, est l’Ouest\u00A0:',
    fullExplanation: 'ra-b4-5',
    simpleExplanation: 'rs-b4-5',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'b4-19',
    category: 'b',
    subject: 'b4',
    label: 'Une baie exposée au Sud permettra-t-elle de générer plus d’apports solaires en hiver, qu’une baie orientée à l’Ouest\u00A0:',
    fullExplanation: 'ra-b4-5',
    simpleExplanation: 'rs-b4-5',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Oui'
      },
      {
        name: 'b',
        label: 'Non'
      }
    ]
  },
  {
    id: 'b5-confort-ete-1',
    category: 'b',
    subject: 'b5',
    label: 'A résistance thermique équivalente et pour une isolation de toiture, lequel de ces isolants procurera au logement un meilleur déphasage et donc un meilleur confort d’été\u00A0?',
    fullExplanation: 'ra-b5-confort-ete-1',
    simpleExplanation: 'rs-b5-confort-ete-1',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Laine de verre'
      },
      {
        name: 'b',
        label: 'Ouate de cellulose'
      },
      {
        name: 'c',
        label: 'Polystyrène graphité'
      },
      {
        name: 'd',
        label: 'Polyuréthane'
      }
    ]
  },
  {
    id: 'b5-confort-ete-2',
    category: 'b',
    subject: 'b5',
    label: 'L’isolant le plus appréciable vis-à-vis du confort d’été est un isolant dont la qualité suivante est importante\u00A0:',
    fullExplanation: 'ra-b5-confort-ete-1',
    simpleExplanation: 'rs-b5-confort-ete-1',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Sa conductivité thermique'
      },
      {
        name: 'b',
        label: 'Sa densité'
      },
      {
        name: 'c',
        label: 'Sa résistance mécanique'
      },
      {
        name: 'd',
        label: 'Son Sd'
      }
    ]
  },
  {
    id: 'b5-confort-ete-3',
    category: 'b',
    subject: 'b5',
    label: 'Pourquoi l’isolation de la toiture est primordiale vis-à-vis du confort d’été\u00A0?',
    fullExplanation: 'ra-b5-confort-ete-2',
    simpleExplanation: 'rs-b5-confort-ete-2',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Parce que la toiture est la paroi la plus ensoleillée en été'
      },
      {
        name: 'b',
        label: 'Parce qu’elle doit laisser sortir la chaleur qui monte '
      },
      {
        name: 'c',
        label: 'Parce que les couvertures en ardoise attirent la chaleur'
      }
    ]
  },
  {
    id: 'b5-confort-ete-4',
    category: 'b',
    subject: 'b5',
    label: 'L’isolation de la toiture n’a aucune importance vis-à-vis du confort d’été',
    fullExplanation: 'ra-b5-confort-ete-2',
    simpleExplanation: 'rs-b5-confort-ete-2',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'b5-confort-ete-5',
    category: 'b',
    subject: 'b5',
    label: 'Le choix de l’isolant en toiture importe peu, seule sa résistance thermique compte pour le confort d’été\u00A0:',
    fullExplanation: 'ra-b5-confort-ete-1',
    simpleExplanation: 'rs-b5-confort-ete-3',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Faux'
      },
      {
        name: 'b',
        label: 'Vrai'
      }
    ]
  },
  {
    id: 'b5-confort-ete-6',
    category: 'b',
    subject: 'b5',
    label: 'Afin de réduire l’inconfort d’été, la réglementation fixe des exigences sur les fermetures extérieures des baies d’un bâtiment\u00A0?',
    fullExplanation: 'ra-b5-confort-ete-3',
    simpleExplanation: 'rs-b5-confort-ete-4',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'b5-confort-ete-7',
    category: 'b',
    subject: 'b5',
    label: 'Quand j’installe une climatisation, je dois vérifier que toutes les baies sont équipées de protections solaires\u00A0?',
    fullExplanation: 'ra-b5-confort-ete-3',
    simpleExplanation: 'rs-b5-confort-ete-5',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux, toutes les baies sauf au Nord'
      }
    ]
  },
  {
    id: 'b5-confort-ete-8',
    category: 'b',
    subject: 'b5',
    label: 'Les protections solaires extérieures sont plus efficaces que les stores et rideaux intérieurs pour réduire la chaleur dans le logement en été',
    fullExplanation: 'ra-b5-confort-ete-3',
    simpleExplanation: 'rs-b5-confort-ete-6',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'b5-confort-ete-9',
    category: 'b',
    subject: 'b5',
    label: 'Les stores et rideaux intérieurs sont plus efficaces que les fermetures extérieures pour réduire la chaleur dans le logement en été',
    fullExplanation: 'ra-b5-confort-ete-3',
    simpleExplanation: 'rs-b5-confort-ete-6',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'b5-1',
    category: 'b',
    subject: 'b5',
    label: 'Le tirage thermique correspond au phénomène physique de\u00A0:',
    fullExplanation: 'ra-b5-1',
    simpleExplanation: 'rs-b5-1',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'Conduction'
      },
      {
        name: 'b',
        label: 'Rayonnement'
      },
      {
        name: 'c',
        label: 'Convection'
      }
    ]
  },
  {
    id: 'b5-2',
    category: 'b',
    subject: 'b5',
    label: 'Le phénomène physique par lequel l’air chaud s’élève (tirage thermique), s’appelle le rayonnement\u00A0:',
    fullExplanation: 'ra-b5-1',
    simpleExplanation: 'rs-b5-1',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'b5-3',
    category: 'b',
    subject: 'b5',
    label: 'Le phénomène de l’air chaud qui monte par convection dans un conduit, est appelé\u00A0:',
    fullExplanation: 'ra-b5-1',
    simpleExplanation: 'rs-b5-1',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Le tirage thermique'
      },
      {
        name: 'b',
        label: 'La ventilation'
      },
      {
        name: 'c',
        label: 'Le vent'
      }
    ]
  },
  {
    id: 'b5-4',
    category: 'b',
    subject: 'b5',
    label: 'Un système de VMC hygroréglable permet d\'adapter le débit\u00A0:',
    fullExplanation: 'ra-b5-2',
    simpleExplanation: 'rs-b5-2',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'Selon la température extérieure'
      },
      {
        name: 'b',
        label: 'Selon le différentiel de température entre l\'intérieur et l\'extérieur'
      },
      {
        name: 'c',
        label: 'A la quantité de vapeur d\'eau émise par les occupants'
      }
    ]
  },
  {
    id: 'b5-5',
    category: 'b',
    subject: 'b5',
    label: 'Un système de VMC hygroréglable adapte les débits en fonction de la quantité de vapeur d\'eau émise par les occupants\u00A0:',
    fullExplanation: 'ra-b5-2',
    simpleExplanation: 'rs-b5-2',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'b5-6',
    category: 'b',
    subject: 'b5',
    label: 'Quel système de VMC permet d\'adapter les débits en fonction de la quantité de vapeur d\'eau émise par les occupants\u00A0:',
    fullExplanation: 'ra-b5-2',
    simpleExplanation: 'rs-b5-2',
    correctAnswers: ['a', 'b'],
    choices: [
      {
        name: 'a',
        label: 'Une VMC hygroréglable type A'
      },
      {
        name: 'b',
        label: 'Une VMC hygroréglable type B'
      },
      {
        name: 'c',
        label: 'Une VMC double flux'
      },
      {
        name: 'd',
        label: 'Une VMC autoréglable'
      }
    ]
  },
  {
    id: 'b5-7',
    category: 'b',
    subject: 'b5',
    label: 'Un système de VMC autoréglable adapte les débits en fonction de la quantité de vapeur d\'eau émise par les occupants\u00A0:',
    fullExplanation: 'ra-b5-2',
    simpleExplanation: 'rs-b5-2',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'b5-8',
    category: 'b',
    subject: 'b5',
    label: 'Qu\'est-ce qui caractérise la capacité d\'un matériau à résister au passage de la vapeur d\'eau\u00A0?',
    fullExplanation: 'ra-b5-3',
    simpleExplanation: 'rs-b5-3',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'La conductivité thermique ? (W/m.K) '
      },
      {
        name: 'b',
        label: 'Le coefficient Sd (m)'
      },
      {
        name: 'c',
        label: 'La résistance thermique R (m².K/W)'
      }
    ]
  },
  {
    id: 'b5-9',
    category: 'b',
    subject: 'b5',
    label: 'Le coefficient Sd (m) caractérise la capacité d\'un matériau à résister au passage de la vapeur d\'eau\u00A0?',
    fullExplanation: 'ra-b5-3',
    simpleExplanation: 'rs-b5-3',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'b5-10',
    category: 'b',
    subject: 'b5',
    label: 'L’unité de la résistance au passage de la vapeur d\'eau Sd s’exprime\u00A0:',
    fullExplanation: 'ra-b5-3',
    simpleExplanation: 'rs-b5-3',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'En W/m.K'
      },
      {
        name: 'b',
        label: 'En m².K/W'
      },
      {
        name: 'c',
        label: 'En mètre de lame d’air équivalente (m)'
      }
    ]
  },
  {
    id: 'b5-11',
    category: 'b',
    subject: 'b5',
    label: 'Plus le coefficient Sd (m) d’un matériau est grand plus il laisse passer la vapeur d\'eau\u00A0:',
    fullExplanation: 'ra-b5-3',
    simpleExplanation: 'rs-b5-3',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'b5-12',
    category: 'b',
    subject: 'b5',
    label: 'Un plancher de comble perdu bien isolé a une valeur de résistance thermique approximative de\u00A0:',
    fullExplanation: 'ra-b5-4',
    simpleExplanation: 'rs-b5-4',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: '0,5 m².K/W'
      },
      {
        name: 'b',
        label: '50 m².K/W'
      },
      {
        name: 'c',
        label: '5 m².K/W'
      }
    ]
  },
  {
    id: 'b5-13',
    category: 'b',
    subject: 'b5',
    label: 'Un plancher de comble perdu bien isolé a une valeur de coefficient de transmission surfacique (U) approximative de\u00A0:',
    fullExplanation: 'ra-b5-4',
    simpleExplanation: 'rs-b5-4',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: '0,2 W/m².K'
      },
      {
        name: 'b',
        label: '50 W/m².K'
      },
      {
        name: 'c',
        label: '1 W/m².K'
      }
    ]
  },
  {
    id: 'b5-14',
    category: 'b',
    subject: 'b5',
    label: 'Un plancher de comble perdu isolé avec un isolant ayant une résistance thermique de 5 m².K/W est considéré comme\u00A0:',
    fullExplanation: 'ra-b5-4',
    simpleExplanation: 'rs-b5-4',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Très bien isolé (passif)'
      },
      {
        name: 'b',
        label: 'Bien isolé'
      },
      {
        name: 'c',
        label: 'Très faiblement isolé'
      }
    ]
  },
  {
    id: 'b5-15',
    category: 'b',
    subject: 'b5',
    label: 'Un plancher de comble perdu isolé avec un isolant ayant une résistance thermique de 0.5 m².K/W est considéré comme bien isolé\u00A0:',
    fullExplanation: 'ra-b5-4',
    simpleExplanation: 'rs-b5-4',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'b5-16',
    category: 'b',
    subject: 'b5',
    label: 'Pour quelle raison principale doit-on installer une ventilation dans un logement\u00A0?',
    fullExplanation: 'ra-b5-5',
    simpleExplanation: 'rs-b5-5',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'Raison économique'
      },
      {
        name: 'b',
        label: 'Raison écologique'
      },
      {
        name: 'c',
        label: 'Raison sanitaire'
      }
    ]
  },
  {
    id: 'b5-17',
    category: 'b',
    subject: 'b5',
    label: 'La principale raison pour installer une ventilation dans un logement est avant tout écologique\u00A0:',
    fullExplanation: 'ra-b5-5',
    simpleExplanation: 'rs-b5-5',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'b5-18',
    category: 'b',
    subject: 'b5',
    label: 'L’installation d’une ventilation dans un logement permet avant tout\u00A0:',
    fullExplanation: 'ra-b5-5',
    simpleExplanation: 'rs-b5-5',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'De faire des économies d’énergie'
      },
      {
        name: 'b',
        label: 'D’assurer la qualité sanitaire du logement et de l’air intérieur'
      },
      {
        name: 'c',
        label: 'De rendre le logement plus écologique'
      }
    ]
  },
  {
    id: 'b5-19',
    category: 'b',
    subject: 'b5',
    label: 'La raison sanitaire est la raison principale pour laquelle on installe une ventilation dans un logement\u00A0:',
    fullExplanation: 'ra-b5-5',
    simpleExplanation: 'rs-b5-5',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'b6-position-isolant-1',
    category: 'b',
    subject: 'b6',
    label: 'Pour améliorer la performance énergétique et le confort thermique d’un bâtiment, il vaut mieux que les matériaux à forte inertie soient situés\u00A0:',
    fullExplanation: 'ra-b6-position-isolant-1',
    simpleExplanation: 'rs-b6-position-isolant-1',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'A l’intérieur de l’enveloppe isolée'
      },
      {
        name: 'b',
        label: 'A l’extérieur de l’enveloppe isolée'
      }
    ]
  },
  {
    id: 'b6-position-isolant-2',
    category: 'b',
    subject: 'b6',
    label: 'La position de l’isolant n’a aucun effet sur l’inertie d’une paroi\u00A0:',
    fullExplanation: 'ra-b6-position-isolant-1',
    simpleExplanation: 'rs-b6-position-isolant-1',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'b6-position-isolant-3',
    category: 'b',
    subject: 'b6',
    label: 'Améliorer le confort d’un logement passe par une forte inertie de celui-ci, ce qui implique que les isolants soient\u00A0:',
    fullExplanation: 'ra-b6-position-isolant-1',
    simpleExplanation: 'rs-b6-position-isolant-2',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Côté extérieur de la paroi'
      },
      {
        name: 'b',
        label: 'Côté intérieur de la paroi'
      }
    ]
  },
  {
    id: 'b6-position-isolant-4',
    category: 'b',
    subject: 'b6',
    label: 'Une paroi maçonnée doit être isolée par l’extérieur pour être considérée comme confortable',
    fullExplanation: 'ra-b6-position-isolant-1',
    simpleExplanation: 'rs-b6-position-isolant-2',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'b6-position-isolant-5',
    category: 'b',
    subject: 'b6',
    label: 'L’isolation par l’intérieur des murs peut influer négativement sur le confort thermique du local en réduisant fortement\u00A0:',
    fullExplanation: 'ra-b6-position-isolant-1',
    simpleExplanation: 'rs-b6-position-isolant-2',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'La surface habitable'
      },
      {
        name: 'b',
        label: 'L’inertie des murs'
      },
      {
        name: 'c',
        label: 'Les déperditions de la paroi'
      }
    ]
  },
  {
    id: 'b6-1',
    category: 'b',
    subject: 'b6',
    label: 'La certification ACERMI concerne les menuiseries\u00A0:',
    fullExplanation: 'ra-b6-1',
    simpleExplanation: 'rs-b6-1',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'b6-2',
    category: 'b',
    subject: 'b6',
    label: 'ACERMI signifie\u00A0:',
    fullExplanation: 'ra-b6-1',
    simpleExplanation: 'rs-b6-1',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Association des certificateurs de maison individuelle'
      },
      {
        name: 'b',
        label: 'Association pour la certification des matériaux isolants'
      },
      {
        name: 'c',
        label: 'Association de certification des menuisiers indépendants'
      }
    ]
  },
  {
    id: 'b6-3',
    category: 'b',
    subject: 'b6',
    label: 'Pour être certain de la performance de l’isolant que je vais mettre en œuvre, je vérifie que sa résistance thermique est certifiée\u00A0:',
    fullExplanation: 'ra-b6-1',
    simpleExplanation: 'rs-b6-1',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'CEKAL'
      },
      {
        name: 'b',
        label: 'ACERMI'
      },
      {
        name: 'c',
        label: 'ACOTHERM'
      }
    ]
  },
  {
    id: 'b6-4',
    category: 'b',
    subject: 'b6',
    label: 'Que signifie le marquage CE sur un produit\u00A0?',
    fullExplanation: 'ra-b6-2',
    simpleExplanation: 'rs-b6-2',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Produit fabriqué dans la communauté européenne'
      },
      {
        name: 'b',
        label: 'Produit conforme aux normes européennes'
      },
      {
        name: 'c',
        label: 'Produit assemblé dans la communauté européenne'
      }
    ]
  },
  {
    id: 'b6-5',
    category: 'b',
    subject: 'b6',
    label: 'Lorsqu’un produit porte le marquage CE, cela signifie\u00A0:',
    fullExplanation: 'ra-b6-2',
    simpleExplanation: 'rs-b6-2',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'Que le produit a été fabriqué dans la communauté européenne'
      },
      {
        name: 'b',
        label: 'Que le produit a été assemblé dans la communauté européenne'
      },
      {
        name: 'c',
        label: 'Que le produit est conforme aux normes européennes '
      }
    ]
  },
  {
    id: 'b6-6',
    category: 'b',
    subject: 'b6',
    label: ' Le marquage CE sur un produit signifie qu’il a été produit dans la communauté européenne\u00A0:',
    fullExplanation: 'ra-b6-2',
    simpleExplanation: 'rs-b6-2',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'b6-7',
    category: 'b',
    subject: 'b6',
    label: 'Lorsqu’un produit est conforme aux normes européennes, en général il porte le marquage\u00A0:',
    fullExplanation: 'ra-b6-2',
    simpleExplanation: 'rs-b6-2',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'ACERMI'
      },
      {
        name: 'b',
        label: 'NF'
      },
      {
        name: 'c',
        label: 'CE'
      }
    ]
  },
  {
    id: 'b6-8',
    category: 'b',
    subject: 'b6',
    label: 'Comment appelle-t-on le système de VMC qui permet de récupérer les calories de l\'air vicié pour réchauffer l\'air neuf\u00A0?',
    fullExplanation: 'ra-b6-3',
    simpleExplanation: 'rs-b6-3',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'Autoréglable'
      },
      {
        name: 'b',
        label: 'Hygroréglable'
      },
      {
        name: 'c',
        label: 'Double flux'
      }
    ]
  },
  {
    id: 'b6-9',
    category: 'b',
    subject: 'b6',
    label: 'Une VMC qui permet de récupérer les calories de l\'air extrait pour réchauffer l\'air neuf, est appelé une VMC\u00A0:',
    fullExplanation: 'ra-b6-3',
    simpleExplanation: 'rs-b6-3',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'Autoréglable'
      },
      {
        name: 'b',
        label: 'Hybride'
      },
      {
        name: 'c',
        label: 'Double flux'
      }
    ]
  },
  {
    id: 'b6-10',
    category: 'b',
    subject: 'b6',
    label: 'Une VMC double flux permet de récupérer les calories de l\'air extrait pour réchauffer l\'air neuf\u00A0:',
    fullExplanation: 'ra-b6-3',
    simpleExplanation: 'rs-b6-3',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'b6-11',
    category: 'b',
    subject: 'b6',
    label: 'Une VMC double flux\u00A0:',
    fullExplanation: 'ra-b6-3',
    simpleExplanation: 'rs-b6-3',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'Permet d’assurer un taux de renouvellement d’air constant'
      },
      {
        name: 'b',
        label: 'Permet de faire varier les débits en fonction du taux d’humidité de l’air intérieur'
      },
      {
        name: 'c',
        label: 'Permet de récupérer les calories de l\'air extrait pour réchauffer l\'air neuf'
      }
    ]
  },
  {
    id: 'b6-12',
    category: 'b',
    subject: 'b6',
    label: 'La présence du logo Acermi sur un isolant signale que le produit\u00A0:',
    fullExplanation: 'ra-b6-4',
    simpleExplanation: 'rs-b6-4',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'A été produit en France'
      },
      {
        name: 'b',
        label: 'Respecte les normes européennes'
      },
      {
        name: 'c',
        label: 'A été certifié par un organisme indépendant'
      }
    ]
  },
  {
    id: 'b6-13',
    category: 'b',
    subject: 'b6',
    label: 'Lorsqu’un produit isolant est maqué du logo Acermi cela signifie que\u00A0:',
    fullExplanation: 'ra-b6-4',
    simpleExplanation: 'rs-b6-4',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'Le produit a été fabriqué en France'
      },
      {
        name: 'b',
        label: 'Le produit respecte les normes européennes'
      },
      {
        name: 'c',
        label: 'Le produit a été certifié par un organisme indépendant'
      }
    ]
  },
  {
    id: 'b6-14',
    category: 'b',
    subject: 'b6',
    label: 'Lorsque la résistance thermique d’un produit isolant a été certifié par un organisme indépendant, il porte le logo\u00A0:',
    fullExplanation: 'ra-b6-4',
    simpleExplanation: 'rs-b6-4',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'ACERMI'
      },
      {
        name: 'b',
        label: 'NF'
      },
      {
        name: 'c',
        label: 'CE'
      }
    ]
  },
  {
    id: 'b6-15',
    category: 'b',
    subject: 'b6',
    label: 'le logo ACERMI sur un produit isolant signifie que la résistance thermique du produit a été certifié par un organisme indépendant\u00A0:',
    fullExplanation: 'ra-b6-4',
    simpleExplanation: 'rs-b6-4',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'b6-16',
    category: 'b',
    subject: 'b6',
    label: 'Quelle est la résistance thermique d\'un isolant de 40 cm d\'épaisseur ayant un lambda (λ) de 0,04\u00A0:',
    fullExplanation: 'ra-b6-5',
    simpleExplanation: 'rs-b4-3',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: '4'
      },
      {
        name: 'b',
        label: '10'
      },
      {
        name: 'c',
        label: '3'
      }
    ]
  },
  {
    id: 'b6-17',
    category: 'b',
    subject: 'b6',
    label: 'La résistance thermique d\'un isolant de 20 cm d\'épaisseur ayant un lambda (λ) de 0,04 est égale à 5 m².K/W \u00A0:',
    fullExplanation: 'ra-b6-5',
    simpleExplanation: 'rs-b4-3',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'b6-18',
    category: 'b',
    subject: 'b6',
    label: 'Une laine minérale avec un lambda (λ) de 0,04 et une résistance thermique de 7,5 a une épaisseur de\u00A0:',
    fullExplanation: 'ra-b6-5',
    simpleExplanation: 'rs-b4-3',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: '10 cm'
      },
      {
        name: 'b',
        label: '20 cm'
      },
      {
        name: 'c',
        label: '30 cm'
      }
    ]
  },
  {
    id: 'b6-19',
    category: 'b',
    subject: 'b6',
    label: 'Une laine minérale d’épaisseur 20 cm et de résistance thermique 5 m².K/W a un lambda (λ) de\u00A0:',
    fullExplanation: 'ra-b6-5',
    simpleExplanation: 'rs-b4-3',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: '0,02 W/m.K'
      },
      {
        name: 'b',
        label: '0,03 W/m.K'
      },
      {
        name: 'c',
        label: '0,04 W/m.K'
      },
      {
        name: 'd',
        label: '0,05 W/m.K'
      }
    ]
  }
]

export default questions
