var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "result" }, [
    _vm._m(0),
    _c("div", { staticClass: "content" }, [
      _c("p", { staticClass: "title" }, [_vm._v(" votre score ")]),
      _c(
        "div",
        { staticClass: "score", class: { success: _vm.testSuccess } },
        [
          _c(
            "progress-ring",
            {
              attrs: {
                radius: 60,
                progress: _vm.progress,
                stroke: 8,
                color: _vm.testSuccess ? "#25c368" : "#e71212",
              },
            },
            [
              _c("span", { staticClass: "score-value" }, [
                _vm._v(" " + _vm._s(_vm.testScore) + " "),
              ]),
            ]
          ),
        ],
        1
      ),
      _vm.progress > 80
        ? _c("span", { staticClass: "big-tag success" }, [
            _vm._v(" Félicitations, vous avez réussi le test ! "),
          ])
        : _vm.progress > 60
        ? _c("span", { staticClass: "big-tag fail" }, [
            _vm._v(" Encore un effort, on y est presque ! "),
          ])
        : _c("span", { staticClass: "big-tag fail" }, [
            _vm._v(" Ce n’est pas encore ça... "),
          ]),
      _c(
        "div",
        { staticClass: "buttons" },
        [
          _c(
            "router-link",
            {
              staticClass: "button valid-button",
              attrs: { to: "/correction" },
            },
            [_vm._v("Consulter le correctif")]
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "button-wrapper" },
      [
        _c(
          "router-link",
          { staticClass: "button return-button", attrs: { to: "/" } },
          [_vm._v("retourner a l'accueil")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "head" }, [
      _c("p", { staticClass: "title" }, [
        _vm._v(" Vous avez complété le test ! "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }