const corrections = [
  {
    name: 'rs-g1-1',
    content: 'Une évaluation énergétique ne peut être réalisée qu’après, ou au cours, d’une visite sur site. Elle prend en compte le comportement des occupants, les consommations réelles du bâtiment et l’état de vétusté et de dégradation des matériaux et équipements.'
  },
  {
    name: 'rs-g1-2',
    content: 'Dans le cadre des évaluations thermiques, on cherche des ordres de grandeur pour orienter les futurs travaux de rénovation, donc une erreur de quelques centimètres sur une prise de cote ne va pas fausser le résultat. Plus la prise de cote est précise mieux c’est, mais une précision au mètre près est suffisante.'
  },
  {
    name: 'rs-g1-3',
    content: 'Lors d’une évaluation énergétique, il est essentiel de prendre en compte les différents travaux d\'isolation et de modification d\'équipement lié au chauffage ou à I\'ECS qui ont été réalisés, car cela a un impact important sur le résultat.'
  },
  {
    name: 'rs-g1-4',
    content: 'Lorsque l’on réalise une évaluation thermique on relève des données pour déterminer les travaux les plus pertinents à réaliser. Certaines données d’entrée sont plus sensibles sur le résultat que d’autres. Par exemple, mal évaluer l’isolation des combles a plus d’impact que de se tromper sur le type précis des vitrages qui a plus d’impact que de se tromper de 5 cm sur les dimensions d’une pièce.'
  },
  {
    name: 'rs-g1-5',
    content: 'Le type de ventilation (naturelle, simple flux auto réglable, … ) a un impact important sur le résultat de l’évaluation thermique, il est impératif de recueillir cette information.'
  },
  {
    name: 'rs-g1-6',
    content: 'L’année de construction est une donnée d’entrée importante du moteur de calcul car elle détermine certaines valeurs par défaut qui correspondent au mode constructif courant de la période de construction.'
  }
]

export default corrections
