var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.showFooter + " " + _vm.theme, attrs: { id: "app" } },
    [
      _vm.examDate && _vm.showExamDate
        ? _c(
            "router-link",
            {
              staticClass: "exam-date-msg",
              attrs: { to: "/reservation-examen/" + _vm.examDate.id },
            },
            [
              _vm._v(
                " Vous avez fait une demande d'inscription à la session d'examen du " +
                  _vm._s(_vm._f("formatDate")(_vm.examDate.date)) +
                  " - " +
                  _vm._s(_vm.examDate.inscription.status) +
                  " "
              ),
            ]
          )
        : _vm._e(),
      _vm.showHeader ? _c("c-header") : _vm._e(),
      !_vm.showHeader && _vm.routeSubHeader
        ? _c("sub-header", {
            attrs: {
              title: _vm.routeSubHeader.title,
              "show-return-arrow": _vm.routeSubHeader.isReturnArrow,
              picto: _vm.routeSubHeader.picto,
              returnLabel: _vm.routeSubHeader.returnLabel,
            },
            on: {
              return: function ($event) {
                return _vm.navigate()
              },
            },
          })
        : _vm._e(),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [
          _vm.appLoading
            ? _c("div", { staticClass: "app-loading" }, [
                _c("img", {
                  attrs: { src: require("./assets/img-loading.gif") },
                }),
              ])
            : !_vm.appLoading && !_vm.appErrors
            ? _c("router-view")
            : _vm.appErrors
            ? _c(
                "div",
                { staticClass: "technical-error" },
                _vm._l(
                  _vm.$store.state.loadingErrorMessage,
                  function (errorMessage, errorIndex) {
                    return _c("div", { key: "error" + errorIndex }, [
                      _c("p", {
                        domProps: { innerHTML: _vm._s(errorMessage) },
                      }),
                    ])
                  }
                ),
                0
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.showFooter ? _c("c-footer") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }