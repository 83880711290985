const revisions = [
  {
    name: 'ra-f1-1',
    label: 'Principales différences DPE / Evaluation énergétique'
  },
  {
    name: 'ra-f1-2',
    label: 'L’Evaluation énergétique'
  },
  {
    name: 'ra-f1-4',
    label: 'Le moteur de calcul 3CL'
  },
  {
    name: 'ra-f1-5',
    label: 'L’Evaluation énergétique'
  }
]

export default revisions
