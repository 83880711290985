var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "big-link" },
    [
      _vm._t("default", function () {
        return [
          _c("p", { staticClass: "label" }, [_vm._v(_vm._s(_vm.label))]),
          _c("p", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
          _vm._m(0),
          _c("div", {
            staticClass: "bg-image",
            domProps: { innerHTML: _vm._s(_vm.bgImage) },
          }),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "arrow" }, [
      _c("img", {
        attrs: { src: require("../assets/pictos/right-arrow.svg") },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }