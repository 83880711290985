<template>
  <div class="time-up">
    <div class="content-wrapper">
      <div class="content">
        <p class="title">
          Le temps est écoulé !
        </p>
        <div class="time">
          <img src="../../assets/pictos/clock-red.svg">
          <p>
            {{ time | millisecondFormat }}
          </p>
        </div>
        <p>
          Vous avez échoué au test...
        </p>
        <img src="../../assets/pictos/down-arrow.svg">
      </div>
      <div class="circle-bg"></div>
    </div>
    <div class="buttons">
      <router-link to="/questionnary" class="button valid-button">Lancer une session de test</router-link>
      <router-link to="/" class="button return-button">retourner a l'accueil</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TimeUp',
  computed: {
    time () {
      return this.$store.state.test.questionnaryTimer
    }
  }
}
</script>

<style lang=scss>
.time-up {
  height: 100vh;
  box-sizing: border-box;
  padding: 40px 0 80px 0;
  background-image: url('../../assets/background_pattern.svg');
  background-size: cover;
  background-color: rgba($orange, 0.25);
  .content-wrapper {
    position: relative;
    .circle-bg {
      position: absolute;
      width: 430px;
      height: 430px;
      background-color: #fff;
      border-radius: 50%;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      top: 0;
      left: 50%;
      z-index: 1;
      transform: translateX(-50%);
    }
  }
  .content {
    box-sizing: border-box;
    padding: 80px $spacer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 2;
    height: 430px;
    .title {
      font-weight: 900;
      font-size: 1.4rem;
    }
    .time {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      img {
        width: 80px;
        height: 80px;
        margin-bottom: 15px;
      }
      p {
        color: $error-color;
        font-size: 2rem;
      }
    }
    p {
      font-size: 1.1rem;
    }
  }
  .buttons {
    position: relative;
    top: -40px;
    z-index: 2;
    .return-button {
      margin-top: $spacer-md;
    }
  }
}
</style>
