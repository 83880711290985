const corrections = [
  {
    name: 'rs-f1-1',
    content: 'Une évaluation énergétique est plus complète et va plus loin qu’un Diagnostic de Performance Energétique (DPE). Elle prend en compte tous les postes de consommation du bâtiment (le DPE n’en prend que 3), elle permet de cumuler des travaux d’amélioration énergétique et de comparer les déperditions avant et après travaux.'
  },
  {
    name: 'rs-f1-2',
    content: 'Une évaluation thermique a pour objectif de modéliser les consommations réelles du bâtiment en fonction du comportement des occupants, (à l’inverse d’une méthode conventionnelle) il est donc indispensable de recueillir les informations sur le logement, son usage et les travaux envisagés.'
  },
  {
    name: 'rs-f1-4',
    content: 'Le moteur de calcul 3CL est utilisé pour les logiciels de DPE. C’est une méthode de calcul conventionnelle.'
  },
  {
    name: 'rs-f1-5',
    content: 'Avec les logiciels d’évaluation énergétique, il est possible de réaliser des simulations d’amélioration de l\'isolation du bâti et des équipements.'
  }
]

export default corrections
