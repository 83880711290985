var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: { collapsable: true, collapsed: _vm.collapse } }, [
    _c(
      "div",
      { staticClass: "head", on: { click: _vm.toggleCollapse } },
      [_c("p", { staticClass: "collapse-arrow" }), _vm._t("head")],
      2
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.collapse,
            expression: "!collapse",
          },
        ],
        staticClass: "content",
      },
      [_vm._t("content")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }