var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "progress-ring" }, [
    _c("svg", { attrs: { height: _vm.radius * 2, width: _vm.radius * 2 } }, [
      _c("circle", {
        attrs: {
          stroke: "#b2b2b2",
          "stroke-width": _vm.stroke,
          fill: "transparent",
          r: _vm.normalizedRadius,
          cx: _vm.radius,
          cy: _vm.radius,
        },
      }),
      _c("circle", {
        style: { strokeDashoffset: _vm.strokeDashoffset },
        attrs: {
          stroke: _vm.color,
          "stroke-dasharray": _vm.circumference + " " + _vm.circumference,
          "stroke-width": _vm.stroke,
          fill: "transparent",
          r: _vm.normalizedRadius,
          cx: _vm.radius,
          cy: _vm.radius,
        },
      }),
    ]),
    _c("div", { staticClass: "inner-circle" }, [_vm._t("default")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }