var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "drawer", class: { open: _vm.drawerOpen } },
    [
      _c(
        "div",
        { staticClass: "drawer-head", on: { click: _vm.toggleDrawer } },
        [_vm._t("head", null, { isOpen: _vm.drawerOpen })],
        2
      ),
      _c(
        "transition",
        {
          attrs: { name: "expand" },
          on: {
            enter: _vm.enter,
            "after-enter": _vm.afterEnter,
            leave: _vm.leave,
            "after-leave": function ($event) {
              return _vm.$emit("drawer-closed")
            },
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.drawerOpen,
                  expression: "drawerOpen",
                },
              ],
            },
            [
              _c(
                "div",
                { staticClass: "drawer-content" },
                [_vm._t("content")],
                2
              ),
            ]
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "toggle-button-wrapper",
          on: { click: _vm.toggleDrawer },
        },
        [_vm._t("toggle-button")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }