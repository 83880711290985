<template>
  <div id="app" :class="showFooter + ' ' + theme" >
    <router-link v-if="examDate && showExamDate" :to="'/reservation-examen/' + examDate.id" class="exam-date-msg">
      Vous avez fait une demande d'inscription à la session d'examen du {{ examDate.date | formatDate }} - {{ examDate.inscription.status }}
    </router-link>
    <c-header v-if="showHeader"></c-header>
    <sub-header
      v-if="!showHeader && routeSubHeader"
      :title="routeSubHeader.title"
      :show-return-arrow="routeSubHeader.isReturnArrow"
      :picto="routeSubHeader.picto"
      :returnLabel="routeSubHeader.returnLabel"
      @return="navigate()">
    </sub-header>
    <transition name="fade" mode="out-in">
      <div class="app-loading" v-if="appLoading">
        <img src="./assets/img-loading.gif">
      </div>
      <router-view v-else-if="!appLoading && !appErrors"/>
      <div class="technical-error" v-else-if="appErrors">
        <div v-for="(errorMessage, errorIndex) in $store.state.loadingErrorMessage" :key="'error' + errorIndex">
          <p v-html="errorMessage"></p>
        </div>
      </div>
    </transition>
    <c-footer v-if="showFooter"></c-footer>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import CHeader from './components/Header'
import CFooter from './components/Footer'
import SubHeader from './components/SubHeader'

export default {
  name: 'App',
  components: {
    CHeader,
    CFooter,
    SubHeader
  },
  data () {
    return {
      showExamDate: true,
      appLoading: true,
      appErrors: false
    }
  },
  async created () {
    // Get enseigne info inside URL
    const codeEnseigne = window.location.hostname.split('.')
    if (codeEnseigne.length > 1) {
      this.$store.commit('setEnseigne', codeEnseigne[0])
    }

    const urlParams = new URLSearchParams(window.location.search)

    if (window.location.pathname === '/demo') { // Checks for demo mode and init demo mode
      await this.$store.dispatch('user/initDemoMode')
    } else if (urlParams.get('token') && urlParams.get('code')) { // login with GET params
      await this.$store.dispatch('user/loginUserFromUrlParams', {
        token: urlParams.get('token'),
        code: urlParams.get('code')
      })
    } else if (this.$store.getters['user/localStorageApiToken']) { // Login user from localStorage
      this.$store.dispatch('user/loginUserFromLocalStorage')
    } else {
      this.$store.dispatch('user/loginRedirection')
    }

    // fetch user data and store it
    await this.$store.dispatch('user/getUserData')

    // fetch tests data
    await this.$store.dispatch('test/getTestDatas')

    // fetch dates examen
    await this.$store.dispatch('getDatesExamens')

    if (this.$store.state.loadingErrorMessage.length > 0) {
      this.appErrors = true
      this.appLoading = false
      return
    }

    // Check if user first login and enseigne PP to redirect to first-login-form
    if (this.$store.getters['user/isFirstLogin'] && this.$store.getters.isEnseigneWithFirstLoginForm) {
      this.$router.push({ name: 'firstLoginForm' })
    }

    this.appLoading = false
  },
  computed: {
    ...mapState(['enseigne', 'examDate', 'examDateStatus']),
    ...mapGetters(['getEnseigneTotal']),
    routeSubHeader () {
      if (!this.showHeader) {
        return this.$route.meta.subHeader
      } else {
        return false
      }
    },
    showHeader () {
      return this.$route.meta.showHeader
    },
    showFooter () {
      return this.$route.meta.showFooter ? 'footer' : ''
    },
    theme () {
      return 'theme-' + this.getEnseigneTotal
    }
  },
  methods: {
    hideExamDate () {
      this.showExamDate = false
    },
    navigate () {
      if (this.routeSubHeader.returnTo) {
        this.$router.push(this.routeSubHeader.returnTo)
      } else {
        this.$router.go(-1)
      }
    }
  }
}
</script>

<style lang="scss">
html {
  width: 100%;
}

body {
  padding: 0;
  margin: 0;
}

p {
  margin: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  max-width: 768px;
  margin: 0 auto;
  position: relative;
  font-size: 16px;
  &.footer {
    padding-bottom: 70px;
  }
}

/* BUTTONS */
.button {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  border: none;
  background-color: #fff;
  cursor: pointer;
  border-radius: $radius;
  font-size: 1rem;
  text-decoration: none;
  &:focus {
    outline: 0;
  }
}

.technical-error {
  font-size: 0.8rem;
  margin-top: 40px;
  background-color: rgba(243, 243, 243, 0.78);
  border-radius: $radius;
  padding: 10px;
  text-align: left;
  p {
    font-weight: bold;
  }
}

.app-loading {
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 50px;
  }
}

.valid-button {
  @include themify($themes) {
    background-color: themed('backgroundColor');
  }
  height: 56px;
  display: flex;
  margin: 0 $spacer-md;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  font-weight: 900;
  color: #fff;
  transition: 0.3s background-color;
  font-size: 1.1rem;
  &.disabled {
    cursor: default;
    background-color: #e2e2e2;
  }
}

.exam-date-msg {
  @include themify($themes) {
    background-color: themed('backgroundColor');
  }
  display: block;
  color: #fff;
  font-weight: 700;
  padding: 5px 10px;
  text-align: left;
  cursor: pointer;
}

.return-button {
  bottom: $spacer-md;
  width: calc(100% - 20px);
  margin: 0 $spacer-md;
  text-transform: uppercase;
  color: $text-color;
  border: 1px solid $text-color;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  border-radius: $radius;
  font-size: 0.95rem;
}

// MISC
.tag {
  margin-bottom: $spacer-md;
  height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  border-radius: 18px;
  width: 78px;
  color: #fff;
  font-size: 0.72rem;
  &.success {
    background-color: $secondary-color;
  }
  &.fail {
    background-color: $error-color;
  }
}

.doc-link {
  color: $text-color;
  text-decoration: none;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.16);
  border-radius: 0 8px 8px 0;
  border: 1px solid $border-color;
  font-weight: 900;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  padding: 0 $spacer-xl 0 $spacer;
  font-size: 0.9rem;
  min-height: 56px;
  position: relative;
  cursor: pointer;
  background-image: url('./assets/pictos/right-arrow.svg');
  background-position: right 15px center;
  background-repeat: no-repeat;
  background-size: 6px 8px;
  &:before {
    content: '';
    position: absolute;
    display: inline-block;
    left: 0;
    top: -1px;
    height: calc(100% + 2px);
    width: 6px;
    background-color: #888;
  }
}
</style>
