<template>
  <div class="date-exam-page">
    <div v-if="demoMode" class="region-select">
      <p>Sélectionner une région pour visualiser la liste des examens disponibles.</p>
      <Dropdown :list="demoRegions" :model.sync="currentRegion"/>
    </div>
    <transition name="fade" mode="out-in">
      <div v-if="loadingExams && !demoMode" class="loading" key="loading">
        <img src="../assets/loading.gif" alt="image de chargement">
      </div>
      <div v-else-if="loadingExamsError" key="error-content" class="important-info error">
        <p>
          {{ loadingExamsError }}
        </p>
      </div>
      <div v-else-if="loadingExamsInfo" key="empty-content" class="important-info">
        <p>
          {{ loadingExamsInfo }}
        </p>
      </div>
      <div v-else-if="datesNotEmpty">
        <h2 class="title">Dates d'examens disponibles</h2>
        <div class="dates-wrapper">
          <div v-for="(date, index) in dates" :key="'date' + index"
               class="date">
            <p class="title">
              Date d'examen : {{ date.date | formatDate }}
            </p>
            <div class="body">
              <div class="left-col">
                <div class="end-date">
                  <img src="../assets/pictos/calendar-orange.svg">
                  <span>Date limite d’inscription : {{ date.date_limit_inscription | formatDate }}</span>
                </div>
                <div class="location">
                  <img src="../assets/pictos/location.svg">
                  <p>
                    {{ date.location }}
                  </p>
                </div>
              </div>
              <div class="stock" v-if="date.nb_place_available">
                <span>{{ date.nb_place_available }}</span> Places disponibles
              </div>
            </div>
            <router-link
              v-if="canPassExam"
              class="button reservation"
              :to="{ name: 'reservation-examen', params: { examenId: date.id }}">S'inscrire à l'examen
            </router-link>
            <router-link
              v-else-if="date.inscription !== null"
              class="booking"
              :to="{ name: 'reservation-examen', params: { examenId: date.id }}">
              Réservation effectuée le <span>{{ date.inscription.date | formatDate }}</span>
              <p class="status" :class="{ error : date.inscription.status === 'En attente de paiement'}">
                {{ date.inscription.status }}
              </p>
            </router-link>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Dropdown from '../components/Dropdown'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'DatesExamen',
  components: {
    Dropdown
  },
  data () {
    return {
      currentRegion: null
    }
  },
  created () {
    if (this.demoMode) {
      this.currentRegion = this.$store.state.demoRegions.find(region => region.code === this.$store.state.demoCurrentRegion)
    }
  },
  computed: {
    ...mapState(['userStatus', 'demoMode', 'demoRegions', 'loadingExams', 'loadingExamsInfo', 'loadingExamsError']),
    ...mapGetters(['canPassExam']),
    dates () {
      return this.$store.state.examDates
    },
    datesNotEmpty () {
      return this.dates.length > 0
    }
  },
  watch: {
    currentRegion (value) {
      this.$store.dispatch('changeDemoCurrentRegion', value.code)
    }
  }
}
</script>

<style lang="scss">
.important-info {
  margin-top: 1em;
  color: $border-color;
  border: 1px solid $border-color;
  padding: $spacer-lg;
  border-radius: $radius;
  &.error {
    color: $error-color;
    border-color: $error-color;
  }
}
.region-select {
  margin: 2em 0;
  p {
    margin-bottom: 1em;
  }
}
.date-exam-page {
  padding: $spacer-md;
  h2 {
    font-size: 1.2rem;
    text-align: left;
    font-weight: 900;
    margin: 0 0 $spacer 0;
  }
  .dates-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .date {
      margin: 5px;
      width: 100%;
      max-width: 100%;
      color: $text-color;
      text-decoration: none;
      box-shadow: 0 1px 5px rgba(0, 0, 0, 0.16);
      border-radius: $radius;
      border: 1px solid $border-color;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: left;
      padding: $spacer-md $spacer;
      .title {
        text-align: center;
        font-weight: 500;
        font-size: 1.2rem;
        margin-bottom: $spacer-md;
      }
      .body {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .left-col {
          display: flex;
          flex-direction: column;
          justify-content: center;
          .end-date {
            display: flex;
            align-items: center;
            padding-left: 6px;
            img {
              margin-right: 8px;
            }
          }
          .location {
            margin: $spacer-md 0;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            text-align: left;
            img {
              width: 30px;
            }
          }
        }
      }
      .stock {
        width: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: $spacer-md;
        text-align: center;
        flex-direction: column;
        border: 2px solid $secondary-color;
        border-radius: 5px;
        font-size: 0.8rem;
        span {
          text-align: center;
          line-height: 30px;
          font-size: 1.8rem;
          /*background-color: $secondary-color;*/
          /*color: #fff;*/
          /*border-radius: 50%;*/
          margin-right: 10px;
          height: 30px;
          width: 30px;
          flex: 0 0 0;
        }
      }
      .booking {
        text-decoration: none;
        color: $text-color;
        .status {
          margin-top: 2px;
          padding: 2px;
          color: #fff;
          border-radius: 2px;
          font-weight: 700;
          text-align: center;
          background-color: $secondary-color;
          &.error {
            background-color: $error-color;
          }
        }
      }
      .button {
        padding: $spacer-md $spacer-lg;
        display: flex;
        flex-grow: 1;
        margin-top: 10px;
        justify-content: center;
        align-items: center;
        font-weight: 900;
        color: #fff;
        @include themify($themes) {
          background-color: themed('backgroundColor');
        }
        transition: 0.3s background-color;
      }
    }
  }
}
</style>
