var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "revision" }, [
    _c("h2", { staticClass: "title" }, [_vm._v(" Fiches de révision ")]),
    _c(
      "div",
      { staticClass: "revisions-list" },
      _vm._l(_vm.revisions, function (revision) {
        return _c(
          "div",
          { key: "revision" + revision.id, staticClass: "revision" },
          [
            _c("collapsable", {
              scopedSlots: _vm._u(
                [
                  {
                    key: "head",
                    fn: function () {
                      return [
                        _c("div", { staticClass: "revision-head" }, [
                          _c("h3", [_vm._v(_vm._s(revision.label))]),
                          _c("p", { staticClass: "counter" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.removeDuplicates(revision.fiches).length
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "content",
                    fn: function () {
                      return _vm._l(
                        _vm.removeDuplicates(revision.fiches),
                        function (fiche) {
                          return _c(
                            "router-link",
                            {
                              key: fiche.name,
                              staticClass: "doc-link",
                              attrs: {
                                href: `${_vm.baseUrl}${fiche.name}.pdf`,
                                to: {
                                  name: "fiche de revision",
                                  params: { ficheName: fiche.name },
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(fiche.label) + " ")]
                          )
                        }
                      )
                    },
                    proxy: true,
                  },
                ],
                null,
                true
              ),
            }),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }