const revisions = [
  {
    name: 'ra-i1-1',
    label: 'Le Procès Verbal de réception'
  },
  {
    name: 'ra-i1-2',
    label: 'La suite du devis'
  },
  {
    name: 'ra-i1-3',
    label: 'Garanties et Assurances – les durées légales'
  },
  {
    name: 'ra-i1-4',
    label: 'Garanties et Assurances – les durées légales'
  },
  {
    name: 'ra-i1-5',
    label: 'Garanties et Assurances – les durées légales'
  },
  {
    name: 'ra-i1-6',
    label: 'La Qualification RGE'
  }
]

export default revisions
