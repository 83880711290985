var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "about-rge-course" }, [
    _c(
      "div",
      { staticClass: "title-wrapper" },
      [
        _c("image-title", {
          attrs: {
            title: "Le parcours de qualification&nbsp;RGE",
            image: require("../../assets/pictos/course.svg"),
          },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "steps-wrapper" }, [
      _c(
        "div",
        { staticClass: "step" },
        [
          _c("drawer", {
            scopedSlots: _vm._u([
              {
                key: "head",
                fn: function (slotProps) {
                  return [
                    _c("drawer-head-image", {
                      attrs: {
                        label: _vm.firstStepTitle,
                        "is-open": slotProps.isOpen,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "image",
                            fn: function () {
                              return [
                                _c("icon-theme-colored", {
                                  attrs: {
                                    "icon-inline": require("@/assets/pictos/pin-locator.svg?inline"),
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                  ]
                },
              },
              {
                key: "content",
                fn: function () {
                  return [
                    _c("div", { staticClass: "content" }, [
                      _c("p", [
                        _vm._v(
                          " Il est important de choisir en amont du parcours la qualification et l’organisme de qualification qui correspond à votre métier ainsi que la personne qui sera formée. Cette personne deviendra le référent technique permettant à l’entreprise de devenir RGE, son choix est donc essentiel. "
                        ),
                      ]),
                      _c("p", [
                        _vm._v(" Pour les métiers de l’isolation, "),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "https://www.qualibat.com/nomenclature/",
                              target: "_blank",
                              rel: "noopener",
                            },
                          },
                          [_vm._v("découvrez la nomenclature de Qualibat")]
                        ),
                      ]),
                      _c("p", [
                        _vm._v(" Pour les métiers du chauffage, "),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "https://www.qualit-enr.org/decouvrir-nos-qualifications-rge/",
                              target: "_blank",
                              rel: "noopener",
                            },
                          },
                          [_vm._v("découvrez les qualifications de Qualit’ENR")]
                        ),
                      ]),
                      _c("p", [
                        _vm._v(" Pour les métiers liés à l’électricité, "),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "https://pros.qualifelec.fr/toutes-nos-qualifications",
                              target: "_blank",
                              rel: "noopener",
                            },
                          },
                          [_vm._v("découvrez les qualifications de Qualifelec")]
                        ),
                      ]),
                    ]),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      !_vm.isCedeo
        ? _c(
            "div",
            { staticClass: "step" },
            [
              _c("drawer", {
                attrs: {
                  "is-initial-open":
                    this.$route.params.openedDrawer === "formation-technique",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "head",
                      fn: function (slotProps) {
                        return [
                          _c("drawer-head-image", {
                            attrs: {
                              label: "Suivre une formation technique",
                              "is-open": slotProps.isOpen,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "image",
                                  fn: function () {
                                    return [
                                      _c("icon-theme-colored", {
                                        attrs: {
                                          "icon-inline": require("@/assets/pictos/pin-locator.svg?inline"),
                                        },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ]
                      },
                    },
                    {
                      key: "content",
                      fn: function () {
                        return [
                          _c("div", { staticClass: "content" }, [
                            _c("p", [
                              _vm._v(
                                " Depuis le 1er janvier 2021, certaines qualifications RGE requièrent des exigences supplémentaires, notamment l’obligation de suivre une formation technique sur la pose des produits chez un industriel ou autre organisme de formation externe. "
                              ),
                            ]),
                            _c("br"),
                            _c("p", [
                              _c("b", [
                                _vm._v("Les qualifications concernées sont :"),
                              ]),
                              _c("br"),
                              _vm._v(
                                " - L’isolation thermique et acoustique par soufflage"
                              ),
                              _c("br"),
                              _vm._v(
                                " - L’isolation thermique par l’extérieur"
                              ),
                              _c("br"),
                              _vm._v(
                                " - L’isolation thermique par l’intérieur"
                              ),
                              _c("br"),
                              _vm._v(
                                " - L’isolation par projection - injection - sécurité passive contre l’incendie "
                              ),
                            ]),
                            _c("br"),
                            _c("p", [
                              _c("b", [
                                _vm._v("Entreprise de moins de 10 salariés :"),
                              ]),
                              _c("br"),
                              _vm._v(
                                " au moins 50 % des salariés affectés à l’exécution des travaux doivent avoir été formé. "
                              ),
                            ]),
                            _c("br"),
                            _c("p", [
                              _c("b", [
                                _vm._v("Entreprise de plus de 10 salariés :"),
                              ]),
                              _c("br"),
                              _vm._v(
                                " au moins 5 salariés affectés à l’exécution des travaux doivent avoir été formé. "
                              ),
                            ]),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  773880307
                ),
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "step" },
        [
          _c("drawer", {
            scopedSlots: _vm._u([
              {
                key: "head",
                fn: function (slotProps) {
                  return [
                    _c("drawer-head-image", {
                      attrs: {
                        label: "Se former à l’efficacité énergétique",
                        "is-open": slotProps.isOpen,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "image",
                            fn: function () {
                              return [
                                _c("icon-theme-colored", {
                                  attrs: {
                                    "icon-inline": require("@/assets/pictos/pin-locator.svg?inline"),
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                  ]
                },
              },
              {
                key: "content",
                fn: function () {
                  return [
                    _c("div", { staticClass: "content" }, [
                      _c("p", [
                        _vm._v(
                          " Pour devenir RGE, il est nécessaire de justifier de compétence en efficacité énergétique. "
                        ),
                        _c("br"),
                        _c("b", [
                          _vm._v(
                            "Selon votre niveau de connaissances initial, vous avez le choix entre deux modes :"
                          ),
                        ]),
                      ]),
                      _c("br"),
                      _c("p", [
                        _c("span", { staticClass: "underline-title" }, [
                          _vm._v("option a"),
                        ]),
                        _c("br"),
                        _c("b", [
                          _vm._v("Suivre une formation FEEBat de 3 jours"),
                        ]),
                        _c("br"),
                        _vm._v(
                          " Formez-vous avec notre partenaire APEE et suivez le stage de 3 jours dans un centre APEE ;"
                        ),
                        _c("br"),
                        _vm._v(
                          " Coût : 660 € H.T – 100 % remboursable par votre OPCO"
                        ),
                        _c("br"),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "https://apee.fr/formez-vous/",
                              rel: "noopener",
                              target: "_blank",
                            },
                          },
                          [_vm._v("Consulter l’agenda des formations")]
                        ),
                      ]),
                      _c("div", { staticClass: "grey-text-bloc greened" }, [
                        _c("img", {
                          attrs: {
                            src: require("../../assets/pictos/lightbulb.svg"),
                          },
                        }),
                        _c("p", [
                          _vm._v(
                            " Nous vous recommandons ce format si vous estimez que votre niveau de connaissances est faible "
                          ),
                        ]),
                      ]),
                      _c("br"),
                      _c("p", [
                        _c("span", { staticClass: "underline-title" }, [
                          _vm._v("option b"),
                        ]),
                        _c("br"),
                        _c("b", [
                          _vm._v(
                            "Passer l’examen d’1h en candidat libre dans une agence POINT. P"
                          ),
                        ]),
                        _c("br"),
                        _vm._v(
                          " Entraînez-vous à passer l’examen grâce à l’application Objectif RGE. Une fois que vous êtes mature (7 tests consécutifs avec 24/30), vous êtes prêt à passer l’examen. "
                        ),
                        _c("br"),
                        _vm._v(
                          " Coût : 149 € H.T (99 € si membre " +
                            _vm._s(_vm.membershipLabel) +
                            ") "
                        ),
                        _c("br"),
                        _c(
                          "a",
                          {
                            attrs: { href: "/dates-examen", target: "_blank" },
                          },
                          [_vm._v("Consulter les dates d’examen")]
                        ),
                      ]),
                    ]),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "step" },
        [
          _c("drawer", {
            scopedSlots: _vm._u([
              {
                key: "head",
                fn: function (slotProps) {
                  return [
                    _c("drawer-head-image", {
                      attrs: {
                        label: "Monter son dossier de qualification RGE",
                        "is-open": slotProps.isOpen,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "image",
                            fn: function () {
                              return [
                                _c("icon-theme-colored", {
                                  attrs: {
                                    "icon-inline": require("@/assets/pictos/pin-locator.svg?inline"),
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                  ]
                },
              },
              {
                key: "content",
                fn: function () {
                  return [
                    _c("div", { staticClass: "content" }, [
                      _vm._v(
                        " Une fois que le référent technique de l’entreprise a réussi son examen, vous devez constituer votre dossier auprès de l’organisme de qualification correspondant au signe de qualité choisi en amont. "
                      ),
                      _c("br"),
                      _c("br"),
                      _c("b", [_vm._v("Liste des documents à fournir :")]),
                      _c("br"),
                      _c("br"),
                      _c("p", { staticClass: "underline-title" }, [
                        _vm._v("Volet administratif"),
                      ]),
                      _c("br"),
                      _vm._v(
                        " - Deux derniers exercices complets (global + celui de l’activité concernée par la demande de qualification) "
                      ),
                      _c("br"),
                      _vm._v(
                        " - Déclaration Annuelle des Données Sociales Unifiées de l’année précédente "
                      ),
                      _c("br"),
                      _c("br"),
                      _c("br"),
                      _c("p", { staticClass: "underline-title" }, [
                        _vm._v("Volet Administratif et Juridique"),
                      ]),
                      _c("br"),
                      _vm._v(" - Attestation de réussite au QCM FEEBat"),
                      _c("br"),
                      _vm._v(" - KBIS ou avis INSEE (moins de 3 mois)"),
                      _c("br"),
                      _vm._v(" - Attestation URSSAF ou SSI (moins de 3 mois)"),
                      _c("br"),
                      _vm._v(
                        " - Attestation sur l’honneur du paiement des impôts et taxes + Lettre d’engagement signée du responsable légal"
                      ),
                      _c("br"),
                      _vm._v(
                        " - Attestation Responsabilité Civile Professionnelle + Décennale"
                      ),
                      _c("br"),
                      _vm._v(
                        " - État des sinistres enregistrés par l’assurance en responsabilité travaux sur les 4 dernières années "
                      ),
                      _c("br"),
                      _c("br"),
                      _c("br"),
                      _c("p", { staticClass: "underline-title" }, [
                        _vm._v("Volet Chantiers"),
                      ]),
                      _c("br"),
                      _vm._v(" - 3 chantiers de référence"),
                      _c("br"),
                      _vm._v(
                        " - Photos de l’ouvrage (avant, pendant et après sa réalisation) / pensez aux EPI !"
                      ),
                      _c("br"),
                      _vm._v(
                        " - Attestation de travaux signés du maître d’œuvre + Bureau de contrôle ou maître d’ouvrage"
                      ),
                      _c("br"),
                      _vm._v(" - Contrat de sous-traitance (si concerné)"),
                      _c("br"),
                      _vm._v(" - Factures et devis descriptifs des chantiers"),
                      _c("br"),
                      _vm._v(
                        " - Liste des chantiers réalisés au cours des 4 dernières années "
                      ),
                      _c("br"),
                      _c("br"),
                      _c("p", { staticClass: "underline-title" }, [
                        _vm._v("Volet Moyens humains et matériels"),
                      ]),
                      _c("br"),
                      _vm._v(" - Liste du personnel dans l’activité"),
                      _c("br"),
                      _vm._v(
                        " - Certificats de travail, attestation de stage et copie des diplômes"
                      ),
                      _c("br"),
                      _vm._v(" - Liste du matériel et machines"),
                      _c("br"),
                      _vm._v(
                        " - Description immobilière (locaux de l’entreprise) "
                      ),
                    ]),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "step" },
        [
          _c("drawer", {
            scopedSlots: _vm._u([
              {
                key: "head",
                fn: function (slotProps) {
                  return [
                    _c("drawer-head-image", {
                      attrs: {
                        label: "Vous êtes RGE !",
                        "is-open": slotProps.isOpen,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "image",
                            fn: function () {
                              return [
                                _c("icon-theme-colored", {
                                  attrs: {
                                    "icon-inline": require("@/assets/pictos/flag.svg?inline"),
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                  ]
                },
              },
              {
                key: "content",
                fn: function () {
                  return [
                    _c("div", { staticClass: "content" }, [
                      _vm._v(
                        " Votre entreprise est RGE pour les 4 prochaines années. "
                      ),
                      _c("br"),
                      _vm._v(
                        " Chaque année, votre organisme de qualification vous demandera de justifier de la présence du référent technique ainsi que la réalisation d’au moins 2 chantiers sur la qualification RGE de votre entreprise. "
                      ),
                      _c("br"),
                      _vm._v(
                        " Au cours de ces 4 années, au moins un de vos chantiers sera audité par votre organisme de qualification pour s’assurer de sa bonne réalisation. "
                      ),
                    ]),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }