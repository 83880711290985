<template>
  <div class="page-404">
    <div class="head">
      <img src="../assets/illus/404-illu.svg" alt="image 404">
    </div>
    <div class="content">
      <img src="../assets/illus/404-picto.svg" alt="image 404">
      <p>
        Cette page n’existe pas ou plus !
        <br>
        Vous trouverez certainement cette information sur notre <router-link to="/" class="link">page d’accueil</router-link>.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageNotFound'
}
</script>

<style lang="scss">
.page-404 {
  position: relative;
  margin-top: 40px;
  .head {
    background-color: #fff;
    border-radius: $radius $radius 0 0;
    height: 130px;
    position: relative;
    img {
      position: absolute;
      bottom: -33px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .content {
    background-color: #202a3a;
    color: #fff;
    border-radius: 0 0 $radius $radius;
    padding: 120px 0 50px 0;
    .link {
      color: #fff;
    }
    p {
      margin-top: 60px;
    }
  }
}
</style>
