<template>
  <div class="prestation-step">
    <step-question title="Quelles prestations réalisez-vous&nbsp;?"
      :current-step-index="currentStepIndex"
      :nb-steps="nbSteps"
    >
      <template v-slot:question>
        <div class="categories">
          <drawer v-for="categorie in categoriesByEnseigne" :key="categorie.name" class="categorie">
            <template v-slot:head="slotProps">
              <drawer-head-image :label="categorie.label"
                :image="require(`@/assets/prestations/${categorie.name}.svg`)"
                :is-open="slotProps.isOpen">
              </drawer-head-image>
            </template>
            <template v-slot:content>
              <div class="categorie-content">
                <check-buttons name="prestations"
                  :choices="categorieChoices(categorie.name)"
                  :value="model"
                  @input="emitModel">
                </check-buttons>
              </div>
            </template>
          </drawer>
        </div>
      </template>
    </step-question>
  </div>
</template>

<script>
import StepQuestion from '@/components/StepQuestion'
import Drawer from '@/components/Drawer'
import CheckButtons from '@/components/CheckButtons'
import firstLoginStepMixin from '@/views/first-login-form/firstLoginStepMixin'
import DrawerHeadImage from '@/components/DrawerHeadImage'
import { mapState } from 'vuex'

export default {
  name: 'PrestationStep',
  components: { DrawerHeadImage, CheckButtons, Drawer, StepQuestion },
  mixins: [firstLoginStepMixin],
  data () {
    return {
      categories: [
        {
          name: 'isolation',
          label: 'Isolation',
          enseigne: ['pointp']
        },
        {
          name: 'menuiseries',
          label: 'Menuiseries',
          enseigne: ['pointp']
        },
        {
          name: 'ecs',
          label: 'Chauffage et eau chaude',
          enseigne: ['pointp', 'cedeo']
        },
        {
          name: 'ventilation',
          label: 'Ventilation',
          enseigne: ['pointp', 'cedeo']
        },
        {
          name: 'prod-energie',
          label: 'Production d’énergie',
          enseigne: ['pointp']
        }
      ],
      choices: [
        {
          name: 'isolation_combles_soufflage',
          label: 'Isolation des combles perdus par soufflage',
          categorie: 'isolation'
        },
        {
          name: 'isolation_combles_deroule',
          label: 'Isolation des combles perdus en déroulé',
          categorie: 'isolation'
        },
        {
          name: 'isolation_combles_amenage',
          label: 'Isolation des combles aménagés sous rampants',
          categorie: 'isolation'
        },
        {
          name: 'isolation_toiture',
          label: 'Isolation des toitures',
          categorie: 'isolation'
        },
        {
          name: 'isolation_interieur',
          label: 'Isolation thermique des murs par l’intérieur',
          categorie: 'isolation'
        },
        {
          name: 'isolation_exterieur',
          label: 'Isolation thermique des murs par l’extérieur',
          categorie: 'isolation'
        },
        {
          name: 'remplacement_fenetres',
          label: 'Remplacement des fenêtres',
          categorie: 'menuiseries'
        },
        {
          name: 'chauffe_eau_solaire',
          label: 'Chauffe-eau solaire individuel',
          categorie: 'ecs'
        },
        {
          name: 'chauffe_eau_thermodynamique',
          label: 'Chauffe-eau thermodynamique',
          categorie: 'ecs'
        },
        {
          name: 'installation_pompe_chaleur',
          label: 'Pompes à chaleur (Air/Eau, Eau/Eau, hybrides, géothermiques)',
          categorie: 'ecs'
        },
        {
          name: 'installation_chaudiere_biomasse',
          label: 'Chaudières biomasse',
          categorie: 'ecs'
        },
        {
          name: 'installation_appareils_bois',
          label: 'Appareils indépendants fonctionnant au bois',
          categorie: 'ecs'
        },
        {
          name: 'installation_ventilation',
          label: 'Installation d’une ventilation',
          categorie: 'ventilation'
        },
        {
          name: 'solaire_photovoltaique',
          label: 'Panneaux photovoltaïques',
          categorie: 'prod-energie'
        }
      ]
    }
  },
  computed: {
    ...mapState(['enseigne']),
    categoriesByEnseigne () {
      return this.categories.filter(categorie => categorie.enseigne.includes(this.enseigne))
    }
  },
  methods: {
    categorieChoices (categorieName) {
      return this.choices.filter(choice => choice.categorie === categorieName)
    }
  }
}
</script>

<style lang="scss" scoped>
.prestation-step {
  .categories {
    max-width: 600px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
  .categorie-content {
    margin: 15px 0 25px 0;
    padding-left: 10px;
  }
}
</style>
