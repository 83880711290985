var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.questions
    ? _c("div", { staticClass: "test" }, [
        _c("div", { staticClass: "head" }, [
          _c("div", { staticClass: "infos" }, [
            _c("p", { staticClass: "steps" }, [
              _vm._v(
                _vm._s(_vm.questionIndex + 1) +
                  " / " +
                  _vm._s(_vm.questions.length)
              ),
            ]),
            _c("p", { staticClass: "time" }, [
              _vm._v(_vm._s(_vm._f("millisecondFormat")(_vm.timer))),
            ]),
          ]),
          _c("p", { staticClass: "question" }, [
            _vm._v(" " + _vm._s(this.currentQuestion.label) + " "),
          ]),
          _vm.currentQuestion.correctAnswers.length > 1
            ? _c("p", { staticClass: "annotation" }, [
                _vm._v(" Plusieurs choix possibles "),
              ])
            : _vm._e(),
        ]),
        _c("div", { staticClass: "content" }, [
          _c(
            "div",
            _vm._l(_vm.currentQuestion.choices, function (choice) {
              return _c("div", { key: choice.name, staticClass: "answer" }, [
                _c("input", {
                  attrs: { type: _vm.currentQuestionType, id: choice.name },
                  domProps: {
                    checked: _vm.currentAnswer.includes(choice.name),
                  },
                  on: {
                    change: function ($event) {
                      return _vm.setAnswer(choice.name)
                    },
                  },
                }),
                _c("label", { attrs: { for: choice.name } }, [
                  _c("span", { staticClass: "label" }, [
                    _vm._v(_vm._s(choice.label)),
                  ]),
                ]),
              ])
            }),
            0
          ),
        ]),
        _c("div", { staticClass: "buttons" }, [
          _c(
            "button",
            {
              staticClass: "valid-button button",
              class: { disabled: !_vm.isCurrentQuestionAnswered },
              on: { click: _vm.nextQuestion },
            },
            [_vm._v(" Valider ")]
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }