var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page-404" }, [
    _vm._m(0),
    _c("div", { staticClass: "content" }, [
      _c("img", {
        attrs: {
          src: require("../assets/illus/404-picto.svg"),
          alt: "image 404",
        },
      }),
      _c(
        "p",
        [
          _vm._v(" Cette page n’existe pas ou plus ! "),
          _c("br"),
          _vm._v(" Vous trouverez certainement cette information sur notre "),
          _c("router-link", { staticClass: "link", attrs: { to: "/" } }, [
            _vm._v("page d’accueil"),
          ]),
          _vm._v(". "),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "head" }, [
      _c("img", {
        attrs: {
          src: require("../assets/illus/404-illu.svg"),
          alt: "image 404",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }