var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "date-exam-page" },
    [
      _vm.demoMode
        ? _c(
            "div",
            { staticClass: "region-select" },
            [
              _c("p", [
                _vm._v(
                  "Sélectionner une région pour visualiser la liste des examens disponibles."
                ),
              ]),
              _c("Dropdown", {
                attrs: { list: _vm.demoRegions, model: _vm.currentRegion },
                on: {
                  "update:model": function ($event) {
                    _vm.currentRegion = $event
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
        _vm.loadingExams && !_vm.demoMode
          ? _c("div", { key: "loading", staticClass: "loading" }, [
              _c("img", {
                attrs: {
                  src: require("../assets/loading.gif"),
                  alt: "image de chargement",
                },
              }),
            ])
          : _vm.loadingExamsError
          ? _c(
              "div",
              { key: "error-content", staticClass: "important-info error" },
              [_c("p", [_vm._v(" " + _vm._s(_vm.loadingExamsError) + " ")])]
            )
          : _vm.loadingExamsInfo
          ? _c("div", { key: "empty-content", staticClass: "important-info" }, [
              _c("p", [_vm._v(" " + _vm._s(_vm.loadingExamsInfo) + " ")]),
            ])
          : _vm.datesNotEmpty
          ? _c("div", [
              _c("h2", { staticClass: "title" }, [
                _vm._v("Dates d'examens disponibles"),
              ]),
              _c(
                "div",
                { staticClass: "dates-wrapper" },
                _vm._l(_vm.dates, function (date, index) {
                  return _c(
                    "div",
                    { key: "date" + index, staticClass: "date" },
                    [
                      _c("p", { staticClass: "title" }, [
                        _vm._v(
                          " Date d'examen : " +
                            _vm._s(_vm._f("formatDate")(date.date)) +
                            " "
                        ),
                      ]),
                      _c("div", { staticClass: "body" }, [
                        _c("div", { staticClass: "left-col" }, [
                          _c("div", { staticClass: "end-date" }, [
                            _c("img", {
                              attrs: {
                                src: require("../assets/pictos/calendar-orange.svg"),
                              },
                            }),
                            _c("span", [
                              _vm._v(
                                "Date limite d’inscription : " +
                                  _vm._s(
                                    _vm._f("formatDate")(
                                      date.date_limit_inscription
                                    )
                                  )
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "location" }, [
                            _c("img", {
                              attrs: {
                                src: require("../assets/pictos/location.svg"),
                              },
                            }),
                            _c("p", [
                              _vm._v(" " + _vm._s(date.location) + " "),
                            ]),
                          ]),
                        ]),
                        date.nb_place_available
                          ? _c("div", { staticClass: "stock" }, [
                              _c("span", [
                                _vm._v(_vm._s(date.nb_place_available)),
                              ]),
                              _vm._v(" Places disponibles "),
                            ])
                          : _vm._e(),
                      ]),
                      _vm.canPassExam
                        ? _c(
                            "router-link",
                            {
                              staticClass: "button reservation",
                              attrs: {
                                to: {
                                  name: "reservation-examen",
                                  params: { examenId: date.id },
                                },
                              },
                            },
                            [_vm._v("S'inscrire à l'examen ")]
                          )
                        : date.inscription !== null
                        ? _c(
                            "router-link",
                            {
                              staticClass: "booking",
                              attrs: {
                                to: {
                                  name: "reservation-examen",
                                  params: { examenId: date.id },
                                },
                              },
                            },
                            [
                              _vm._v(" Réservation effectuée le "),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("formatDate")(date.inscription.date)
                                  )
                                ),
                              ]),
                              _c(
                                "p",
                                {
                                  staticClass: "status",
                                  class: {
                                    error:
                                      date.inscription.status ===
                                      "En attente de paiement",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(date.inscription.status) + " "
                                  ),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                }),
                0
              ),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }