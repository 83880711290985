var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "corrections" },
    [
      _c(
        "div",
        { staticClass: "corrections-list" },
        _vm._l(_vm.questions, function (question, index) {
          return _c(
            "router-link",
            {
              key: question.id,
              staticClass: "correction",
              attrs: {
                to: { name: "correctif", params: { questionId: question.id } },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "tag",
                  class: {
                    success: _vm.isQuestionValid(question),
                    fail: !_vm.isQuestionValid(question),
                  },
                },
                [_vm._v(" Question " + _vm._s(index + 1) + " ")]
              ),
              _c("p", { staticClass: "label" }, [
                _vm._v(" " + _vm._s(question.label) + " "),
              ]),
            ]
          )
        }),
        1
      ),
      _c(
        "router-link",
        { staticClass: "button return-button", attrs: { to: "/" } },
        [_vm._v("retourner a l'accueil")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }