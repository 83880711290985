<template>
  <div class="corrections">
    <div class="corrections-list">
      <router-link v-for="(question, index) in questions"
        :key="question.id"
        :to="{name: 'correctif', params: { questionId: question.id }}"
        class="correction">
        <div class="tag"
          :class="{ success: isQuestionValid(question), fail: !isQuestionValid(question) }">
          Question {{ index + 1 }}
        </div>
        <p class="label">
          {{ question.label }}
        </p>
      </router-link>
    </div>
    <router-link class="button return-button" to="/">retourner a l'accueil</router-link>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'Correction',
  data () {
    return {
      currentQuestion: null
    }
  },
  computed: {
    ...mapState('test', ['questions', 'testAnswer']),
    ...mapGetters('test', ['nbQuestionnaries']),
    answers () {
      return this.testAnswer
    }
  },
  methods: {
    isQuestionValid (question) {
      return this.answers[question.id].length === question.correctAnswers.length &&
        question.correctAnswers.every(ans => this.answers[question.id].includes(ans))
    }
  }
}
</script>

<style lang=scss>
.corrections {
  .corrections-list {
    .correction + .correction {
      border-top: 1px solid $border-color;
    }
    .correction:last-child {
      border-bottom: 1px solid $border-color;
      margin-bottom: $spacer;
    }
    .correction {
      color: $text-color;
      text-decoration: none;
      display: inline-block;
      padding: 15px;
      cursor: pointer;
      background-image: url('../../assets/pictos/right-arrow.svg');
      background-position: right 20px center;
      background-repeat: no-repeat;
      background-size: 10px 16px;
      width: 100%;
      box-sizing: border-box;
      .label {
        font-weight: 900;
        font-size: 0.9rem;
        text-align: left;
        padding-right: 30px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2; /* number of lines to show */
      }
    }
  }
}
</style>
