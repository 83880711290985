var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "radio-buttons" },
    _vm._l(_vm.choices, function (choice) {
      return _c(
        "label",
        {
          key: choice.name,
          staticClass: "radio-button",
          class: { checked: _vm.isChecked(choice.name) },
          attrs: { for: _vm.name + choice.name },
        },
        [
          _c("input", {
            attrs: {
              type: "radio",
              id: _vm.name + choice.name,
              name: choice.name + _vm.name,
            },
            domProps: {
              value: choice.name,
              checked: _vm.isChecked(choice.name),
            },
            on: {
              click: function ($event) {
                return _vm.setValue(choice.name)
              },
            },
          }),
          _c("span", { staticClass: "radio-label" }, [
            _vm._v(" " + _vm._s(choice.label) + " "),
          ]),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }