const revisions = [
  {
    name: 'ra-g1-1',
    label: 'Evaluation énergétique – Définition et contenu'
  },
  {
    name: 'ra-g1-2',
    label: 'L’Evaluation énergétique – Précision des données d’entrées'
  },
  {
    name: 'ra-g1-3',
    label: 'L’Evaluation énergétique – Précision des données d’entrées'
  },
  {
    name: 'ra-g1-4',
    label: 'L’Evaluation énergétique – Précision des données d’entrées'
  },
  {
    name: 'ra-g1-5',
    label: 'L’Evaluation énergétique – Précision des données d’entrées'
  },
  {
    name: 'ra-g1-6',
    label: 'L’Evaluation énergétique – Précision des données d’entrées'
  }
]

export default revisions
