<template>
  <div class="level-step">
    <step-question :current-step-index="currentStepIndex"
      :nb-steps="nbSteps"
      title="Comment estimez-vous votre niveau de connaissance de l’efficacité énergétique&nbsp;?">
      <template v-slot:question>
        <radio-buttons name="reason" :value="model" @input="emitModel" :choices="choices"></radio-buttons>
      </template>
    </step-question>
  </div>
</template>

<script>
import StepQuestion from '@/components/StepQuestion'
import RadioButtons from '@/components/RadioButtons'
import firstLoginStepMixin from '@/views/first-login-form/firstLoginStepMixin'

export default {
  name: 'LevelStep',
  components: { RadioButtons, StepQuestion },
  mixins: [firstLoginStepMixin],
  data () {
    return {
      choices: [
        {
          name: 'incollable',
          label: 'Je suis incollable ou presque'
        },
        {
          name: 'bases_solide',
          label: 'J’ai des bases solides'
        },
        {
          name: 'connais_bases',
          label: 'Je connais les bases'
        },
        {
          name: 'tout_a_apprendre',
          label: 'J’ai tout à apprendre ou presque'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
