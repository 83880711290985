var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "test-questions" },
    _vm._l(_vm.categories, function (category) {
      return _c("div", { key: category.name, staticClass: "category" }, [
        _c("h2", [_vm._v(_vm._s(category.label))]),
        _c(
          "div",
          { staticClass: "questions" },
          _vm._l(
            _vm.getQuestionsByCategory(category.name),
            function (question) {
              return _c("div", { key: question.id, staticClass: "question" }, [
                _c("div", { staticClass: "id" }, [
                  _vm._v(" " + _vm._s(question.id) + " "),
                ]),
                _c("div", { staticClass: "label" }, [
                  _vm._v(" " + _vm._s(question.label) + " "),
                ]),
                _c(
                  "div",
                  { staticClass: "answers" },
                  _vm._l(question.choices, function (choice) {
                    return _c(
                      "div",
                      {
                        key: question.id + choice.name,
                        staticClass: "answer",
                        class: {
                          valid: question.correctAnswers.includes(choice.name),
                        },
                      },
                      [
                        _vm._v(
                          " " + _vm._s(choice.name + " - " + choice.label) + " "
                        ),
                      ]
                    )
                  }),
                  0
                ),
                _c("br"),
                _c("div", { staticClass: "correction-name" }, [
                  _c("b", [
                    _vm._v(
                      _vm._s(
                        _vm.getCorrectionByName(question.simpleExplanation).name
                      )
                    ),
                  ]),
                ]),
                _c("br"),
                _c("div", {
                  staticClass: "correction",
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.getCorrectionByName(question.simpleExplanation)
                        .content
                    ),
                  },
                }),
                _c("br"),
                _c("div", { staticClass: "revision-name" }, [
                  _c("b", [
                    _vm._v(
                      _vm._s(
                        _vm.getRevisionByName(question.fullExplanation).name
                      )
                    ),
                  ]),
                ]),
                _c("br"),
                _c(
                  "a",
                  {
                    staticClass: "revision",
                    attrs: {
                      href: `${_vm.revisionsBaseUrl}${
                        _vm.getRevisionByName(question.fullExplanation).name
                      }.pdf`,
                      target: "_blank",
                      rel: "noopener",
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.getRevisionByName(question.fullExplanation).label
                      )
                    ),
                  ]
                ),
              ])
            }
          ),
          0
        ),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }