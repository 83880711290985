const corrections = [
  {
    name: 'rs-a1-1',
    content: 'Les <strong>énergies</strong> considérées comme <strong>renouvelables</strong> sont les énergies issues de sources qui se régénèrent naturellement (le sol, l’eau, l’air), qui sont <strong>inépuisables</strong> à notre échelle (le soleil, l’hydraulique, les marées) ou qui sont de <strong>récupération</strong> (énergie fatale, méthanisation, incinération des déchets, …) <br>' +
      'Les <strong>énergies</strong> considérées comme <strong>non renouvelables</strong> sont les ressources consommées à une vitesse bien supérieure à la vitesse à laquelle celles-ci sont naturellement créées ou disponibles. Par exemple les combustibles <strong>fossiles</strong> (pétrole, charbon… ) ou les <strong>minéraux</strong> (uranium …).\n'
  },
  {
    name: 'rs-a1-4',
    content: 'Les énergies fossiles sont celles tirées principalement des combustibles charbon, pétrole et gaz naturel.'
  },
  {
    name: 'rs-a1-5',
    content: 'En France, c’est le secteur du bâtiment qui consomme le plus d’énergie avec près de 46% de la consommation totale.'
  },
  {
    name: 'rs-a1-6',
    content: 'En France, le secteur qui émet le plus de gaz à effet de serre, c’est le secteur des transports avec 28% des émissions totales.'
  },
  {
    name: 'rs-a1-7',
    content: 'A condition que les travaux concernent la résidence principale du bénéficiaire et que le logement ait été construit il y a plus de 15 ans (2 ans si chauffé au fioul), tous les contribuables peuvent bénéficier de Ma Prime Rénov'
  },
  {
    name: 'rs-a2-mpr-1',
    content: 'L’aide Ma Prime Rénov est accessible sous certains critères mais pas uniquement pour les revenus modestes. Le montant des aides est cependant différent selon le niveau de revenu.'
  },
  {
    name: 'rs-a2-mpr-2',
    content: 'Des critères de performance énergétique sont imposés pour bénéficier de MPR (résistance d’un isolant, efficacité d’un générateur, ...)'
  },
  {
    name: 'rs-a2-mpr-3',
    content: 'Les seuls critères de performance à respecter pour MPR portent sur la performance thermique des matériaux.'
  },
  {
    name: 'rs-a2-1',
    content: 'Le « facteur 4 » concerne les émissions de gaz à effet de serre (GES). Il traduit l’engagement qu’a pris la France dans la Loi POPE du 13 juillet 2005 de diviser par 4 ses émissions de gaz à effet de serre (GES) par rapport au niveau de 1990 et ce à l’horizon 2050.'
  },
  {
    name: 'rs-a2-3',
    content: 'La valeur verte d’un bâtiment est définie comme la plus-value liée à ses performances environnementales et notamment sa sobriété énergétique.'
  },
  {
    name: 'rs-a2-4',
    content: 'L’acronyme CEE est utilisé pour Certificat d’Economie d’Energie.'
  },
  {
    name: 'rs-a2-7',
    content: 'Parmi les conditions nécessaires à l’obtention de Ma Prime Rénov, il est impératif qu’apparaisse sur la facture la mention des critères de performances des équipements et/ou des matériaux qui sont posés, ainsi que leur coût.'
  },
  {
    name: 'rs-a2-8',
    content: 'Pour obtenir la mention RGE, une entreprise doit obtenir une qualification par un organisme habilité par l\'état à délivrer cette mention.'
  },
  {
    name: 'rs-a3-1',
    content: 'La Stratégie Nationale Bas Carbone (SNBC) fixe les objectifs de la France en termes de diminution des émissions de CO2 pour tous les secteurs d’activités jusqu’en 2050.'
  },
  {
    name: 'rs-a4-1',
    content: 'Le prix de l’énergie a augmenté en moyenne de 6% par an sur les 20 dernières années.'
  }
]

export default corrections
