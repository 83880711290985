const revisions = [
  {
    name: 'ra-c1-1',
    label: 'Unité des consommations énergétiques dans la réglementation thermique'
  },
  {
    name: 'ra-c2-energie-primaire-1',
    label: 'Énergie primaire / énergie finale'
  },
  {
    name: 'ra-c2-1',
    label: 'Travaux de rénovation et réglementation thermique des bâtiments existants'
  },
  {
    name: 'ra-c2-2',
    label: 'Naissance de la Réglementation Thermique des bâtiments neufs'
  },
  {
    name: 'ra-c2-3',
    label: 'La Réglementation Thermique des bâtiments existants (RTEX)'
  },
  {
    name: 'ra-c2-4',
    label: 'La Réglementation Thermique des bâtiments existants (RTEX)'
  },
  {
    name: 'ra-c3-dpe',
    label: 'Le Diagnostic de Performance Energétique (DPE)'
  },
  {
    name: 'ra-c3-6',
    label: 'RT existant RTEX – Menuiseries et entrées d’air'
  },
  {
    name: 'ra-c3-8',
    label: 'RT existant RTEX globale – Postes de consommation'
  },
  {
    name: 'ra-c3-dpe-1',
    label: 'Etiquette DPE et classe de performance énergétique'
  },
  {
    name: 'ra-c4-1',
    label: 'Conditions d’obtention d’un label BBC Rénovation'
  },
  {
    name: 'ra-c4-2',
    label: 'RT existant RTEX et test d’étanchéité à l’air'
  },
  {
    name: 'ra-c4-rt-1',
    label: 'RT existant : obligation de création d’entrée d’air en cas d’isolation des parois opaques'
  },
  {
    name: 'ra-c4-rt-2',
    label: 'RT existant : obligation de R minimum'
  },
  {
    name: 'ra-c4-rt-3',
    label: 'Extension et RE2020'
  }
]

export default revisions
