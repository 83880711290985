var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "about-rge" }, [
    _c(
      "div",
      { staticClass: "links-wrapper" },
      [
        _c(
          "router-link",
          {
            staticClass: "link-wrapper",
            attrs: { to: { name: "aboutRGECourse" } },
          },
          [
            _c("big-link", {
              attrs: {
                label: "Comment devenir RGE ?",
                title: "Le parcours de qualification RGE",
                "bg-image": require("../assets/illus/illu-parcours.svg?inline"),
              },
            }),
          ],
          1
        ),
        _c(
          "router-link",
          {
            staticClass: "link-wrapper",
            attrs: { to: { name: "aboutRGEAdvantages" } },
          },
          [
            _c("big-link", {
              attrs: {
                label: "Quels coûts pour quels avantages ?",
                title: "Les avantages et coûts de la qualification RGE",
                "bg-image": require("../assets/illus/illu-balance.svg?inline"),
              },
            }),
          ],
          1
        ),
        _c(
          "router-link",
          {
            staticClass: "link-wrapper",
            attrs: { to: { name: "aboutRGEFarther" } },
          },
          [
            _c("big-link", {
              attrs: {
                label: "Pour aller plus loin…",
                title: "Se faire accompagner au montage de son dossier RGE",
                "bg-image": require("../assets/illus/illu-dossier.svg?inline"),
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }