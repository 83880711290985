var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "how-it-works" }, [
      _c("div", { staticClass: "grey-text-bloc" }, [
        _c("b", [_vm._v("Entrainez-vous gratuitement")]),
        _vm._v(
          " au passage de l’examen en réalisant autant de tests que nécessaire. "
        ),
      ]),
      _c("div", { staticClass: "grey-text-bloc" }, [
        _vm._v(" Les sessions de test contiennent "),
        _c("b", [_vm._v("30 questions et doivent être complétées en 1h30")]),
        _vm._v(" pour reproduire les conditions de l’examen. "),
      ]),
      _c("div", { staticClass: "grey-text-bloc" }, [
        _vm._v(
          " Une session de test est considérée comme réussie lorsque vous obtenez "
        ),
        _c("b", [_vm._v("au moins 25 bonnes réponses")]),
        _vm._v(". "),
      ]),
      _c("div", { staticClass: "grey-text-bloc" }, [
        _vm._v(
          " Pour accéder à la réservation d’une date d’examen en candidat libre, vous devez "
        ),
        _c("b", [_vm._v("réussir 7 sessions de test consécutives.")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }