const corrections = [
  {
    name: 'rs-i1-1',
    content: 'Le procès-verbal de réception des travaux se signe dès la fin des travaux, même si le maître d’ouvrage émet des réserves (celles-ci pourront être levées plus tard). Ce document est le point de départ des différentes garanties auprès des assurances (parfait achèvement, biennale et décennale).'
  },
  {
    name: 'rs-i1-2',
    content: 'La bonne attitude à avoir suite à l’envoi d’un devis à un client, c’est de reprendre contact avec lui dans les deux semaines pour s’assurer qu’il l’a bien reçu et aussi pour avoir l’opportunité de l’expliquer et de répondre aux questions du client.'
  },
  {
    name: 'rs-i1-3',
    content: 'La signature du procès-verbal (PV) de réception des travaux déclenche le début des garanties légales (assurance décennale...)'
  },
  {
    name: 'rs-i1-4',
    content: 'La signature du procès-verbal (PV) de réception des travaux déclenche le début des garanties légales (assurance décennale...)'
  },
  {
    name: 'rs-i1-5',
    content: 'L’assurance dommage-ouvrage ne prend effet qu\'à l’issue de l’année de parfait achèvement, soit un an après la réception des travaux.'
  },
  {
    name: 'rs-i1-6',
    content: 'L’audit réalisé dans le cadre de l’obtention de la qualification RGE doit être réalisé sur un chantier de travaux réalisés et en présence du référent technique.'
  }
]

export default corrections
