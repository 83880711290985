<template>
  <div class="image-title">
    <p class="title" v-html="title"></p>
    <img :src="image">
  </div>
</template>

<script>
export default {
  name: 'ImageTitle',
  props: {
    title: {
      type: String,
      required: true
    },
    image: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.image-title {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 360px;
  margin: 0 auto;
  .title {
    font-size: 1.5rem;
    font-weight: 900;
    line-height: 1.9rem;
    text-align: left;
    margin-right: 30px;
  }
}
</style>
