<template>
  <div class="logout">
    <p>Vous êtes correctement déconnecté de l'application Objectif RGE.</p>
  </div>
</template>

<script>
export default {
  name: 'Logout',
  mounted () {
    this.$store.commit('setUserId', '')
    localStorage.removeItem('token')
  }
}
</script>

<style lang="scss">
.logout {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2em;
}
</style>
