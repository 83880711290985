const corrections = [
  {
    name: 'rs-c1-1',
    content: 'Les consommations d’énergie dans la réglementation thermique sont exprimées en kilowattheure d’énergie primaire par mètre carré et par an : kWhep/m².an. Ne pas confondre énergie primaire (ep) et énergie finale (ef).'
  },
  {
    name: 'rs-c2-energie-primaire-1',
    content: 'Depuis 2021, le coefficient de multiplication de l’énergie finale vers l’énergie primaire est de 2,30 pour l’électricité uniquement et de 1 pour les autres énergies.'
  },
  {
    name: 'rs-c2-energie-primaire-2',
    content: 'On doit multiplier la consommation en énergie finale par le coefficient de conversion en énergie primaire pour obtenir la consommation en énergie primaire.'
  },
  {
    name: 'rs-c2-energie-primaire-3',
    content: 'Les pertes moyennes au niveau national pour la production et la distribution de l’électricité sont équivalent à 1,3 kWh perdu pour 1 kWh fourni.'
  },
  {
    name: 'rs-c2-1',
    content: 'Tous les bâtiments existants sont soumis à une réglementation thermique lorsqu’ils font l’objet de travaux de rénovation.<br>' +
      'Il y a deux volets à la réglementation thermique des bâtiments existants : la RT existant élément par élément et la RT existant globale.<br>' +
      'Tous les bâtiments existants sont soumis à la RT existant élément par élément sauf les bâtiments de plus de 1000 m² de SHON, qui datent d’avant 1948 et dont le montant des travaux engagés représente plus de 25% du prix du bâtiment (prix défini par arrêté).'
  },
  {
    name: 'rs-c2-2',
    content: 'La première réglementation thermique française pour les bâtiments neufs a vu le jour en 1974 au lendemain du premier choc pétrolier de 1973.'
  },
  {
    name: 'rs-c2-3',
    content: 'La réglementation thermique qui s\'impose dans les bâtiments existants à l\'occasion d\'une rénovation s’appelle la RTEX. Le premier volet de cette réglementation date de 2007.'
  },
  {
    name: 'rs-c3-2',
    content: 'Le DPE est un document qui exprime les consommations conventionnelles du bâtiment et pas les consommations réelles.'
  },
  {
    name: 'rs-c3-3',
    content: 'Le Diagnostic de Performance Energétique ne peut être réalisé que par un professionnel indépendant, assuré et certifié.'
  },
  {
    name: 'rs-c3-4',
    content: 'La réalisation du DPE est obligatoire uniquement au moment de la vente, de la location ou de la construction du logement ou du bâtiment.'
  },
  {
    name: 'rs-c3-5',
    content: 'GES signifie Gaz à Effet de Serre.'
  },
  {
    name: 'rs-c3-6',
    content: 'La RTEX élément par élément impose, en cas de changement de menuiserie dans un local ne disposant pas de système de ventilation, l’installation de grilles d’entrée d’air dans les menuiseries.'
  },
  {
    name: 'rs-c3-7',
    content: 'La réalisation du DPE est obligatoire uniquement au moment de la vente, de la location ou de la construction du logement ou du bâtiment.'
  },
  {
    name: 'rs-c3-8',
    content: 'Les postes de consommation pris en compte dans la réglementation thermique sont :<br>' +
      'Le chauffage, le refroidissement, l’eau chaude sanitaire, les auxiliaires et l’éclairage.'
  },
  {
    name: 'rs-c3-9',
    content: 'Le DPE présente deux classements de performance sous forme d’étiquette : une étiquette « énergie » et une étiquette « climat ».'
  },
  {
    name: 'rs-c3-10',
    content: 'Les consommations dans le DPE sont quantifiées en kWhep/m².an (kilo Watt/heure d’énergie primaire par mètre carré de surface habitable et par an).'
  },
  {
    name: 'rs-c3-dpe-1',
    content: 'La classe de performance d’un logement établi par le DPE est une double étiquette intégrant la consommation énergétique et de la production de gaz à effet de serre.'
  },
  {
    name: 'rs-c3-dpe-2',
    content: 'Pour l’étiquette du DPE, on prend en compte la plus mauvaise des 2 notes. Le logement serait classé en C.'
  },
  {
    name: 'rs-c3-dpe-3',
    content: 'Pour l’étiquette du DPE, on prend en compte la plus mauvaise des 2 notes'
  },
  {
    name: 'rs-c3-dpe-4',
    content: 'L’étiquette A est la meilleure étiquette possible et concerne les bâtiments très bien isolés et utilisant des systèmes de production à énergies renouvelables.'
  },
  {
    name: 'rs-c3-dpe-5',
    content: 'L’étiquette B est considéré comme le niveau de rénovation performante et concerne les bâtiments peu énergivores.'
  },
  {
    name: 'rs-c3-dpe-6',
    content: 'L’étiquette A est la meilleure étiquette possible et concerne les bâtiments très bien isolés et utilisant des systèmes de production à énergies renouvelables. Le recours au chauffage électrique ne garantit pas l’atteinte de cette étiquette.'
  },
  {
    name: 'rs-c4-1',
    content: 'Pour obtenir un label BBC Rénovation, il est obligatoire de passer par un organisme certificateur, de faire réaliser une étude thermique réglementaire et un test d’étanchéité à l’air du bâtiment à l’achèvement des travaux. Le résultat du test d’étanchéité à l’air doit être inférieur à la valeur d’étanchéité à l’air déclarée dans l’étude. Si ce n’est pas le cas, la consommation du bâtiment sera supérieure et le projet ne sera pas labellisé.'
  },
  {
    name: 'rs-c4-2',
    content: 'Il n’y a pas d’obligation de réalisation d’un test d’étanchéité à l’air dans le cadre de la réglementation thermique des bâtiments existants.'
  },
  {
    name: 'rs-c4-rt-1',
    content: 'Le maintien ou la création d’entrées d’air dans les pièces principales est obligatoire en cas de travaux d’isolation sur les parois.'
  },
  {
    name: 'rs-c4-rt-2',
    content: 'Les résistances thermiques minimales sont à respecter pour tous les types de bâtiments. Les valeurs sont inférieures à celle exigées par les aides financières.'
  },
  {
    name: 'rs-c4-rt-3',
    content: 'La RE2020 concerne uniquement les travaux de constructions et sous certaines conditions les travaux d’extension.'
  }
]

export default corrections
