export default function (value) {
  const date = new Date(value)
  const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' }
  if (value && date) {
    const dateToString = date.toLocaleDateString('fr-FR', options)
    const splitedDate = dateToString.split(',')
    let finalDate = ''
    if (splitedDate[0]) {
      const dateDayMonthYear = splitedDate[0]
      finalDate += dateDayMonthYear
    }
    if (splitedDate[1]) {
      const splitedHour = splitedDate[1].split(':')
      const dateHourMinute = splitedHour[0] + 'h' + splitedHour[1]
      finalDate += ` à ${dateHourMinute}`
    }

    return finalDate
  }
  return ''
}
