var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "drawer-head-image", class: { open: _vm.isOpen } },
    [
      _c("div", { staticClass: "left-side" }, [
        _c(
          "div",
          { staticClass: "img-wrapper" },
          [
            _vm._t("image", function () {
              return [_c("img", { attrs: { src: _vm.image } })]
            }),
          ],
          2
        ),
        _c("p", { staticClass: "label" }, [_vm._v(_vm._s(_vm.label))]),
      ]),
      _c("transition", { attrs: { name: "fade" } }, [
        !_vm.isOpen
          ? _c("div", { staticClass: "plus" }, [_vm._v("+")])
          : _c("div", { staticClass: "plus" }, [_vm._v("-")]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }