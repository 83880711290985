var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "outro-step" }, [
    _c("div", { staticClass: "background-head head" }, [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "bottom-arrow", on: { click: _vm.scrollToQuestion } },
        [
          _c("img", {
            attrs: { src: require("../../assets/pictos/bottom-arrow.svg") },
          }),
        ]
      ),
    ]),
    _c("div", { ref: "outroContent", staticClass: "outro-content" }, [
      _vm.isLevelWarning
        ? _c("div", { staticClass: "warning-content" }, [_vm._m(1), _vm._m(2)])
        : _vm._e(),
      _c("div", { staticClass: "info-content" }, [
        _vm._m(3),
        _c("div", { staticClass: "content" }, [
          _vm._v(
            " D’après vos domaines de compétence, plusieurs pré-requis seront nécessaires pour pouvoir soumettre votre dossier de qualification :"
          ),
          _c("br"),
          _vm._v(" - "),
          _c("b", [_vm._v("des preuves de vos réalisations antérieures")]),
          _vm._v(" (devis, photos avant-pendant-après)"),
          _c("br"),
          _vm.isPrestaNeedTraining
            ? _c("p", [
                _vm._v(" - "),
                _c("b", [_vm._v("suivre une formation technique")]),
                _vm._v(
                  " dispensée par un industriel (nécessaire pour l’isolation par soufflage, l’ITI et l’ITE) "
                ),
              ])
            : _vm._e(),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "content" }, [
      _c("p", { staticClass: "title" }, [_vm._v("C’est tout !")]),
      _c("p", { staticClass: "intro" }, [
        _c("b", [
          _vm._v(
            "Veuillez prendre connaissance des points suivants avant de commencer votre entrainement."
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title-wrapper" }, [
      _c("img", { attrs: { src: require("../../assets/pictos/warning.svg") } }),
      _c("p", { staticClass: "title" }, [_vm._v(" Attention ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "content" }, [
      _vm._v(" Compte tenu de votre niveau de connaissance, "),
      _c("b", [
        _vm._v(
          "nous vous recommandons de suivre une formation complète pour être certain d’acquérir les bases nécessaires"
        ),
      ]),
      _vm._v(
        " et d’utiliser OBJECTIF RGE pour vous entraîner et tester vos connaissances. "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title-wrapper" }, [
      _c("img", {
        attrs: { src: require("../../assets/pictos/lightbulb.svg") },
      }),
      _c("p", { staticClass: "title" }, [
        _vm._v(" Pré-requis à la qualification RGE "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }