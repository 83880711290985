const revisions = [
  {
    name: 'ra-e1-1',
    label: 'L’isolation thermique répartie'
  },
  {
    name: 'ra-e1-2',
    label: 'Isolation en combles perdus'
  },
  {
    name: 'ra-e1-3',
    label: 'Isolation biosourcée et ACERMI'
  },
  {
    name: 'ra-e1-4',
    label: 'Propriété de migration de vapeur d’eau et type de bâti'
  },
  {
    name: 'ra-e1-5',
    label: 'Isolation en combles et obligation de pare-vapeur'
  },
  {
    name: 'ra-e1-6',
    label: 'Ordre de grandeur des performances des matériaux isolants'
  },
  {
    name: 'ra-e1-8',
    label: 'Etanchéité à l’air – Les trappes d’accès aux combles'
  },
  {
    name: 'ra-e1-9',
    label: 'Isolation Thermique par l’Extérieur (ITE) sous enduit'
  },
  {
    name: 'ra-e1-10',
    label: 'Isolation Thermique par l’Extérieur (ITE)'
  },
  {
    name: 'ra-e2-1',
    label: 'Le label Flamme Verte'
  },
  {
    name: 'ra-e2-2',
    label: 'Humidité du bois et énergie récupérable'
  },
  {
    name: 'ra-e2-3',
    label: 'La biomasse'
  },
  {
    name: 'ra-e3-1',
    label: 'Le solaire photovoltaïque'
  },
  {
    name: 'ra-e3-2',
    label: 'La production photovoltaïque'
  },
  {
    name: 'ra-e3-3',
    label: 'Les systèmes solaires combinés'
  },
  {
    name: 'ra-e3-4',
    label: 'Le solaire photovoltaïque'
  },
  {
    name: 'ra-e3-5',
    label: 'Le Chauffe Eau Solaire Individuel (CESI)'
  },
  {
    name: 'ra-e4-1',
    label: 'Les Pompes A Chaleur'
  },
  {
    name: 'ra-e4-2',
    label: 'Les Pompes A Chaleur et leur Coefficient de Performance (COP)'
  },
  {
    name: 'ra-e4-3',
    label: 'Installation géothermique ou Pompes A Chaleur géothermique'
  },
  {
    name: 'ra-e4-4',
    label: 'Installation géothermique ou Pompes A Chaleur géothermique'
  },
  {
    name: 'ra-e4-5',
    label: 'Les Pompes A Chaleur et leur Coefficient de Performance (COP)'
  },
  {
    name: 'ra-e4-6',
    label: 'Les Pompes A Chaleur et leur Coefficient de Performance (COP)'
  },
  {
    name: 'ra-e4-7',
    label: 'Les Pompes A Chaleur et leur Coefficient de Performance (COP)'
  },
  {
    name: 'ra-e4-8',
    label: 'Les Pompes A Chaleur et leur Coefficient de Performance (COP)'
  },
  {
    name: 'ra-e5-1',
    label: 'Dénomination des vitrages doubles et triples'
  },
  {
    name: 'ra-e5-2',
    label: 'Les profilés à rupture de pont thermique'
  },
  {
    name: 'ra-e5-3',
    label: 'Le double vitrage peu émissif'
  },
  {
    name: 'ra-e5-4',
    label: 'Les gaz dans les doubles ou triples vitrages'
  },
  {
    name: 'ra-e6-bruit-pac-1',
    label: 'Pompe à chaleur et nuisances sonores'
  },
  {
    name: 'ra-e6-1',
    label: 'Principe de la Ventilation Mécanique Contrôlée (VMC) simple flux'
  },
  {
    name: 'ra-e6-2',
    label: 'Principe de la Ventilation Mécanique Contrôlée (VMC) simple flux'
  },
  {
    name: 'ra-e6-3',
    label: 'La ventilation mécanique double-flux - Principe'
  },
  {
    name: 'ra-e6-4',
    label: 'Les VMC hygroréglables – Différences entre type A et type B'
  },
  {
    name: 'ra-e6-5',
    label: 'Les VMC Double flux – Entretien et maintenance'
  },
  {
    name: 'ra-e6-6',
    label: 'Les VMC hygroréglables – Différences entre type A et type B'
  },
  {
    name: 'ra-e6-7',
    label: 'Différence entrée d’air Autoréglable et Hygroréglable'
  },
  {
    name: 'ra-e6-8',
    label: 'La ventilation mécanique double-flux - Principe'
  },
  {
    name: 'ra-e7-1',
    label: 'Les infiltrations dans le bâtiment'
  },
  {
    name: 'ra-e7-2',
    label: 'Le test d’étanchéité à l’air'
  },
  {
    name: 'ra-e7-3',
    label: 'Doublage collé – Mise en œuvre des gaines'
  },
  {
    name: 'ra-e8-1',
    label: 'Installation d’Eau Chaude Sanitaire avec stockage'
  },
  {
    name: 'ra-e8-2',
    label: 'Installation d’Eau Chaude Sanitaire avec stockage'
  },
  {
    name: 'ra-e8-3',
    label: 'L’unité de puissance – Le Watt'
  },
  {
    name: 'ra-e8-4',
    label: 'Le rendement des chaudières à condensation'
  },
  {
    name: 'ra-e8-5',
    label: 'Ballon de stockage de l’Eau Chaude Sanitaire, en volume, ou hors volume chauffé ?'
  },
  {
    name: 'ra-e8-6',
    label: 'Sonde de température - emplacement'
  },
  {
    name: 'ra-e8-7',
    label: 'Les chaudières à condensation'
  }
]

export default revisions
