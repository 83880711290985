<template>
  <div class="about-rge-advantages">
    <div class="title-wrapper">
      <image-title title="Les avantages et coûts de la qualification&nbsp;RGE"
        :image="require('../../assets/pictos/scale.svg')">
      </image-title>
    </div>
    <div class="steps-wrapper">
      <drawer>
        <template v-slot:head="slotProps">
          <drawer-head-image label="Les avantages d'être qualifié RGE"
            :is-open="slotProps.isOpen">
            <template v-slot:image>
              <icon-theme-colored :icon-inline="require('@/assets/pictos/thumb-up.svg?inline')"></icon-theme-colored>
            </template>
          </drawer-head-image>
        </template>
        <template v-slot:content>
          <div class="content">
            <div class="grey-wrapper">
              <p class="title">Réaliser des devis attractifs</p>
              <p>
                en faisant bénéficier à vos clients d’aides financières
              </p>
            </div>
            <div class="grey-wrapper">
              <p class="title">Maintenir / Augmenter son Chiffre d’Affaires</p>
              <p>
                en valorisant votre savoir faire
              </p>
            </div>
            <div class="grey-wrapper">
              <p class="title">Gagner en visibilité</p>
              <p>
                en étant référencé sur <a href="https://france-renov.gouv.fr/" target="_blank" rel="noopener">le site de l’État</a>
              </p>
            </div>
            <div class="grey-wrapper">
              <p class="title">Utiliser les services liés à la rénovation énergétique&nbsp;:</p>
              <p v-if="isEnseignePP">
                <a href="https://www.pointp.fr/services-pour-les-professionnels/renoprim-obtenez-des-primes-pour-vos-chantiers" target="_blank" rel="noopener">Rénoprim</a> /
                <a href="https://www.pointp.fr/services-pour-les-professionnels/cap-renov-votre-stimulateur-de-renovation-energetiques" target="_blank" rel="noopener">Cap Rénov</a> /
                <a href="https://www.pointp.fr/services-pour-les-professionnels/solu-chiffrez-simplement-et-rapidement-vos-chantiers-de-0" target="_blank" rel="noopener">Solu+</a>
              </p>
              <p v-else-if="isEnseigneCedeo">
                <a href="https://www.cedeo.fr/ma-prime-facile" target="_blank" rel="noopener">Ma prime facile</a> /
                <a href="https://www.cedeo.fr/simulateur-renovation-energetique-caprenov" target="_blank" rel="noopener">Cap Rénov + Cap Rénov Aides</a> /
                <a href="https://www.cedeo.fr/configurateur-chantier-solu" target="_blank" rel="noopener">Solu+</a>
              </p>
            </div>
          </div>
        </template>
      </drawer>
      <drawer>
        <template v-slot:head="slotProps">
          <drawer-head-image label="Les coûts pour devenir RGE"
            :is-open="slotProps.isOpen">
            <template v-slot:image>
              <icon-theme-colored :icon-inline="require('@/assets/pictos/coin-stack.svg?inline')"></icon-theme-colored>
            </template>
          </drawer-head-image>
        </template>
        <template v-slot:content>
          <div class="content cost-content">
            <div class="flex-wrapper or-connector">
              <div class="grey-wrapper">
                <p class="title">
                  Formation FEEBat
                </p>
                <div class="white-wrapper">
                  <p class="sub-title">660 € H.T</p>
                  <p>100 % remboursable</p>
                </div>
              </div>
              <div class="grey-wrapper">
                <p class="title">
                  Examen en candidat libre
                </p>
                <div class="white-wrapper">
                  <p class="sub-title">149 € H.T</p>
                  <p>non remboursable</p>
                </div>
              </div>
            </div>

            <div class="spliter"></div>

            <div class="grey-wrapper">
              <p class="title">
                Formation technique
              </p>
              <div class="white-wrapper">
                <p class="sub-title">entre 400 € et 650 € H.T</p>
                <p>selon l’industriel</p>
              </div>
            </div>

            <p>
              Obligatoire pour <a href="/tout-savoir-rge-parcours/formation-technique">certaines qualifications</a> et recommandées pour toutes les autres
            </p>

            <div class="spliter"></div>

            <div class="grey-wrapper">
              <p class="title">
                Dossier de qualification RGE
              </p>
              <div class="white-wrapper">
                <p class="sub-title">280 € H.T</p>
                <p>pour 1 qualification</p>
              </div>
              <br>
              <p class="title">
                Aide au montage de dossier RGE (facultatif)
              </p>
              <div class="white-wrapper">
                <p class="sub-title">275 € H.T</p>
              </div>
            </div>

            <div class="spliter"></div>

            <div class="enseigne-wrapper">
              <p class="title">
                Coût total pour 1 qualification
              </p>
              <p class="sub-title">
                à partir de 555 € H.T
              </p>
            </div>
          </div>
        </template>
      </drawer>
    </div>
  </div>
</template>

<script>
import ImageTitle from '@/components/ImageTitle'
import Drawer from '@/components/Drawer'
import DrawerHeadImage from '@/components/DrawerHeadImage'
import IconThemeColored from '@/components/IconThemeColored'
import { mapGetters } from 'vuex'

export default {
  name: 'AboutRGEAdvantages',
  components: {
    IconThemeColored,
    DrawerHeadImage,
    Drawer,
    ImageTitle
  },
  computed: {
    ...mapGetters(['isEnseignePP', 'isEnseigneCedeo'])
  }
}
</script>

<style lang="scss" scoped>
.about-rge-advantages {
  padding: 20px 10px;
  .title-wrapper {
    padding: 0 12px;
  }
  .steps-wrapper {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    .content {
      padding: 10px 10px 20px 10px;
      text-align: left;
      font-size: 0.95rem;
      font-weight: 400;
      display: flex;
      flex-direction: column;
      gap: 15px;
      .spliter {
        border: 1px solid $border-color;
      }
      .sub-title {
        font-size: 1.15rem;
      }
      .grey-wrapper {
        background-color: $bg-color;
        border-radius: 8px;
        padding: 12px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .white-wrapper {
          background-color: #fff;
          border-radius: 8px;
          padding: 8px;
          margin-top: 10px;
        }
      }
      .title {
        padding: 0 15px;
        font-weight: 900;
        font-size: 1.05rem;
      }
      a {
        @include themify($themes) { color: themed('backgroundColor'); }
        font-weight: 700;
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
      .flex-wrapper {
        display: flex;
        gap: 6px;
        > .grey-wrapper {
          flex: 1 1 0;
        }
        .grey-wrapper:first-child {
          position: relative;
          &:after {
            content: 'ou';
            position: absolute;
            right: 0;
            top: 20px;
            transform: translateX(50%);
            background-color: #fff;
            width: 36px;
            height: 36px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 900;
          }
          &.plus-connector:after {
            content: '+'
          }
        }
      }
      .enseigne-wrapper {
        border-radius: 8px;
        @include themify($themes) { background-color: rgba(themed('backgroundColor'), 0.12); }
        padding: 25px;
        text-align: center;
      }
    }
  }
}
</style>
