const questions = [
  {
    id: 'f1-1',
    category: 'f',
    subject: 'f1',
    label: 'Une évaluation énergétique c’est ni plus ni moins qu’un DPE\u00A0:',
    fullExplanation: 'ra-f1-1',
    simpleExplanation: 'rs-f1-1',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'f1-2',
    category: 'f',
    subject: 'f1',
    label: 'Une évaluation énergétique comprend les mêmes postes de consommations qu’un DPE\u00A0:',
    fullExplanation: 'ra-f1-1',
    simpleExplanation: 'rs-f1-1',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'f1-3',
    category: 'f',
    subject: 'f1',
    label: 'Dans un DPE on peut simuler un cumul de travaux d’amélioration énergétique\u00A0:',
    fullExplanation: 'ra-f1-1',
    simpleExplanation: 'rs-f1-1',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'f1-4',
    category: 'f',
    subject: 'f1',
    label: 'Dans le cadre d\'une évaluation thermique, le recueil des informations sur le logement, son usage et les travaux envisagés : nombre d\'occupants, température d\'ambiance en journée et la nuit, douches ou bains, type de bâtiment, exposition, matériaux\u00A0:',
    fullExplanation: 'ra-f1-2',
    simpleExplanation: 'rs-f1-2',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'N\'est pas nécessaire pour réaliser une proposition de travaux personnalisée'
      },
      {
        name: 'b',
        label: 'Est indispensable pour s\'adapter aux besoins de l\'occupant'
      },
      {
        name: 'c',
        label: 'N\'est pas nécessaire car je ne peux pas changer les paramètres liés à l\'occupant dans l\'outil d\'évaluation énergétique'
      }
    ]
  },
  {
    id: 'f1-5',
    category: 'f',
    subject: 'f1',
    label: 'Pour réaliser une évaluation thermique, est-il indispensable de recueillir les informations sur le logement, son usage et les travaux envisagés (nombre d\'occupants, température d\'ambiance en journée et la nuit, douches ou bains, type de bâtiment, exposition, matériaux, …)\u00A0:',
    fullExplanation: 'ra-f1-2',
    simpleExplanation: 'rs-f1-2',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Oui'
      },
      {
        name: 'b',
        label: 'Non'
      },
      {
        name: 'c',
        label: 'C’est mieux, mais ce n’est pas indispensable car je n’ai pas la main sur ces paramètres dans l\'outil d\'évaluation énergétique'
      }
    ]
  },
  {
    id: 'f1-6',
    category: 'f',
    subject: 'f1',
    label: 'Dans l\'outil d\'évaluation énergétique, l’utilisateur n’a pas la main sur les paramètres liés à l’usage du bâtiment (nombre d\'occupants, température d\'ambiance en journée et la nuit, douches ou bains, type de bâtiment, exposition, matériaux, …)\u00A0:',
    fullExplanation: 'ra-f1-2',
    simpleExplanation: 'rs-f1-2',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai, car l’évaluation énergétique est basée sur un calcul conventionnel'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'f1-7',
    category: 'f',
    subject: 'f1',
    label: 'Une évaluation thermique est basée sur un calcul conventionnel, donc il n’est pas indispensable de recueillir les informations sur le logement, son usage et les travaux envisagés (nombre d\'occupants, température d\'ambiance en journée et la nuit, douches ou bains, type de bâtiment, exposition, matériaux, …)\u00A0:',
    fullExplanation: 'ra-f1-2',
    simpleExplanation: 'rs-f1-2',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'f1-8',
    category: 'f',
    subject: 'f1',
    label: 'A quoi se rapporte "3CL" \u00A0?',
    fullExplanation: 'ra-f1-4',
    simpleExplanation: 'rs-f1-4',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Un moteur de calculs pour le diagnostic de performance énergétique en France '
      },
      {
        name: 'b',
        label: 'Un logiciel d\'évaluation thermique'
      },
      {
        name: 'c',
        label: 'Une unité de volume'
      }
    ]
  },
  {
    id: 'f1-9',
    category: 'f',
    subject: 'f1',
    label: 'Dans quel cadre est utilisée le moteur de calcul "3CL"\u00A0?',
    fullExplanation: 'ra-f1-4',
    simpleExplanation: 'rs-f1-4',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Pour les logiciels de diagnostic de performance énergétique (DPE) '
      },
      {
        name: 'b',
        label: 'Pour les logiciels d\'évaluation thermique'
      },
      {
        name: 'c',
        label: 'Pour les logiciels de calculs de la réglementation thermique'
      }
    ]
  },
  {
    id: 'f1-10',
    category: 'f',
    subject: 'f1',
    label: 'On utilise le moteur de calcul "3CL" pour réaliser une évaluation thermique\u00A0:',
    fullExplanation: 'ra-f1-4',
    simpleExplanation: 'rs-f1-4',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'f1-11',
    category: 'f',
    subject: 'f1',
    label: 'Lorsque l’on réalise un Diagnostic de performance énergétique (DPE), on utilise une méthode de calcul\u00A0:',
    fullExplanation: 'ra-f1-4',
    simpleExplanation: 'rs-f1-4',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'Comportementale'
      },
      {
        name: 'b',
        label: 'ThCEex'
      },
      {
        name: 'c',
        label: '3CL'
      },
      {
        name: 'd',
        label: 'ThBCE'
      }
    ]
  },
  {
    id: 'f1-12',
    category: 'f',
    subject: 'f1',
    label: 'Les simulations des logiciels d\'évaluation énergétique portent sur\u00A0:',
    fullExplanation: 'ra-f1-5',
    simpleExplanation: 'rs-f1-5',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Uniquement l\'isolation du bâti'
      },
      {
        name: 'b',
        label: 'Ces deux éléments'
      },
      {
        name: 'c',
        label: 'Uniquement les équipements'
      }
    ]
  },
  {
    id: 'f1-13',
    category: 'f',
    subject: 'f1',
    label: 'Avec les logiciels d\'évaluation énergétique, il est possible de réaliser des simulations d’amélioration, mais uniquement sur l\'isolation du bâti\u00A0:',
    fullExplanation: 'ra-f1-5',
    simpleExplanation: 'rs-f1-5',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'f1-14',
    category: 'f',
    subject: 'f1',
    label: 'Avec les logiciels d\'évaluation énergétique, il est possible de réaliser des simulations d’amélioration, mais uniquement sur l\'amélioration des équipements\u00A0:',
    fullExplanation: 'ra-f1-5',
    simpleExplanation: 'rs-f1-5',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'f1-15',
    category: 'f',
    subject: 'f1',
    label: 'Quelles possibilités de simulations offre des logiciels d\'évaluation énergétique\u00A0?',
    fullExplanation: 'ra-f1-5',
    simpleExplanation: 'rs-f1-5',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'Simulations d’amélioration de l\'isolation du bâti '
      },
      {
        name: 'b',
        label: 'Simulation d’amélioration des équipements'
      },
      {
        name: 'c',
        label: 'Simulation d’amélioration de l\'isolation du bâti et des équipements'
      }
    ]
  }
]

export default questions
