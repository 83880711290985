<template>
  <div>
    <div v-if="!isSinglePage" class="pdf-navigation">
      <div>
        <div v-if="currentPage !== 1" @click="currentPage -= 1"> < précédent </div>
      </div>
      <div>
        <div class="current">{{currentPage}} / {{pageCount}}</div>
      </div>
      <div>
        <div v-if="currentPage !== pageCount" @click="currentPage += 1"> suivant > </div>
      </div>
    </div>
    <pdf
      :src="pdfUrl"
      @num-pages="pageCount = $event"
      @page-loaded="currentPage = $event"
      :page="currentPage"
    ></pdf>
  </div>
</template>

<script>
import pdf from 'vue-pdf'

export default {
  name: 'FicheRevision',
  components: {
    pdf
  },
  data () {
    return {
      currentPage: 1,
      pageCount: 0
    }
  },
  computed: {
    pdfUrl () {
      return `${this.$store.state.revisionsBaseUrl}${this.$route.params.ficheName}.pdf`
    },
    isSinglePage () {
      return this.pageCount === 1
    }
  }
}
</script>

<style lang="scss">
.pdf-navigation {
  display: flex;
  justify-content: center;
  >div {
    margin-top: 10px;
    width: 20%;
    display: flex;
    justify-content: center;
    >div {
      &:not(.current) {
        cursor: pointer;
      }
      border-radius: 0.2em;
      padding: 5px 10px;
      border: 1px solid $border-color;
    }
    &:first-child, &:last-child {
      width: 40%;
    }
    &:first-child {
      justify-content: flex-end;
    }
    &:last-child {
      justify-content: flex-start;
    }
  }
}
</style>
