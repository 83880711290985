<template>
  <header>
    <img v-if="currentLogo" :src="currentLogo" class="logo-entreprise" :alt="'logo ' + enseigne">
    <img :src="orgeLogo" alt="logo objectif-rge" class="logo-orge">
  </header>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import logoCedeo from '../assets/logos/logo-cedeo.png'
import logoAsturienne from '../assets/logos/logo-asturienne.png'
import logoPointp from '../assets/logos/logo-pointp.png'
import logoDispano from '../assets/logos/logo-dispano.png'
import logoSfic from '../assets/logos/logo-sfic.png'
import logoSficEtVous from '../assets/logos/logo-sfic-et-vous.jpg'
import logoPum from '../assets/logos/logo-pum.png'
import logoGenerationArtistan from '../assets/logos/GA.svg'
import logoCedeoBoost from '../assets/logos/logo-cedeoboost.png'
import logoCbaSom from '../assets/logos/logo-cba-som.png'
import logoBretagneMateriaux from '../assets/logos/logo-bretagne-materiaux.png'
import logoBernardPhilibert from '../assets/logos/logo-bernardphilibert.png'

export default {
  name: 'Header',
  data () {
    return {
      logos: {
        cedeo: logoCedeo,
        cedeoclub: logoCedeoBoost,
        asturienne: logoAsturienne,
        pointp: logoPointp,
        pointpclub: logoGenerationArtistan,
        dispano: logoDispano,
        sfic: logoSfic,
        sficclub: logoSficEtVous,
        pum: logoPum,
        'cba-som': logoCbaSom,
        'bretagne-materiaux': logoBretagneMateriaux,
        'bernard-philibert': logoBernardPhilibert
      }
    }
  },
  computed: {
    ...mapState(['enseigne']),
    ...mapGetters(['getEnseigneTotal', 'isEnseignePP']),
    currentLogo () {
      return this.logos[this.getEnseigneTotal]
    },
    orgeLogo () {
      return this.isEnseignePP
        ? require('../assets/logos/obj-rge-pp.png')
        : require('../assets/logos/obj-rge.svg')
    }
  }
}
</script>

<style lang=scss>
header {
  @include themify($themes) {
    justify-content: themed('content');
  }

  display: flex;
  padding: 0 $spacer;
  align-items: center;
  height: 70px;
  border-bottom: 1px solid $border-color;

  img {
    max-width: 48%;
  }
  .logo-orge {
    width: 190px;
  }
}

.logo-entreprise {
  @include themify($themes) {
    display: themed('logo');
  }
}

</style>
