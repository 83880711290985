import questions from '../datas/questions'

const QuestionnaryBuilder = {
  getRandomQuestion (questions) {
    return Math.round(Math.random() * (questions.length - 1))
  },
  build (questionnarySubjects) {
    const allQuestions = questions
    const questionnary = []
    if (questionnarySubjects.length !== [...new Set(questionnarySubjects)].length) {
      throw new Error('il existe des doublons dans la liste des sujets')
    }
    questionnarySubjects.forEach(subject => {
      const questionsMatchSubject = allQuestions.filter(question => question.subject === subject)
      const question = questionsMatchSubject[this.getRandomQuestion(questionsMatchSubject)]
      if (question) {
        questionnary.push(question)
      } else {
        throw new Error(`aucune question avec le sujet ${subject} n'a été trouvée`)
      }
    })
    return questionnary
  }
}

export default QuestionnaryBuilder
