var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("header", [
    _vm.currentLogo
      ? _c("img", {
          staticClass: "logo-entreprise",
          attrs: { src: _vm.currentLogo, alt: "logo " + _vm.enseigne },
        })
      : _vm._e(),
    _c("img", {
      staticClass: "logo-orge",
      attrs: { src: _vm.orgeLogo, alt: "logo objectif-rge" },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }