<template>
  <div class="sub-header">
    <a class="return" @click="$emit('return')">
      <a v-show="showReturnArrow"></a>
      <span v-if="returnLabel">{{ returnLabel }}</span>
    </a>
    <div class="title">
      <img :src="require(`@/assets/pictos/${picto}-white.svg`)">
      <p>
        {{ title }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SubHeader',
  props: {
    title: {
      type: String,
      required: true
    },
    showReturnArrow: {
      type: Boolean,
      required: false,
      default: false
    },
    picto: {
      type: String,
      required: true
    },
    returnLabel: {
      type: String,
      required: false,
      default: null
    }
  }
}
</script>

<style lang=scss>
.sub-header {
  @include themify($themes) {
    background-color: themed('backgroundColor');
  }
  color: #fff;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .return {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    display: inline-block;
    cursor: pointer;
    position: absolute;
    display: flex;
    flex-direction: row;
    left: 0;
    top: 50%;
    height: 100%;
    a {
      width: 50px;
      height: 100%;
      background-image: url('../assets/pictos/arrow.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 16px 22px;
    }
    span {
      display: flex;
      font-weight: 700;
      align-items: center;
    }
  }
  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    img {
      margin-right: 10px;
    }
    p {
      font-weight: 900;
      font-size: 1.2rem;
    }
  }
}
</style>
