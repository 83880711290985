const questions = [
  {
    id: 'g1-1',
    category: 'g',
    subject: 'g1',
    label: 'Une évaluation énergétique prend en compte la vétusté ou la dégradation des équipements qui sont en place\u00A0:',
    fullExplanation: 'ra-g1-1',
    simpleExplanation: 'rs-g1-1',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'g1-2',
    category: 'g',
    subject: 'g1',
    label: 'Une évaluation énergétique prend en compte le comportement des occupants\u00A0:',
    fullExplanation: 'ra-g1-1',
    simpleExplanation: 'rs-g1-1',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'g1-3',
    category: 'g',
    subject: 'g1',
    label: 'Une évaluation énergétique se base sur les consommations réelles du bâtiment\u00A0:',
    fullExplanation: 'ra-g1-1',
    simpleExplanation: 'rs-g1-1',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'g1-4',
    category: 'g',
    subject: 'g1',
    label: 'Dans le cadre d\'une évaluation thermique, les cotes du logement doivent-elles être prises avec une précision\u00A0:',
    fullExplanation: 'ra-g1-2',
    simpleExplanation: 'rs-g1-2',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Au cm près'
      },
      {
        name: 'b',
        label: 'Au m près'
      },
      {
        name: 'c',
        label: 'Au mm près'
      }
    ]
  },
  {
    id: 'g1-5',
    category: 'g',
    subject: 'g1',
    label: 'La précision demandée sur les cotes du logement pour réaliser une évaluation thermique est\u00A0:',
    fullExplanation: 'ra-g1-2',
    simpleExplanation: 'rs-g1-2',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Le mm'
      },
      {
        name: 'b',
        label: 'Le cm'
      },
      {
        name: 'c',
        label: 'Le m'
      }
    ]
  },
  {
    id: 'g1-6',
    category: 'g',
    subject: 'g1',
    label: 'Pour réaliser une évaluation thermique, la précision demandée sur les cotes du logement est de l’ordre du centimètre\u00A0:',
    fullExplanation: 'ra-g1-2',
    simpleExplanation: 'rs-g1-2',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'g1-7',
    category: 'g',
    subject: 'g1',
    label: 'Lors de la réalisation d’une évaluation thermique, il faut être très précis sur la prise de cotes car la précision demandée est de l’ordre du millimètre\u00A0:',
    fullExplanation: 'ra-g1-2',
    simpleExplanation: 'rs-g1-2',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'g1-8',
    category: 'g',
    subject: 'g1',
    label: 'Parmi les paramètres suivants d\'une évaluation énergétique, lequel est le plus impactant sur la consommation énergétique du logement\u00A0?',
    fullExplanation: 'ra-g1-3',
    simpleExplanation: 'rs-g1-3',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'La date de construction du logement'
      },
      {
        name: 'b',
        label: 'La nature des revêtements de sol'
      },
      {
        name: 'c',
        label: 'Les différents travaux d\'isolation et de modification d\'équipement lié au chauffage ou à I\'ECS'
      }
    ]
  },
  {
    id: 'g1-9',
    category: 'g',
    subject: 'g1',
    label: 'Lors d’une évaluation énergétique, faut-il prendre en compte les différents travaux d\'isolation et de modification d\'équipement lié au chauffage ou à I\'ECS\u00A0:',
    fullExplanation: 'ra-g1-3',
    simpleExplanation: 'rs-g1-3',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Oui, car cela a un impact important sur la consommation'
      },
      {
        name: 'b',
        label: 'Non, la date de construction a plus d’impact sur la consommation'
      }
    ]
  },
  {
    id: 'g1-10',
    category: 'g',
    subject: 'g1',
    label: 'Lors d’une évaluation énergétique, la prise en compte des différents travaux d\'isolation et de modification d\'équipement lié au chauffage ou à I\'ECS a un impact important sur la consommation\u00A0:',
    fullExplanation: 'ra-g1-3',
    simpleExplanation: 'rs-g1-3',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Vrai'
      },
      {
        name: 'b',
        label: 'Faux'
      }
    ]
  },
  {
    id: 'g1-11',
    category: 'g',
    subject: 'g1',
    label: 'Les différents travaux d\'isolation et de modification d\'équipement lié au chauffage ou à I\'ECS ne sont pas à prendre en compte lors d\'une évaluation énergétique\u00A0:',
    fullExplanation: 'ra-g1-3',
    simpleExplanation: 'rs-g1-3',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai, la date de construction du logement a un impact plus important'
      },
      {
        name: 'b',
        label: 'Faux, ces paramètres impact énormément la consommation'
      }
    ]
  },
  {
    id: 'g1-12',
    category: 'g',
    subject: 'g1',
    label: 'Parmi les paramètres suivants d\'une évaluation énergétique, lequel est le moins impactant sur la consommation énergétique du logement\u00A0?',
    fullExplanation: 'ra-g1-4',
    simpleExplanation: 'rs-g1-4',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Une erreur de 5 cm sur les dimensions d\'une pièce'
      },
      {
        name: 'b',
        label: 'Le niveau d\'isolation des combles'
      },
      {
        name: 'c',
        label: 'Le type précis des doubles vitrages'
      }
    ]
  },
  {
    id: 'g1-13',
    category: 'g',
    subject: 'g1',
    label: 'Lors d\'une évaluation énergétique, une erreur de 5 cm sur les dimensions d\'une pièce est-elle plus impactante sur la consommation énergétique du logement, qu’une erreur sur le type des doubles vitrages\u00A0?',
    fullExplanation: 'ra-g1-4',
    simpleExplanation: 'rs-g1-4',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Oui'
      },
      {
        name: 'b',
        label: 'Non'
      }
    ]
  },
  {
    id: 'g1-14',
    category: 'g',
    subject: 'g1',
    label: 'Classez les paramètres suivants dans l’ordre croissant de leur impact sur la consommation énergétique du logement lors d’une évaluation énergétique\u00A0:',
    fullExplanation: 'ra-g1-4',
    simpleExplanation: 'rs-g1-4',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: '1) une erreur de 5 cm sur les dimensions d\'une pièce, 2) le type précis des doubles vitrages, 3) le niveau d\'isolation des combles'
      },
      {
        name: 'b',
        label: '1) le niveau d\'isolation des combles, 2) le type précis des doubles vitrages, 3) une erreur de 5 cm sur les dimensions d\'une pièce'
      },
      {
        name: 'c',
        label: '1) le type précis des doubles vitrages, 2) une erreur de 5 cm sur les dimensions d\'une pièce, 3) le niveau d\'isolation des combles'
      }
    ]
  },
  {
    id: 'g1-15',
    category: 'g',
    subject: 'g1',
    label: 'Classez les paramètres suivants dans l’ordre décroissant de leur impact sur la consommation énergétique du logement lors d’une évaluation énergétique\u00A0:',
    fullExplanation: 'ra-g1-4',
    simpleExplanation: 'rs-g1-4',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: '1) une erreur de 5 cm sur les dimensions d\'une pièce, 2) le type précis des doubles vitrages, 3) le niveau d\'isolation des combles'
      },
      {
        name: 'b',
        label: '1) le niveau d\'isolation des combles, 2) le type précis des doubles vitrages, 3) une erreur de 5 cm sur les dimensions d\'une pièce'
      },
      {
        name: 'c',
        label: '1) le type précis des doubles vitrages, 2) une erreur de 5 cm sur les dimensions d\'une pièce, 3) le niveau d\'isolation des combles'
      }
    ]
  },
  {
    id: 'g1-16',
    category: 'g',
    subject: 'g1',
    label: 'Dans le cadre de l’évaluation thermique d’un logement, que faut-il impérativement recueillir comme donnée s’agissant de la ventilation\u00A0?',
    fullExplanation: 'ra-g1-5',
    simpleExplanation: 'rs-g1-5',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'Le nombre de grilles de ventilation'
      },
      {
        name: 'b',
        label: 'La surface totale des grilles de ventilation'
      },
      {
        name: 'c',
        label: 'Le type de ventilation utilisé pour le logement'
      }
    ]
  },
  {
    id: 'g1-17',
    category: 'g',
    subject: 'g1',
    label: 'Le type de ventilation utilisé pour le logement est-il une donnée d’entrée essentielle pour l’évaluation thermique d’un logement\u00A0?',
    fullExplanation: 'ra-g1-5',
    simpleExplanation: 'rs-g1-5',
    correctAnswers: ['a'],
    choices: [
      {
        name: 'a',
        label: 'Oui'
      },
      {
        name: 'b',
        label: 'Non'
      }
    ]
  },
  {
    id: 'g1-18',
    category: 'g',
    subject: 'g1',
    label: 'Lorsque je réalise une évaluation thermique d’un logement, je n’ai pas besoin de relever le type de ventilation utilisé pour le logement\u00A0:',
    fullExplanation: 'ra-g1-5',
    simpleExplanation: 'rs-g1-5',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Vrai, l’isolation est plus importante'
      },
      {
        name: 'b',
        label: 'Faux, c’est une donnée d’entrée essentielle'
      }
    ]
  },
  {
    id: 'g1-19',
    category: 'g',
    subject: 'g1',
    label: 'Quelle est l’information importante à recueillir concernant la ventilation lors de l’évaluation thermique d’un logement\u00A0?',
    fullExplanation: 'ra-g1-5',
    simpleExplanation: 'rs-g1-5',
    correctAnswers: ['c'],
    choices: [
      {
        name: 'a',
        label: 'Le nombre de bouches d’extraction'
      },
      {
        name: 'b',
        label: 'Le nombre d’entrée d’air'
      },
      {
        name: 'c',
        label: 'Le type de ventilation'
      }
    ]
  },
  {
    id: 'g1-20',
    category: 'g',
    subject: 'g1',
    label: 'Dans le cadre de la réalisation d\'une évaluation thermique, doit-on en général se renseigner sur la date de construction de la maison ou de l\'appartement\u00A0?',
    fullExplanation: 'ra-g1-6',
    simpleExplanation: 'rs-g1-6',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Non'
      },
      {
        name: 'b',
        label: 'Oui'
      }
    ]
  },
  {
    id: 'g1-21',
    category: 'g',
    subject: 'g1',
    label: 'Lorsque je réalise une évaluation thermique d’un logement, ai-je besoin de recueillir l’année de construction de ce dernier\u00A0?',
    fullExplanation: 'ra-g1-6',
    simpleExplanation: 'rs-g1-6',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Non'
      },
      {
        name: 'b',
        label: 'Oui'
      }
    ]
  },
  {
    id: 'g1-22',
    category: 'g',
    subject: 'g1',
    label: 'L’année de construction est-elle une donnée d’entrée de l’évaluation thermique d’un logement, que je dois chercher à recueillir\u00A0?',
    fullExplanation: 'ra-g1-6',
    simpleExplanation: 'rs-g1-6',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Non'
      },
      {
        name: 'b',
        label: 'Oui'
      }
    ]
  },
  {
    id: 'g1-23',
    category: 'g',
    subject: 'g1',
    label: 'Lorsque je rentre l’année de construction du logement dans un logiciel d’évaluation thermique, cela a-t-il un impact sur le résultat de l’évaluation\u00A0?',
    fullExplanation: 'ra-g1-6',
    simpleExplanation: 'rs-g1-6',
    correctAnswers: ['b'],
    choices: [
      {
        name: 'a',
        label: 'Non'
      },
      {
        name: 'b',
        label: 'Oui'
      }
    ]
  }
]

export default questions
