<template>
  <div class="correctif">
    <div class="question">
      <div class="tag"
         :class="{ success: isQuestionValid, fail: !isQuestionValid }">
         Question {{ currentQuestionIndex + 1 }}
      </div>
      <div class="label">
        {{ currentQuestion.label }}
      </div>
      <div class="answers">
        <div v-for="choice in currentQuestion.choices"
           :key="choice.name"
           :class="{ correct: isChoiceValid(choice.name) }"
           class="answer">
          {{ choice.label }}
        </div>
      </div>
    </div>
    <div class="correction-bloc">
      <div class="content" v-html="currentCorrectif.content"></div>
    </div>
    <div class="revision-bloc" v-if="currentRevision">
      <h2>
        Fiche de révision
      </h2>
      <a :href="`${revisionsBaseUrl}${currentRevision.name}.pdf`"
         target="_blank"
         class="doc-link">
        {{ currentRevision.label }}
      </a>
    </div>
    <div class="nav">
      <router-link :to="{ name: 'correctif', params: { questionId: prevQuestionId } }" class="button">Précédent</router-link>
      <router-link :to="{ name: 'correctif', params: { questionId: nextQuestionId } }" class="button">Suivant</router-link>
    </div>
    <router-link class="button return-button" to="/">retourner a l'accueil</router-link>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import revisions from '../../datas/revisions'
import corrections from '../../datas/corrections'

export default {
  name: 'Correctif',
  computed: {
    ...mapState(['revisionsBaseUrl']),
    ...mapState('test', ['questions', 'testAnswer']),
    ...mapGetters('test', ['nbQuestionnaries']),
    currentQuestion () {
      return this.questions.filter(question => question.id === this.$route.params.questionId)[0]
    },
    currentQuestionIndex () {
      return this.questions.indexOf(this.currentQuestion)
    },
    nextQuestionId () {
      if (this.currentQuestionIndex >= this.questions.length - 1) {
        return this.questions[0].id
      } else {
        return this.questions[this.currentQuestionIndex + 1].id
      }
    },
    prevQuestionId () {
      if (this.currentQuestionIndex > 0) {
        return this.questions[this.currentQuestionIndex - 1].id
      } else {
        return this.questions[this.questions.length - 1].id
      }
    },
    answers () {
      return this.testAnswer
    },
    currentRevision () {
      return revisions.filter(revision => revision.name === this.currentQuestion.fullExplanation)[0]
    },
    currentCorrectif () {
      return corrections.filter(correctif => correctif.name === this.currentQuestion.simpleExplanation)[0]
    },
    isQuestionValid () {
      return this.answers[this.currentQuestion.id].length === this.currentQuestion.correctAnswers.length &&
        this.currentQuestion.correctAnswers.every(ans => this.answers[this.currentQuestion.id].includes(ans))
    }
  },
  methods: {
    isChoiceValid (choiceName) {
      return this.currentQuestion.correctAnswers.includes(choiceName)
    }
  }
}
</script>

<style lang=scss>
.correctif {
  padding: 15px;
  .question {
    .tag {
      margin-bottom: $spacer;
    }
    .label {
      text-align: left;
      font-weight: 900;
      font-size: 1.15rem;
      margin-bottom: $spacer;
    }
    .answers {
      margin-bottom: $spacer;
      .answer + .answer {
        margin-top: $spacer-md;
      }
      .answer {
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.16);
        border: 1px solid #c6c6c6;
        font-size: 0.95rem;
        padding: $spacer $spacer-lg;
        border-radius: $radius;
        position: relative;
        text-align: left;
        &:before {
          content: '';
          position: absolute;
          top: -1px;
          left: -1px;
          width: 6px;
          height: calc(100% + 2px);
          background-color: $error-color;
          transition: 0.2s ease-in width;
        }
        &.correct {
          border-color: $secondary-color;
          padding: $spacer-xl $spacer-lg;
          &:before {
            background-color: $secondary-color;
          }
        }
      }
    }
  }
  .correction-bloc {
    font-size: 0.95rem;
    text-align: left;
    margin-bottom: $spacer-xl;
  }
  .revision-bloc {
    margin-bottom: 60px;
    h2 {
      font-weight: 900;
      text-align: left;
      margin: 0 0 $spacer-md;
      font-size: 1.1rem;
    }
  }
  .nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $spacer-md;
    .button {
      background-color: $primary-color;
      color: #fff;
      width: 162px;
      height: 48px;
      border-radius: $radius;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.9rem;
    }
  }
  .return-button {
    margin: 0;
    width: 100%;
  }
}
</style>
