const corrections = [
  {
    name: 'rs-d1-1',
    content: 'Un pare-vapeur est un matériau (de type membrane ou panneau) qui ne laisse pas (ou peu) passer la vapeur d’eau. Son rôle est de protéger les isolants sensibles à l’humidité, d’un risque de condensation de la vapeur d’eau qui peut migrer dans l’isolation pendant la période de chauffage. Il se place toujours du côté chaud de la paroi.'
  },
  {
    name: 'rs-d1-2',
    content: 'Le Pare vapeur d’un isolant doit toujours être placé côté chaud de la paroi.'
  },
  {
    name: 'rs-d1-3',
    content: 'Une plaque de plâtre n’est pas étanche à la vapeur d’eau, elle ne peut pas jouer le rôle de pare-vapeur.'
  },
  {
    name: 'rs-d2-1',
    content: 'L’activité humaine (cuisine, vaisselle, douche, respiration …) à l’intérieur d’un bâtiment génère de l’humidité sous forme de vapeur d’eau, ce qui a pour effet d’augmenter le taux d’humidité dans l’air intérieur du bâtiment. On parle d’hygrométrie ! Plus l’air contient de la vapeur d’eau, plus l’hygrométrie de l’air est importante.'
  },
  {
    name: 'rs-d2-2',
    content: 'La vapeur d’eau (gaz) contenue dans l’air se transforme en eau (liquide) lorsque l’air qui la contient atteint son point de rosée.'
  },
  {
    name: 'rs-d2-3',
    content: 'Dans un logement, toutes les parois qui sont froides peuvent générer des risques de condensation sur leur surface. En effet, il y a un risque que ces parois froides descendent en dessous du point de rosée de l’air intérieur.'
  },
  {
    name: 'rs-d2-4',
    content: 'La vapeur d’eau est un gaz, il est invisible dans l’air. Dès que l’on voit de la fumée ou de la brume, il s’agit de gouttelettes d’eau liquide en suspension dans l’air.'
  },
  {
    name: 'rs-d3-1',
    content: 'Une chaudière à condensation condense quand la température de l’eau de retour du chauffage est froide, par exemple inférieure à 58°C pour le gaz et 47°C pour le fioul. Pour qu’elle puisse condenser sur toute la période de chauffe, il faut donc impérativement que les émetteurs soient prévus pour fonctionner à basse température (cela demande un dimensionnement précis). Si ce n’est pas le cas le système fonctionne, mais pas avec un rendement optimum, et la durée de vie peut être affectée.'
  },
  {
    name: 'rs-d3-2',
    content: 'Le sur dimensionnement d’un appareil de chauffage par rapport aux déperditions du bâti peut générer une surconsommation d’énergie.'
  },
  {
    name: 'rs-d3-3',
    content: 'Un isolant humide va en général voire ses performances thermiques diminuer, sauf s’il est complètement insensible à l’eau comme le verre cellulaire par exemple.'
  },
  {
    name: 'rs-d4-1',
    content: 'Lors de travaux d’isolation des parois opaques ou vitrées, il est impératif en parallèle, soit de prescrire une ventilation, soit de s’assurer que la ventilation en place est suffisante. A défaut, il y a un risque important de condensation sur les surfaces de parois les plus froides (ponts thermiques, menuiserie non remplacée, …)'
  },
  {
    name: 'rs-d4-2',
    content: 'Une ventilation mécanique ne peut pas fonctionner si l’air ne peut pas transiter des pièces sèches vers les pièces humides. Donc il faut prévoir le détalonnage ou l’installation de grilles de transferts dans les portes intérieures.'
  },
  {
    name: 'rs-d4-3',
    content: 'Un système de ventilation double flux avec récupération de chaleur est plus efficace si le bâtiment est étanche à l\'air car le système ne sera pas court-circuité par les entrées d’air parasites liées aux infiltrations.'
  },
  {
    name: 'rs-d5-1',
    content: 'Tout air qui s’infiltre ou qui s’exfiltre peut provoquer des phénomènes de condensation dès lors qu’il y a une différence de température entre l’intérieur et l’extérieur. En effet, lorsque de l’air s’infiltre, il refroidi des zones sur lesquelles l’air chaud intérieur peut condenser. Et lorsque l’air chaud s’exfiltre, il condense en croisant des matériaux froids à mesure qu’il progresse vers l’extérieur.'
  },
  {
    name: 'rs-d5-2',
    content: 'Lorsqu’un bâtiment présente des traces d’humidité liées à de la condensation (piquetage), une des premières actions à conseiller est l’installation d’un système de ventilation mécanique, si le bâtiment n’en est pas équipé.'
  },
  {
    name: 'rs-d6-1',
    content: 'Une caméra thermique enregistre les rayonnements infrarouges (ondes de chaleur) émis par les corps et qui varient en fonction de leur température. C’est donc la température de surface des objets qu’elle permet d’interpréter. Les ponts thermiques (discontinuité d’isolation par exemple) peuvent générer des différences de température de surface au niveau des parois (dans certaines conditions). La caméra thermique est donc un moyen de repérer ceux-ci.'
  },
  {
    name: 'rs-d6-2',
    content: 'Selon le DTU pose de menuiseries, la réalisation des appuis des baies doit être faite avant la pose des menuiseries.'
  }
]

export default corrections
