var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "footer",
    [
      _c(
        "router-link",
        {
          staticClass: "profil-link",
          class: { activ: _vm.$route.name === "home" },
          attrs: { to: "/" },
        },
        [
          _c("span", { staticClass: "ico" }),
          _c("span", { staticClass: "label" }, [_vm._v("Accueil")]),
        ]
      ),
      _c(
        "router-link",
        {
          staticClass: "revision-link",
          class: { activ: _vm.$route.name === "revision" },
          attrs: { to: "/revision" },
        },
        [
          _c("span", { staticClass: "ico" }),
          _c("span", { staticClass: "label" }, [_vm._v("Révision")]),
        ]
      ),
      _c(
        "router-link",
        {
          staticClass: "dates-link",
          class: { activ: _vm.$route.name === "dates-examen" },
          attrs: { to: "/dates-examen" },
        },
        [
          _c("span", { staticClass: "ico" }),
          _c("span", { staticClass: "label" }, [_vm._v("Dates d'examen")]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }