var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "correctif" },
    [
      _c("div", { staticClass: "question" }, [
        _c(
          "div",
          {
            staticClass: "tag",
            class: { success: _vm.isQuestionValid, fail: !_vm.isQuestionValid },
          },
          [_vm._v(" Question " + _vm._s(_vm.currentQuestionIndex + 1) + " ")]
        ),
        _c("div", { staticClass: "label" }, [
          _vm._v(" " + _vm._s(_vm.currentQuestion.label) + " "),
        ]),
        _c(
          "div",
          { staticClass: "answers" },
          _vm._l(_vm.currentQuestion.choices, function (choice) {
            return _c(
              "div",
              {
                key: choice.name,
                staticClass: "answer",
                class: { correct: _vm.isChoiceValid(choice.name) },
              },
              [_vm._v(" " + _vm._s(choice.label) + " ")]
            )
          }),
          0
        ),
      ]),
      _c("div", { staticClass: "correction-bloc" }, [
        _c("div", {
          staticClass: "content",
          domProps: { innerHTML: _vm._s(_vm.currentCorrectif.content) },
        }),
      ]),
      _vm.currentRevision
        ? _c("div", { staticClass: "revision-bloc" }, [
            _c("h2", [_vm._v(" Fiche de révision ")]),
            _c(
              "a",
              {
                staticClass: "doc-link",
                attrs: {
                  href: `${_vm.revisionsBaseUrl}${_vm.currentRevision.name}.pdf`,
                  target: "_blank",
                },
              },
              [_vm._v(" " + _vm._s(_vm.currentRevision.label) + " ")]
            ),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "nav" },
        [
          _c(
            "router-link",
            {
              staticClass: "button",
              attrs: {
                to: {
                  name: "correctif",
                  params: { questionId: _vm.prevQuestionId },
                },
              },
            },
            [_vm._v("Précédent")]
          ),
          _c(
            "router-link",
            {
              staticClass: "button",
              attrs: {
                to: {
                  name: "correctif",
                  params: { questionId: _vm.nextQuestionId },
                },
              },
            },
            [_vm._v("Suivant")]
          ),
        ],
        1
      ),
      _c(
        "router-link",
        { staticClass: "button return-button", attrs: { to: "/" } },
        [_vm._v("retourner a l'accueil")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }