var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "sub-header" }, [
    _c(
      "a",
      {
        staticClass: "return",
        on: {
          click: function ($event) {
            return _vm.$emit("return")
          },
        },
      },
      [
        _c("a", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showReturnArrow,
              expression: "showReturnArrow",
            },
          ],
        }),
        _vm.returnLabel
          ? _c("span", [_vm._v(_vm._s(_vm.returnLabel))])
          : _vm._e(),
      ]
    ),
    _c("div", { staticClass: "title" }, [
      _c("img", {
        attrs: { src: require(`@/assets/pictos/${_vm.picto}-white.svg`) },
      }),
      _c("p", [_vm._v(" " + _vm._s(_vm.title) + " ")]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }