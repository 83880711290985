var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "image-title" }, [
    _c("p", {
      staticClass: "title",
      domProps: { innerHTML: _vm._s(_vm.title) },
    }),
    _c("img", { attrs: { src: _vm.image } }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }