const revisions = [
  {
    name: 'ra-a1-1',
    label: 'Les ressources énergétiques non renouvelable mondiales'
  },
  {
    name: 'ra-a1-2',
    label: 'Les ressources énergétiques non renouvelable mondiales'
  },
  /* {
    name: 'ra-a1-3',
    label: 'L’objectif des 500 000 rénovation de logement en France'
  }, */
  {
    name: 'ra-a1-4',
    label: 'Les énergies fossiles'
  },
  {
    name: 'ra-a1-5',
    label: 'Répartition de la consommation d’énergie et des émissions de gaz à effet de serre par secteur en France'
  },
  {
    name: 'ra-a1-6',
    label: 'Répartition de la consommation d’énergie et des émissions de gaz à effet de serre par secteur en France'
  },
  {
    name: 'ra-a1-8',
    label: 'Répartition de la consommation d’énergie et des émissions de gaz à effet de serre par secteur en France'
  },
  {
    name: 'ra-a2-1',
    label: 'Le FACTEUR 4'
  },
  {
    name: 'ra-a2-3',
    label: 'La Valeur Verte d’un bâtiment'
  },
  {
    name: 'ra-a2-4',
    label: 'Le dispositif des Certificats d’Economies d’Energie (CEE)'
  },
  {
    name: 'ra-a2-mpr-1',
    label: 'MaPrimeRenov\''
  },
  {
    name: 'ra-a2-8',
    label: 'Comment devenir une entreprise RGE ?'
  },
  {
    name: 'ra-a3-1',
    label: 'La Stratégie Nationale Bas-Carbone (SNBC)'
  },
  {
    name: 'ra-a4-1',
    label: 'Evolution du prix des énergies'
  }
]

export default revisions
