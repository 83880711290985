var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "step-question" }, [
    _c(
      "div",
      { staticClass: "count-wrapper" },
      [
        _vm._t("counter", function () {
          return [
            _vm._v(
              " " +
                _vm._s(_vm.currentStepIndex + 1) +
                " / " +
                _vm._s(_vm.nbSteps) +
                " "
            ),
          ]
        }),
      ],
      2
    ),
    _c(
      "div",
      { staticClass: "title" },
      [
        _vm._t("title", function () {
          return [_c("p", { domProps: { innerHTML: _vm._s(_vm.title) } })]
        }),
      ],
      2
    ),
    _c("div", { staticClass: "question-wrapper" }, [_vm._t("question")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }